// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupPostSub = (props) => {
  const navigate = useNavigate();
  const {
    platform,
    searchText,
    setSearchText,
    closeModal,
    onConfirmUnLink,
    linkPostFB,
    linkPostTiktok,
    onShowGuide,
    subDetail,
    onGuideGetLinkFB,
  } = props;
  const [isShowCopy, setIsShowCopy] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isShowGuide, setIsShowGuide] = useState(true);
  const [rejectReason, setRejectReason] = useState("");

  useEffect(() => {
    switch (platform) {
      case "Facebook":
        if (subDetail?.linkedSocialData?.linkedFacebookStatus === 3) {
          setIsReject(true);
          setRejectReason(subDetail?.linkedSocialData?.facebookReason);
        } else if (subDetail?.linkedSocialData?.linkedFacebookStatus === 2) {
          setIsShowGuide(false);
        }
        break;

      case "Tiktok":
        if (subDetail?.linkedSocialData?.linkedTiktokStatus === 3) {
          setIsReject(true);
          setRejectReason(subDetail?.linkedSocialData?.tiktokReason);
        } else if (subDetail?.linkedSocialData?.linkedTiktokStatus === 2) {
          setIsShowGuide(false);
        }
        break;

      default:
        break;
    }

    return () => {
      setIsReject(false);
      setRejectReason("");
    };
  }, []);

  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>
                LIÊN KẾT {isReject ? "BỊ TỪ CHỐI" : platform?.toUpperCase()}
              </h3>
              <p>
                <b>
                  Copy link video dự thi đã đăng trên {platform} và điền vào ô
                  bên dưới để được cộng điểm từ lượt like và share.
                </b>
              </p>
              <p>Link liên kết của bạn :</p>
              <div className={`form-field hasIcon`}>
                <img
                  src="assets/img/icon/i-copy.svg"
                  alt="text-info "
                  className="i-copy"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(searchText)
                      .then(() => {
                        console.log("Copied the text: " + searchText);
                      })
                      .catch((err) => {
                        console.error("Failed to copy text: ", err);
                      })
                  }
                />

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="linkUrl"
                    name="linkUrl"
                    placeholder={`http://${platform}.com/123456789...`}
                    value={searchText}
                    style={{ paddingRight: 30 }}
                  />
                </div>
              </div>

              {!!rejectReason && (
                <p className="text-red">
                  Link liên kết bị từ chối: {rejectReason}
                </p>
              )}

              {isShowGuide && (
                <p>
                  Chi tiết cách lấy link bài dự thi xin hãy{" "}
                  <a className="guideLink" onClick={onGuideGetLinkFB}>
                    xem ở đây
                  </a>
                  .
                </p>
              )}

              {!!rejectReason && (
                <p>Ấn “Hủy link” để gỡ link này và điền link Video mới.</p>
              )}
            </div>
            <div className="group-btn-inline">
              <button
                onClick={() => {
                  closeModal("");
                }}
                type="button"
                className="btn btn-warning btn-block"
              >
                <a>Đóng</a>
              </button>
              <button
                onClick={() => onConfirmUnLink()}
                type="button"
                className="btn btn-primary btn-block"
              >
                <a>Huỷ link</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupPostSub;
