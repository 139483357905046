import actions from "./actions";
import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import {
  CREATE_SUBMISSION,
  GET_SASTOKEN,
  UPLOAD_VIDEO,
  GET_SINGERS,
  DELETE_SUBMISSION,
  CREATE_SETTINGS,
  GET_DETAIL_SUB,
  GET_MY_SUB,
  GET_LIST_SUB,
  CREATE_SUBMISSION_TIKTOK,
  GET_MY_POINTS,
  GET_TOP_SUB,
  ADD_OR_UN_SOCIAL_LINK,
  GET_BILL_POINT,
  GET_SETTINGS,
  LIKE_OR_DISLIKE,
  GET_TOP_10_WEB,
  GET_TOP_10_ALL,
  GET_HIGHEST_POINT,
  GET_FULL_LINK,
  PUBLIC_VIDEO,
  GET_PUBLIC_VIDEO_URL
} from "./constants";
import {
  addOrUnSocialLinkApi,
  createSettingsApi,
  createSubmissionApi,
  createSubmissionTiktokApi,
  deleteSubmissionApi,
  getBillPointApi,
  getDetailSubApi,
  getHighestPointApi,
  getListSubApi,
  getMyPointsApi,
  getMySubApi,
  getSasTokenApi,
  getSettingsApi,
  getSingersApi,
  getTopSubApi,
  likeOrDislikeApi,
  getTop10AllApi,
  getTop10WebApi,
  uploadVideoApi,
  getFullLinkApi,
  publicVideoDetailApi,
  getPublicVideoApi,
} from "api/duet";

export function* getSasTokenSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getSasTokenApi, payload);
    yield put(actions.GET_SASTOKEN.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_SASTOKEN.failure(err));
  }
}

export function* uploadVideoSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(uploadVideoApi, payload);
    yield put(actions.UPLOAD_VIDEO.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.UPLOAD_VIDEO.failure(err));
  }
}

export function* createSubmissionSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(createSubmissionApi, payload);
    yield put(actions.CREATE_SUBMISSION.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.CREATE_SUBMISSION.failure(err));
  }
}

export function* createSubmissionTiktokSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(createSubmissionTiktokApi, payload);
    yield put(actions.CREATE_SUBMISSION_TIKTOK.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.CREATE_SUBMISSION_TIKTOK.failure(err));
  }
}

export function* getSingersSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getSingersApi, payload);
    yield put(actions.GET_SINGERS.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_SINGERS.failure(err));
  }
}

export function* deleteSubmissionSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(deleteSubmissionApi, payload);
    yield put(actions.DELETE_SUBMISSION.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.DELETE_SUBMISSION.failure(err));
  }
}

export function* getSettingsSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getSettingsApi, payload);
    yield put(actions.GET_SETTINGS.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_SETTINGS.failure(err));
  }
}

export function* createSettingsSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(createSettingsApi, payload);
    yield put(actions.CREATE_SETTINGS.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.CREATE_SETTINGS.failure(err));
  }
}

export function* getMySubSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getMySubApi, payload);
    yield put(actions.GET_MY_SUB.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_MY_SUB.failure(err));
  }
}

export function* getMyPointsSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getMyPointsApi, payload);
    yield put(actions.GET_MY_POINTS.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_MY_POINTS.failure(err));
  }
}

export function* getDetailSubSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getDetailSubApi, payload);
    yield put(actions.GET_DETAIL_SUB.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_DETAIL_SUB.failure(err));
  }
}

export function* getListSubSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getListSubApi, payload);
    yield put(actions.GET_LIST_SUB.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_LIST_SUB.failure(err));
  }
}

export function* getTopSubSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getTopSubApi, payload);
    yield put(actions.GET_TOP_SUB.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_TOP_SUB.failure(err));
  }
}

export function* addOrUnSocialLinkSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(addOrUnSocialLinkApi, payload);
    yield put(actions.ADD_OR_UN_SOCIAL_LINK.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.ADD_OR_UN_SOCIAL_LINK.failure(err));
  }
}

export function* getBillPointSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getBillPointApi, payload);
    yield put(actions.GET_BILL_POINT.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_BILL_POINT.failure(err));
  }
}

export function* likeOrDislikeSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(likeOrDislikeApi, payload);
    yield put(actions.LIKE_OR_DISLIKE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.LIKE_OR_DISLIKE.failure(err));
  }
}

export function* getTop10AllSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getTop10AllApi, payload);
    yield put(actions.GET_TOP_10_ALL.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_TOP_10_ALL.failure(err));
  }
}

export function* getTop10WebSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getTop10WebApi, payload);
    yield put(actions.GET_TOP_10_WEB.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_TOP_10_WEB.failure(err));
  }
}

export function* getHighestPointSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getHighestPointApi, payload);
    yield put(actions.GET_HIGHEST_POINT.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_HIGHEST_POINT.failure(err));
  }
}

export function* getFullLinkSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getFullLinkApi, payload);
    yield put(actions.GET_FULL_LINK.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_FULL_LINK.failure(err));
  }
}

export function* publicVideoSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(publicVideoDetailApi, payload);
    yield put(actions.PUBLIC_VIDEO.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.PUBLIC_VIDEO.failure(err));
  }
}

export function* getPublicVideoSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getPublicVideoApi, payload);
    yield put(actions.GET_PUBLIC_VIDEO_URL.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_PUBLIC_VIDEO_URL.failure(err));
  }
}

function* watchCommon() {
  yield takeLatest(GET_SASTOKEN.request, getSasTokenSaga);
  yield takeLatest(UPLOAD_VIDEO.request, uploadVideoSaga);
  yield takeLatest(CREATE_SUBMISSION.request, createSubmissionSaga);
  yield takeLatest(CREATE_SUBMISSION_TIKTOK.request, createSubmissionTiktokSaga);
  yield takeLatest(GET_SINGERS.request, getSingersSaga);
  yield takeLatest(DELETE_SUBMISSION.request, deleteSubmissionSaga);
  yield takeLatest(GET_SETTINGS.request, getSettingsSaga);
  yield takeLatest(CREATE_SETTINGS.request, createSettingsSaga);
  yield takeLatest(GET_MY_SUB.request, getMySubSaga);
  yield takeLatest(GET_MY_POINTS.request, getMyPointsSaga);
  yield takeLatest(GET_DETAIL_SUB.request, getDetailSubSaga);
  yield takeLatest(GET_LIST_SUB.request, getListSubSaga);
  yield takeEvery(GET_TOP_SUB.request, getTopSubSaga);
  yield takeLatest(ADD_OR_UN_SOCIAL_LINK.request, addOrUnSocialLinkSaga);
  yield takeLatest(GET_BILL_POINT.request, getBillPointSaga);
  yield takeLatest(LIKE_OR_DISLIKE.request, likeOrDislikeSaga);
  yield takeLatest(GET_TOP_10_WEB.request, getTop10WebSaga);
  yield takeLatest(GET_TOP_10_ALL.request, getTop10AllSaga);
  yield takeLatest(GET_HIGHEST_POINT.request, getHighestPointSaga);
  yield takeLatest(GET_FULL_LINK.request, getFullLinkSaga);
  yield takeLatest(PUBLIC_VIDEO.request, publicVideoSaga);
  yield takeLatest(GET_PUBLIC_VIDEO_URL.request, getPublicVideoSaga);

}

export default function* rootChild() {
  yield fork(watchCommon);
}
