/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
// @ts-nocheck

import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Popup } from "./components";
import ReactPaginate from "react-paginate";
import { ReactUtils } from "utils";
import { LINK_VIDEO } from "constants/common";
import TheBanner from "layout/TheBanner/TheBanner";
import moment from "moment";
import { formatNumber } from "utils/common";
import { SliderComponent } from "pages/home/components";
import CommingSoonCountDown from "components/commingsoon-count-down";
import TheFooter from "layout/TheFooter";
import reactUtils from "utils/reactUtils";

const PrizeView = (props) => {
  const navigate = useNavigate();
  const commingSoon = JSON.parse(localStorage.getItem("commingSoon"));
  const {
    listWinnerSecond,
    listWinnerThird,
    listWinnerSpecial,
    listPhaseSpecial,
    listPhaseSecond,
    listPhaseThird,
    pagesSecond,
    pagesSpecial,
    pagesThird,
    onChangePageSecond,
    onChangePageThird,
    onChangePageSpecial,
    onChangePrizeTypeSpecial,
    onChangePrizeTypeSecond,
    onChangePrizeTypeThird,
    prizeTypeSpecial,
    prizeTypeSecond,
    prizeTypeThird,
    onSearchCodeSpecial,
    onSearchCodeSecond,
    onSearchCodeThird,
    searchCodeSecond,
    searchCodeThird,
    searchCodeSpecial,
    setSearchCodeSpecial,
    setSearchCodeSecond,
    setSearchCodeThird,
    listWinnerSpeciaSwiper,
    listPicture,
    pageOffsetSecond,
    pageOffsetThird,
    pageOffsetSpecial,
    remaining,
    listBannerSpecial,
  } = props;

  const handlePageChangeSecond = (event) => {
    onChangePageSecond(event.selected);
    console.log(event);
    // TODO Only change displayed selected page
    // when its content is loaded in useEffect.
    // props.setPageOffset(event.selected);
  };

  const handlePageChangeThird = (event) => {
    onChangePageThird(event.selected);
    console.log(event);
    // TODO Only change displayed selected page
    // when its content is loaded in useEffect.
    // props.setPageOffset(event.selected);
  };

  const handlePageChangeSpecial = (event) => {
    onChangePageSpecial(event.selected);
    console.log(event);
    // TODO Only change displayed selected page
    // when its content is loaded in useEffect.
    // props.setPageOffset(event.selected);
  };

  const getRemaining = (id) => {
    if (remaining?.length > 0) {
      let dataRemaining = remaining.find((item) => item?.id === id);
      return reactUtils.formatNumber(dataRemaining?.remaining) || "";
    }
  };

  return (
    <>
      <TheBanner />
      <section className="prizes bgr-green">
        <div className="group-action container d-none">
          <div className="group-btn-inline">
            <button
              type="button"
              className="btn btn-warning btn-block"
              onClick={() => {
                navigate("/");
              }}
            >
              <a href="#joinNow">SĂN THẺ CÀO</a>
            </button>
            <button
              type="button"
              className={`btn btn-primary  btn-block ${
                commingSoon ? "disabled" : ""
              }`}
              onClick={() => {
                navigate("/hatcungthantuong");
              }}
            >
              <a>THAM GIA DUET</a>
            </button>
          </div>
          <CommingSoonCountDown />
        </div>

        <h2 className="name-block ">
          <img
            className="img-full d-none-mb"
            src="assets/img/Title-dstt.png"
            alt=""
          />
          <div className="container d-none">
            <img
              className="img-full"
              src="assets/img/Title-dstt-mb.png"
              alt=""
            />
          </div>
        </h2>
        {/* <div className="container group-video">
          <div className="videoWrapper"> */}
        {/* <iframe
              width="100%"
              height="100%"
              src={LINK_VIDEO}
              title="MUA HaoHao - HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> */}
        {/* <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FAcecookVietnam.vn%2Fvideos%2F1368508170608817%2F&show_text=false&width=560&t=0"
              width="100%"
              height="100%"
              scrolling="no"
              frameborder="0"
              // allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              // allowFullScreen="true"
            ></iframe> */}
        {/* </div>
        </div> */}
        <div className="container">
          {/* {!listWinnerSpeciaSwiper.length && (
            <>
              <h2 className="name-block title-slider-sprize">
                <img
                  className="img-full d-none-mb"
                  src="assets/img/title-slider-sprize.png"
                  alt=""
                />
                <img
                  className="img-full d-none"
                  src="assets/img/title-slider-sprize-mb.png"
                  alt=""
                />
              </h2>

              <SliderComponent listWinnerSpecial={listWinnerSpeciaSwiper} />
              <br />
              <br />
              <br />
            </>
          )} */}
          {/* <div className="line"></div> */}
          <h2 className="name-block title-slider-sprize">
            <img
              className="img-full d-none-mb"
              src="assets/img/title-slider-sprize.png"
              alt=""
            />
            <img
              className="img-full d-none"
              src="assets/img/title-slider-sprize-mb.png"
              alt=""
            />
          </h2>
          {listBannerSpecial.length > 0 && (
            <>
              {/* <SliderComponent
                listBannerSpecial={listBannerSpecial.slice(0, 4)}
              /> */}
              <SliderComponent listWinnerSpecial={listBannerSpecial} />
            </>
          )}
          {/* {props?.listPicture.length > 0 && (
            <>
              <SliderComponent listPicture={props?.listPicture} />
              <div className="line"></div>
            </>
          )} */}
          <br />
          <div className="table-sprize">
            <div className="box-flex">
              <div className="form-group">
                <select
                  className="form-control"
                  id="prize_type_1"
                  value={prizeTypeSpecial}
                  defaultValue={0}
                  onChange={(event) => {
                    console.log(event.target.value);
                    onChangePrizeTypeSpecial(event.target.value);
                  }}
                >
                  {listPhaseSpecial?.map((elm) => {
                    return (
                      <option value={elm}>Đợt {elm > 0 ? elm : ""}</option>
                    );
                  })}
                </select>
              </div>
              <div className="search-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control "
                    id="codeSpecial"
                    name="phone"
                    placeholder="Nhập số điện thoại hoặc mã thẻ cào"
                    min="0"
                    maxLength={10}
                    value={searchCodeSpecial}
                    onKeyDown={(e) => {
                      if (e?.keyCode === 13) {
                        const el = document.querySelector("#codeSpecial");
                        if (el.value?.trim()?.length > 0) {
                          onSearchCodeSpecial(el.value);
                        }
                      }
                    }}
                    onChange={(e) => {
                      setSearchCodeSpecial(e?.target?.value);
                      if (e.target.value === "") {
                        onSearchCodeSpecial("", true);
                      }
                    }}
                  />
                </div>

                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  onClick={() => {
                    const el = document.querySelector("#codeSpecial");
                    if (el.value?.trim()?.length > 0) {
                      onSearchCodeSpecial(el.value);
                    }
                  }}
                >
                  <a>
                    <span class="i-search"></span>
                    <span>Tìm kiếm</span>
                  </a>
                </button>
              </div>
            </div>

            <ul className="list-code">
              <li>
                <span>Họ và tên</span>
                <span>Số điện thoại</span>
                <span className="d-none-mb">Mã thẻ cào</span>
                <span className="d-none-mb">Ngày quay số</span>
              </li>
              {listWinnerSpecial?.length > 0 ? (
                <ul className="list-scroll">
                  {listWinnerSpecial?.length > 0 &&
                    listWinnerSpecial?.map((item, index) => {
                      return (
                        <li>
                          <span>{item?.userName || "Đang chờ cập nhật"}</span>
                          <span>{item?.phoneNumber}</span>
                          <span className="d-none-mb">{item?.code}</span>
                          <span className="d-none-mb">
                            {moment(item?.winTime)
                              .format("DD/MM/YYYY")
                              .toString()}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className="no-date">
                  <p>Chưa có dữ liệu.</p>
                </div>
              )}
            </ul>

            {/* {listWinnerSpecial?.length > 0 && (
              <ReactPaginate
                previousLabel=""
                nextLabel=""
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pagesSpecial || 0}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageChangeSpecial}
                containerClassName="pagination container"
                activeClassName="active"
                forcePage={pageOffsetSpecial}
              />
            )} */}

            {/* <div className="pagination container">
          <a href="#">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.70711 0.292893C6.09763 0.683416 6.09763 1.31658 5.70711 1.7071L2.41421 4.99999L5.70711 8.29288C6.09763 8.6834 6.09763 9.31657 5.70711 9.70709C5.31658 10.0976 4.68342 10.0976 4.29289 9.70709L0.292894 5.7071C-0.0976309 5.31657 -0.0976309 4.68341 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976309 5.31658 -0.0976309 5.70711 0.292893Z"
                fill="#6B7280"
              />
            </svg>
          </a>
          {props?.page?.length > 0 &&
            props?.page?.map((item, index) => {
              return (
                <a
                  href="javascript:;"
                  className={props?.pageActive === index ? "active" : ""}
                  onClick={() => {
                    props.onChangePage(index);
                  }}
                >
                  {item}
                </a>
              );
            })}

          <a href="javascript:;">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.2929L3.58579 5.00001L0.292893 1.70712C-0.0976315 1.3166 -0.0976315 0.683432 0.292893 0.292909C0.683417 -0.0976162 1.31658 -0.0976163 1.70711 0.292909L5.70711 4.2929C6.09763 4.68343 6.09763 5.31659 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292893 9.70711Z"
                fill="#6B7280"
              />
            </svg>
          </a>
        </div> */}
          </div>
          <div className="line"></div>
          <img
            className="name-table"
            src="assets/img/table-title-2.png"
            alt=""
          />

          <div className="table-sprize">
            <div className="box-flex">
              <div className="form-group">
                <select
                  className="form-control"
                  id="prize_type_2"
                  value={prizeTypeSecond}
                  defaultValue={0}
                  onChange={(event) => {
                    console.log(event.target.value);
                    onChangePrizeTypeSecond(event.target.value);
                  }}
                >
                  {listPhaseSecond?.map((elm) => {
                    return (
                      <option value={elm}>Đợt {elm > 0 ? elm : ""}</option>
                    );
                  })}
                </select>
              </div>
              <div className="search-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control "
                    id="codeSecond"
                    name="phone"
                    placeholder="Nhập số điện thoại hoặc mã thẻ cào"
                    min="0"
                    maxLength={10}
                    value={searchCodeSecond}
                    onKeyDown={(e) => {
                      if (e?.keyCode === 13) {
                        const el = document.querySelector("#codeSecond");
                        if (e.target?.value?.trim()?.length > 0) {
                          onSearchCodeSecond(el.value);
                        }
                      }
                    }}
                    onChange={(e) => {
                      setSearchCodeSecond(e?.target?.value);
                      if (e.target.value === "") {
                        onSearchCodeSecond("", true);
                      }
                    }}
                  />
                </div>

                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  onClick={() => {
                    const el = document.querySelector("#codeSecond");
                    if (el.value?.trim()?.length > 0) {
                      onSearchCodeSecond(el.value);
                    }
                  }}
                >
                  <a>
                    <span class="i-search"></span>
                    <span>Tìm kiếm</span>
                  </a>
                </button>
              </div>
            </div>

            <ul className="list-code">
              <li>
                <span>Họ và tên</span>
                <span>Số điện thoại</span>
                <span className="d-none-mb">Mã thẻ cào</span>
                <span className="d-none-mb">Ngày quay số</span>
              </li>
              {listWinnerSecond?.length > 0 ? (
                <ul className="list-scroll">
                  {listWinnerSecond?.length > 0 &&
                    listWinnerSecond?.map((item, index) => {
                      return (
                        <li>
                          <span>{item?.userName || "Đang chờ cập nhật"}</span>
                          <span>{item?.phoneNumber}</span>
                          <span className="d-none-mb">{item?.code}</span>
                          <span className="d-none-mb">
                            {moment(item?.winTime)
                              .format("DD/MM/YYYY")
                              .toString()}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className="no-date">
                  <p>Chưa có dữ liệu.</p>
                </div>
              )}
            </ul>
            {console.log("pagesSecond >>>", pagesSecond)}
            {listWinnerSecond?.length > 0 && (
              <ReactPaginate
                previousLabel=""
                nextLabel=""
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pagesSecond || 0}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageChangeSecond}
                containerClassName="pagination container"
                activeClassName="active"
                forcePage={pageOffsetSecond}
              />
            )}

            {/* <div className="pagination container">
          <a href="#">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.70711 0.292893C6.09763 0.683416 6.09763 1.31658 5.70711 1.7071L2.41421 4.99999L5.70711 8.29288C6.09763 8.6834 6.09763 9.31657 5.70711 9.70709C5.31658 10.0976 4.68342 10.0976 4.29289 9.70709L0.292894 5.7071C-0.0976309 5.31657 -0.0976309 4.68341 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976309 5.31658 -0.0976309 5.70711 0.292893Z"
                fill="#6B7280"
              />
            </svg>
          </a>
          {props?.page?.length > 0 &&
            props?.page?.map((item, index) => {
              return (
                <a
                  href="javascript:;"
                  className={props?.pageActive === index ? "active" : ""}
                  onClick={() => {
                    props.onChangePage(index);
                  }}
                >
                  {item}
                </a>
              );
            })}

          <a href="javascript:;">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.2929L3.58579 5.00001L0.292893 1.70712C-0.0976315 1.3166 -0.0976315 0.683432 0.292893 0.292909C0.683417 -0.0976162 1.31658 -0.0976163 1.70711 0.292909L5.70711 4.2929C6.09763 4.68343 6.09763 5.31659 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292893 9.70711Z"
                fill="#6B7280"
              />
            </svg>
          </a>
        </div> */}
          </div>
          <div className="line"></div>
          <img
            className="name-table"
            src="assets/img/table-title-3.png"
            alt=""
          />

          <div className="table-sprize">
            <div className="box-flex">
              <div className="form-group">
                <select
                  className="form-control"
                  id="prize_type_3"
                  value={prizeTypeThird}
                  defaultValue={0}
                  onChange={(event) => {
                    console.log(event.target.value);
                    onChangePrizeTypeThird(event.target.value);
                  }}
                >
                  {listPhaseThird?.map((elm) => {
                    return (
                      <option value={elm}>Đợt {elm > 0 ? elm : ""}</option>
                    );
                  })}
                </select>
              </div>
              <div className="search-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control "
                    id="codeThird"
                    name="phone"
                    placeholder="Nhập số điện thoại hoặc mã thẻ cào"
                    min="0"
                    maxLength={10}
                    value={searchCodeThird}
                    onKeyDown={(e) => {
                      if (e?.keyCode === 13) {
                        const el = document.querySelector("#codeThird");
                        if (e.target?.value?.trim()?.length > 0) {
                          onSearchCodeThird(el.value);
                        }
                      }
                    }}
                    onChange={(e) => {
                      setSearchCodeThird(e?.target?.value);
                      if (e.target.value === "") {
                        onSearchCodeThird("", true);
                      }
                    }}
                  />
                </div>

                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  onClick={() => {
                    const el = document.querySelector("#codeThird");
                    if (el.value?.trim()?.length > 0) {
                      onSearchCodeThird(el.value);
                    }
                  }}
                >
                  <a>
                    <span class="i-search"></span>
                    <span>Tìm kiếm</span>
                  </a>
                </button>
              </div>
            </div>

            <ul className="list-code">
              <li>
                <span>Họ và tên</span>
                <span>Số điện thoại</span>
                <span className="d-none-mb">Mã thẻ cào</span>
                <span className="d-none-mb">Ngày quay số</span>
              </li>
              {listWinnerThird?.length > 0 ? (
                <ul className="list-scroll">
                  {listWinnerThird?.length > 0 &&
                    listWinnerThird?.map((item, index) => {
                      return (
                        <li>
                          <span>{item?.userName || "Đang chờ cập nhật"}</span>
                          <span>{item?.phoneNumber}</span>
                          <span className="d-none-mb">{item?.code}</span>
                          <span className="d-none-mb">
                            {moment(item?.winTime)
                              .format("DD/MM/YYYY")
                              .toString()}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className="no-date">
                  <p>Chưa có dữ liệu.</p>
                </div>
              )}
            </ul>
            {console.log("pagesSecond >>>", pagesSecond)}
            {listWinnerThird?.length > 0 && (
              <ReactPaginate
                previousLabel=""
                nextLabel=""
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pagesThird || 0}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageChangeThird}
                containerClassName="pagination container"
                activeClassName="active"
                forcePage={pageOffsetThird}
              />
            )}
          </div>
          <div className="line"></div>

          <div className="box-topup">
            <div className="item-topup">
              <img className="" src="assets/img/topup1.png" alt="" />
              {/* <div className="number-topup">
                {getRemaining("44a506b4-b67c-4800-a89a-c791377507ef")}
              </div> */}
            </div>
            <div className="line-d d-none-mb"></div>
            <div className="item-topup">
              <img className="" src="assets/img/topup2.png" alt="" />
              {/* <div className="number-topup">
                {getRemaining("9bfa9c5a-119a-4948-965d-d5b045b26e19")}
              </div> */}
            </div>
          </div>
        </div>
        <TheFooter />
      </section>
    </>
  );
};
export default PrizeView;
