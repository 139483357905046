// @ts-nocheck
import { LINK_VIDEO } from "constants/common";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { useDispatch } from "react-redux";
import LoadingSpinner from "components/LoadingSpinner";
import * as _ from "lodash";
import actions from "redux/home/actions";

const LIST_WHEEL = [
  // {
  //   id: 1,
  //   icon: (
  //     <img
  //       // style={{ width: "40rem", marginTop: "-10rem", objectFit: "contain" }}
  //       src={gift1}
  //     />
  //   ),
  // },
  // {
  //   id: 2,
  //   icon: (
  //     <img
  //       // style={{ width: "55rem", marginTop: "-20rem", objectFit: "contain" }}
  //       src={gift2}
  //     />
  //   ),
  // },
  // {
  //   id: 3,
  //   icon: (
  //     <img
  //       // style={{ width: "40rem", marginTop: "-10rem", objectFit: "contain" }}
  //       src={gift3}
  //     />
  //   ),
  // },
  // {
  //   id: 4,
  //   icon: (
  //     <img
  //       // style={{ width: "55rem", marginTop: "-20rem", objectFit: "contain" }}
  //       src={gift4}
  //     />
  //   ),
  // },
  // {
  //   id: 5,
  //   icon: (
  //     <img
  //       // style={{ width: "40rem", marginTop: "-10rem", objectFit: "contain" }}
  //       src={gift5}
  //     />
  //   ),
  // },
  // {
  //   id: 6,
  //   icon: (
  //     <img
  //       // style={{ width: "40rem", marginTop: "-10rem", objectFit: "contain" }}
  //       src={gift6}
  //     />
  //   ),
  // },
  // {
  //   id: 6,
  //   icon: (
  //     <img
  //       // style={{ width: "40rem", marginTop: "-10rem", objectFit: "contain" }}
  //       src={gift6}
  //     />
  //   ),
  // },
  // {
  //   id: 6,
  //   icon: (
  //     <img
  //       // style={{ width: "40rem", marginTop: "-10rem", objectFit: "contain" }}
  //       src={gift6}
  //     />
  //   ),
  // },
];
const PopupLuckyDraw = (props) => {
  const { dataModalTopup, dataSubmit, onShowGift, checkError } = props;

  const navigate = useNavigate();
  const [dataExchangeCode, setDataExchangeCode] = useState({});
  let intervalId = useRef(null);
  const dispatch = useDispatch();
  const [isSpin, setIsSpin] = useState(false);

  const [spinning, setSpinning] = React.useState(false);

  const spinTweenRef = React.useRef(null);
  const loopIterationRef = React.useRef(0);
  const wheelRef = React.useRef(null);
  const stopAtRef = React.useRef(null);
  const rewardDataRef = React.useRef(null);
  const [stopAt, setStopAt] = React.useState(0);

  const app = useRef();
  const luckdyWidth = 2 * Math.sin(Math.PI / (2 * LIST_WHEEL.length)) * 400; //310
  // const luckdyWidth = 130
  console.log("luckdyWidth", luckdyWidth);
  // const luckdyWidth2 = 142; 5 cánh
  // const luckdyWidth2 = 94; // 6 cánh
  const luckdyWidth2 = 65; // 8 cánh
  // const luckdyWidth2 = 250; // 4 cánh
  const [count, setCount] = useState(0);
  const [countLoading, setCountLoading] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [delayedCount, setDelayedCount] = useState(0);
  // const [isShowModalNoGift, setIsShowModalNoGift] = useState(false);
  const [isShowPopupGiftPhycial, setIsShowPopupGiftPhycial] = useState(false);
  const [isShowPopupGiftSuccess, setIsShowPopupGiftSuccess] = useState(false);
  const [disableBtnSpin, setDisableBtnSpin] = useState(false);
  const [nameBtn, setNameBtn] = useState("");
  const [nameBtn1, setNameBtn1] = useState("");
  const [nameBtn2, setNameBtn2] = useState("");
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [isShowModalTopup, setIsShowModalTopup] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [marts, setMarts] = useState([]);
  const [provincePopupSelected, setProvincePopupSelected] = useState("");
  const [districtsPopupSelected, setDistrictsPopupSelected] = useState("");
  const [martsPopupSelected, setMartsPopupSelected] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const rewardUser = JSON.parse(localStorage.getItem("dataExchangeCode"));
    if (!!rewardUser?.code) {
      setDataExchangeCode(rewardUser);
      localStorage.setItem("dataExchangeCode", JSON.stringify({}));
    } else {
      navigate("/");
    }
  }, []);
  var debounce_fun = _.debounce(function () {
    // requestTurn();
    startSpin();
  }, 0);
  const getFullSpin = (rewardType) => {
    switch (rewardType) {
      // case "e4c811f9-c5c2-43bb-962b-f069386a791e": //Thẻ ĐT
      //   return 200;
      // case "945fb4d4-3350-463f-957d-1f8251bfd6ba": //Voucher CGV
      //   return 108;
      // case "c8bbb9b7-6e89-4d8a-9d9e-cf31b9d92fe0": //Macbook
      //   return 150;
      // case "4d2484db-b191-4254-a2f9-cf4cc1dcf8a9": //  may mắn
      //   return 240;
      // case "20518fb1-2982-49c7-9d92-04aad37225ad": // nồi chiên
      //   return 60;

      case "4d2484db-b191-4254-a2f9-cf4cc1dcf8a9": //  may mắn
        return 185;
      case "44a506b4-b67c-4800-a89a-c791377507ef": // voucher
        return 130;

      default:
        return 0;
    }
  };

  const onExchangeCode = () => {
    let params = {
      code: dataSubmit?.code,
      provinceCode: dataSubmit?.province,
      fullName: dataSubmit?.fullName,
      telcoCode: dataSubmit?.telco,
      retryId: dataModalTopup?.retryId,
    };

    dispatch(
      actions.EXCHANGE_CODE.request(
        params,
        (res) => {
          console.log(res);
          if (!!res) {
            stopAtRef.current = getFullSpin(res?.giftId);
            rewardDataRef.current = res;
          }
        },
        (err) => {
          spinTweenRef.current.kill();
          gsap.to(wheelRef.current, {
            rotation: 240,
            duration: 0,
            onComplete: () => {},
          });
          setIsSpin(false);
          props?.closeModal();
          checkError(err?.data?.error?.code);
          // if (err?.status !== 500) {
          //     let errCode = !!err?.data?.error?.code
          //         ? err?.data?.error?.code
          //         : err?.errorCode;
          //     checkError(errCode);
          // } else {
          //     showDefaultErrModal();
          // }
        }
      )
    );

    // stopAtRef.current = getFullSpin("4d2484db-b191-4254-a2f9-cf4cc1dcf8a9");
    // rewardDataRef.current = {
    //   "id": "HTEST021",
    //   "phoneNumber": "0906021995",
    //   "userName": "hhh",
    //   "provinceCode": "89",
    //   "giftId": "4d2484db-b191-4254-a2f9-cf4cc1dcf8a9",
    //   "giftName": "Thẻ nạp điện thoại trị giá 150.000Đ",
    //   "smsGiftName": "Giai IV",
    //   "giftCode": null,
    //   "allocationId": "00244c76-071b-4cf1-863b-bec7e5a6036b",
    //   "giftType": 4,
    //   "phaseId": "phase01",
    //   "joinTimeUtc": "2024-08-05T08:08:08.8017436Z",
    //   "channel": "LandingPage",
    //   "isLandingPage": true,
    //   "hasReward": true,
    //   "retryId": null,
    //   "telcoCode": "VTT"
    // };
  };
  React.useEffect(() => {
    // window.scrollTo(0, 150);
    spinTweenRef.current = gsap
      .to(wheelRef.current, {
        rotation: "+=360",
        ease: "none",
        duration: 0.8,
        onStart: () => {
          setSpinning(true);
        },
        onComplete: () => {
          console.log("onComplete", { stopAt, stopAtRef: stopAtRef.current });

          if (
            loopIterationRef.current >= 2 &&
            typeof stopAtRef.current === "number"
          ) {
            spinTweenRef.current.timeScale(0);

            gsap.to(wheelRef.current, {
              // rotation: `+=${stopAtRef.current + 360}`,
              rotation: 2 * 360 + stopAtRef.current,
              duration: (2 * (stopAtRef.current + 360)) / 360,
              onComplete: () => {
                setSpinning(false);
                setIsSpin(false);
                onShowGift(rewardDataRef?.current?.giftType);
                props.closeModal(false);
              },
            });
          } else {
            loopIterationRef.current++;
            spinTweenRef.current.play(0);
          }
        },
      })
      .timeScale(0)
      .restart();
  }, []);
  const startSpin = React.useCallback(() => {
    loopIterationRef.current = 0;

    gsap.to(spinTweenRef.current, {
      ease: "none",
      duration: 0.75,
      timeScale: 1,
    });
  }, []);
  useEffect(() => {
    setDelayedCount(countLoading);
  }, [countLoading]);
  useLayoutEffect(() => {
    // getProvinces();
    const ctx = gsap.context(() => {
      gsap.to(".box-1", { rotation: "+=360" });
    }, app);
    return () => ctx.revert();
  }, []);

  // runs on first render and every time delayedCount changes
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(".box-2", { rotation: "+=360" });
    }, app);
    return () => ctx.revert();
  }, [delayedCount]);

  // runs on every render
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(".box-3", { rotation: "+=360" });
    }, app);
    return () => ctx.revert();
  });

  return (
    <>
      <div className="popup-overlay modal-lucky">
        <div className="popup-warp">
          {/* <div
                        className="close-modal"
                        onClick={() => {
                            props.closeModal(false);
                        }}
                    ></div> */}
          <div className="popup-inner">
            <img
              className="img-full title-lucky"
              src="assets/img/title-lucky.png"
            />
            <div
              className="lucky-container"
              // style={{ height: window.innerHeight }}
            >
              <div className="w-wheel">
                <div className="spin-container">
                  <div
                    id="spin"
                    className="spin"
                    // onClick={() => {
                    //   if (!isSpin) {
                    //     setIsSpin(true);
                    //     onExchangeCode();
                    //     debounce_fun();
                    //   }
                    // }}
                  >
                    <div id="inner-spin" className="innerspin"></div>
                  </div>
                </div>
                <div id="wrapper" className="wrapper">
                  <div className="border-custom">
                    {/* <img src={bg_dot} /> */}
                  </div>
                  <div ref={wheelRef} className="wheel">
                    <div id="inner-wheel" className="innerwheel">
                      {LIST_WHEEL.map((item, index) => {
                        return (
                          <div
                            className={"sec"}
                            style={{
                              transform: `rotate(${
                                (360 * index) / LIST_WHEEL.length
                              }deg)`,
                              borderTopWidth: `${luckdyWidth}rem`,
                              transformOrigin: ` ${luckdyWidth2}rem ${luckdyWidth}rem`,
                              borderRightWidth: `${luckdyWidth2}rem`,
                              borderLeftWidth: `${luckdyWidth2}rem`,
                              top: `${-16}rem`, //8canh 400
                              left: `${75}rem`, //8canh 400
                              // top: `${-27}rem`, //6canh
                              // left: `${37}rem`, //6canh
                              // top: `${-52}rem`, //5canh
                              // left: `${4}rem`,  //5canh

                              // top: `${-102}rem`, // 4canh
                              // left: `${-109}rem`, // 4canh
                            }}
                          >
                            <span className="fa">{item.icon}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div className="spin-container">
                    <div
                      id="spin"
                      className="spin"
                      // onClick={() => {
                      //   if (!isSpin) {
                      //     setIsSpin(true);
                      //     onExchangeCode();
                      //     debounce_fun();
                      //   }
                      // }}
                    >
                      <div id="inner-spin" className="innerspin"></div>
                    </div>
                  </div> */}
                  <div id="shine" className="shine"></div>
                  <div id="txt"></div>

                  <div className="text-spin btn-lucky">
                    <br />
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary   btn-block "
                      onClick={() => {
                        if (!isSpin) {
                          setIsSpin(true);
                          onExchangeCode();
                          debounce_fun();
                        }
                      }}
                    >
                      QUAY NGAY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupLuckyDraw;
