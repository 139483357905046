import actions from "./actions";
import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import {
  CHECK_PHONE,
  CREATE_USER,
  EXCHANGE_CODE,
  GET_PROVINCES,
  GET_WINNERS_HOME,
  OTP_LOG,
  REQUEST_GIGYA,
  REQUEST_LOGIN,
  REQUEST_OTP,
  TOPUP_MOBILE,
  UPDATE_USER_INFO,
  VALIDATE_CAMPAIGN,
  VALIDATE_CODE,
  VERIFY_OTP,
} from "./constants";
import {
  checkPhoneApi,
  createUserApi,
  exchangeCodeApi,
  getProvincesApi,
  getWinnersHomeApi,
  OTPLogApi,
  requestGigyaApi,
  requestLoginApi,
  requestOTPApi,
  topupMobileApi,
  updateUserInfoApi,
  validateCampaignApi,
  validateCodeApi,
  verifyOTPApi,
} from "api/home";

export function* getProvincesSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getProvincesApi, payload);
    yield put(actions.GET_PROVINCES.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_PROVINCES.failure(err));
  }
}

export function* validateCodeSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(validateCodeApi, payload);
    yield put(actions.VALIDATE_CODE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.VALIDATE_CODE.failure(err));
  }
}

export function* validateCampaignSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(validateCampaignApi, payload);
    yield put(actions.VALIDATE_CAMPAIGN.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.VALIDATE_CAMPAIGN.failure(err));
  }
}

export function* requestOTPSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(requestOTPApi, payload);
    yield put(actions.REQUEST_OTP.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.REQUEST_OTP.failure(err));
  }
}

export function* OTPLogSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(OTPLogApi, payload);
    yield put(actions.OTP_LOG.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.OTP_LOG.failure(err));
  }
}

export function* verifyOTPSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(verifyOTPApi, payload);
    yield put(actions.VERIFY_OTP.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.VERIFY_OTP.failure(err));
  }
}

export function* requestLoginSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(requestLoginApi, payload);
    yield put(actions.REQUEST_LOGIN.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.REQUEST_LOGIN.failure(err));
  }
}

export function* updateUserInfoSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(updateUserInfoApi, payload);
    yield put(actions.UPDATE_USER_INFO.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.UPDATE_USER_INFO.failure(err));
  }
}

export function* requestGigyaSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(requestGigyaApi, payload);
    yield put(actions.REQUEST_GIGYA.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.REQUEST_GIGYA.failure(err));
  }
}

export function* exchangeCodeSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(exchangeCodeApi, payload);
    yield put(actions.EXCHANGE_CODE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.EXCHANGE_CODE.failure(err));
  }
}

export function* topupMobileSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(topupMobileApi, payload);
    yield put(actions.TOPUP_MOBILE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.TOPUP_MOBILE.failure(err));
  }
}

export function* checkPhoneSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(checkPhoneApi, payload);
    yield put(actions.CHECK_PHONE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.CHECK_PHONE.failure(err));
  }
}

export function* getWinnersHomeSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getWinnersHomeApi, payload);
    yield put(actions.GET_WINNERS_HOME.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_WINNERS_HOME.failure(err));
  }
}

export function* createUserSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(createUserApi, payload);
    yield put(actions.CREATE_USER.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.CREATE_USER.failure(err));
  }
}

function* watchCommon() {
  yield takeLatest(GET_PROVINCES.request, getProvincesSaga);
  yield takeEvery(VALIDATE_CODE.request, validateCodeSaga);
  yield takeLatest(VALIDATE_CAMPAIGN.request, validateCampaignSaga);
  yield takeLatest(REQUEST_OTP.request, requestOTPSaga);
  yield takeLatest(OTP_LOG.request, OTPLogSaga);
  yield takeLatest(VERIFY_OTP.request, verifyOTPSaga);
  yield takeLatest(REQUEST_LOGIN.request, requestLoginSaga);
  yield takeLatest(UPDATE_USER_INFO.request, updateUserInfoSaga);
  yield takeLatest(REQUEST_GIGYA.request, requestGigyaSaga);
  yield takeLatest(EXCHANGE_CODE.request, exchangeCodeSaga);
  yield takeLatest(TOPUP_MOBILE.request, topupMobileSaga);
  yield takeLatest(CHECK_PHONE.request, checkPhoneSaga);
  yield takeLatest(GET_WINNERS_HOME.request, getWinnersHomeSaga);
  yield takeLatest(CREATE_USER.request, createUserSaga);
}

export default function* rootChild() {
  yield fork(watchCommon);
}
