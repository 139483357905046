import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "react-slick";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
const SliderComponent = (props) => {
  const [data, setData] = useState([
    // {
    //   id: "CODE01",
    //   phoneNumber: "******21228",
    //   fullName: "Hồ Thị Linh Chi",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: "assets/img/slider1.png",
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
    // {
    //   id: "CODE02",
    //   phoneNumber: "******3008",
    //   fullName: "Nguyễn Hồ Quang Phước",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: "assets/img/slider2.png",
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
    // {
    //   id: "CODE03",
    //   phoneNumber: "******3008",
    //   fullName: "Nguyễn Văn A",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: null,
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
    // {
    //   id: "CODE03",
    //   phoneNumber: "******3008",
    //   fullName: "Nguyễn Văn A",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: null,
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
    // {
    //   id: "CODE03",
    //   phoneNumber: "******3008",
    //   fullName: "Nguyễn Văn A",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: null,
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
    // {
    //   id: "CODE03",
    //   phoneNumber: "******3008",
    //   fullName: "Nguyễn Văn A",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: null,
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
    // {
    //   id: "CODE03",
    //   phoneNumber: "******3008",
    //   fullName: "Nguyễn Văn A",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: null,
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
    // {
    //   id: "CODE03",
    //   phoneNumber: "******3008",
    //   fullName: "Nguyễn Văn A",
    //   provinceCode: "79",
    //   giftId: "20518fb1-2982-49c7-9d92-04aad37225ad",
    //   giftName: "Thẻ nạp điện thoại trị giá 10.000Đ",
    //   imageUrl: null,
    //   giftType: 3,
    //   joinTimeUtc: "2024-06-04T07:39:50.4018229Z",
    //   hasReward: true,
    // },
  ]);

  const settingRef = useRef({
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  useEffect(() => {
    if (props?.listWinnerSpecial?.length > 0) {
      // settingRef.current.slidesToShow =
      //   props?.listWinnerSpecial?.length >= 6 ? 6 : 3;

      setData(props?.listWinnerSpecial);
      // var y = props?.listWinnerSpecial;
      // var x = {
      //   image: "assets/img/slider1.png",
      // };
      // var z = y.push(x);
    }
  }, [props?.listWinnerSpecial]);

  useEffect(() => {
    if (props?.listPicture?.length > 0) {
      // settingRef.current.slidesToShow =
      //   props?.listWinnerSpecial?.length >= 6 ? 6 : 3;

      setData(props?.listPicture);
      // var y = props?.listPicture;
      // var x = {
      //   image: "assets/img/slider1.png",
      // };
      // var z = y.push(x);
      console.log("props?.listPicture >>>", props?.listPicture);
    }
  }, [props?.listPicture]);

  return (
    <div className={`slider-warp ${data?.length > 1 ? "" : "hasWiner"}`}>
      <Slider {...settingRef.current}>
        {data.map((item, index) => {
          if (props?.listWinnerSpecial?.length > 0) {
            if (
              index === props?.listWinnerSpecial?.length &&
              props?.listWinnerSpecial?.length < 7
            ) {
              return (
                <div className="slick-slide">
                  <div className="commin">
                    <p>
                      Cùng chờ đón <br />
                      người trúng thưởng tiếp theo
                    </p>
                    <h3>HỌ & tên</h3>
                    <h5>Số điện thoại</h5>
                    <img src="assets/img/bgrblur.png" alt="comingsoon-img" />
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <div className="slick-slide has">
                    <div className="box-item">
                      <p className="wellcome">Chúc mừng anh / chị</p>

                      <img
                        className="avatar"
                        src={item?.imageUrl || "assets/img/avatar.png"}
                        alt={item?.imageUrl}
                      />
                      <h3>{item?.userName || "Đang chờ cập nhật"}</h3>
                      <h5>
                        <b>{item?.phoneNumber || "Đang chờ cập nhật"}</b>
                      </h5>

                      <img
                        className="BG_box"
                        src="assets/img/BG_box.png"
                        alt="BG_box.png"
                      />
                    </div>
                  </div>
                </>
              );
            }
          } else {
            if (index === data?.length - 1) {
              return (
                <div className="slick-slide">
                  <div className="commin">
                    <p>
                      Cùng chờ đón <br />
                      người trúng thưởng tiếp theo
                    </p>
                    <h3>HỌ & tên</h3>
                    <h5>Số điện thoại</h5>
                    <img src="assets/img/bgrblur.png" alt="comingsoon-img" />
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <div className="slick-slide has">
                    <div className="box-item">
                      <p className="wellcome">Chúc mừng anh / chị</p>

                      <img
                        className="avatar"
                        src={item?.imageUrl || "assets/img/avatar.png"}
                        alt={item?.imageUrl}
                      />
                      <h3>{item?.userName || "Đang chờ cập nhật"}</h3>
                      <h5>
                        <b>{item?.phoneNumber || "Đang chờ cập nhật"}</b>
                      </h5>

                      <img
                        className="BG_box"
                        src="assets/img/BG_box.png"
                        alt="BG_box.png"
                      />
                    </div>
                  </div>
                </>
              );
            }
          }
        })}
      </Slider>
    </div>
  );
};
export default React.memo(SliderComponent);
