import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import DuetCameraView from "./view";
import PopupMessage from "components/popup-message";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "redux/duet/actions";
import actionsHome from "redux/home/actions";
import PopupInputName from "components/popup-input-name";
import { checkPc } from "utils/common";
import { MESSAGE_ERROR } from "constants/common";
import PopupGuideLink from "components/popup-guide-link";

const DuetCameraPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { songDetail } = location.state || {};

  const [isLoading, setIsLoading] = useState(false);
  const [pending, setPending] = useState(false);

  const [nameBtn, setNameBtn] = useState("");
  const [nameBtn2, setNameBtn2] = useState("");
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");

  const [play, setPlay] = useState(false);
  const [pause, setPause] = useState(false);

  const cameraRef = useRef(null);
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const iframeRef = useRef(null);
  const audioRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [blobUrl, setBlobUrl] = useState("");
  const [blob, setBlob] = useState(null);
  const [subId, setSubId] = useState("");
  const [streamTmp, setStreamTmp] = useState(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [isShowModalName, setIsShowModalName] = useState(false);
  const [searchText, setSearchText] = useState("");
  const isNotCameraPermission = useRef(true);
  const [isHidePlay, setIsHidePlay] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [isShowGuideLink, setIsShowGuideLink] = useState(false);
  const [isReadyRecord, setReadyRecord] = useState(false);

  useEffect(() => {
    if (blobUrl === "" && !!videoRef.current) {
      videoRef.current.currentTime = 0;
      setCurrentTime(0);
      // getCameraPermission();
    }

    const updateTime = () => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime);
      }
    };

    const setVideoDuration = () => {
      if (videoRef.current) {
        setDuration(videoRef.current.duration);
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", updateTime);
      videoElement.addEventListener("loadedmetadata", setVideoDuration);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", updateTime);
        videoElement.removeEventListener("loadedmetadata", setVideoDuration);
      }
    };
  }, [blobUrl, videoRef]);

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }
    return os;
  }

  function onchange(evt) {
    console.log("donchange >>>", evt);
    if (document.hidden) {
      console.log("document.hidden >>>", document.hidden);
      if (getOS() === "iOS" && mediaRecorderRef.current) {
        mediaRecorderRef.current.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      // mediaRecorderRef.current.stop();
      setIsHidePlay(false);
      setPlay(false);
      const videoNote = document.querySelector("video");
      videoNote.pause();
      videoNote.currentTime = 0;
      videoRef2?.current?.pause();
      if (currentTime > 0) {
        if (!blobUrl) {
          setMessageError(MESSAGE_ERROR.InActiveBrowser);
          setShowModalError(true);
          getCameraPermission();
        }
      }
      setCurrentTime(0);

      console.log("donchange getCameraPermission >>>");
    }else{
      getCameraPermission();
    }
    console.log("visibilitychange >>>", document.hidden);
  }

  const getCameraPermission = async (callback) => {
    setReadyRecord(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
        audio: true,
      });
      if (cameraRef.current) {
        cameraRef.current.srcObject = stream;
      }
      console.log("cameraRef.current >>>", cameraRef.current);
      mediaRecorderRef.current = new MediaRecorder(stream);

      // setStreamTmp(mediaRecorderRef.current.stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        console.log("ondataavailable >>>", event);
        chunks.push(event.data);
        // if (event.data.size > 0) {
        //   setRecordedChunks((prev) => prev.concat(event.data));
        // } else {
        //   console.log("ondataavailable error");

        // }
      };
      const chunks = [];
      mediaRecorderRef.current.onstop = (e) => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        if (blob.size > 0) {
          const url = URL.createObjectURL(blob);
          console.log("url >>>", url);
          console.log("blob >>>", blob);

          setBlobUrl(url);
          setBlob(blob);
          // const a = document.createElement('a');
          // a.style.display = 'none';
          // a.href = url;
          // a.download = 'recorded-video.mp4';
          // document.body.appendChild(a);
          // a.click();
          // window.URL.revokeObjectURL(url);
        } else {
          console.error("Recorded blob is empty");
        }
      };

      mediaRecorderRef.current.onerror = (event) => {
        console.error(`error recording stream: ${event.error.name}`);
      };

      isNotCameraPermission.current = false;
      document.addEventListener("visibilitychange", onchange);
      callback?.();
      setTimeout(() => {
        setReadyRecord(true);
      }, 2000);
    } catch (error) {
      setReadyRecord(true);
      setMessageError(MESSAGE_ERROR.NotAllowCameraPermission);
      setShowModalError(true);
      console.error("Error accessing camera:", error);
    }
  };

  const onPlay = () => {
    if (isNotCameraPermission.current) {
      console.log("onPlay getCameraPermission >>>");
      getCameraPermission(() => {
        videoRef?.current?.play();
        setPlay(!play);
      });
    } else {
      videoRef?.current?.play();
      setPlay(!play);
    }
  };

  const startRecording = async () => {
    if (!isNotCameraPermission.current) {
      try {
        // @ts-ignore
        setRecordedChunks([]);
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (err) {
        if (err?.message?.indexOf("must be inactive") > -1) {
          if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stream
              .getTracks()
              .forEach(function (track) {
                track.stop();
              });
          }
          setMessageError(MESSAGE_ERROR.InActiveBrowser);
          setShowModalError(true);
          setIsHidePlay(false);
          setPlay(false);
          const videoNote = document.querySelector("video");
          videoNote.pause();
          videoNote.currentTime = 0;
          videoRef2?.current?.pause();
          setCurrentTime(0);
          console.log("startRecording getCameraPermission >>>");
          getCameraPermission();
        } else {
          alert(err.message);
        }
      }
    }
  };

  // useEffect(() => {
  //   if (recordedChunks?.length > 0) {
  //     const blob = new Blob(recordedChunks, { type: "video/mp4" });
  //     if (blob.size > 0) {
  //       const url = URL.createObjectURL(blob);
  //       console.log("url >>>", url);
  //       console.log("blob >>>", blob);

  //       setBlobUrl(url);
  //       setBlob(blob);
  //       // const a = document.createElement('a');
  //       // a.style.display = 'none';
  //       // a.href = url;
  //       // a.download = 'recorded-video.mp4';
  //       // document.body.appendChild(a);
  //       // a.click();
  //       // window.URL.revokeObjectURL(url);
  //     } else {
  //       console.error("Recorded blob is empty");
  //     }
  //   }
  // }, [recordedChunks]);

  // const handleBlur = () => {
  //   stopRecording();
  // };

  // const handleVisibilityChange = () => {
  //   if (document.visibilityState === 'hidden') {
  //     stopRecording();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   window.addEventListener('blur', handleBlur);

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     window.removeEventListener('blur', handleBlur);
  //   };
  // }, []);

  const stopRecording = () => {
    setPause(!pause);
    videoRef?.current?.pause();
    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.stream
      .getTracks()
      .forEach((track) => track.stop());
    setIsRecording(false);
  };

  const onVideoPlay = () => {
    startRecording();
  };

  const onVideoEnd = () => {
    stopRecording();
  };

  const onVideoEnd2 = () => {
    videoRef1?.current?.pause();
    videoRef2?.current?.pause();
  };

  const playVideoReview = async () => {
    const video1 = videoRef1.current;
    const video2 = videoRef2.current;

    video1.muted = true;
    video2.muted = false;

    const audio = audioRef.current;

    if (video1 && video2 && audio) {
      try {
        // Ensure video1, video2, and audio play together
        await Promise.all([video1.play(), video2.play(), audio.play()]);
      } catch (error) {
        console.error("Error playing media:", error);
      }
    }
  };

  const pauseVideoReview = async () => {
    const video1 = videoRef1.current;
    const video2 = videoRef2.current;
    const audio = audioRef.current;
    if (video1 && video2 && audio) {
      video1.pause();
      video2.pause();
      audio.pause();
    }
  };

  useEffect(() => {
    // Function to handle messages from the TikTok player
    const handleMessage = (event) => {
      if (event.origin !== "https://www.tiktok.com") return;

      const message = event.data;
      if (message["x-tiktok-player"]) {
        switch (message.type) {
          case "play":
            console.log("Video started playing:", message.value);
            break;
          case "pause":
            console.log("Video paused:", message.value);
            break;
          case "error":
            console.error("Error from TikTok player:", message.value);
            break;
          case "onPlayerReady":
            break;
          default:
            console.log("Unknown message type:", message.type);
        }
      }
    };

    try {
      let portrait = window.matchMedia("(orientation: portrait)");

      portrait.addEventListener("change", function (e) {
        console.log("orientation >>>", e);
        if (!e.matches) {
          setMessageError(MESSAGE_ERROR.DetectLandscape);
          setShowModalError(true);
        }
      });
    } catch (error) {
      console.log("error >>>", error);
    }

    // Add event listener for messages
    window.addEventListener("message", handleMessage);

    // Clean up event listener on component unmount
    return () => {
      try {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      } catch (error) {}

      window.removeEventListener("message", handleMessage);
      document.removeEventListener("visibilitychange", onchange);
    };
  }, []);

  const playiframe = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const message = {
        "x-tiktok-player": true,
        type: "play", // Change this based on TikTok's supported types
        value: {},
      };

      iframeRef.current.contentWindow.postMessage(
        message,
        "https://www.tiktok.com"
      );
    }
  };

  const pauseiframe = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const message = {
        "x-tiktok-player": true,
        type: "pause", // Change this based on TikTok's supported types
        value: {},
      };

      iframeRef.current.contentWindow.postMessage(
        message,
        "https://www.tiktok.com"
      );
    }
  };

  const hideModalError = () => {
    setShowModalError(false);
    setContent("");
    setNameBtn("");
    document.body.classList.remove("modal-open");
    // document.body.classList.remove("open-duet");
  };

  const hideModal = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    document.body.classList.remove("modal-open");
    // document.body.classList.remove("open-duet");
  };
  const showModal = () => {
    setModal(true);
    document.body.classList.add("modal-open");
  };
  const clickSendPost = () => {
    hideModal();
    // setPending(true);
    navigate(`/chitietbaithi?id=${subId}`);
  };

  const sendPost = () => {
    setIsLoading(true);
    dispatch(
      actionsHome.CREATE_USER.request(
        {},
        (res) => {
          setIsLoading(false);
          console.log(res);
          if (!!res?.userName) {
            getSastoken(res?.userName);
          } else {
            setIsShowModalName(true);
          }
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const getSastoken = (userName) => {
    setIsLoading(true);

    dispatch(
      actions.GET_SASTOKEN.request(
        {},
        (res) => {
          if (!!res?.blobEndpoint && !!res?.sasToken) {
            uploadVideo(res?.blobEndpoint, res?.sasToken, userName);
          }
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const uploadVideo = async (blobEndpoint, sasToken, userName) => {
    try {
      let timeStamp = Date.now();
      let songName = `recorded-video-${timeStamp}.mp4`;
      const urlApi = `${blobEndpoint}/${songName}?${sasToken}`;

      let params = {
        url: urlApi,
        blob: blob,
      };
      dispatch(
        actions.UPLOAD_VIDEO.request(
          { params },
          (res) => {
            createSubmission(songName, userName);
          },
          (err) => {
            setIsLoading(false);
          }
        )
      );
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading video to Azure Blob Storage", error);
    }
  };

  const createSubmission = (songName, userName) => {
    dispatch(
      actions.CREATE_SUBMISSION.request(
        {
          songId: songDetail?.songId,
          SubmissionFileName: songName,
          Name: userName,
        },
        (res) => {
          if (!!res?.id) {
            setSubId(res?.id);
            setIsLoading(false);
            setContent(
              ` <h3>THÔNG BÁO</h3> <p>Bài dự thi của bạn đang được xử lý, bạn có thể ĐĂNG BÀI sau khi xử lý xong</p>`
            );

            setNameBtn(`ĐẾN BÀI DỰ THI`);
            // setNameBtn2(`TRANG CHỦ`);
            showModal();
          }
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const reRecord = () => {
    getCameraPermission(() => {});
    setPlay(false);
    setPause(false);
    setBlob(null);
    setBlobUrl("");
  };

  useEffect(() => {
    document.body.classList.remove("open-duet");
    document.body.classList.add("open-duet-camera");
  }, []);

  useEffect(() => {
    return () => {
      // if (streamTmp) {
      //   streamTmp.getTracks().forEach((track) => track.stop());
      // }
      document.body.classList.remove("modal-open");
      // document.body.classList.remove("open-duet");
    };
  }, []);

  const onContinue = () => {
    console.log(searchText);
    if (searchText?.trim()?.length > 0) {
      getSastoken(searchText);
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <DuetCameraView
        sendPost={sendPost}
        pending={pending}
        videoRef={videoRef}
        videoRef1={videoRef1}
        videoRef2={videoRef2}
        cameraRef={cameraRef}
        onPlay={onPlay}
        onVideoEnd={onVideoEnd}
        onVideoPlay={onVideoPlay}
        play={play}
        setPlay={setPlay}
        pause={pause}
        setPause={setPause}
        getCameraPermission={getCameraPermission}
        stopRecording={stopRecording}
        currentTime={currentTime}
        duration={duration}
        blobUrl={blobUrl}
        playVideoReview={playVideoReview}
        onVideoEnd2={onVideoEnd2}
        reRecord={reRecord}
        songDetail={songDetail}
        isHidePlay={isHidePlay}
        setIsHidePlay={setIsHidePlay}
        audioRef={audioRef}
        pauseVideoReview={pauseVideoReview}
        isReadyRecord={isReadyRecord}
      />
      {isShowModalName && (
        <PopupInputName
          searchText={searchText}
          setSearchText={setSearchText}
          closeModal={setIsShowModalName}
          onContinue={onContinue}
        />
      )}
      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          nameBtn2={nameBtn2}
          content={content}
          clickAction={clickSendPost}
          clickAction1={clickSendPost}
          clickAction2={() => {
            navigate("/hatcungthantuong");
          }}
          showModalGuideLink={setIsShowGuideLink}
        />
      )}

      {showModalError && (
        <PopupMessage
          nameBtn={"Đóng"}
          nameBtn1={""}
          nameBtn2={""}
          setModal={setShowModalError}
          content={messageError}
          clickAction={hideModalError}
          clickAction1={hideModalError}
          clickAction2={hideModalError}
        />
      )}

      {isShowGuideLink && <PopupGuideLink closeModal={setIsShowGuideLink} />}
    </>
  );
};
export default DuetCameraPage;
