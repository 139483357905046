// @ts-nocheck
import { END_POINTS } from "constants/apiUrl";
import { APIUtils } from "utils";

export const getRemainingApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_REMAINING_URL, {
    params: payload,
  });
};

export const getListWinnerByTypeApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_LIST_WINNER_BY_TYPE, {
    params: payload,
  });
};

export const getListPictureApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_LIST_PICTURE, {
    params: payload,
  });
};

export const getRemainingPrizeApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_REMAINING_PRIZE, {
    params: payload,
  });
};
