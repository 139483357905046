// @ts-nocheck
import React, { useEffect, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import DuetSelectSongView from "./view";
import PopupMessage from "components/popup-message";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import actions from "redux/duet/actions";
import PopupShareLink from "components/popup-share-link";
import reactUtils from "utils/reactUtils";
import { checkPc } from "utils/common";
import PopupGuideGetLinkFB from "components/popup-guide-get-linkfb";

const DuetSelectSongPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [connectTiktok, setconnectTiktok] = useState(false);

  const [nameBtn, setNameBtn] = useState("");
  const [nameBtn1, setNameBtn1] = useState("");
  const [nameBtn2, setNameBtn2] = useState("");
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [subId, setSubId] = useState("");
  const [action, setAction] = useState("");
  const [searchText, setSearchText] = useState("");
  // const [singerId, setSingerId] = useState("");
  const [songs, setSongs] = useState(false);
  const [songSelected, setSongSelected] = useState({});
  const [isShowPopupEmbedLink, setIsShowPopupEmbedLink] = useState(false);
  const [isGuideGetLinkFB, setIsGuideGetLinkFB] = useState(false);

  const hideModal = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    setNameBtn1("");
    setNameBtn2("");
    document.body.classList.remove("modal-open");
  };
  const showModal = () => {
    setModal(true);
    document.body.classList.add("modal-open");
  };
  const clickAction = () => {
    setAction("");
    hideModal();
  };
  const sendconnectTiktok = (songDetail) => {
    getSetting(songDetail);
  };

  const getSetting = (songDetail) => {
    setIsLoading(true);
    dispatch(
      actions.GET_SETTINGS.request(
        {},
        (res) => {
          setIsLoading(false);
          let isLinkTiktok = null;
          if (res?.length > 0) {
            isLinkTiktok = res.some((item) => item.name === "tiktoksetting");
          }
          if (isLinkTiktok) {
            setSongSelected(songDetail);
            setIsShowPopupEmbedLink(true);
          } else {
            setAction("linkTiktok");
            setContent(
              `<h3>LIÊN KẾT TÀI KHOẢN</h3><p>Để đăng ký bài đăng trên các nền tảng và đồng bộ lượt like và share, bạn cần cấp quyền truy cập bài đăng cho Acecook.</p><p><b>Lưu ý: Tài khoản đã liên kết không thể thay đổi, xin hãy liên kết đúng tài khoản dự thi của bạn.</b></p>`
            );
            setNameBtn1(`HỦY BỎ`);
            setNameBtn2(`LIÊN KẾT`);
            showModal();
          }
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    document.body.className = "";
    let singerId = localStorage.getItem("singerId");
    getSongs(singerId);
  }, []);

  const getSongs = (singerId) => {
    if (!!singerId) {
      setIsLoading(true);
      dispatch(
        actions.GET_SINGERS.request(
          {
            singerId: singerId,
          },
          (res) => {
            setIsLoading(false);
            setSongs(res?.songs || []);
          },
          (err) => {
            setIsLoading(false);
          }
        )
      );
    }
  };

  const onDuet = (songDetail) => {
    navigate("/quay", { state: { songDetail: songDetail } });
  };

  const onDeleteSub = (submissionId) => {
    if (!!submissionId) {
      setAction("deleteSub");
      setSubId(submissionId);
      setContent(
        `<h3>THÔNG BÁO </h3><p>Bài dự thi bị xoá sẽ không thể phục hồi. Bạn có chắc chắn muốn xoá bài dự thi này?</p>`
      );
      setNameBtn1(`HỦY`);
      setNameBtn2(`XOÁ BÀI DỰ THI`);
      showModal();
    }
  };

  const clickAction2 = () => {
    switch (action) {
      case "deleteSub":
        setIsLoading(true);
        dispatch(
          actions.DELETE_SUBMISSION.request(
            {
              submissionId: subId,
            },
            (res) => {
              const newSongs = songs.map((item) => {
                if (item.submissionId === subId) {
                  return { ...item, submissionId: null };
                }
                return item;
              });
              setSongs(newSongs);
              hideModal();
              setIsLoading(false);
            },
            (err) => {
              setIsLoading(false);
            }
          )
        );
        break;

      case "linkTiktok":
        const currentUrl = window.location.href;
        const urlTmp = new URL(currentUrl);
        const baseUrl = urlTmp.origin;

        const clientKey = process.env.REACT_APP_CLIENT_KEY_TIKTOK;
        const redirectUri = `${baseUrl}/xemlaibaithi`;
        // const redirectUri = reactUtils.extractBaseURL(window.location.href);
        const scope = "user.info.basic,video.list,user.info.stats";
        const responseType = "code";
        const state = "0";

        const url = `https://www.tiktok.com/v2/auth/authorize/?client_key=${clientKey}&scope=${scope}&response_type=${responseType}&redirect_uri=${redirectUri}&state=${state}`;

        window.location.replace(url);
        break;

      default:
        break;
    }
  };

  const onContinue = () => {
    if (searchText?.split('/video/')?.length > 1) {
      navigate("/preview", {
        state: { linkTiktok: searchText, songDetail: songSelected },
      });
    } else {
      setIsLoading(true);
      dispatch(
        actions.GET_FULL_LINK.request(
          {
            ShortLink: searchText
          },
          (res) => {
            setIsLoading(false);
            console.log(res);
            if (!!res?.fullLink) {
              navigate("/preview", {
                state: { linkTiktok: res?.fullLink, songDetail: songSelected },
              });
            } else {
              setContent(`
                  <h5>Thông báo</h5>
                  <p>Đường link không hợp lệ hoặc không được chia sẻ công khai. Vui lòng kiểm tra lại.<p/>
                  `);
              setNameBtn(`ĐÓNG`);
              setModal(true);
            }
          },
          (err) => {
            setIsLoading(false);
            if (err?.status !== 500) {
              let errCode = err?.data?.error?.code;
              checkError(errCode);
              if (errCode === "ERR_NETWORK") {
                setContent(`
                  <h5>Thông báo</h5>
                  <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
                  `);
                setNameBtn(`ĐÓNG`);
                setModal(true);
              } else {
                setContent(`
                  <h5>Thông báo</h5>
                  <p>Đường link không hợp lệ hoặc không được chia sẻ công khai. Vui lòng kiểm tra lại.<p/>
                  `);
                setNameBtn(`ĐÓNG`);
                setModal(true);
              }
            } else {
              showDefaultErrModal();
            }
          }
        )
      );
    }
  };

  const onReview = (songDetail) => {
    navigate("/preview", { state: { linkTiktok: "", songDetail: songDetail } });
  };


  const checkError = (errCode) => {
    let content = "";
    switch (errCode) {
      case "ERR_NETWORK":
        content = `
          <h5>Thông báo</h5>
          <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      default:
        content = `
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;
    }
    setContent(content);
    showModal();
  };

  const showDefaultErrModal = () => {
    setModal(true);
    setContent(`
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `);
    setNameBtn(`ĐÓNG`);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <DuetSelectSongView
        sendconnectTiktok={sendconnectTiktok}
        songs={songs}
        onDuet={onDuet}
        onDeleteSub={onDeleteSub}
        onReview={onReview}
      />

      {isShowPopupEmbedLink && (
        <PopupShareLink
          searchText={searchText}
          setSearchText={setSearchText}
          closeModal={setIsShowPopupEmbedLink}
          onContinue={onContinue}
          setIsGuideGetLinkFB={setIsGuideGetLinkFB}
        />
      )}

      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          nameBtn1={nameBtn1}
          nameBtn2={nameBtn2}
          setModal={setModal}
          content={content}
          clickAction={clickAction}
          clickAction1={clickAction}
          clickAction2={clickAction2}
        />
      )}

      {isGuideGetLinkFB && (
        <PopupGuideGetLinkFB
          closeModal={setIsGuideGetLinkFB}
          platform={"Tiktok"}
        />
      )}
    </>
  );
};
export default DuetSelectSongPage;
