import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

const PopupOtp = (props) => {
  const navigate = useNavigate();
  const closeModal = () => {
    props.setModalInput(false);
    document.body.classList.remove("modal-open");
  };
  const inputCodeAgain = () => {};
  const [config, setConfig] = useState({
    numInputs: 6,
    otpValue: "",
    separator: "",
    regex: /^([0-9]{0,})$/,
    isDisabled: false,
    isInputNum: true,
    hasErrored: false,
  });

  const TIME_OUT = 120;
  const [seconds, setSeconds] = useState(TIME_OUT - 1);
  const [numberResendOTPTime, setNumberResendOTPTime] = useState(0);
  const [isResendSMSOTP, setIsResendSMSOTP] = useState(false);
  const [isResendZaloOTP, setIsResendZaloOTP] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    countTime();
    props.setOtp("");

    // setTimeout(() => {
    //   handleButton("#resend_sms_otp", false);
    // }, 14000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const countTime = () => {
    intervalRef.current = setInterval(() => {
      setSeconds((second) => {
        if(second <= 60){
          handleButton("#resend_sms_otp", false);
        }else{
          handleButton("#resend_sms_otp", true);
        }
        if (second < 1) {
          clearInterval(intervalRef.current);
          if (isResendSMSOTP) {
            handleButton("#resend_sms_otp", false);
          }

          if (isResendZaloOTP) {
            handleButton("#resend_zalo_otp", false);
          }
          return 0;
        }
      
        return second - 1;
      });
    }, 1000);
  };

  const handleButton = (id, isDisabled) => {
    try {
      const el = document.querySelector(id);
      if (!isDisabled && !!el?.classList) {
        el.classList.remove("disabled");
      } else {
        el.classList.add("disabled");
      }
    } catch (error) {}
  };

  const onChangeOTP = (value) => {
    if (!props?.isLoading) {
      props.setOtp(value);
    }
    // if (value.length === 6 && seconds !== 0) {
    //   handleButton("#submit_otp", false);
    // } else {
    //   handleButton("#submit_otp", true);
    // }
  };

  const onResendSMSOTP = () => {
    setSeconds(TIME_OUT - 1);
    countTime();
    setIsResendSMSOTP(true);
    handleButton("#resend_sms_otp", true);
    props.resendOTP();
  };

  const onResendZaloOTP = () => {
    setIsResendSMSOTP(true);
    handleButton("#resend_sms_otp", true);
    props.handleSubmitOTP();
  };

  return (
    <>
      <div className="popup-overlay modal-otp">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="close-modal" onClick={props.closeModalOTP}></div>
          <div className="popup-inner">
            <div className="content-popup">
              <form className="login-otp">
                <h3>Nhập mã OTP</h3>
                <div className="form-group">
                  <OtpInput
                    value={props.otp}
                    onChange={onChangeOTP}
                    numInputs={Number(config.numInputs)}
                    // onChangeRegex={config.regex}
                    shouldAutoFocus
                    inputType="number"
                    separator={<span>{config.separator}</span>}
                    isInputNum={config.isInputNum}
                    hasErrored={config.hasErrored}
                    inputStyle={"otp-field__input"}
                    containerStyle={"otp-field"}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                {props.isErrorOTP && (
                  <div className="note-login">
                    <div className="ionic-label-center error">
                      <div className="text-red">
                        Mã xác thực chưa chính xác.
                        <br /> Vui lòng kiểm tra lại thông tin.
                      </div>
                    </div>
                  </div>
                )}

                <p className="note-login" id="description-otp">
                  Mã OTP sẽ hết hiệu lực trong
                  <span className="text-red" id="count-down-time">
                    {" "}
                    {seconds} giây
                  </span>
                </p>
                {/* <div className="group-btn-inline">
                  <button
                    id="resend_sms_otp"
                    type="button"
                    className="btn btn-warning disabled btn-block "
                    onClick={onResendSMSOTP}
                  // onClick={() => {
                  //   if (numberResendOTPTime < 3) {
                  //     const el = document.querySelector("#resend_sms_otp");
                  //     el.classList.add("disabled");
                  //     setSeconds(TIME_OUT);
                  //     props.resendOTP();
                  //     countTime();
                  //     setNumberResendOTPTime(numberResendOTPTime + 1);
                  //   }
                  // }}
                  >
                    Gửi SMS OTP
                  </button>
                  <button
                    id="resend_zalo_otp"
                    type="button"
                    className="btn btn-warning disabled btn-block"
                    onClick={onResendZaloOTP}
                  >
                    gửi lại Zalo otp
                  </button>
                </div>
                <p className="note-login">
                  Trường hợp chưa thể truy cập Zalo, vui lòng chọn gửi lại OTP
                  qua SMS
                </p> */}
                <div className="btn-wrapper btn-center">
                  <button
                    id="resend_sms_otp"
                    type="button"
                    className="btn btn-warning disabled "
                    onClick={onResendSMSOTP}
                    // onClick={() => {
                    //   if (numberResendOTPTime < 3) {
                    //     const el = document.querySelector("#resend_sms_otp");
                    //     el.classList.add("disabled");
                    //     setSeconds(TIME_OUT);
                    //     props.resendOTP();
                    //     countTime();
                    //     setNumberResendOTPTime(numberResendOTPTime + 1);
                    //   }
                    // }}
                  >
                    <a>Gửi SMS OTP</a>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupOtp;
