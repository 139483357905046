// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupGuideFacebook = (props) => {
  const navigate = useNavigate();
  const {
    searchText,
    setSearchText,
    closeModal,
    onContinue,
    platform,
    onDownloadVideo,
    videoSource,
    onGuideGetLinkFB,
  } = props;

  return (
    <>
      <div className="popup-overlay modal-notify  guide-lk-fb">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>HƯỚNG DẪN</h3>
              {videoSource === 0 ? (
                <>
                  <p>1. Đầu tiên, bạn hãy lưu/tải bài dự thi về.</p>
                  <button
                    onClick={onDownloadVideo}
                    type="button"
                    className="btn btn-primary btn-border btn-block"
                  >
                    <a>Lưu/tải bài dự thi</a>
                  </button>
                </>
              ) : (
                <>
                  <p>
                    1. Đầu tiên, bạn hãy vào Tiktok và lưu/tải video dự thi
                    tương ứng trên tiktok.
                  </p>
                  <button
                    onClick={() => {
                      window.open(`https://www.tiktok.com/`, "_blank");
                    }}
                    type="button"
                    className="btn btn-primary btn-border btn-block"
                  >
                    <a>Nhấn để tiếp tục trên TikTok</a>
                  </button>
                </>
              )}

              {/* <p className="text-success">
                <b>Tải bài dự thi thành công</b>
              </p>
              <p className="text-red">
                <b>Tải bài dự thi thất bại. Xin hãy thử lại</b>
              </p> */}
              <br />
              <p>
                2. Tiếp theo, hãy đăng video bài dự thi vừa tải lên trang cá
                nhân {platform} ở chế độ công khai.
              </p>
              <button
                onClick={() => {
                  window.open(`https://www.${platform}.com`, "_blank");
                }}
                type="button"
                className="btn btn-primary btn-border btn-block"
              >
                <a>Nhấn để tiếp tục trên {platform}</a>
              </button>
              <br />
              <p>3. Copy link video đã đăng trên {platform} và điền vào đây.</p>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="linkUrl"
                  name="linkUrl"
                  placeholder={`http://${platform}.com/123456789...`}
                  value={searchText}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.startsWith(" ")) {
                      setSearchText(value.trimStart());
                    } else {
                      setSearchText(value);
                    }
                  }}
                />
              </div>
              <p>
                Chi tiết cách lấy link bài dự thi xin hãy{" "}
                <a className="guideLink" onClick={onGuideGetLinkFB}>
                  xem ở đây
                </a>
                .
              </p>
            </div>
            <div className="group-btn-inline">
              <button
                onClick={() => {
                  setSearchText("");
                  closeModal("");
                }}
                type="button"
                className="btn btn-warning btn-block"
              >
                <a>Đóng</a>
              </button>
              <button
                onClick={() => onContinue(platform)}
                type="button"
                className={`btn btn-primary btn-block ${
                  searchText?.length === 0 && "disabled"
                }`}
              >
                <a>Liên kết</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupGuideFacebook;
