import TheFooterInner from "layout/TheFooterInner/TheFooterInner";
import React from "react";
import { useNavigate } from "react-router-dom";
const TheFooter = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer>
        <ul>
          <li className="flex-box">
            <div>
              <img src="assets/img/icon/hotline.svg" />{" "}
              <a target={"_blank"} href="tel:1900638880">
                <span>1900 638 880</span>
              </a>
            </div>
            <div>
              <img src="assets/img/icon/facebook.svg" />{" "}
              <a target={"_blank"} href="https://www.facebook.com/acecookcup">
                <span>Acecook Cup</span>
              </a>
            </div>
          </li>
          <li className="line-f"></li>
          <li>
            {/* <img src="assets/img/icon/LogoUnilever.svg" />{" "} */}
            <span>
              Bản quyền thuộc Công ty Cổ phần Acecook Việt Nam. <br />
              Powered by Utop
            </span>
          </li>
        </ul>
        <p className="d-none-mb">
          <img src="assets/img/text-footer.png" />
        </p>
      </footer>
    </>
  );
};

export default TheFooter;
