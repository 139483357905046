// @ts-nocheck
import { LINK_VIDEO } from "constants/common";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
const PopupBillTrue = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="popup-overlay  guide-true-false">
        <div className="popup-warp">
          <div className="close-modal" onClick={props.closeBillTrue}></div>
          <div class="popup-inner pad-popup">
            <div class="name-popup">Điều kiện hoá đơn hợp lệ</div>
            <div class="content-popup">
              <Slider {...settings}>
                <div class="swiper-slide pad-0">
                  <p>1. Không ghép, chắp nối hoá đơn.</p>
                  <div class="flex-slide">
                    <div class="flex-slide-item">
                      <h3 class="title true">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_true.png?ver=20210119'
                                     "
                          alt=""
                        />
                      </h3>
                      <img src="https://static.quatangunilever.vn/loyalty/assets/img/slider-1.jpeg?ver=20210119" />
                    </div>
                    <div class="flex-slide-item">
                      <h3 class="title false">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_false.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img
                        src="
                      https://static.quatangunilever.vn/loyalty/assets/img/slider-bill-false-1.jpeg?ver=20210119'
                    
                    "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="swiper-slide pad-0">
                  <p>2. Không gấp hóa đơn.</p>
                  <div class="flex-slide">
                    <div class="flex-slide-item">
                      <h3 class="title true">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_true.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img src="https://static.quatangunilever.vn/loyalty/assets/img/slider-1.jpeg?ver=20210119" />
                    </div>
                    <div class="flex-slide-item">
                      <h3 class="title false">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_false.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img
                        src="
                      https://static.quatangunilever.vn/loyalty/assets/img/slider-bill-false-2.jpeg?ver=20210119'
                    
                    "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="swiper-slide pad-0">
                  <p>
                    3. Không chụp hóa đơn nhàu nát <br />
                    không rõ thông tin.
                  </p>
                  <div class="flex-slide">
                    <div class="flex-slide-item">
                      <h3 class="title true">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_true.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img src="https://static.quatangunilever.vn/loyalty/assets/img/slider-1.jpeg?ver=20210119" />
                    </div>
                    <div class="flex-slide-item">
                      <h3 class="title false">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_false.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img
                        src="
                      https://static.quatangunilever.vn/loyalty/assets/img/slider-bill-false-3.jpeg?ver=20210119'
                    
                    "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="swiper-slide pad-0">
                  <p>4. Chụp đầy đủ tên chuỗi siêu thị và tên siêu thị.</p>
                  <div class="flex-slide">
                    <div class="flex-slide-item">
                      <h3 class="title true">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_true.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img src="https://static.quatangunilever.vn/loyalty/assets/img/slider-1.jpeg?ver=20210119" />
                    </div>
                    <div class="flex-slide-item">
                      <h3 class="title false">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_false.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img
                        src="
                      https://static.quatangunilever.vn/loyalty/assets/img/slider-bill-false-4.jpeg?ver=20210119'
                    
                    "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="swiper-slide pad-0">
                  <p>5. Không chụp ảnh không có hoá đơn.</p>
                  <div class="flex-slide">
                    <div class="flex-slide-item">
                      <h3 class="title true">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_true.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img src="https://static.quatangunilever.vn/loyalty/assets/img/slider-1.jpeg?ver=20210119" />
                    </div>
                    <div class="flex-slide-item">
                      <h3 class="title false">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_false.png?ver=20210119'
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img
                        src="
                      https://static.quatangunilever.vn/loyalty/assets/img/slider-bill-false-5.jpeg?ver=20210119'
                    
                    "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="swiper-slide pad-0">
                  <p>
                    6. Không chụp hoá đơn là ảnh chụp <br />
                    màn hình điện thoại, laptop.
                  </p>
                  <div class="flex-slide">
                    <div class="flex-slide-item">
                      <h3 class="title true">
                        <img
                          src="  https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_true.png?ver=20210119"
                          alt=""
                        />
                      </h3>
                      <img src="https://static.quatangunilever.vn/loyalty/assets/img/slider-1.jpeg?ver=20210119" />
                    </div>
                    <div class="flex-slide-item">
                      <h3 class="title false">
                        <img
                          src="
                        https://static.quatangunilever.vn/loyalty/assets/img/theme_2024/ic_bill_false.png?ver=20210119
                      
                      "
                          alt=""
                        />
                      </h3>
                      <img
                        src="
                      https://static.quatangunilever.vn/loyalty/assets/img/slider-bill-false-6.jpeg?ver=20210119
                    
                    "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupBillTrue;
