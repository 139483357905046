// @ts-nocheck
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable no-throw-literal */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import AppConfigs from "configs/env";
import axios from "axios";
import { CAMPAIGNID, TENANTID } from "constants/common";
import { auth } from "App";

const REQUEST_TIMEOUT = 60000;

const headersDefault = {
  "Content-Type": "application/json",
  Accept: "application/json; charset=utf-8",
  "Access-Control-Allow-Origin": "*",
  "Api-version": AppConfigs.apiVersion,
  "X-Environment": AppConfigs.xEnvironment,
  "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyCampaign,
  e_platform: "Web",
  tenantId: TENANTID,
};

const buildURLWithParams = (url, params = {}) => {
  let requestedURL = url;
  if (params) {
    const keys = Object.keys(params);

    if (Array.isArray(keys) && keys.length > 0) {
      requestedURL += "?";
      for (const property of keys) {
        const index = keys.indexOf(property);
        if (index > 0 && index < keys.length) {
          requestedURL += "&";
        }
        requestedURL += `${property}=${params[property]}`;
      }
    }
  }
  return requestedURL;
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (refreshToken) {
    try {
      const response = await fetch(`https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          grant_type: 'refresh_token',
          refresh_token: refreshToken
        })
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);
        return data.access_token;
      } else {
        console.error('Lỗi khi làm mới token:', data.error);
      }
    } catch (error) {
      console.error('Lỗi khi làm mới token:', error);
    }
  }

  return null;
};


const getToken = async () => {
  const user = auth.currentUser;
  const accessToken = localStorage.getItem("accessToken");

  if (user && !!accessToken) {
    try {
      const token = await user.getIdToken(true);
      localStorage.setItem("accessToken", token);
      localStorage.setItem("refreshToken", user.refreshToken); 
      return token;
    } catch (error) {
      console.error('Lỗi khi lấy token:', error);
    }
  } else {
    const newToken = await refreshAccessToken();
    if (newToken) {
      return newToken;
    }
  }
  return null;
};

const handleRequest = async (method, url, config) => {
  const { headers, params, body, ...restConfig } = config;

  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  const { signal } = controller;
  const accessToken = await getToken() || localStorage.getItem("accessToken");

  let headerTmp = {
    ...headersDefault,
    ...headers,
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  }

  if (url.includes('videosource')) {
    headerTmp = {
      ...headersDefault,
      ...headers,
    }
  }

  const fetchConfig = {
    cache: "default",
    credentials: "include",
    headers: headerTmp,
    ...restConfig,
    signal,
    method,
    data: body,
  };

  const requestedURL = buildURLWithParams(url, params);

  setTimeout(() => {
    controller.abort();
  }, REQUEST_TIMEOUT);

  return axios({ ...fetchConfig, url: requestedURL });
};

export default class APIUtils {
  static get(url, config = { headers: {}, params: {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await handleRequest("GET", url, config);
        resolve(response);
      } catch (error) {
        if (error?.response?.status === 401) {
          // Token expired, attempt to refresh and retry
          try {
            await getToken(); // Refresh the token
            const retryResponse = await handleRequest("GET", url, config);
            resolve(retryResponse);
          } catch (retryError) {
            reject(retryError);
          }
        } else {
          let dataErr = error?.response?.data;
          if (error?.code === "ERR_NETWORK") {
            dataErr = {
              error: {
                code: error?.code
              }
            }
          }
          reject({
            data: dataErr,
            status: error?.response?.status,
          });
        }
      }
    });
  }

  static post(url, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await handleRequest("POST", url, config);
        resolve(response);
      } catch (error) {
        if (error?.response?.status === 401) {
          try {
            await getToken();
            const retryResponse = await handleRequest("POST", url, config);
            resolve(retryResponse);
          } catch (retryError) {
            reject(retryError);
          }
        } else {
          let dataErr = error?.response?.data;
          if (error?.code === "ERR_NETWORK") {
            dataErr = {
              error: {
                code: error?.code
              }
            }
          }
          reject({
            data: dataErr,
            status: error?.response?.status,
          });
        }
      }
    });
  }

  static delete(url, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await handleRequest("DELETE", url, config);
        resolve(response);
      } catch (error) {
        if (error?.response?.status === 401) {
          try {
            await getToken();
            const retryResponse = await handleRequest("DELETE", url, config);
            resolve(retryResponse);
          } catch (retryError) {
            reject(retryError);
          }
        } else {
          let dataErr = error?.response?.data;
          if (error?.code === "ERR_NETWORK") {
            dataErr = {
              error: {
                code: error?.code
              }
            }
          }
          reject({
            data: dataErr,
            status: error?.response?.status,
          });
        }
      }
    });
  }

  static put(url, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await handleRequest("PUT", url, config);
        resolve(response);
      } catch (error) {
        if (error?.response?.status === 401) {
          try {
            await getToken();
            const retryResponse = await handleRequest("PUT", url, config);
            resolve(retryResponse);
          } catch (retryError) {
            reject(retryError);
          }
        } else {
          let dataErr = error?.response?.data;
          if (error?.code === "ERR_NETWORK") {
            dataErr = {
              error: {
                code: error?.code
              }
            }
          }
          reject({
            data: dataErr,
            status: error?.response?.status,
          });
        }
      }
    });
  }
}
