import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "routes";
const PopupLogout = (props) => {
  const location = useLocation();
  const commingSoon = JSON.parse(localStorage.getItem("commingSoon"));
  const [active, setActive] = useState(null);
  const [namePage, setNamePage] = useState("Home");
  const navigate = useNavigate();

  const handleNavigate = (e, params) => {
    props.handleCloseNav();
    navigate(e, params ? { state: { scrollToJoin: true } } : {});
  };

  const tmp = [
    ...routes,
    //   {
    //   path: "/thongtintichluy",
    //   name: "Thông tin tích lũy",
    // }
  ];

  // const tmp = [...routes]

  return (
    <>
      <div className="popup-overlay modal-top move">
        <div className="popup-warp">
          <div className="popup-inner">
            <div className="content-popup">
              <ul className={`${commingSoon ? "commingSoon" : ""}`}>
                {tmp.map((link, index) => {
                  return (
                    <>
                      {link.path !== "/chonbaihat" &&
                        link.path !== "/choncasi" &&
                        link.path !== "/lichsuchuphoadon" &&
                        link.path !== "/chitietbaithi" &&
                        link.path !== "/scanbillsuccess" &&
                        link.path !== "/preview" &&
                        link.path !== "/danhsachbaithi" &&
                        link.path !== "/scanbillguide" &&
                        link.path !== "/scanbill" &&
                        link.path !== "/xemlaibaithi" &&
                        link.path !== "/guideduet" &&
                        link.path !== "/quay" &&
                        link.path !== "/logout" &&
                        link.path !== "/pc" && (
                          <>
                            <li key={index} className="nav-item">
                              <a
                                className={`nav-link ${
                                  active == link.path && "active"
                                }`}
                                href={`${
                                  link.path === "guide" ||
                                  link.path === "joinNow"
                                    ? `#${link.path}`
                                    : "javascript:void(0)"
                                }`}
                                onClick={() => {
                                  if (link.path === "/thongtintichluy") {
                                    window.open(
                                      "https://web-sandbox.akachains.io"
                                    );
                                    props.handleCloseNav();
                                  } else {
                                    setActive(link.path);
                                    setNamePage(link.name);
                                    if (link.path === "guide") {
                                      handleNavigate(link.path, true);
                                    } else {
                                      handleNavigate(link.path);
                                    }
                                  }
                                }}
                              >
                                {link.name}
                              </a>
                            </li>
                          </>
                        )}
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="close-nav" onClick={props.handleCloseNav}></div>
        </div>
      </div>
    </>
  );
};
export default PopupLogout;
