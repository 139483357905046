import { LINK_VIDEO } from "constants/common";
import TheFooter from "layout/TheFooter";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
const GuideDuetPage = (props) => {
  useEffect(() => {
    $(window).scrollTop(0);
  });
  return (
    <>
      <div className="container rules">
        <h2 className="name-block d-none-mb">
          <img src="assets/img/Title-guide.png" alt="Title-guide.png" />
        </h2>
        <h2 className="name-block d-none">
          <img src="assets/img/Title-guide-mb.png" alt="Title-guide.png" />
        </h2>
        <ul className="numberList">
          <li>
            <p>
              <b>
                Hướng dẫn đăng nhập bằng SĐT để tham gia chương trình Hòa Nhạc
                Mì Ly, Trúng Ngay Mic Vàng
              </b>
            </p>
            <div className="videoWrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/1i105XEDeEg?si=5FqtdbQ5ppZN2Hu9"
                title="HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </li>
          <li>
            <p>
              <b>
                Hướng dẫn quay video bài dự thi trực tiếp trên website chương
                trình
              </b>
            </p>
            <div className="videoWrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/AOs3mwm1dKk?si=zKAiQo_li2C9DUX9"
                title="HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </li>
          <li>
            <p>
              <b>Hướng dẫn quay video Duet trên Tiktok</b>
            </p>
            <div className="videoWrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ykGhLJchmAc?si=FdtljF_Eoa9TCpct"
                title="HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </li>
          <li>
            <p>
              <b>
                Hướng dẫn quay video dự thi trên Tiktok Acecookvietnam và gắn
                link tạo bài dự thi trên Website
              </b>
            </p>
            <div className="videoWrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/EItTwZheVjo?si=HTw2UPEmpOz7R8KX"
                title="HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </li>
          <li>
            <p>
              <b>
                Hướng dẫn liên kết bài dự thi trên Facebook cá nhân với trang
                Website chương trình
              </b>
            </p>
            <div className="videoWrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/v96o3ZVR37w?si=YoCKKR2pFCbz_IPa"
                title="HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </li>
          <li>
            <p>
              <b>
                Hướng dẫn liên kết bài dự thi trên Tiktok cá nhân với trang
                Website chương trình
              </b>
            </p>
            <div className="videoWrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/r9iXupFuA9I?si=JRJnIpOUdVeFslD0"
                title="HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </li>
          <li>
            <p>
              <b>
                Hướng dẫn tìm kiếm và like video cho bạn bè trong chương trình
                Hòa Nhạc Mì Ly, Trúng Ngay Mic Vàng
              </b>
            </p>
            <div className="videoWrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/-Pn6aDV3P-4?si=zjjyMBkM3wmELrWR"
                title="HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </li>
        </ul>
      </div>
      <TheFooter />
    </>
  );
};
export default GuideDuetPage;
