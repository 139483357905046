// @ts-nocheck
import AppConfigs from "configs/env";
import { END_POINTS } from "constants/apiUrl";
import { CAMPAIGNID, TENANTID_SCAN_BILL } from "constants/common";
import { APIUtils } from "utils";

export const getSasTokenApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_SASTOKEN_URL_FOR_SCAN_BILL + '/' +payload , {
    params: '',
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      "CampaignId": CAMPAIGNID,
      "tenantId": TENANTID_SCAN_BILL

    },
  });
};


export const executeInvoiceApi = (payload) => {
  return APIUtils.post(END_POINTS.EXECUTE_INVOICE_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      "CampaignId": CAMPAIGNID,
      "tenantId": TENANTID_SCAN_BILL
    },
  });
};

export const requestCheckApi = (payload) => {
  return APIUtils.post(END_POINTS.REQUEST_CHECK_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      "CampaignId": CAMPAIGNID,
      "tenantId": TENANTID_SCAN_BILL
    },
  });
};

export const uploadInvoiceApi = (payload) => {
  return APIUtils.post(END_POINTS.UPLOAD_INVOICE_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      "CampaignId": CAMPAIGNID,
      "tenantId": TENANTID_SCAN_BILL
    },
  });
};

export const shopperApproveApi = (payload) => {
  return APIUtils.post(END_POINTS.SHOPPER_APPROVE_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      "CampaignId": CAMPAIGNID,
      "tenantId": TENANTID_SCAN_BILL
    },
  });
};

export const checkStatusFilesApi = (payload) => {
  return APIUtils.post(END_POINTS.CHECK_STATUS_FILE_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      "CampaignId": CAMPAIGNID,
      "tenantId": TENANTID_SCAN_BILL
    },
  });
};
