// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import TheFooter from "layout/TheFooter";

const DuetCameraView = (props) => {
  const {
    playVideoReview,
    linkVideo,
    videoId,
    videoRef,
    playVideoTiktok,
    isPlay,
    setIsPlay,
    iframeRef,
    isLoading,
    onPauseVideoTiktok,
  } = props;
  const navigate = useNavigate();

  const [isHidePlay, setIsHidePlay] = useState(false);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <section>
      <div className="duet-camera">
        <div className="control-duet">
          <span
            className="i-back"
            onClick={() => {
              window.history.back();
            }}
          ></span>
        </div>
        {!!videoId ? (
          <div className="video-warp">
            {!isPlay && (
              <span
                className="playCam"
                onClick={() => {
                  setIsPlay(true);
                  if (!!videoId) {
                    playVideoTiktok();
                  } else {
                    videoRef?.current?.play();
                  }
                }}
              ></span>
            )}
            <div
              className="videoIframe"
              onClick={() => {
                try {
                  if (!!videoId) {
                    if (isPlay) {
                      onPauseVideoTiktok();
                    } else {
                      playVideoTiktok();
                    }
                    setIsPlay(!isPlay);
                  }
                } catch (error) {}
              }}
            >
              <iframe
                src={`https://www.tiktok.com/player/v1/${videoId}?music_info=0&description=0&fullscreen_button=0&progress_bar=0&rel=0`}
                width="100%"
                height="600"
                frameborder="0"
                style={{ pointerEvents: "none" }}
                allow="autoplay; encrypted-media"
                ref={iframeRef}
                allowfullscreen
              ></iframe>
            </div>
          </div>
        ) : (
          <div className="video-warp">
            {!isPlay && (
              <span
                className="playCam"
                onClick={() => {
                  setIsPlay(true);
                  if (!!videoId) {
                    playVideoTiktok();
                  } else {
                    videoRef?.current?.play();
                  }
                }}
              ></span>
            )}
            <video
              webkit-playsinline="true"
              playsInline
              width="100%"
              height="600"
              ref={videoRef}
              src={`${linkVideo}#t=0.001`}
              style={{ objectFit: "contain" }}
              onClick={(e) => {
                try {
                  if (linkVideo) {
                    if (isPlay) {
                      videoRef?.current?.pause();
                    } else {
                      videoRef?.current?.play();
                    }
                    setIsPlay(!isPlay);
                  }
                } catch (error) {}
              }}
              onEnded={() => {
                setIsPlay(false);
              }}
            ></video>
          </div>
        )}

        {!!videoId ? (
          <div className="group-btn-inline noFixed container">
            <button
              type="button"
              className="btn btn-warning  btn-block"
              onClick={() => {
                // setPause(false);
                // setPlay(false);
                // props.sendPost();
                // reRecord();
                // window.history.back();
                navigate("/choncasi");
              }}
            >
              <a>Chọn bài khác</a>
            </button>
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => {
                if (!isLoading) {
                  props.sendPost();
                }
              }}
            >
              <a>Đăng bài</a>
            </button>
          </div>
        ) : (
          <div className="btn-wrapper btn-center">
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                window.history.back();
              }}
            >
              <a>ĐÓNG</a>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};
export default DuetCameraView;
