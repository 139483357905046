// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PopupCheckPC = (props) => {
  const { closePopupPC } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="popup-overlay modal-success">
        <div className="popup-warp">
          <div
            className="close-modal"
            onClick={closePopupPC}
          ></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>THÔNG BÁO</h3>
              <img
                className="img-gift"
                src="assets/img/Iphone.png"
                alt="200.png"
              />
              <p>
                Tính năng này chỉ dành trên điện thoại.
                <br />
                Vui lòng truy cập lại website này trên điện thoại di động
              </p>
            </div>
            <div className="btn-wrapper btn-center">
              <button
                onClick={closePopupPC}
                type="button"
                className="btn btn-primary btn-block "
              >
                <a>ĐÓNG</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupCheckPC;
