// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupGuideLink = (props) => {
  const navigate = useNavigate();
  const { closeModal } = props;

  return (
    <>
      <div className="popup-overlay modal-notify facebook-point">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>CHI TIẾT CÁCH TÍNH ĐIỂM</h3>

              <p>
                <b>Cách thức tính điểm như sau:</b>
              </p>
              <ul>
                <li>
                  <b className="green">Tổng điểm cả ba nền tảng: </b>
                  <p>+ Điểm quy đổi từ react/share trên FB.</p>
                  <p>+ Điểm quy đổi từ like/share trên Tiktok. </p>
                  <p>+ Điểm trên website chương trình.</p>
                </li>
              </ul>
              <ul>
                <li>
                  <b className="yellow">Tổng điểm website chương trình: </b>
                  <p>+ Điểm quy đổi từ hóa đơn sản phẩm</p>
                  <p>+ Điểm từ lượt like trên website chương trình.</p>
                </li>
              </ul>
              <p>
                <b>Chi tiết hệ số quy đổi:</b>
              </p>
              <ul>
                <li>
                  1 like/react trên Facebook, Tiktok hoặc website chương trình =
                  1 điểm.
                </li>
                <li>1 share video trên Facebook, Tiktok = 5 điểm.</li>
                <li>
                  {" "}
                  500 đồng trên hóa đơn khi mua sản phẩm nằm trong{" "}
                  <a href="/thelechuongtrinh" target="_blank">thể lệ chương trình</a> (tại các
                  siêu thị áp dụng chương trình) = 2 điểm.
                </li>{" "}
              </ul>
            </div>
            <div className="group-btn-inline">
              <button
                onClick={() => {
                  closeModal(false);
                }}
                type="button"
                className="btn btn-warning btn-block"
              >
                <a>Đóng</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupGuideLink;
