import { actionGenerator } from "utils";
import {
  GET_SASTOKEN,
  EXECUTE_INVOICE,
  SHOPPER_APPROVE,
  REQUEST_CHECK,
  UPLOAD_INVOICE,
  CHECK_STATUS_FILE_URL

} from "./constants";

const actions = {
  GET_SASTOKEN: actionGenerator.genActions(GET_SASTOKEN),
  EXECUTE_INVOICE: actionGenerator.genActions(EXECUTE_INVOICE),
  SHOPPER_APPROVE: actionGenerator.genActions(SHOPPER_APPROVE),
  REQUEST_CHECK: actionGenerator.genActions(REQUEST_CHECK),
  UPLOAD_INVOICE: actionGenerator.genActions(UPLOAD_INVOICE),
  CHECK_STATUS_FILE_URL: actionGenerator.genActions(CHECK_STATUS_FILE_URL),
};
export default actions;
