// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import { Popup, SliderComponent } from "./components";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import Select from "react-select";
import TheFooter from "layout/TheFooter";
import { PROVINCES, TELCO } from "constants/common";

// import * as styles from "./styles.module.scss";
// import {bgs, than,btn1, btn2, btn3 } from "assets/img"
const HomePageView = (props) => {
  const {
    provinceSelected,
    setProvinceSelected,
    telcoSelected,
    setTelcoSelected,
    winnersHome,
  } = props;
  const navigate = useNavigate();
  const phonenumberRef = useRef();
  const fullnameRef = useRef();
  const commingSoon = JSON.parse(localStorage.getItem("commingSoon"));
  const campainEnd = JSON.parse(localStorage.getItem("campainEnd"));
  return (
    <section>
      {/* <div className="container">
        <div className="box-warp">
          <p>Thời gian: <strong>20.03.2023 - 20.05.2023</strong></p>
          <div className="box-info">
            <p>Kết quả sẽ được thông báo trong mục Danh sách trúng thưởng của website</p>
            <p><strong>HaoHao.QUATANGUNILEVER.COM</strong></p>
            <p>Thời gian quay số trúng thưởng các giải thưởng được thông báo trong mục Thể lệ.</p>
          </div>
        </div>
      </div> */}
      <TheBanner />
      <div className="bgr-green">
        <div className="group-action container d-none">
          <div className="group-btn-inline">
            <button
              type="button"
              className="btn btn-warning btn-block"
              onClick={() => {
                navigate("/");
              }}
            >
              <a href="#joinNow">SĂN THẺ CÀO</a>
            </button>
            <button
              type="button"
              className={`btn btn-primary  btn-block ${
                commingSoon ? "disabled" : ""
              }`}
              onClick={() => {
                navigate("/hatcungthantuong");
              }}
            >
              <a>THAM GIA DUET</a>
            </button>
          </div>
          <CommingSoonCountDown />
        </div>

        {winnersHome?.length > 0 && (
          <>
            <div className="container d-none">
              <img
                className="img-full title-slider "
                src={"assets/img/title-slider-mb.png"}
              />
            </div>
            <img
              className="img-full title-slider d-none-mb"
              src={"assets/img/title-slider.png"}
            />
            <div className="container">
              <SliderComponent listWinnerSpecial={winnersHome} />
            </div>
          </>
        )}

        {/* {props?.listPicture.length > 0 && (
          <>
            <div className="container">
              <div className="line "></div>
            </div>
            <div className="container">
              <SliderComponent listPicture={props?.listPicture} />
            </div>
          </>
        )} */}
        <div className="group-sun">
          {winnersHome?.length > 0 && <div className="line "></div>}
          <img className="img-full d-none-mb" src="assets/img/title-rule.png" />
          <div className="container">
            <img
              className="img-full d-none"
              src="assets/img/title-rule-mb.png"
            />
            <div className="home-rule">
              <div className="rule-item">
                <div className="rule-item-inner">
                  <h3>
                    <span>Săn thẻ cào trong ly</span>
                  </h3>
                  <p>
                    <b>1. Các sản phẩm áp dụng:</b>
                  </p>
                  <p>
                    - Mì Ly Modern (hương vị Bò Hầm Rau Thơm/ Thịt Xào/ Lẩu Thái
                    Tôm/ Riêu Cua)
                  </p>
                  <p>
                    - Mì Ly Handy Hảo Hảo (hương vị Tôm Chua Cay/ Tomyum/ Lẩu
                    Nấm Thập Cẩm)
                  </p>
                  <p>- Mì Ly Caykay (hương vị Bò/ Kim Chi)</p>
                  <p>
                    - Nhớ Mãi Mãi (hương vị Mì Lẩu Thái/ Phở Bò/ Hủ Tiếu Nam
                    Vang/ Miến Sườn Heo)
                  </p>
                  <p>
                    <b>2. Thời gian áp dụng: </b>Từ 00:00 ngày 12/08/2024 đến
                    23:59 ngày 11/11/2024
                  </p>
                  <p>
                    <b>3. Địa bàn (phạm vi) khuyến mại:</b> Trên toàn lãnh thổ
                    Việt Nam
                  </p>
                  <p>
                    <b>4. Cơ cấu giải thưởng:</b>
                  </p>
                  <div className="scroll-table">
                    <table className="rules-table">
                      <tr>
                        <th>Cơ cấu giải thưởng</th>
                        <th style={{ width: 300 }}>Nội dung giải thưởng</th>
                        <th style={{ width: 300 }}>
                          Trị giá giải thưởng (VND)
                        </th>
                        <th>Số giải</th>
                        <th>Tổng giá trị giải thưởng (VND)</th>
                      </tr>
                      <tr>
                        <td>Giải I​</td>
                        <td>
                          01 Ly bằng vàng 24K từ thương hiệu PNJ, tương đương 7
                          chỉ vàng. (Giá trị 7 chỉ vàng tại thời điểm đăng ký:
                          54.313.000 VND; chi phí gia công cho 7 chỉ vàng:
                          7.000.000 VND)
                        </td>
                        <td>61.313.000</td>
                        <td>6</td>
                        <td>367.878.000</td>
                      </tr>
                      <tr>
                        <td>Giải II</td>
                        <td>
                          01 ĐIỆN THOẠI IPHONE 15​​ Pro 128gb, màu Titan tự
                          nhiên
                        </td>
                        <td>24.490.000</td>
                        <td>18​</td>
                        <td>440.820.000</td>
                      </tr>
                      <tr>
                        <td>Giải III</td>
                        <td>
                          01 SMART TIVI SAMSUNG CRYSTAL UHD 4K 55 INCH
                          UA55AU7700KXXV
                        </td>
                        <td>9.190.000</td>
                        <td>30</td>
                        <td>275.700.000​</td>
                      </tr>
                      <tr>
                        <td>Giải IV</td>
                        <td>01 tài khoản điện thoại trị giá 150.000 đồng</td>
                        <td>150.000</td>
                        <td>5.000​</td>
                        <td>750.000.000​</td>
                      </tr>
                      <tr>
                        <td>Giải V</td>
                        <td>01 tài khoản điện thoại trị giá 100.000 đồng</td>
                        <td>100.000</td>
                        <td>35.000​</td>
                        <td>3.500.000.000</td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="text-center">
                          <b>TỔNG CỘNG (bao gồm VAT)</b>
                        </td>
                        <td>
                          <b>40.054</b>
                        </td>
                        <td>
                          <b>5.334.398.000</b>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <ul>
                    <li>
                      Giải V trúng ngay (giải thưởng là tài khoản điện thoại
                      100k) dành cho 35,000 mã code được gởi sớm nhất về chương
                      trình.
                    </li>
                    <li>
                      Giải IV vòng quay may mắn (giải thưởng là tài khoản điện
                      thoại 150k) dành cho Người tiêu dùng (NTD) thực hiện quay
                      vòng quay may mắn trên Microsite để xác nhận trúng thưởng.
                    </li>
                    <li>
                      Giải lớn (I,II,III) được Quay số định kỳ bởi Ban Tổ Chức
                      sẽ tiến hành quay số (2 tuần/ lần). Phát trực tiếp buổi
                      quay số trên Microsite và fanpage Acecook Cup.
                    </li>
                    <li>
                      Tổng giá trị giải thưởng trong chương trình khuyến mại đã
                      bao gồm thuế GTGT là: 5.334.398.000 đồng (bằng chữ: Năm tỷ
                      ba trăm ba mươi bốn triệu ba trăm chín mươi tám ngàn đồng
                      chẵn./.).
                    </li>
                  </ul>
                  <p>
                    <b>5. Nội dung và thể lệ chương trình:</b>
                  </p>
                  <p>
                    - Trong thời gian khuyến mại, khi mua bất kỳ sản phẩm thuộc
                    loại sản phẩm khuyến mại chương trình quy định, khách hàng
                    sẽ có cơ hội nhận được 01 (một) thẻ cào đặt bên trong sản
                    phẩm khuyến mại. Khách hàng nhận được mã thẻ cào làm theo
                    hướng dẫn in trên thẻ cào nhận cơ hội trúng thưởng:
                  </p>
                  <ul>
                    <li>
                      Khách hàng có mã thẻ cào thuộc 35.000 mã thẻ cào đầu tiên
                      tham gia Chương Trình hợp lệ sẽ chắc chắn trúng Giải V.
                    </li>
                    <li>
                      Khách hàng có mã thẻ cào không thuộc nhóm 35.000 mã thẻ
                      cào đầu tiên tham gia Chương Trình hợp lệ sẽ được tham gia
                      vòng quay trên Website Chương Trình để có cơ hội trúng
                      giải IV.
                    </li>
                    <li>
                      Tất cả khách hàng tham gia chương trình hợp lệ (kể cả
                      khách hàng đã trúng thưởng Giải IV và Giải V) sẽ có cơ hội
                      trúng Giải I, giải II hoặc Giải III bằng hình thức quay số
                      trúng thưởng.
                    </li>
                  </ul>
                  <p>
                    <b>Cách thức tham gia gửi mã dự thưởng</b>{" "}
                  </p>
                  <p>
                    Người tiêu dùng có 3 cách để gửi mã dự thưởng tham gia
                    chương trình:
                  </p>
                  <p>
                    <b>Cách 1: Nhập mã thẻ cào tại Website</b>{" "}
                    “www.KhuyenmaiAcecook.com”
                    <p>
                      Khách hàng truy cập trực tiếp vào Website Chương Trình tại
                      đường dẫn www.KhuyenmaiAcecook.com hoặc quét mã QR trên
                      bao bì sản phẩm khuyến mại để vào Website Chương trình.
                    </p>{" "}
                    <b />
                    <b>
                      Cách 2: Nhắn tin mã thẻ cào đến tổng đài tin nhắn 8066
                    </b>
                  </p>
                  <p>
                    Khách hàng nhắn tin mã thẻ cào theo cú pháp “ACECOOK{"<"}Mã
                    số thẻ cào{">"}” tới tổng đài tin nhắn 8066 (1,000 VND/01
                    tin nhắn).
                  </p>
                  <p>
                    <b>
                      Cách 3: Truy cập tài khoản Zalo OA “Acecook Việt Nam” và
                      nhắn tin mã thẻ cào theo hướng dẫn
                    </b>
                  </p>
                  <p>
                    Quy định về đầu mối giải đáp thắc mắc liên quan đến chương
                    trình khuyến mại:
                  </p>
                  <p>
                    Mọi thắc mắc liên quan đến chương trình khuyến mại này,
                    khách hàng liên hệ đến Hotline 1900638880 (1,000 đồng/phút)
                    để được hướng dẫn, giải đáp. Hotline hoạt động từ 08:00 đến
                    20:00 hằng ngày (kể cả ngày lễ, thứ Bảy, Chủ Nhật) bắt đầu
                    từ ngày 12/08/2024 đến ngày 20/11/2024.
                  </p>
                </div>
                <div className="btn-wrapper btn-center">
                  <button
                    type="button"
                    className={`btn btn-warning    ${
                      commingSoon ? "disabled" : ""
                    }`}
                    // onClick={() => {
                    //   navigate("/thelechuongtrinh");
                    // }}
                  >
                    <a href="/thelechuongtrinh" target="_blank">
                      xem thể lệ đầy đủ
                    </a>
                  </button>
                </div>
              </div>
              <div className="rule-line d-none-mb"></div>
              <div className="line d-none"></div>
              <div className="rule-item">
                <div className="rule-item-inner">
                  <h3>
                    <span>hát cùng thần tượng</span>
                  </h3>
                  <p>
                    <strong>
                      1. Tên chương trình: Hát cùng thần tượng, cơ hội trúng mic
                      vàng
                    </strong>
                  </p>
                  <p>
                    <strong>2. Thời gian tham gia:</strong> từ 00h00 ngày
                    12/08/2024 đến 23h59 ngày 11/11/2024
                  </p>
                  <p>
                    {" "}
                    <strong>3.Cách thức tham gia:</strong>
                    Người tham gia có 2 cách thực hiện để tạo và gửi bài dự thi:
                  </p>
                  <p>
                    <strong>
                      <u>Cách 1: </u>Tham gia thông qua Website chương trình{" "}
                    </strong>
                    www.KhuyenmaiAcecook.com
                  </p>
                  <p>
                    <strong>Bước 1:</strong> Truy cập Website
                    www.KhuyenmaiAcecook.com, chọn “Tham gia Duet” {"->"} chọn
                    “Tham gia ngay”. Sau đó chọn 1 trong 3 ca sĩ bạn yêu thích
                    để cùng song ca
                  </p>
                  <ul>
                    <li>Sơn Tùng M-TP (02 đoạn nhạc)</li>

                    <li>Mỹ Mỹ (02 đoạn nhạc)</li>

                    <li>Anh Tú (01 đoạn nhạc)</li>
                  </ul>
                  <p>
                    <strong>Bước 2:</strong> Chọn “Bài hát” và chọn “Quay ngay”
                    và cùng hát đoạn nhạc với ca sĩ đã chọn. Sau khi quay xong
                    thì chọn “Lưu bài”.
                  </p>
                  <p>
                    <strong>Bước 3:</strong> Sau khi đợi hệ thống xử lý video
                    xong thì chọn “Đăng bài”. Bài dự thi sẽ được Ban tổ chức
                    kiểm duyệt theo quy định và sẽ duyệt bài thi nếu đúng quy
                    định hợp lệ.
                  </p>
                  <p>
                    <strong>Bước 4 (không bắt buộc thực hiện):</strong> Người
                    chơi có thể chia sẻ video lên các nền tảng mạng xã hội
                    (Facebook, Tiktok) để được cộng thêm điểm nếu muốn, bằng
                    cách tải lưu video về và đăng video lên các nền tảng mạng xã
                    hội ở chế độ công khai kèm hastag #HoaNhacMiLy
                    #TrungNgayMicVang. Trong trường hợp này, người chơi cần thực
                    hiện liên kết tài khoản Facebook/Tiktok cá nhân với trang
                    website chương trình và gắn đường link video đã đăng trên
                    các nền tảng mạng xã hội (Facebook/Tiktok) vào trang website
                    chương trình www.KhuyenmaiAcecook.com để được cộng thêm điểm
                    từ lượt like/share.
                  </p>{" "}
                  <br />
                  <p>
                    <strong>
                      <u>Cách 2:</u>
                      Tham gia thông qua Tiktok “Acecook Vietnam”
                    </strong>{" "}
                  </p>
                  <p>
                    {" "}
                    <strong>Bước 1:</strong> Truy cập trang Tiktok “Acecook
                    Vietnam” https://www.tiktok.com/@acecook.vietnam , Chọn
                    video tiktok tương ứng với đoạn nhạc của ca sĩ mà mình muốn
                    song ca.
                  </p>
                  <ul>
                    <li>Sơn Tùng M-TP (02 đoạn nhạc)</li>

                    <li>Mỹ Mỹ (02 đoạn nhạc)</li>

                    <li>Anh Tú (01 đoạn nhạc)</li>
                  </ul>
                  <p>
                    {" "}
                    <strong> Bước 2:</strong> Bấm nút “Chia sẻ”, chọn tính năng
                    “Duet”.
                  </p>
                  <p>
                    {" "}
                    <strong>Bước 3:</strong> Hoàn tất video và đăng tải bài thi
                    lên trang Tiktok Cá Nhân ở chế độ công khai kèm hastag
                    #HoaNhacMiLy #TrungNgayMicVang
                  </p>
                  <p>
                    {" "}
                    <strong>Bước 4: </strong>Liên kết tài khoản Tiktok cá nhân
                    với website chương trình (bằng cách vào website
                    www.KhuyenmaiAcecook.com -> vào mục “Tham gia Duet” -> chọn
                    nút “tham gia ngay” -> chọn ca sĩ -> chọn nút “Quay trên
                    TikTok” -> chọn “Liên kết”)
                  </p>
                  <p>
                    {" "}
                    <strong>Bước 5:</strong> Gắn đường link video Tiktok vào
                    website để tạo bài dự thi (bằng cách chọn nút “Quay trên
                    TikTok” trên website chương trình -> nhập đường link video
                    TikTok vào ô)
                  </p>
                  <p>
                    {" "}
                    <strong>Bước 6:</strong> Chọn "Đăng bài" để nộp bài dự thi
                    và chờ BTC duyệt bài dự thi. Bài dự thi gốc trên tiktok sẽ
                    được đăng kí để cộng điểm từ lượt like/share.
                  </p>
                  <p>
                    {" "}
                    <strong>Bước 7: </strong>(không bắt buộc thực hiện): Người
                    chơi có thể chia sẻ bài dự thi video lên thêm trên nền tảng
                    mạng xã hội khác (Facebook - ở chế độ công khai) để được
                    cộng thêm điểm nếu muốn. Trong trường hợp này, người chơi
                    cần liên kết tài khoản Facebook cá nhân với trang website
                    chương trình www.KhuyenmaiAcecook.com và gắn đường link
                    video đã đăng trên Facebook để được BTC tính điểm cộng thêm
                    hợp lệ.
                  </p>
                  <p>
                    Đặc biệt ngoài ra, người chơi có thể được cộng điểm cho bài
                    dự thi của mình bằng cách:
                  </p>
                  <ul>
                    <li>
                      Mua bất kỳ sản phẩm mì ly Modern/ Handy Hảo Hảo/ Caykay/
                      Tô Nhớ Mãi Mãi tại các siêu thị hoặc cửa hàng tiện lợi
                      trên toàn quốc có tên trong danh sách hệ thống áp dụng
                      trong thời gian diễn ra chương trình;
                    </li>
                    <li>
                      Đăng tải ảnh chụp/ scan hoá đơn mua hàng trên website
                      chương trình www.KhuyenmaiAcecook.com để nhận thêm điểm.
                    </li>
                  </ul>
                  <p>
                    <strong>4. Cách thức tính điểm:</strong>
                  </p>
                  <ul>
                    <li>
                      Mỗi lượt share của bài đăng trên Facebook và Tiktok: 5
                      điểm
                    </li>

                    <li>
                      Mỗi lượt tương tác mạng xã hội (Facbook, TikTok) và trên
                      Website www.KhuyenmaiAcecook.com: 1 điểm
                    </li>

                    <li>
                      {" "}
                      Đối với hóa đơn mua hàng: Với mỗi giá trị 500 đồng (Bằng
                      chữ: Năm trăm đồng) trên hóa đơn khi mua bất kỳ sản phẩm
                      mì ly Modern/ Handy Hảo Hảo/ Caykay/ Tô Nhớ Mãi Mãi (không
                      tính sản phẩm khác) sẽ được quy đổi thành 2 điểm. Càng
                      nhiều số lượng hoá đơn mua sản phẩm mì ly của Acecook hoặc
                      hóa đơn có giá trị mua sản phẩm mì ly Acecook càng cao
                      được đăng tải hơp lệ trên Website chương trình
                      www.KhuyenmaiAcecook.com thì người tham gia sẽ được quy
                      đổi ra càng nhiều điểm bình chọn.
                    </li>
                  </ul>
                  <p>
                    <strong>5. Cơ cấu giải thưởng</strong>
                  </p>
                  <p>
                    <b>
                      01 Giải Đặc Biệt 1: là 01 microphone bằng vàng 24K từ
                      thương hiệu PNJ với trọng lượng 8 chỉ vàng trị giá 70
                      triệu đồng
                    </b>{" "}
                    dành cho bài dự thi hợp lệ và có tổng điểm bình chọn cao
                    nhất ở cả 3 nền tảng Website/Facebook/Tiktok. Giải thưởng
                    không quy đổi thành tiền mặt.
                  </p>
                  <p>
                    <b>
                      01 Giải Đặc Biệt 2: là 01 microphone bằng vàng 24K từ
                      thương hiệu PNJ với trọng lượng 8 chỉ vàng trị giá 70
                      triệu đồng
                    </b>{" "}
                    dành cho bài dự thi hợp lệ và có điểm bình chọn cao nhất ở 1
                    nền tảng Website chương trình. Giải thưởng không quy đổi
                    thành tiền mặt.
                  </p>
                  <p>
                    (Tổng số điểm bình chọn tính trên 1 nền tảng Website sẽ gồm:
                    số lượt Like của video bài dự thi + số điểm được quy đổi từ
                    hóa đơn mua hàng)
                  </p>
                  <p>
                    <strong>6. Quy định khác:</strong>
                  </p>
                  <ul>
                    <li>
                      Người nhận giải phải là cùng 1 người trong video bài dự
                      thi.
                    </li>
                    <li>
                      BTC có quyền hủy kết quả, không trao giải hoặc thu hồi các
                      quà tặng đối với các trường hợp có dấu hiệu, vi phạm thể
                      lệ, gian lận hoặc trục lợi từ chương trình.
                    </li>
                    <li>
                      Bài dự thi phải đảm bảo thuần phong mỹ tục, không vi phạm
                      đạo đức xã hội và các quy định về pháp luật Việt Nam hiện
                      hành.
                    </li>
                  </ul>
                </div>
                <div className="btn-wrapper btn-center">
                  <button
                    type="button"
                    className={`btn btn-warning   ${
                      commingSoon ? "disabled" : ""
                    }`}
                    // onClick={() => {
                    //   navigate("/thelechuongtrinh");
                    // }}
                  >
                    <a href="/thelechuongtrinh" target="_blank">
                      xem thể lệ đầy đủ
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <img className="img-full d-none-mb" src="assets/img/title-join.png" />
          <img className="img-full d-none" src="assets/img/title-join-mb.png" />
          <div className="group-flex container">
            <section className="guide-warp" id="guide">
              <h2 className="name-block d-none-mb">
                <img src="assets/img/Title-guide.png" alt="Title-guide.png" />
              </h2>
              <h2 className="name-block d-none">
                <img
                  src="assets/img/Title-guide-mb.png"
                  alt="Title-guide.png"
                />
              </h2>
              <div className="guide">
                <ul className="container">
                  <li>
                    <div className="content-step">
                      <div className="step">Bước 1</div>
                      <p>Mua 01 trong các sản phẩm khuyến mại:</p>
                      <p></p>
                      <img
                        className="magr16 d-none-mb"
                        src="assets/img/logos.png"
                      />
                    </div>
                    <img src="assets/img/imgstep1.png" />
                    <img
                      className="magr16 d-none"
                      src="assets/img/logos-mb.png"
                    />
                  </li>
                  <li>
                    <div className="content-step">
                      <div className="step">Bước 2</div>
                      <p>Tìm thẻ cào đặt bên trong sản phẩm khuyến mại</p>
                    </div>
                    <img src="assets/img/imgstep2.png" />
                  </li>
                  <li>
                    <div className="step3content">
                      <div className="content-step">
                        <div className="step">Bước 3</div>
                        <p>Đăng ký tham gia theo 1 trong 3 cách sau:</p>
                      </div>

                      <img className="d-none" src="assets/img/imgstep3.png" />
                    </div>
                    <ul className="warp-step">
                      <li>
                        <img src="assets/img/imgstep3c1.png" />
                        <div>
                          <p>
                            <strong className="stepNumber">Cách 1</strong>
                            <br />
                            Nhắn tin mã thẻ cào theo cú pháp{" "}
                            <strong>
                              {" "}
                              “ACECOOK{"<"}Mã số thẻ cào{">"}”
                            </strong>{" "}
                            gửi <strong>8066</strong> (1,000 đồng/tin nhắn)
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src="assets/img/imgstep3c2.png" />
                        <div>
                          <p>
                            <strong className="stepNumber">Cách 2</strong>
                            <br />
                            Truy cập vào website{" "}
                            <strong>khuyenmaiacecook.com </strong>
                            và nhập mã thẻ cào theo hướng dẫn (miễn phí)
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src="assets/img/imgstep3c3.png" />
                        <div>
                          <p>
                            <strong className="stepNumber">Cách 3</strong>
                            <br />
                            Truy cập tài khoản{" "}
                            <strong>Zalo OA "Acecook Việt Nam"</strong> và nhắn
                            tin mã thẻ cào theo hướng dẫn (miễn phí)
                          </p>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <div className="content-step">
                      <div className="step">Bước 4</div>
                      <p>Nhận thông báo kết quả trúng thưởng</p>
                    </div>
                    <img src="assets/img/rafiki.png" />
                  </li>
                </ul>
              </div>
            </section>
            <section className="register-warp" id="joinNow">
              <h2 className="name-block d-none-mb">
                <img src="assets/img/Title-form.png" alt="Title-form.png" />
              </h2>
              <h2 className="name-block d-none">
                <img src="assets/img/Title-form-mb.png" alt="Title-form.png" />
              </h2>
              <div className="register-inner">
                <div className="container">
                  <p className="text-note">
                    Vui lòng điền đầy đủ thông tin bên dưới
                  </p>
                  <div
                    className={`register ${props.isHideForm ? "hideForm" : ""}`}
                  >
                    <form
                      className="register-form"
                      onSubmit={props.handleSubmit}
                    >
                      {" "}
                      <div className="form-group">
                        <label className="title-form" htmlFor="usr">
                          Họ và tên <span>*</span>
                        </label>
                        <div className="form-field">
                          <img
                            src="assets/img/icon/close-input.png"
                            alt="text-info "
                            className="clear-icon"
                            onClick={() => props.setValue("fullName", "")}
                          />
                          <input
                            ref={fullnameRef}
                            type="text"
                            className="form-control"
                            // onChange={(e) => {
                            //   fullnameRef.current.value = e.target.value.replace(/[!-_|=@#$%^~`()&*]/g, "");
                            // }}
                            id="usr"
                            {...props.register("fullName")}
                            name="fullName"
                            placeholder="Nhập họ và tên..."
                            maxLength={50}
                            min="0"
                            max="50"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="title-form" htmlFor="phone">
                          Số điện thoại <span>*</span>
                        </label>
                        <div className="form-field">
                          <img
                            src="assets/img/icon/close-input.png"
                            alt="text-info "
                            className="clear-icon"
                            id="clear-icon-phone"
                            onClick={() => props.setValue("phone", "")}
                          />
                          <input
                            type="tel"
                            className={`form-control ${
                              props?.isErrorPhoneNumber && "errors"
                            }`}
                            ref={phonenumberRef}
                            id="phone"
                            maxLength={10}
                            name="phone"
                            {...props.register("phone", {
                              onChange: (elm) => {
                                props.setValue(
                                  "phone",
                                  elm.target.value.replace(/[^\d]/gi, "")
                                );
                              },
                            })}
                            placeholder="Nhập số điện thoại..."
                            min="0"
                            max="10"
                            inputMode="numeric"
                          />
                        </div>
                        {props?.isErrorPhoneNumber && (
                          <small className="errors  error-message">
                            Vui lòng nhập số điện thoại có 10 chữ số bắt đầu
                            bằng đầu số 0
                          </small>
                        )}
                      </div>{" "}
                      <div
                        className="form-group"
                        style={{ position: "relative", zIndex: 3 }}
                      >
                        <label className="title-form" htmlFor="usr">
                          Nhà mạng <span>*</span>
                        </label>

                        <Select
                          className={`form-control`}
                          placeholder="Chọn nhà mạng..."
                          options={TELCO}
                          isClearable={true}
                          value={TELCO.filter(
                            ({ value }) => value === telcoSelected
                          )}
                          isSearchable={false}
                          onChange={(e) => {
                            console.log(e);
                            setTelcoSelected(e?.value);
                            props.setValue("telco", e?.value);
                          }}
                        />
                      </div>{" "}
                      <div className="form-group">
                        <label className="title-form" htmlFor="usr">
                          Tỉnh/Thành phố <span>*</span>
                        </label>
                        <div className="form-field">
                          <img
                            src="assets/img/icon/close-input.png"
                            alt="text-info "
                            className="clear-icon select-list"
                            onClick={() => {
                              setProvinceSelected("");
                              props.setValue("province", "");
                            }}
                          />
                          <Select
                            className={`form-control`}
                            placeholder="Chọn Tỉnh/Thành Phố"
                            options={PROVINCES}
                            value={PROVINCES.filter(
                              ({ value }) => value === provinceSelected
                            )}
                            isSearchable={true}
                            onChange={(e) => {
                              console.log(e);
                              setProvinceSelected(e?.value);
                              props.setValue("province", e?.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="title-form" htmlFor="usr">
                          Mã thẻ cào tham gia Chương trình khuyến mại
                          <span>*</span>
                        </label>
                        <div className="form-field">
                          <img
                            src="assets/img/icon/close-input.png"
                            alt="text-info "
                            className="clear-icon"
                            onClick={() => props.setValue("code", "")}
                          />
                          <input
                            type="text"
                            className={`form-control ${
                              props?.isErrorCode &&
                              !props?.isSubmitSuccess &&
                              "errors"
                            }`}
                            id="code"
                            maxLength={8}
                            name="code"
                            {...props.register("code", {
                              onChange: (elm) => {
                                props.setValue(
                                  "code",
                                  elm.target.value.replace(
                                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                                    ""
                                  )
                                );
                              },
                              onBlur: (elm) => {
                                if (elm.target?.value?.trim()?.length > 8) {
                                  props.setValue(
                                    "code",
                                    (elm.target.value =
                                      elm.target.value.substring(0, 8))
                                  );
                                }
                              },
                            })}
                            placeholder="Nhập mã thẻ cào..."
                            min="0"
                            max="8"
                          />
                        </div>

                        {props?.isErrorCode && !props?.isSubmitSuccess && (
                          <small className="errors error-message">
                            Vui lòng nhập mã dự thưởng gồm 8 kí tự được in trên
                            thẻ
                          </small>
                        )}
                      </div>
                      <div
                        className={`group-checkbox ${
                          commingSoon && "isCommingSoon"
                        }`}
                      >
                        {" "}
                        <div className="checkbox-item">
                          <label htmlFor="isAgree1">
                            <input
                              type="checkbox"
                              value="isAgree1"
                              name="isAgree1"
                              id="isAgree1"
                              {...props.register("isAgree1")}
                            />
                            <span className="checkmark"></span>
                            <p>
                              Tôi đã hiểu và đồng ý với{" "}
                              <a
                                className="policy"
                                target="_blank"
                                href="/chinhsachthuthapthongtin"
                                rel="noreferrer"
                              >
                                Chính sách thu thập thông tin cá nhân
                              </a>{" "}
                              khi tham gia chương trình khuyến mại này
                            </p>
                          </label>
                        </div>
                        <div className="checkbox-item">
                          <label htmlFor="isAgree">
                            <input
                              type="checkbox"
                              value="isAgree"
                              name="isAgree"
                              id="isAgree"
                              {...props.register("isAgree")}
                            />
                            <span className="checkmark"></span>
                            <p>
                              Tôi đã đọc và đồng ý với{" "}
                              <a target="_blank" href="/thelechuongtrinh">
                                {" "}
                                Thể lệ của chương trình
                              </a>
                            </p>
                          </label>
                        </div>
                        {/* <div className="checkbox-item">
                          <label htmlFor="isAgree1">
                            <input
                              type="checkbox"
                              value="isAgree1"
                              name="isAgree1"
                              id="isAgree1"
                              {...props.register("isAgree1")}
                            />
                            <span className="checkmark"></span>
                            <p>
                              Tôi đồng ý với điều khoản sử dụng của ACECOOK và
                              đã được thông báo về{" "}
                              <a
                                className="policy"
                                target="_blank"
                                href="https://www.unilevernotices.com/vietnam/vietnamese/privacy-notice/notice.html"
                                rel="noreferrer"
                              >
                                Quyền riêng tư
                              </a>
                              ,{" "}
                              <a
                                className="policy"
                                target="_blank"
                                href="https://www.unilevernotices.com/vietnam/vietnamese/cookie-notice/notice.html"
                                rel="noreferrer"
                              >
                                thông báo cookies
                              </a>{" "}
                              và{" "}
                              <a
                                className="policy"
                                target="_blank"
                                href="https://www.unilever.com.vn/legal.html"
                                rel="noreferrer"
                              >
                                Thông tin pháp lý.
                              </a>
                            </p>
                          </label>
                        </div>
                        <div className="checkbox-item">
                          <label htmlFor="isAgree2">
                            <input
                              type="checkbox"
                              value="isAgree2"
                              name="isAgree2"
                              id="isAgree2"
                              {...props.register("isAgree2")}
                            />
                            <span className="checkmark"></span>
                            <p>
                              Tôi xác nhận những thông tin đăng ký ở trên là
                              chính xác và chịu trách nhiệm trước Pháp luật về
                              tính chính xác của các thông tin trên
                            </p>
                          </label>
                        </div> */}
                      </div>
                      <div className="btn-wrapper text-center">
                        <p>
                          Vui lòng giữ lại mã thẻ cào
                          <br /> để tham gia quay thưởng trúng thêm giải thưởng
                          khác bạn nhé!
                        </p>
                        <button
                          type="submit"
                          className="btn btn-primary btn-block disabled"
                          id="btn-register"
                        >
                          <a>THAM GIA NGAY</a>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="line "></div>
          <br />
          <img
            className="img-full  d-none-mb"
            src="assets/img/Title-hctt.png"
          />

          <div className="container d-none">
            <img className="img-full" src="assets/img/Title-hctt-mb.png" />
          </div>
          <div className="container singer">
            <img className="img-full" src="assets/img/Contents-hctt.png" />
            <button
              type="button"
              className={`btn btn-primary    ${commingSoon ? "disabled" : ""}`}
              onClick={() => {
                navigate("/hatcungthantuong");
              }}
            >
              <a href="/hatcungthantuong">THAM GIA</a>
            </button>
          </div>
          <div className="line "></div>
          <img className="img-full d-none-mb" src="assets/img/Title-spkm.png" />
          <div className="container">
            <img
              className="img-full d-none"
              src="assets/img/Title-spkm-mb.png"
            />
            <br />
            <img
              className="group-pro img-full d-none-mb"
              src="assets/img/group-pro.png"
            />
          </div>
          <img
            className="group-pro-mb img-full d-none"
            src="assets/img/group-pro-mb.png"
          />
        </div>
        <div className="container d-none">
          <ul className="list-pro-name">
            <li>Mì ly Modern hương vị Lẩu Thái Tôm</li>
            <li>Mì ly Modern hương vị Bò Hầm Rau Thơm</li>
            <li>Mì ly Modern hương vị Thịt Xào</li>
            <li>Mì ly Modern hương vị Riêu Cua</li>
            <li>Mì ly CayKay hương vị Bò</li>
            <li>Mì ly CayKay hương vị Kim Chi</li>
            <li>Mì ly Handy Hảo Hảo hương vị TomYum</li>
            <li>Mì ly Handy Hảo Hảo hương vị Tôm Chua Cay</li>
            <li>Handy Hảo Hảo Mì Chay hương vị Lẩu Nấm Thập Cẩm </li>
            <li>Nhớ Mãi Mãi hương vị Hủ Tiếu Nam Vang</li>
            <li>Nhớ Mãi Mãi hương vị Phở Bò</li>
            <li>Mì Nhớ Mãi Mãi hương vị Lẩu Thái</li>
            <li>Nhớ Mãi Mãi hương vị Miến Sườn Heo</li>
          </ul>
        </div>

        <TheFooter />
      </div>
      <div className="group-social-icon">
        <ul>
          <li>
            <a className="i-phone" target={"_blank"} href="tel:1900638880"></a>
          </li>
          <li>
            <a
              className="i-zalo"
              target={"_blank"}
              href="https://zalo.me/2220329456072879891"
            ></a>
          </li>
          <li>
            <a
              className="i-facebook-c"
              target={"_blank"}
              href="https://www.facebook.com/acecookcup"
            ></a>
          </li>
          <li>
            <a
              className="i-titok-c"
              target={"_blank"}
              href="https://www.tiktok.com/@acecook.vietnam?_t=8oh4bw7eTxQ&_r=1"
            ></a>
          </li>
        </ul>
      </div>
    </section>
  );
};
export default HomePageView;
