// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import TheFooter from "layout/TheFooter";

const DuetSelectSingerView = (props) => {
  const { singers, onSelectSinger } = props;
  return (
    <section>
      <div className="bgr-green des-green">
        <div
          className="des-green container"
          style={{
            minHeight: window.innerHeight - 220,
            objectFit: "contain",
          }}
        >
          <br />
          <img className="img-full" src="assets/img/Title-selectsinger.png" />
          <ul className="list-singer">
            {singers?.length > 0 &&
              singers.map((item) => {
                return (
                  <li
                    onClick={() => {
                      onSelectSinger(item?.singerId);
                    }}
                  >
                    <img
                      className="img-full"
                      src={item?.singerImage || "assets/img/singer.png"}
                    />
                    <div className="list-action">
                      <h3>{item?.singerName || ""}</h3>
                      <button type="button" className="btn btn-sm btn-primary">
                        <a>Chọn</a>
                      </button>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="TheFooterRaidius">
          <TheFooter />
        </div>
      </div>
    </section>
  );
};
export default DuetSelectSingerView;
