// @ts-nocheck
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupLogin = (props) => {
  const { cancel, requestOTP, setModalLogin } = props;
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <>
      <div className="popup-overlay modal-otp">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div
            className="close-modal"
            onClick={() => {
              setModalLogin(false);
            }}
          ></div>
          <div className="popup-inner">
            <div className="content-popup">
              <form className="login-otp">
                <h3>ĐĂNG NHẬP BẰNG SĐT</h3>
                <br />
                <p className="note-login">
                  Nhập số điện thoại của bạn để
                  <br /> nhận mã OTP Đăng Nhập
                </p>
                <br />
                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    maxLength={10}
                    name="phone"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value.replace(/[^\d]/gi, "")
                      );
                    }}
                    // {...props.register("phone", {
                    //   onChange: (elm) => {
                    //     props.setValue(
                    //       "phone",
                    //       elm.target.value.replace(/[^\d]/gi, "")
                    //     );
                    //   },
                    // })}
                    placeholder="Nhập SĐT của bạn..."
                    min="0"
                    max="10"
                    inputMode="numeric"
                  />

                  {/* <small className="errors">
                    Vui lòng nhập số điện thoại có 10 chữ số bắt đầu bằng đầu số
                    0
                  </small> */}
                </div>
                <div className="group-btn-inline">

                  <button onClick={() => {
                    setModalLogin(false);
                  }} type="button" className="btn btn-warning btn-block">
                    <a>Hủy</a>
                  </button>
                  <button onClick={() => {
                    requestOTP(phoneNumber)
                  }} type="button" className={`btn btn-primary btn-block ${phoneNumber?.length < 10 && "disabled"}`}>
                    <a>nhận otp</a>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupLogin;
