// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import TheFooter from "layout/TheFooter";
import { useRef } from "react";
import reactUtils from "utils/reactUtils";
import PopupPostFacebook from "components/popup-post-facebook";

const DuetInfoDetailView = (props) => {
  const navigate = useNavigate();
  const {
    goBack,
    onLinkFB,
    onLinkTikTok,
    accessTokenFB,
    isLinkTikTok,
    subDetail,
    onDeleteSub,
    onCheckTiktok,
    onCheckFb,
    onViewTiktok,
    onViewFb,
    onDownloadVideo,
    videoId,
    onRegLink,
    onLikeOrDislike,
    isLiked,
    playVideoTiktok,
    iframeRef,
    isPlay,
    setIsPlay,
    isLoading,
    setIsShowGuideLink,
    publicVideo,
    onPlayerReady,
    setOnPlayerReady,
    onPauseVideoTiktok,
  } = props;
  const [show, setShow] = useState(true);
  const [like, setLike] = useState(false);
  const ref = useRef(null);
  const videoRef = useRef(null);

  const checkSubTiktok = () => {
    return (
      <>
        {subDetail?.linkedSocialData?.socialData?.Tiktok?.FULL_FINAL_URL ? (
          checkStatusTiktok()
        ) : (
          <a className="linkAcc" onClick={() => onRegLink("Tiktok")}>
            Chưa liên kết bài đăng
          </a>
        )}
      </>
    );
  };

  const checkStatusTiktok = () => {
    let content = null;
    switch (subDetail?.linkedSocialData?.linkedTiktokStatus) {
      case 1:
        content = (
          <span
            onClick={() => {
              if (subDetail?.videoSource === 0) {
                onCheckTiktok();
              }
            }}
            className="cancel"
          >
            Liên kết chờ duyệt
          </span>
        );

        break;

      case 2:
        content = (
          <span
            onClick={() => {
              if (subDetail?.videoSource === 0) {
                onCheckTiktok();
              }
            }}
            className="pink"
          >
            Đã liên kết {subDetail?.videoSource === 1 && "(Bài dự thi gốc)"}
          </span>
        );
        break;

      case 3:
        content = (
          <span
            onClick={() => {
              if (subDetail?.videoSource === 0) {
                onCheckTiktok();
              }
            }}
            className="pink"
          >
            Liên kết bị từ chối
          </span>
        );
        break;

      default:
        break;
    }
    return content;
  };

  const checkSubFb = () => {
    return (
      <>
        {subDetail?.linkedSocialData?.socialData?.Facebook?.FULL_FINAL_URL ? (
          checkStatusFb()
        ) : (
          <a className="linkAcc" onClick={() => onRegLink("Facebook")}>
            Chưa liên kết bài đăng
          </a>
        )}
      </>
    );
  };

  const checkStatusFb = () => {
    let content = null;
    switch (subDetail?.linkedSocialData?.linkedFacebookStatus) {
      case 1:
        content = (
          <span onClick={onCheckFb} className="cancel">
            Liên kết chờ duyệt
          </span>
        );
        break;

      case 2:
        content = (
          <span onClick={onCheckFb} className="pink">
            Đã liên kết
          </span>
        );

        break;

      case 3:
        content = (
          <span onClick={onCheckFb} className="pink">
            Liên kết bị từ chối
          </span>
        );

        break;

      default:
        break;
    }
    return content;
  };

  const checkStatus = (status) => {
    let content = null;
    switch (status) {
      case 0:
        content = (
          <>
            <div className="pending-video">
              Video bài dự thi của bạn đang
              <br /> được xử lý...
            </div>
            <video
              webkit-playsinline="true"
              playsInline
              width="100%"
              height="400"
            ></video>
          </>
        );
        break;

      case 2:
        content = (
          <>
            <img
              className="pending-video images-vi"
              src={subDetail?.image}
              alt=""
            />
            <video
              webkit-playsinline="true"
              playsInline
              width="100%"
              height="400"
            ></video>
          </>
        );
        break;

      case 6:
        content = (
          <>
            <video
              webkit-playsinline="true"
              playsInline
              width="100%"
              height="auto"
              ref={videoRef}
              src={`${subDetail?.publicUrl}#t=0.001`}
              style={{ objectFit: "contain" }}
              onLoadedData={() => setOnPlayerReady(true)}
              onClick={(e) => {
                try {
                  if (subDetail?.status !== 2 && !!subDetail?.publicUrl) {
                    if (isPlay) {
                      videoRef?.current?.pause();
                    } else {
                      videoRef?.current?.play();
                    }
                    setIsPlay(!isPlay);
                  }
                } catch (error) {}
              }}
              onEnded={() => {
                setIsPlay(false);
              }}
            ></video>
          </>
        );
        break;

      default:
        content = (
          <>
            {subDetail?.videoSource == 1 ? (
              <div
                className="videoIframe"
                onClick={() => {
                  try {
                    if (!!videoId) {
                      if (isPlay) {
                        onPauseVideoTiktok();
                      } else {
                        playVideoTiktok();
                      }
                      setIsPlay(!isPlay);
                    }
                  } catch (error) {}
                }}
              >
                <iframe
                  src={`https://www.tiktok.com/player/v1/${videoId}?music_info=0&description=0&fullscreen_button=0&progress_bar=0&rel=0`}
                  width="100%"
                  height="400"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  style={{ pointerEvents: "none" }}
                  allowfullscreen
                  ref={iframeRef}
                ></iframe>
              </div>
            ) : (
              <video
                webkit-playsinline="true"
                playsInline
                width="100%"
                height="auto"
                ref={videoRef}
                src={`${subDetail?.publicUrl}#t=0.001`}
                style={{ objectFit: "contain" }}
                onLoadedData={() => setOnPlayerReady(true)}
                onClick={(e) => {
                  try {
                    if (subDetail?.status !== 2 && !!subDetail?.publicUrl) {
                      if (isPlay) {
                        videoRef?.current?.pause();
                      } else {
                        videoRef?.current?.play();
                      }
                      setIsPlay(!isPlay);
                    }
                  } catch (error) {}
                }}
                onEnded={() => {
                  setIsPlay(false);
                }}
              ></video>
            )}
          </>
        );
        break;
    }

    return content;
  };
  return (
    <section>
      <div className="duet-camera des-green container">
        <div className="control-duet">
          <span className="i-back" onClick={goBack}></span>
          {subDetail?.videoSource === 0 &&
            (subDetail?.status === 1 ||
              subDetail?.status === 3 ||
              subDetail?.status === 4 ||
              subDetail?.status === 6) &&
            subDetail?.isCurrentUser && (
              <span className="i-save" onClick={onDownloadVideo}></span>
            )}

          {subDetail?.isCurrentUser && subDetail?.status !== 0 && (
            <span className="i-del-p" onClick={onDeleteSub}></span>
          )}
        </div>
        <div
          className="video-height"
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: window.innerHeight - 300,
            objectFit: "contain",
          }}
        >
          <div style={{ width: "100%" }} className="video-warp">
            {subDetail?.status === 3 && (
              <div
                onClick={onLikeOrDislike}
                className={`i-like ${isLiked ? "hasLike" : "disLike"}`}
              ></div>
            )}
            {!isPlay &&
              !!subDetail?.id &&
              subDetail?.status !== 0 &&
              subDetail?.status !== 2 &&
              onPlayerReady && (
                <span
                  className="playCam"
                  onClick={() => {
                    // reRecord();
                    if (!!subDetail?.publicUrl) {
                      if (subDetail?.videoSource === 1) {
                        setIsPlay(true);
                        playVideoTiktok();
                      } else {
                        setIsPlay(true);
                        videoRef?.current?.play();
                      }
                    }
                  }}
                ></span>
              )}

            {subDetail?.status === 3 && (
              <div className="gr-share">
                <ul>
                  <li onClick={onViewTiktok}>
                    <div className="i-tiktok"></div>
                    <p>Xem</p>
                  </li>
                  <li onClick={onViewFb}>
                    <div className="i-facebook"></div>
                    <p>Xem</p>
                  </li>
                </ul>
              </div>
            )}
            {!!subDetail?.id ? (
              checkStatus(subDetail?.status)
            ) : (
              <>
                {!isLoading && (
                  <>
                    <div className="pending-video">
                      Bài dự thi gốc không tìm thấy hoặc đã bị xóa...
                    </div>
                    <video
                      webkit-playsinline="true"
                      playsInline
                      width="100%"
                      height="400"
                    ></video>
                  </>
                )}
              </>
            )}
            {subDetail?.status === 6 && (
              <div className="group-btn-duet-info container">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    videoRef?.current?.pause();
                    setIsPlay(false);
                    publicVideo();
                  }}
                >
                  <a>Đăng Bài</a>
                </button>
              </div>
            )}

            {/* {subDetail?.status !== 0 ? (
              <>
                {subDetail?.videoSource == 0 ? (
                  <video
                    webkit-playsinline="true"
                    playsInline
                    width="100%"
                    height="400"
                    ref={videoRef}
                    src={`${subDetail?.publicUrl}#t=0.001`}
                    style={{ objectFit: "cover" }}
                    onClick={(e) => {
                      try {
                        if (subDetail?.status !== 2 && !!subDetail?.publicUrl) {
                          if (isPlay) {
                            videoRef?.current?.pause();
                          } else {
                            videoRef?.current?.play();
                          }
                          setIsPlay(!isPlay);
                        }
                      } catch (error) {

                      }

                    }}
                    onEnded={() => {
                      setIsPlay(false);
                    }}
                  ></video>
                ) : (
                  <iframe src={`https://www.tiktok.com/player/v1/${videoId}?music_info=0&description=0&fullscreen_button=0&progress_bar=0&rel=0`}
                    width="100%"
                    height="600"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen>
                  </iframe>
                )}
              </>
            ) : (
              <>
                <div className="pending-video">
                  Video bài dự thi của bạn đang
                  <br /> được xử lý...
                </div>
                <video
                  webkit-playsinline="true"
                  playsInline
                  width="100%"
                  height="400"
                ></video>
              </>
            )} */}
          </div>
        </div>
        {/* <div className="video-duet">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/4N9Y47aVTHM?si=9xHwM_2WOI1z4qtA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/4N9Y47aVTHM?si=9xHwM_2WOI1z4qtA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div> */}
        {/* {subDetail?.status === 0 && (
          <div className="text-pending">Bài dự thi này đang được xử lý ...</div>
        )} */}

        {subDetail?.status === 1 && (
          <div className="text-pending">Bài dự thi này đang chờ duyệt ...</div>
        )}

        {subDetail?.status === 2 && (
          <div className="text-pending text-pending-red">
            Hệ thống gặp lỗi khi xử lý video, bạn vui lòng thực hiện quay bài dự
            thi khác
          </div>
        )}
        {subDetail?.status === 4 && (
          <div className="text-pending text-pending-red">
            Rất tiếc, bài dự thi của bạn bị từ chối vì {subDetail?.reason}
          </div>
        )}
        <div className="footer-duet-pending">
          <div className="item-duet bottom-duet">
            <img className="avarta" src={subDetail?.image} alt="" />
            <ul className="content">
              <li>
                SBD {subDetail?.contestantInfo?.contestNumber} |{" "}
                {subDetail?.contestantInfo?.userName}
              </li>
              <li>
                {subDetail?.submissionInfo?.songName} -{" "}
                {subDetail?.submissionInfo?.singerName}
              </li>
              {subDetail?.status === 3 && subDetail?.isCurrentUser && (
                <>
                  <li>
                    <span className="sumcount">
                      Tổng điểm: {reactUtils.formatNumber(subDetail?.points)}{" "}
                      điểm{" "}
                      {subDetail?.rank > 0 &&
                        subDetail?.rank <= 10 &&
                        `(Top ${subDetail?.rank})`}
                    </span>
                  </li>
                  <li>
                    <span className="webcount">
                      Điểm website:{" "}
                      {reactUtils.formatNumber(subDetail?.webPoints)} điểm
                    </span>
                  </li>
                  {/* <li
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    <b>{show ? "Ẩn chi tiết" : "Chi tiết"}</b>
                    <span
                      className={`i-arrow ${show ? "i-arrow-u" : "i-arrow-d"}`}
                    ></span>
                  </li> */}
                </>
              )}
            </ul>
          </div>
          {subDetail?.status === 3 && subDetail?.isCurrentUser && (
            <ul className="infor-detail" ref={ref}>
              <li className="sumcount-color">
                Tổng điểm trên các nền tảng:
                <span class="sumcount">
                  {reactUtils.formatNumber(subDetail?.points)} điểm
                </span>
              </li>
              <li>
                <p>
                  <span className="lg-website"></span> Website
                </p>
                <span className="webcount">
                  Điểm website: {reactUtils.formatNumber(subDetail?.webPoints)}{" "}
                  điểm
                </span>
              </li>
              <li>
                Lượt like trên website:
                <span className="pending">
                  {reactUtils.formatNumber(
                    subDetail?.statistics?.Internal?.Like
                  )}{" "}
                  like ={" "}
                  {reactUtils.formatNumber(
                    subDetail?.socialPoint?.internalLikePoint
                  ) || 0}{" "}
                  điểm
                </span>
              </li>
              <li>
                Tổng điểm từ hóa đơn thanh toán:
                <span className="pending">
                  {reactUtils.formatNumber(subDetail?.scanBillPoint)} điểm
                </span>
              </li>
              <li>
                <div>
                  <span className="i-tiktok"></span>Tiktok
                </div>
                {!subDetail?.isLinkedTiktok ? (
                  <a className="linkAcc" onClick={onLinkTikTok}>
                    Chưa liên kết tài khoản
                  </a>
                ) : (
                  checkSubTiktok()
                )}
              </li>
              {subDetail?.isLinkedTiktok &&
                !!subDetail?.linkedSocialData?.socialData?.Tiktok
                  ?.FULL_FINAL_URL &&
                subDetail?.linkedSocialData?.linkedTiktokStatus === 2 && (
                  <>
                    <li>
                      Tổng số lượt like:
                      <span className="pending">
                        {reactUtils.formatNumber(
                          subDetail?.statistics?.Tiktok?.Like
                        )}{" "}
                        lượt ={" "}
                        {reactUtils.formatNumber(
                          subDetail?.socialPoint?.tiktokLikePoint
                        ) || 0}{" "}
                        điểm
                      </span>
                    </li>
                    <li>
                      Tổng số lượt share:
                      <span className="pending">
                        {reactUtils.formatNumber(
                          subDetail?.statistics?.Tiktok?.Share
                        )}{" "}
                        lượt ={" "}
                        {reactUtils.formatNumber(
                          subDetail?.socialPoint?.tiktokSharePoint
                        ) || 0}{" "}
                        điểm
                      </span>
                    </li>
                  </>
                )}

              <li>
                <div>
                  <span className="i-facebook"></span>Facebook
                </div>
                {!subDetail?.isLinkedFacebook ? (
                  <a className="linkAcc" onClick={onLinkFB}>
                    Chưa liên kết tài khoản
                  </a>
                ) : (
                  checkSubFb()
                )}
              </li>
              {subDetail?.isLinkedFacebook &&
                !!subDetail?.linkedSocialData?.socialData?.Facebook
                  ?.FULL_FINAL_URL &&
                subDetail?.linkedSocialData?.linkedFacebookStatus === 2 && (
                  <>
                    <li>
                      Tổng số lượt like:{" "}
                      <span>
                        {true
                          ? `${reactUtils.formatNumber(
                              subDetail?.statistics?.Facebook?.Like
                            )} lượt = ${
                              reactUtils.formatNumber(
                                subDetail?.socialPoint?.facebookLikePoint
                              ) || 0
                            } điểm`
                          : "Chưa đăng ký bài dự thi"}
                      </span>
                    </li>
                    <li>
                      Tổng số lượt share:{" "}
                      <span>
                        {true
                          ? `${reactUtils.formatNumber(
                              subDetail?.statistics?.Facebook?.Share
                            )} lượt = ${
                              reactUtils.formatNumber(
                                subDetail?.socialPoint?.facebookSharePoint
                              ) || 0
                            } điểm`
                          : "Chưa đăng ký bài dự thi"}
                      </span>
                    </li>
                  </>
                )}

              <li>
                <div>
                  Chi tiết cách tính điểm xin hãy{" "}
                  <a
                    onClick={() => setIsShowGuideLink(true)}
                    className="linkAcc"
                  >
                    xem ở đây
                  </a>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
      {/* <PopupPostFacebook /> */}
      {/* <PopupGuideFacebook /> */}
      {/* </div> */}
    </section>
  );
};
export default DuetInfoDetailView;
