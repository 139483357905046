// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import TheFooter from "layout/TheFooter";

const DuetCameraView = (props) => {
  const {
    play,
    pause,
    setPlay,
    setPause,
    videoRef,
    cameraRef,
    onVideoEnd,
    onVideoPlay,
    blobUrl,
    stopRecording,
    currentTime,
    duration,
    onPlay,
    videoRef1,
    videoRef2,
    playVideoReview,
    onVideoEnd2,
    reRecord,
    songDetail,
    getCameraPermission,
    setIsHidePlay,
    isHidePlay,
    isLoading,
    audioRef,
    pauseVideoReview,
    isReadyRecord,
  } = props;
  const navigate = useNavigate();

  const [heightVideo, setHeightVideo] = useState(0);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <section>
      <div
        className="duet-camera-custom"
        style={{
          height: window.innerHeight,
          objectFit: "contain",
        }}
      >
        {/* {!!blobUrl && (
          <span
            className="playCam"
            onClick={() => {
              if (!isHidePlay) {
                setIsHidePlay(true);
                playVideoReview();
              } else {
                setIsHidePlay(false);
                pauseVideoReview();
              }
            }}
          ></span>
        )} */}

        <div className="control-duet-camera">
          <span
            className="i-back"
            onClick={() => {
              window.history.back();
            }}
          ></span>
          {/* {props.pending && (
            <>
              <span
                className="i-save"
                onClick={() => {
                  window.history.back();
                }}
              ></span>
              <span
                className="i-del-p"
                onClick={() => {
                  window.history.back();
                }}
              ></span>
            </>
          )} */}
        </div>
        <div>
          <div className="video-duet">
            {!!blobUrl ? (
              <>
                <div style={{ flex: 1, backgroundColor: "#000" }}>
                  <video
                    id="song-detail"
                    // autoPlay
                    playsInline
                    muted
                    webkit-playsinline="true"
                    width="100%"
                    height="auto"
                    ref={videoRef1}
                    src={`${songDetail?.url}#t=0.001`}
                    style={{ objectFit: "cover" }}
                    onClick={(e) => e.preventDefault()}
                    onPlay={() => {
                      videoRef1.current.muted = true;
                      videoRef2.current.muted = true;
                    }}
                  ></video>
                </div>
                <div style={{ flex: 1, paddingTop: 4 }}>
                  <video
                    autoPlay
                    playsInline
                    id="song-record"
                    webkit-playsinline="true"
                    src={blobUrl}
                    width="100%"
                    height={heightVideo}
                    ref={videoRef2}
                    onPlay={() => {
                      setTimeout(() => {
                        videoRef2?.current?.pause();
                      }, 500);
                    }}
                    muted
                    onEnded={() => {
                      onVideoEnd2();
                      setIsHidePlay(false);
                    }}
                    style={{ transform: "scaleX(-1)", objectFit: "cover" }}
                  />
                </div>
              </>
            ) : (
              <>
                <video
                  controls={false}
                  webkit-playsinline="true"
                  playsInline
                  id="video-casi"
                  width="100%"
                  height={"auto"}
                  ref={videoRef}
                  src={`${songDetail?.url}#t=0.001`}
                  onCanPlay={() => {
                    if (!isReadyRecord) {
                      var clientHeight =
                        document.getElementById("video-casi").clientHeight;
                      setHeightVideo(clientHeight);
                      setTimeout(() => {
                        getCameraPermission();
                      }, 1000);
                    }
                  }}
                  onEnded={onVideoEnd}
                  onPlay={onVideoPlay}
                  style={{ objectFit: "cover" }}
                  onClick={(e) => e.preventDefault()}
                ></video>
                <video
                  width="100%"
                  key={heightVideo}
                  id="video-record"
                  height={heightVideo}
                  ref={cameraRef}
                  onCanPlay={() => {
                    // var clientHeight =
                    //   document.getElementById("video-record").clientHeight;
                    // if (clientHeight > heightVideo) {
                    //   document.getElementById(
                    //     "video-record"
                    //   ).style.paddingBottom = clientHeight - heightVideo + "px";
                    // }
                  }}
                  autoPlay
                  playsInline
                  muted
                  style={{ transform: "scaleX(-1)", objectFit: "cover" }}
                  onClick={(e) => e.preventDefault()}
                />
              </>
            )}
          </div>
          {!props.pending && (
            <>
              {!pause ? (
                <div className="control-duet-fo control-duet-fo-duet-camera">
                  <p className="count-time">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </p>

                  {play ? (
                    <div
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <p className="cancel-camera">
                        <span className="close-x"></span> Huỷ Quay
                      </p>
                    </div>
                  ) : isReadyRecord ? (
                    <span
                      className="pause-video"
                      onClick={() => {
                        console.log(play);
                        onPlay();
                        // if (!!play) {
                        //   setPlay(false);
                        // } else {
                        //   setPlay(true);
                        // }
                      }}
                    ></span>
                  ) : (
                    <></>
                  )}

                  {/* {!play ? (
                  <span
                    className="pause-video"
                    onClick={() => {
                      console.log(play);
                      onPlay();
                      // if (!!play) {
                      //   setPlay(false);
                      // } else {
                      //   setPlay(true);
                      // }
                    }}
                  ></span>
                ) : (
                  <span
                    className="play-video"
                    onClick={() => {
                      console.log(pause);
                      stopRecording();
                      // setPause(!pause);
                      // if (!!pause) {
                      //   setPause(false);
                      // } else {
                      //   setPause(true);
                      // }
                    }}
                  ></span>
                )} */}
                </div>
              ) : (
                <div className="group-btn-inline container">
                  <button
                    type="button"
                    className="btn btn-warning  btn-block"
                    onClick={() => {
                      // setPause(false);
                      // setPlay(false);
                      // props.sendPost();
                      reRecord();
                    }}
                  >
                    <a>Quay lại lần nữa</a>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      if (!isLoading) {
                        props.sendPost();
                      }
                    }}
                  >
                    <a>Lưu Bài</a>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {props.pending && (
          <>
            <div className="text-pending">
              Bài dự thi này đang được duyệt...
            </div>
            <div className="footer-duet-pending">
              <div className="item-duet bottom-duet">
                <div className="avarta"></div>
                <ul className="content">
                  <li>SBD | Tên người dự thi</li>
                  <li>Bài hát - Ca sĩ</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
export default DuetCameraView;
