import React, { useEffect, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { useDispatch } from "react-redux";
import actionsDuet from "redux/duet/actions";
import actions from "redux/home/actions";
import { useLocation, useNavigate } from "react-router-dom";
import ScanBillHistoryView from "./view";
import PopupImages from "components/popup-images";
import PopupBillTrue from "components/popup-bill-true";

const ScanBillHistoryPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { text = "" } = location.state || {};

  const [isBillTrue, setIsBillTrue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listSub, setListSub] = useState(false);
  const [modalImages, setModalImages] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [searchText, setSearchText] = useState(0);
  const [billPoint, setBillPoint] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [imageZoom, setImageZoom] = useState("");

  const PIZE_SIZE = 7;

  useEffect(() => {
    setSearchText(text);
    getListSub(1, text);
    getListHistory();
  }, []);

  const getListHistory = () => {
    dispatch(
      actionsDuet.GET_MY_POINTS.request(
        { page: 1, pageSize: 20 },
        (res) => {
          setHistoryData(res);
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };
  const closeBillTrue = () => {
    setIsBillTrue(false);
  };
  const getListSub = (page, searchText) => {
    setIsLoading(true);
    let params = {
      page: page,
      searchText: searchText,
      pageSize: PIZE_SIZE,
    };
    dispatch(
      actionsDuet.GET_LIST_SUB.request(
        params,
        (res) => {
          setIsLoading(false);
          setListSub(res?.data || []);
          setPages(Math.ceil(res?.total / PIZE_SIZE));
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const onSearchSub = () => {
    setPageOffset(0);
    getListSub(1, searchText);
  };

  const onChangePage = (pageOffsetTmp, prizeType = 0) => {
    setPageOffset(pageOffsetTmp);
    getListSub(pageOffsetTmp + 1);
  };
  useEffect(() => {
    document.body.className = "";
    const accessToken = localStorage.getItem("accessToken");
    if (!!accessToken) {
      getBillPoint();
    }
  }, []);

  const getBillPoint = () => {
    setIsLoading(true);
    dispatch(
      actionsDuet.GET_BILL_POINT.request(
        {},
        (res) => {
          setBillPoint(res || {});
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            // checkError(errCode);
          } else {
            // showDefaultErrModal();
          }
        }
      )
    );
  };
  const closeModalImages = () => {
    setModalImages(false);
    document.body.classList.remove("modal-open");
  };
  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <ScanBillHistoryView
        listSub={listSub}
        setModalImages={setModalImages}
        pages={pages}
        onChangePage={onChangePage}
        pageOffset={pageOffset}
        searchText={searchText}
        setSearchText={setSearchText}
        onSearchSub={onSearchSub}
        historyData={historyData}
        setImageZoom={setImageZoom}
        billPoint={billPoint}
        setIsBillTrue={setIsBillTrue}
      />
      {modalImages && (
        <PopupImages setModalImages={setModalImages} image={imageZoom} />
      )}
      {isBillTrue && <PopupBillTrue closeBillTrue={closeBillTrue} />}
    </>
  );
};
export default ScanBillHistoryPage;
