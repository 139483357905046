
const reactUtils = {
    formatNumber(num) {
        if (!num && typeof num !== 'number') {
            num = '';
        }
        return num
            .toString()
            .split(/(?=(?:\d{3})+(?:\.|$))/g)
            .join('.');
    },
    extractBaseURL(url) {
        try {
            const urlObj = new URL(url);
            return `${urlObj.origin}${urlObj.pathname}`;
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }
}


export default reactUtils;