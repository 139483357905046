const domainCampaign = process.env.REACT_APP_DOMAIN_CAMPAIGN;
const domainLadingpage = process.env.REACT_APP_DOMAIN_LANDINGPAGE_API;
const domainUtop = process.env.REACT_APP_DOMAIN_UTOP;
const END_POINTS = {
  VALIDATE_CAMPAIGN_URL: domainCampaign + "promotion/CheckCampaign",
  GET_PROVINCES_URL: domainCampaign + "cms/Cities",
  VALIDATE_CODE_URL: domainCampaign + "campaign/validate",
  REQUEST_OTP_URL: domainCampaign + "auth/SmsOtp/RequestSmsOrOtp",
  OTP_LOG_URL: domainCampaign + "campaign/OTPLog",
  VERIFY_OTP_URL: domainCampaign + "auth/SmsOtp/VerifyOtp",
  REQUEST_LOGIN_URL: domainCampaign + "campaign/RequestLogin",
  UPDATE_USER_INFO_URL: domainLadingpage + "Member/UpdateInfo",
  REQUEST_GIGYA_URL: domainCampaign + "Gigya/GigyaRequest",
  EXCHANGE_CODE_URL: domainCampaign + "promotion/ExchangeCode",
  GET_REMAINING_URL: domainCampaign + "promotion/RemainingGifts",
  GET_LIST_WINNER_BY_TYPE: domainCampaign + "promotion/LuckyToolWinners",
  GET_LIST_PICTURE: domainCampaign + "campaign/awarded/image",
  TOPUP_MOBILE_URL: domainUtop + "gift/Topup",
  GET_REMAINING_PRIZE: domainCampaign + "campaign/gift/remaining",
  CHECK_PHONE_URL: domainCampaign + "promotion/CheckPhone",
  GET_WINNERS_HOME_URL: domainCampaign + "cms/getwinners",
  GET_SASTOKEN_URL: domainCampaign + "duet/sastoken",
  CREATE_SUBMISSION_URL: domainCampaign + "duet/submissions",
  CREATE_SUBMISSION_TIKTOK_URL: domainCampaign + "duet/tiktok-submissions",
  GET_SINGERS_URL: domainCampaign + "duet/songs",
  DELETE_SUBMISSION_URL: domainCampaign + "duet/submissions",
  CREATE_SETTINGS_URL: domainCampaign + "duet/settings",
  GET_SETTINGS_URL: domainCampaign + "duet/settings",
  GET_MY_SUB_URL: domainCampaign + "duet/me/submissions",
  GET_MY_POINTS_URL:
    domainCampaign + "loyalty/api/Historical/GetAllUserHistoryScallBill",
  GET_DETAIL_SUB_URL: domainCampaign + "duet/submissions",
  GET_LIST_SUB_URL: domainCampaign + "duet/submissions",
  GET_TOP_SUB_URL: domainCampaign + "duet/top/submissions",
  ADD_OR_UN_SOCIAL_LINK_URL: domainCampaign + "duet/socials",
  CREATE_USER_URL: domainCampaign + "duet/user-contestant",
  GET_SASTOKEN_URL_FOR_SCAN_BILL:
    domainCampaign + "loyalty/api/Invoice/GetSasToken",
  CHECK_STATUS_FILE_URL: domainCampaign + "loyalty/FilesStatus",
  EXECUTE_INVOICE_URL: domainCampaign + "loyalty/api/Invoice/ExecuteInvoice",
  REQUEST_CHECK_URL: domainCampaign + "loyalty/api/Invoice/RequestCheck",
  UPLOAD_INVOICE_URL: domainCampaign + "loyalty/api/Invoice/UploadInvoice",
  SHOPPER_APPROVE_URL: domainCampaign + "loyalty/api/Invoice/ShopperApprove",
  GET_BILL_POINT_URL:
    domainCampaign + "loyalty/api/Historical/GetSummaryScanBill",
  GET_TOP_10_WEB_JSON_URL: process.env.REACT_APP_TOP_10_WEB_JSON,
  GET_TOP_10_ALL_JSON_URL: process.env.REACT_APP_TOP_10_ALL_JSON,
  GET_HIGHEST_POINT_URL:  domainCampaign + "duet/highest-points",
  LIKE_OR_DISLIKE_URL: domainCampaign + "duet/submissions",
  GET_FULL_LINK_URL: domainCampaign + "duet/get-full-link",
  PUBLIC_VIDEO_DETAIL: domainCampaign + "duet/preview/",
  GET_PUBLIC_VIDEO_URL:  domainCampaign + "duet/status/",

};

export { END_POINTS };