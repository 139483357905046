import CommingSoonCountDown from "components/commingsoon-count-down";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const TheBanner = () => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const handleNav = () => {
    setNav(true);
  };
  const commingSoon = JSON.parse(localStorage.getItem("commingSoon"));

  const handleCloseNav = () => {
    setNav(false);
  };
  const handleScroll = (node) => {
    node.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    console.log("window.location.pathname", window.location.pathname);
    if (window.location.pathname) {
    }
  }, [window.location.pathname]);
  return (
    <section className="banner">
      <img
        className="img-full d-none-mb"
        src="assets/img/KV.jpg"
        alt="line-bt"
      />
      {window.location.pathname === "/hatcungthantuong" ? (
        <img
          className="img-full d-none"
          src="assets/img/KV-mb.png"
          alt="line-bt"
        />
      ) : (
        <img
          className="img-full d-none"
          src="assets/img/KV.jpg"
          alt="line-bt"
        />
      )}
      <div className="group-action container d-none-mb">
        {window.location.pathname === "/" && (
          <a href="#joinNow">
            <img src="assets/img/scrolldown.png" alt="scrolldown.png" />
          </a>
        )}

        <div className="group-btn-inline">
          <button
            type="button"
            className="btn btn-warning btn-block"
            onClick={() => {
              navigate("/#joinNow");
            }}
          >
            <a href="#joinNow">SĂN THẺ CÀO</a>
          </button>
          <button
            type="button"
            className={`btn btn-primary  btn-block ${
              commingSoon ? "disabled" : ""
            }`}
            onClick={() => {
              navigate("/hatcungthantuong");
            }}
          >
            {" "}
            <a href="#joinDuet">THAM GIA DUET</a>{" "}
          </button>
        </div>
        <CommingSoonCountDown />
      </div>
      {/* <div className="box-time">
          <p>Thời gian khuyến mại: <strong>20.03.2023 - 20.05.2023</strong></p>
        </div> */}
      {/* <div className="box-hotline">
          <p>Hotline: <strong>1900 299 981</strong></p>
        </div> */}
    </section>
  );
};

export default TheBanner;
