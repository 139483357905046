// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import Select from "react-select";
import TheFooter from "layout/TheFooter";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import reactUtils from "utils/reactUtils";

const DuetPageView = (props) => {
  const navigate = useNavigate();
  const {
    isLogin,
    submissions,
    onSeeAllSub,
    onSearchSub,
    searchText,
    setSearchText,
    topSubs,
    topEventSubs,
    highestPoints,
    onClickScanBill,
    setIsShowGuideLink,
    joinNow,
    onViewHistoryBill,
  } = props;

  const checkStatus = (item) => {
    let strStatus = "";
    switch (item.status) {
      case 0:
        strStatus = "Đang xử lý";
        break;

      case 1:
        strStatus = "Đang chờ duyệt";
        break;

      case 2:
        strStatus = "Video dự thi xử lý thất bại";
        break;

      case 4:
        strStatus = "Bị từ chối: " + item?.reason;
        break;

      case 6:
        strStatus = "Bản nháp";
        break;

      default:
        break;
    }
    return strStatus;
  };
  return (
    <section>
      <TheBanner />
      <div className="bgr-green screen-duet">
        <div className="group-action container d-none">
          <CommingSoonCountDown />
        </div>
        <div className="myListDuet">
          <div className="container" id="joinDuet">
            <img className="img-full d-none" src="assets/img/Title-btct.png" />
            <img
              className="img-full d-none-mb"
              src="assets/img/Title-btct-des.png"
            />
            {!isLogin && (
              <div className="box-warper">
                <p>
                  Bạn cần đăng nhập để
                  <br />
                  tham gia chương trình
                </p>
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      props.setModalLogin(true);
                    }}
                  >
                    <a>Đăng nhập bằng SĐT</a>
                  </button>
                </div>
              </div>
            )}
            {highestPoints && submissions.length > 0 && (
              <div className="box-warper box-count">
                <ul>
                  <li>
                    <p>Cao nhất ở tất cả nền tảng: </p>
                    <p>
                      {reactUtils.formatNumber(highestPoints?.maxPoint)} điểm
                    </p>
                  </li>
                  <li>
                    <p>Cao nhất trên website: </p>
                    <p>
                      {reactUtils.formatNumber(highestPoints?.maxWebPoint)} điểm
                    </p>
                  </li>
                  <li>
                    <p>Điểm nhận được từ hóa đơn: </p>
                    <p>
                      {reactUtils.formatNumber(highestPoints?.scanBillPoint)}{" "}
                      điểm
                    </p>
                  </li>
                </ul>
                <p>
                  Chi tiết cách tính điểm xin hãy{" "}
                  <a
                    className="linkAcc"
                    onClick={() => setIsShowGuideLink(true)}
                  >
                    xem ở đây.
                  </a>
                </p>
              </div>
            )}

            {isLogin && (
              <div className="box-warper">
                {submissions?.length > 0 ? (
                  <ul className="list-duet">
                    {submissions.map((item) => {
                      return (
                        <li
                          onClick={() => {
                            navigate(`/chitietbaithi?id=${item?.id}`);
                          }}
                        >
                          <img className="avarta" src={item?.image} alt="" />
                          <ul className="content">
                            <li>
                              SBD {item?.contestantInfo?.contestNumber} |{" "}
                              {item?.contestantInfo?.userName}
                            </li>
                            <li>
                              {item?.submissionInfo?.songName} -{" "}
                              {item?.submissionInfo?.singerName}
                            </li>
                            <li>
                              Ngày dự thi:{" "}
                              {moment(item?.createdAt)
                                .format("DD/MM/YYYY")
                                .toString()}
                            </li>
                            {item?.status !== 3 ? (
                              <li>
                                <span
                                  className={`${
                                    item?.status === 2 || item?.status === 4
                                      ? "cancel"
                                      : "pending"
                                  }`}
                                >
                                  {checkStatus(item)}
                                </span>
                              </li>
                            ) : (
                              <>
                                <li>
                                  <span className="sumcount">
                                    Tổng điểm:{" "}
                                    {reactUtils.formatNumber(item?.points)} điểm{" "}
                                    {item?.rank > 0 &&
                                      item?.rank <= 10 &&
                                      `(Top ${item?.rank})`}
                                  </span>
                                </li>
                                <li>
                                  <span className="webcount">
                                    Điểm website:{" "}
                                    {reactUtils.formatNumber(item?.webPoints)}{" "}
                                    điểm
                                  </span>
                                </li>
                              </>
                            )}

                            {/* {item?.status === 4 && (
                              <li>
                                <span className="cancel">
                                  Bị từ chối: Vi phạm quy dịnh quay video
                                </span>
                              </li>
                            )} */}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p>Bạn hiện chưa có video nào...</p>
                )}

                <div className="btn-wrapper">
                  {submissions?.length > 0 ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={joinNow}
                    >
                      <a> Thêm bài dự thi mới</a>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={joinNow}
                    >
                      <a>Tham gia ngay</a>
                    </button>
                  )}
                </div>
                <div className="group-btn-inline">
                  <button
                    onClick={onViewHistoryBill}
                    type="button"
                    className="btn btn-primary btn-border btn-block"
                  >
                    <a>Xem hóa đơn</a>
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning btn-block"
                    onClick={() => {
                      onClickScanBill();
                    }}
                  >
                    <a>Chụp hóa đơn</a>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <p className="text-guide">
          Hướng dẫn tham gia Duet xin hãy{" "}
          <a href="/guideduet" className="text-red">
            xem ở đây.
          </a>
        </p> */}
        <div className="container">
          <br />
          <button
            type="button"
            className="btn btn-warning btn-block"
            onClick={() => {
              navigate(`/guideduet`);
            }}
          >
            <a>HƯỚNG DẪN THAM GIA</a>
          </button>
        </div>
        <div
          className="searchDuet  container"
          // style={{
          //   minHeight: window.innerHeight - 220,
          //   objectFit: "contain",
          // }}
        >
          <div className="line"></div>
          <img className="img-full d-none" src="assets/img/title-tbdt.png" />
          <img
            className="img-full-des d-none-mb"
            src="assets/img/title-tbdt-des.png"
          />
          <div className="search-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="usr"
                name="usr"
                placeholder="Nhập SĐT/ SDB/ Bài hát/ Ca sĩ..."
                maxLength={50}
                min="0"
                max="50"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e?.target?.value);
                }}
              />
            </div>
            <button
              className={`btn btn-primary btn-block ${
                searchText?.trim() === "" && "disabled"
              }`}
              id="btn-search"
              onClick={onSearchSub}
            >
              <a>
                <img src="assets/img/icon/search.svg" />
              </a>
            </button>
          </div>
          <button
            onClick={onSeeAllSub}
            type="button"
            className="btn btn-warning btn-block"
          >
            <a>Xem tất cả bài dự thi</a>
          </button>
        </div>
        <div className="line"></div>

        <div className="leaderboard">
          <img className="img-full d-none" src="assets/img/title-duet.png" />
          <div className="container  d-none-mb">
            <img className="img-full" src="assets/img/title-duet-des.png" />
          </div>

          <div className="container">
            <Tabs>
              <TabList>
                <Tab>
                  tổng điểm trên
                  <br />
                  các nền tảng
                </Tab>
                <Tab>
                  tổng điểm trên
                  <br />
                  trang sự kiện
                </Tab>
              </TabList>

              <TabPanel>
                <div className="TopDuet">
                  {topSubs?.[0] ? (
                    <ul
                      key={topSubs?.[0]?.id}
                      onClick={() => {
                        navigate(`/chitietbaithi?id=${topSubs?.[0]?.id}`);
                      }}
                      className="list-duet"
                    >
                      <li>
                        <div className="avarta">
                          <img
                            className="avarta"
                            src={topSubs?.[0]?.image}
                            alt=""
                          />
                          <span className="tag">1</span>
                        </div>
                        <ul className="content">
                          <li>
                            SBD {topSubs[0]?.contestantInfo?.contestNumber} |{" "}
                            {topSubs[0]?.contestantInfo?.userName}
                          </li>
                          <li>
                            {topSubs[0]?.submissionInfo?.songName} -{" "}
                            {topSubs[0]?.submissionInfo?.singerName}
                          </li>
                          <li>
                            Ngày dự thi:{" "}
                            {moment(topSubs[0]?.createdAt)
                              .format("DD/MM/YYYY")
                              .toString()}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    <span>Chưa có bài dự thi.</span>
                  )}
                </div>
                <div className="TabPanel-duet">
                  <ul className="list-duet">
                    {topSubs?.map((item, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <li
                              key={item?.id}
                              onClick={() => {
                                navigate(`/chitietbaithi?id=${item?.id}`);
                              }}
                            >
                              <div className="avarta">
                                <img
                                  className="avarta"
                                  src={item?.image}
                                  alt=""
                                />
                                {item?.rank > 0 && (
                                  <span className="tag">{item?.rank}</span>
                                )}
                              </div>
                              <ul className="content">
                                <li>
                                  SBD {item?.contestantInfo?.contestNumber} |{" "}
                                  {item?.contestantInfo?.userName}
                                </li>
                                <li>
                                  {item?.submissionInfo?.songName} -{" "}
                                  {item?.submissionInfo?.singerName}
                                </li>
                                <li>
                                  Ngày dự thi:{" "}
                                  {moment(item?.createdAt)
                                    .format("DD/MM/YYYY")
                                    .toString()}
                                </li>
                              </ul>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="TopDuet">
                  {topEventSubs?.[0] ? (
                    <ul className="list-duet">
                      <li
                        onClick={() => {
                          navigate(
                            `/chitietbaithi?id=${topEventSubs?.[0]?.id}`
                          );
                        }}
                      >
                        <div className="avarta">
                          <img
                            className="avarta"
                            src={topEventSubs[0]?.image}
                            alt=""
                          />
                          <span className="tag">1</span>
                        </div>
                        <ul className="content">
                          <li>
                            SBD {topEventSubs[0]?.contestantInfo?.contestNumber}{" "}
                            | {topEventSubs[0]?.contestantInfo?.userName}
                          </li>
                          <li>
                            {topEventSubs[0]?.submissionInfo?.songName} -{" "}
                            {topEventSubs[0]?.submissionInfo?.singerName}
                          </li>
                          <li>
                            Ngày dự thi:{" "}
                            {moment(topEventSubs[0]?.createdAt)
                              .format("DD/MM/YYYY")
                              .toString()}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    <span>Chưa có bài dự thi.</span>
                  )}
                </div>
                <div className="TabPanel-duet">
                  <ul className="list-duet">
                    {topEventSubs?.map((item, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <li
                              key={item?.id}
                              onClick={() => {
                                navigate(`/chitietbaithi?id=${item?.id}`);
                              }}
                            >
                              <div className="avarta">
                                <img
                                  className="avarta"
                                  src={item?.image}
                                  alt=""
                                />
                                {item?.rank > 0 && (
                                  <span className="tag">{item?.rank}</span>
                                )}
                              </div>
                              <ul className="content">
                                <li>
                                  SBD {item?.contestantInfo?.contestNumber} |{" "}
                                  {item?.contestantInfo?.userName}
                                </li>
                                <li>
                                  {item?.submissionInfo?.songName} -{" "}
                                  {item?.submissionInfo?.singerName}
                                </li>
                                <li>
                                  Ngày dự thi:{" "}
                                  {moment(item?.createdAt)
                                    .format("DD/MM/YYYY")
                                    .toString()}
                                </li>
                              </ul>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>

        <TheFooter />
      </div>
    </section>
  );
};
export default DuetPageView;
