const initialState = {};

const homeReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    default:
      return state;
  }
};

export default homeReducer;
