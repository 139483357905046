import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/configureStore";
import apiConfig from './configs/env'
const root = ReactDOM.createRoot(document.getElementById("root"));
// 
const script = document.createElement('script')
script.src = 'https://www.googletagmanager.com/gtag/js?id=' + apiConfig.gaKey
script.async = true
document.body.appendChild(script)
// @ts-ignore
window.dataLayer = window.dataLayer || []
function gtag() {
  // @ts-ignore
  // console.log("window.dataLayer", window.dataLayer)
  // @ts-ignore
  window.dataLayer.push(arguments)
}
gtag('js', new Date())
gtag('config', apiConfig.gaKey)
// 
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
