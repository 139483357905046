import { actionGenerator } from "utils";

const PREFIX = "SCAN_BILL";

export const GET_SASTOKEN = actionGenerator.genActionTypes(
  `${PREFIX}_GET_SASTOKEN`
);

export const EXECUTE_INVOICE = actionGenerator.genActionTypes(
  `${PREFIX}_EXECUTE_INVOICE`
);

export const UPLOAD_INVOICE = actionGenerator.genActionTypes(
  `${PREFIX}_UPLOAD_INVOICE`
);

export const SHOPPER_APPROVE = actionGenerator.genActionTypes(
  `${PREFIX}_SHOPPER_APPROVE`
);

export const REQUEST_CHECK = actionGenerator.genActionTypes(
  `${PREFIX}_REQUEST_CHECK`
);

export const CHECK_STATUS_FILE_URL = actionGenerator.genActionTypes(
  `${PREFIX}_CHECK_STATUS_FILE_URL`
);
