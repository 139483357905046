export const CAMPAIGNID = process.env.REACT_APP_CAMPAIGNID;
export const SAAS_CAMPAIGNID = process.env.REACT_APP_SAAS_CAMPAIGNID;
export const TENANTID = process.env.REACT_APP_TENANTID;
export const TENANTID_SCAN_BILL = process.env.REACT_APP_TENANTID_SCAN_BILL;

export const BUSINESS_ACCOUNT_ID = process.env.REACT_APP_BUSINESS_ACCOUNT_ID;

export const START_DATE = process.env.REACT_APP_START_DATE_CAMPAIGN;
export const START_DATE_TIME = process.env.REACT_APP_START_DATE_TIME_CAMPAIGN;

export const END_DATE = process.env.REACT_APP_END_DATE_CAMPAIGN;
export const END_DATE_TIME = process.env.REACT_APP_END_DATE_TIME_CAMPAIGN;

export const LINK_VIDEO = process.env.REACT_APP_LINK_VIDEO;

export const TELCO = [
  {
    label: "Viettel",
    value: "VTT",
  },
  {
    label: "Mobiphone",
    value: "VMS",
  },
  {
    label: "Vinaphone",
    value: "VNP",
  },
  {
    label: "Vietnamobile",
    value: "VNM",
  },
  // {
  //   label: "Vietnamobile",
  //   value: "Vietnamobile",
  // },
  // {
  //   label: "Reddi",
  //   value: "REDDI",
  // },
  {
    label: "Gtel",
    value: "BEE",
  },
  // {
  //   label: "Itelecom",
  //   value: "ITC",
  // },
];

export const MESSAGE_ERROR = {
  default:
    "Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ 1900638880 (1.000Đ/phút) để được hỗ trợ và tư vấn.",
  InActiveBrowser:
    "Quay duet bị gián đoạn do kết nối không ổn định, vui lòng duet lại.",
  NotAllowCameraPermission:
    "Vui lòng cho phép truy cập camera và micro để tiếp tục quay duet.",
  DetectLandscape:
    "Vui lòng xoay dọc màn hình điện thoại để tham gia quay duet.",
  NotEngaged:
    "Điểm từ hoạt động chụp ảnh hóa đơn vẫn sẽ được cộng cho bạn, tuy nhiên bạn cần có ít nhất một bài dự thi để có thể tham gia xếp hạng nhận thưởng.",
};

export const MESSAGE_SCAN_BILL = {
  BillDateIsEmpty:
    "Hóa đơn của bạn có thể đã bị che thông tin hoặc bị mờ khiến hệ thống chưa đọc được đầy đủ nội dung trên hóa đơn.",
  BillExist:
    "Hóa đơn đã tồn tại trên hệ thống, vui lòng không chụp tiếp và liên hệ hotline để được hỗ trợ",
  BillNotFull:
    "Vui lòng chụp lại toàn bộ hóa đơn (bao gồm cả QRCode/ Barcode). Nếu hóa đơn dài, bạn có thể chụp nhiều ảnh.",
  BlackList: "Hoá đơn của bạn có thể đã bị lỗi.",
  BlurInvoice:
    "Hóa đơn của bạn có thể đã bị che thông tin hoặc bị mờ khiến hệ thống chưa đọc được đầy đủ nội dung trên hóa đơn",
  CaptureFromScreen:
    "Hệ thống không chấp nhận hóa đơn chụp qua màn hình điện tử. Vui lòng chụp lại hóa đơn thật.",
  ExceptionBill: "Mẫu hóa đơn chưa được hỗ trợ. Vui lòng kiểm tra lại.",
  ExpiredDatetime:
    "Ngày mua hàng không nằm trong thời gian mua hàng hợp lệ thuộc chương trình khuyến mại của Acecook.",
  ExtractDataFail:
    "Hoá đơn của bạn có thể bị che thông tin hoặc bị mờ khiến hệ thống chưa đọc được nội dung đầy đủ trên hoá đơn.",
  FarBill:
    "Phạm vi hiển thị của hoá đơn trên hình ảnh đang có kích thước nhỏ. Thử đưa hóa đơn lại gần màn hình và chụp lại nhé.",
  IncorrectImageSequence:
    "Hình chụp không hợp lệ hoặc các ảnh chụp của hóa đơn không đúng thứ tự. Vui lòng kiểm tra lại.",
  InvalidInvoiceNotExtractStore: "Không nhận diện được tên siêu thị",
  MallNull: "Không nhận diện được tên siêu thị",
  MaxAngleBill: "Vui lòng để hóa đơn ngay ngắn và chụp lại nhé",
  NotEnoughAmount:
    "Hóa đơn không có sản phẩm được áp dụng của Acecook hoặc bị mờ khiến hệ thống chưa đọc được đầy đủ nội dung trên hóa đơn.",
  NotFoundBillCode:
    "Vui lòng chụp lại toàn bộ hóa đơn (bao gồm cả QRCode/ Barcode). Nếu hóa đơn dài, bạn có thể chụp nhiều ảnh.",
  OCRFail: "Đã có lỗi xảy ra. Bạn vui lòng thử lại sau.",
  ReceiptIdBillIsNull:
    "Hóa đơn của bạn có thể đã bị che thông tin hoặc bị mờ khiến hệ thống chưa đọc được đầy đủ nội dung trên hóa đơn",
  Reprint: "Hóa đơn in lại không thể tham gia chương trình",
  ReprintBill: "Hóa đơn in lại không thể tham gia chương trình",
  ReverseBill: "Vui lòng để hóa đơn ngay ngắn và chụp lại nhé",
  StoreNull: "Không nhận diện được tên siêu thị",
  SystemError: "Đã có lỗi xảy ra. Bạn vui lòng thử lại sau.",
  TwoInvoice:
    "Hệ thống không hỗ trợ nhiều hóa đơn khác nhau, vui lòng kiểm tra lại",
  UnsupportedMall:
    "Siêu thị không nằm trong danh sách áp dụng chương trình hoặc đã hết thời gian tích điểm",
};

export const PENDING_ERROR_CODES = [
  "ABDuplicateAmount",
  "ABDuplicateName",
  "BillCodeDuplicate",
  "BillCodeNotMapBillId",
  "BillIdChanged",
  "BlackList",
  "BodyChanged",
  "CheckSumDuplicate",
  "DuplicateCheck",
  "InvalidValidityTime",
  "IpLimitByDate",
  "LimitFpUserAgentGpu",
  "LimitFpUserAgentGpuKA",
  "LimitGift",
  "LimitImage",
  "LimitInvoiceByDay",
  "LimitMemberCode",
  "MaxTotalAmountU",
  "MinimumProductCount",
  "NotExistsEInvoice",
  "OutOfServiceTime",
  "PendingPhonePattern",
  "PriceDifference",
  "QRCodeNotFound",
  "StampInDetail",
  "StampInQRCode",
  "StockIsEmpty",
];

export const VT = [
  "086",
  "096",
  "097",
  "098",
  "032",
  "033",
  "034",
  "035",
  "036",
  "037",
  "038",
  "039",
];
export const MB = ["089", "090", "093", "070", "079", "077", "076", "078"];
export const VN = ["088", "091", "094", "083", "084", "085", "081", "082"];
export const VNMB = ["092", "056", "058"];
export const GM = ["099", "059"];

export const PROVINCES = [
  {
    label: "An Giang",
    value: "89",
  },
  {
    label: "Bà Rịa - Vũng Tàu",
    value: "77",
  },
  {
    label: "Bắc Giang",
    value: "24",
  },
  {
    label: "Bắc Kạn",
    value: "06",
  },
  {
    label: "Bạc Liêu",
    value: "95",
  },
  {
    label: "Bắc Ninh",
    value: "27",
  },
  {
    label: "Bến Tre",
    value: "83",
  },
  {
    label: "Bình Định",
    value: "52",
  },
  {
    label: "Bình Dương",
    value: "74",
  },
  {
    label: "Bình Phước",
    value: "70",
  },
  {
    label: "Bình Thuận",
    value: "60",
  },
  {
    label: "Cà Mau",
    value: "96",
  },
  {
    label: "Cần Thơ",
    value: "92",
  },
  {
    label: "Cao Bằng",
    value: "04",
  },
  {
    label: "Đà Nẵng",
    value: "48",
    hot: true,
  },
  {
    label: "Đắk Lắk",
    value: "66",
  },
  {
    label: "Đắk Nông",
    value: "67",
  },
  {
    label: "Điện Biên",
    value: "11",
  },
  {
    label: "Đồng Nai",
    value: "75",
  },
  {
    label: "Đồng Tháp",
    value: "87",
  },
  {
    label: "Gia Lai",
    value: "64",
  },
  {
    label: "Hà Giang",
    value: "02",
  },
  {
    label: "Hà Nam",
    value: "35",
  },
  {
    label: "Hà Nội",
    value: "01",
    hot: true,
  },
  {
    label: "Hà Tĩnh",
    value: "42",
  },
  {
    label: "Hải Dương",
    value: "30",
  },
  {
    label: "Hải Phòng",
    value: "31",
  },
  {
    label: "Hậu Giang",
    value: "93",
  },
  {
    label: "Hòa Bình",
    value: "17",
  },
  {
    label: "Hưng Yên",
    value: "33",
  },
  {
    label: "Khánh Hòa",
    value: "56",
  },
  {
    label: "Kiên Giang",
    value: "91",
  },
  {
    label: "Kon Tum",
    value: "62",
  },
  {
    label: "Lai Châu",
    value: "12",
  },
  {
    label: "Lâm Đồng",
    value: "68",
  },
  {
    label: "Lạng Sơn",
    value: "20",
  },
  {
    label: "Lào Cai",
    value: "10",
  },
  {
    label: "Long An",
    value: "80",
  },
  {
    label: "Nam Định",
    value: "36",
  },
  {
    label: "Nghệ An",
    value: "40",
  },
  {
    label: "Ninh Bình",
    value: "37",
  },
  {
    label: "Ninh Thuận",
    value: "58",
  },
  {
    label: "Phú Thọ",
    value: "25",
  },
  {
    label: "Phú Yên",
    value: "54",
  },
  {
    label: "Quảng Bình",
    value: "44",
  },
  {
    label: "Quảng Nam",
    value: "49",
  },
  {
    label: "Quảng Ngãi",
    value: "51",
  },
  {
    label: "Quảng Ninh",
    value: "22",
  },
  {
    label: "Quảng Trị",
    value: "45",
  },
  {
    label: "Sóc Trăng",
    value: "94",
  },
  {
    label: "Sơn La",
    value: "14",
  },
  {
    label: "Tây Ninh",
    value: "72",
  },
  {
    label: "Thái Bình",
    value: "34",
  },
  {
    label: "Thái Nguyên",
    value: "19",
  },
  {
    label: "Thanh Hóa",
    value: "38",
  },
  {
    label: "Thừa Thiên Huế",
    value: "46",
  },
  {
    label: "Tiền Giang",
    value: "82",
  },
  {
    label: "TP.HCM",
    value: "79",
    hot: true,
  },
  {
    label: "Trà Vinh",
    value: "84",
  },
  {
    label: "Tuyên Quang",
    value: "08",
  },
  {
    label: "Vĩnh Long",
    value: "86",
  },
  {
    label: "Vĩnh Phúc",
    value: "26",
  },
  {
    label: "Yên Bái",
    value: "15",
  },
];
