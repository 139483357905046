import actions from "./actions";
import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import {
  GET_LIST_WINNER_BY_TYPE,
  GET_REMAINING,
  GET_REMAINING_PRIZE,
  GET_LIST_PICTURE,
} from "./constants";
import {
  getListWinnerByTypeApi,
  getListPictureApi,
  getRemainingApi,
  getRemainingPrizeApi,
} from "api/prize";

export function* getRemainingSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getRemainingApi, payload);
    yield put(actions.GET_REMAINING.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_REMAINING.failure(err));
  }
}

export function* getListWinnerByTypeSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getListWinnerByTypeApi, payload);
    yield put(actions.GET_LIST_WINNER_BY_TYPE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_LIST_WINNER_BY_TYPE.failure(err));
  }
}

export function* getListPictureSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getListPictureApi, payload);
    yield put(actions.GET_LIST_PICTURE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_LIST_PICTURE.failure(err));
  }
}

export function* getRemainingPrizeSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getRemainingPrizeApi, payload);
    yield put(actions.GET_REMAINING_PRIZE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_REMAINING_PRIZE.failure(err));
  }
}

function* watchCommon() {
  yield takeLatest(GET_REMAINING.request, getRemainingSaga);
  yield takeEvery(GET_LIST_WINNER_BY_TYPE.request, getListWinnerByTypeSaga);
  yield takeLatest(GET_LIST_PICTURE.request, getListPictureSaga);
  yield takeLatest(GET_REMAINING_PRIZE.request, getRemainingPrizeSaga);
}

export default function* rootChild() {
  yield fork(watchCommon);
}
