import actions from "./actions";
import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import {
  CHECK_STATUS_FILE_URL,
  EXECUTE_INVOICE,
  GET_SASTOKEN,
  REQUEST_CHECK,
  SHOPPER_APPROVE,
  UPLOAD_INVOICE,
} from "./constants";
import {} from "api/duet";
import {
  checkStatusFilesApi,
  executeInvoiceApi,
  getSasTokenApi,
  requestCheckApi,
  shopperApproveApi,
  uploadInvoiceApi,
} from "api/scanBill";

export function* getSasTokenSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(getSasTokenApi, payload);
    yield put(actions.GET_SASTOKEN.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.GET_SASTOKEN.failure(err));
  }
}

export function* executeInvoiceSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(executeInvoiceApi, payload);
    yield put(actions.EXECUTE_INVOICE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.EXECUTE_INVOICE.failure(err));
  }
}

export function* uploadInvoiceSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(uploadInvoiceApi, payload);
    yield put(actions.UPLOAD_INVOICE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.UPLOAD_INVOICE.failure(err));
  }
}

export function* shopperApproveSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(shopperApproveApi, payload);
    yield put(actions.SHOPPER_APPROVE.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.SHOPPER_APPROVE.failure(err));
  }
}

export function* requestCheckSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(requestCheckApi, payload);
    yield put(actions.REQUEST_CHECK.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.REQUEST_CHECK.failure(err));
  }
}

export function* checkStatusFilesSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield call(checkStatusFilesApi, payload);
    yield put(actions.CHECK_STATUS_FILE_URL.success(res));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(actions.CHECK_STATUS_FILE_URL.failure(err));
  }
}

function* watchCommon() {
  yield takeLatest(GET_SASTOKEN.request, getSasTokenSaga);
  yield takeLatest(EXECUTE_INVOICE.request, executeInvoiceSaga);
  yield takeLatest(UPLOAD_INVOICE.request, uploadInvoiceSaga);
  yield takeLatest(SHOPPER_APPROVE.request, shopperApproveSaga);
  yield takeLatest(REQUEST_CHECK.request, requestCheckSaga);
  yield takeLatest(CHECK_STATUS_FILE_URL.request, checkStatusFilesSaga);

}

export default function* rootChild() {
  yield fork(watchCommon);
}
