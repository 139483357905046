import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupNoGift = (props) => {
  const [data, setData] = useState(props?.dataModalTopup);
  const navigate = useNavigate();
  const closeModal = () => {
    props.closeModal();
    document.body.classList.remove("modal-open");
  };

  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="popup-inner">
            <div className="content-popup">
              <p>
                Rất tiếc bạn không trúng thưởng với mã dự thưởng{" "}
                <span>{data?.code}</span>.
              </p>
              <p>
                <strong>{data?.extraInfo?.name}</strong>
              </p>
              <p>
                Bạn vẫn còn cơ hội trúng <b>1 TRONG 150 CHỈ VÀNG từ HaoHao</b>.
                Lưu ý hãy giữ lại phần vỏ nhãn chứa mã dự thưởng gồm{" "}
                <strong>10 ký tự còn nguyên vẹn</strong> để đợi công bố GIẢI ĐẶC
                BIỆT từ HaoHao. Hotline <strong>1900 299 981</strong>
              </p>
              {/* <p>
                Vui lòng truy cập{" "}
                <a
                  href="javascript:;"
                  onClick={() => {
                    window.open("/thelechuongtrinh", "_blank");
                  }}
                >
                  Thể lệ{" "}
                </a>
                <br />
                hoặc liên hệ Hotline <strong>1900 299 981</strong>
                <br />
                để biết thêm chi tiết
              </p> */}
            </div>
            <div className="btn-wrapper btn-center">
              <button
                type="button"
                className="btn btn-primary "
                onClick={closeModal}
              >
                ĐÓNG
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupNoGift;
