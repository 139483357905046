import { actionGenerator } from "utils";
import {
  GET_PROVINCES,
  VALIDATE_CODE,
  REQUEST_OTP,
  VERIFY_OTP,
  OTP_LOG,
  REQUEST_LOGIN,
  UPDATE_USER_INFO,
  REQUEST_GIGYA,
  EXCHANGE_CODE,
  TOPUP_MOBILE,
  VALIDATE_CAMPAIGN,
  CHECK_PHONE,
  GET_WINNERS_HOME,
  CREATE_USER,
} from "./constants";

const actions = {
  GET_PROVINCES: actionGenerator.genActions(GET_PROVINCES),
  VALIDATE_CODE: actionGenerator.genActions(VALIDATE_CODE),
  VALIDATE_CAMPAIGN: actionGenerator.genActions(VALIDATE_CAMPAIGN),
  REQUEST_OTP: actionGenerator.genActions(REQUEST_OTP),
  OTP_LOG: actionGenerator.genActions(OTP_LOG),
  VERIFY_OTP: actionGenerator.genActions(VERIFY_OTP),
  REQUEST_LOGIN: actionGenerator.genActions(REQUEST_LOGIN),
  UPDATE_USER_INFO: actionGenerator.genActions(UPDATE_USER_INFO),
  REQUEST_GIGYA: actionGenerator.genActions(REQUEST_GIGYA),
  EXCHANGE_CODE: actionGenerator.genActions(EXCHANGE_CODE),
  TOPUP_MOBILE: actionGenerator.genActions(TOPUP_MOBILE),
  CHECK_PHONE: actionGenerator.genActions(CHECK_PHONE),
  GET_WINNERS_HOME: actionGenerator.genActions(GET_WINNERS_HOME),
  CREATE_USER: actionGenerator.genActions(CREATE_USER),
};

export default actions;
