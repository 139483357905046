import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/hatcungthantuong");
    }, 2000);
  }, []);

  return <div></div>
};

export default LogoutPage;
