import { actionGenerator } from "utils";

const PREFIX = "HOME";

export const GET_PROVINCES = actionGenerator.genActionTypes(
  `${PREFIX}_GET_PROVINCES`
);

export const VALIDATE_CAMPAIGN = actionGenerator.genActionTypes(
  `${PREFIX}_VALIDATE_CAMPAIGN`
);

export const VALIDATE_CODE = actionGenerator.genActionTypes(
  `${PREFIX}_VALIDATE_CODE`
);

export const REQUEST_OTP = actionGenerator.genActionTypes(
  `${PREFIX}_REQUEST_OTP`
);

export const OTP_LOG = actionGenerator.genActionTypes(`${PREFIX}_OTP_LOG`);

export const VERIFY_OTP = actionGenerator.genActionTypes(
  `${PREFIX}_VERIFY_OTP`
);
export const REQUEST_LOGIN = actionGenerator.genActionTypes(
  `${PREFIX}_REQUEST_LOGIN`
);
export const UPDATE_USER_INFO = actionGenerator.genActionTypes(
  `${PREFIX}_UPDATE_USER_INFO`
);
export const REQUEST_GIGYA = actionGenerator.genActionTypes(
  `${PREFIX}_REQUEST_GIGYA`
);
export const EXCHANGE_CODE = actionGenerator.genActionTypes(
  `${PREFIX}_EXCHANGE_CODE`
);
export const TOPUP_MOBILE = actionGenerator.genActionTypes(
  `${PREFIX}_TOPUP_MOBILE`
);
export const CHECK_PHONE = actionGenerator.genActionTypes(
  `${PREFIX}_CHECK_PHONE`
);
export const GET_WINNERS_HOME = actionGenerator.genActionTypes(
  `${PREFIX}_GET_WINNERS_HOME`
);
export const CREATE_USER = actionGenerator.genActionTypes(
  `${PREFIX}_CREATE_USER`
);
