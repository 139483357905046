import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const ScanBillGuidePage = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add("open-scanbill");
  }, []);
  return (
    <>
      <div className="capture-scanbill">
        <div className="capture-guide">
          <img className="brand" alt="" src="assets/img/guide0.png" />
          <div className="content-step">
            <h4>Hướng dẫn</h4>
            <ul>
              <li>
                <p>
                  Để khung chụp sát với hóa đơn và chụp từ đầu đến cuối hóa đơn
                </p>
              </li>
              <li>
                <p>
                  Nhấn “Chụp thêm” để tiếp tục chụp phần còn lại của hóa đơn đến
                  hết
                </p>
              </li>
              <li>
                <p>Ảnh chụp sau không trùng lặp với các ảnh trước</p>
              </li>
            </ul>
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => {
                navigate("/scanbill");
              }}
            >
              <a>Tiếp </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScanBillGuidePage;
