import { actionGenerator } from "utils";
import {
  GET_REMAINING,
  GET_LIST_WINNER_BY_TYPE,
  GET_REMAINING_PRIZE,
  GET_LIST_PICTURE,
} from "./constants";

const actions = {
  GET_REMAINING: actionGenerator.genActions(GET_REMAINING),
  GET_LIST_WINNER_BY_TYPE: actionGenerator.genActions(GET_LIST_WINNER_BY_TYPE),
  GET_LIST_PICTURE: actionGenerator.genActions(GET_LIST_PICTURE),
  GET_REMAINING_PRIZE: actionGenerator.genActions(GET_REMAINING_PRIZE),
};

export default actions;
