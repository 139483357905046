// @ts-nocheck
import { LINK_VIDEO } from "constants/common";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupGuideGetLinkFB = (props) => {
  const navigate = useNavigate();
  const { closeModal, platform } = props;

  return (
    <>
      <div className="popup-overlay modal-notify modal-getlinkFB">
        <div className="popup-warp">
          <div
            className="close-modal"
            onClick={() => {
              closeModal(false);
            }}
          ></div>
          <div className="popup-inner">
            <div className="content-popup">
              {platform === "Facebook" && (
                <>
                  <h3>Lấy link Facebook</h3>
                  <p>B1: Nhấn vào nút “Chia sẻ” ở bên dưới bài viết</p>
                  <img src="assets/img/getlinkfb1.png" alt="text-info " />
                  <p>B2: Nhấn vào “Sao chép liên kết”</p>
                  <img src="assets/img/getlinkfb2.png" alt="text-info " />
                </>
              )}
              {platform === "Tiktok" && (
                <>
                  <h3>Lấy link Tiktok</h3>
                  <p>B1: Nhấn vào “...” ở góc dưới bên phải của video</p>
                  <img src="assets/img/getlinkfb1-tk.png" alt="text-info " />
                  <p>B2: Nhấn vào “Sao chép liên kết”</p>
                  <img src="assets/img/getlinkfb2-tk.png" alt="text-info " />
                </>
              )}
              <p>
                Note: Link lấy được từ các cách lấy link khác cũng có thể sử
                dụng để liên kết.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupGuideGetLinkFB;
