import { actionGenerator } from "utils";
import {
  GET_SASTOKEN,
  UPLOAD_VIDEO,
  CREATE_SUBMISSION,
  CREATE_SUBMISSION_TIKTOK,
  GET_SINGERS,
  DELETE_SUBMISSION,
  GET_SETTINGS,
  CREATE_SETTINGS,
  GET_MY_SUB,
  GET_MY_POINTS,
  GET_DETAIL_SUB,
  GET_LIST_SUB,
  GET_TOP_SUB,
  ADD_OR_UN_SOCIAL_LINK,
  GET_BILL_POINT,
  LIKE_OR_DISLIKE,
  GET_TOP_10_ALL,
  GET_TOP_10_WEB,
  GET_FULL_LINK,
  GET_HIGHEST_POINT,
  PUBLIC_VIDEO,
  GET_PUBLIC_VIDEO_URL
} from "./constants";

const actions = {
  GET_SASTOKEN: actionGenerator.genActions(GET_SASTOKEN),
  UPLOAD_VIDEO: actionGenerator.genActions(UPLOAD_VIDEO),
  CREATE_SUBMISSION: actionGenerator.genActions(CREATE_SUBMISSION),
  CREATE_SUBMISSION_TIKTOK: actionGenerator.genActions(CREATE_SUBMISSION_TIKTOK),
  GET_SINGERS: actionGenerator.genActions(GET_SINGERS),
  DELETE_SUBMISSION: actionGenerator.genActions(DELETE_SUBMISSION),
  GET_SETTINGS: actionGenerator.genActions(GET_SETTINGS),
  CREATE_SETTINGS: actionGenerator.genActions(CREATE_SETTINGS),
  GET_MY_SUB: actionGenerator.genActions(GET_MY_SUB),
  GET_MY_POINTS: actionGenerator.genActions(GET_MY_POINTS),
  GET_DETAIL_SUB: actionGenerator.genActions(GET_DETAIL_SUB),
  GET_LIST_SUB: actionGenerator.genActions(GET_LIST_SUB),
  GET_TOP_SUB: actionGenerator.genActions(GET_TOP_SUB),
  ADD_OR_UN_SOCIAL_LINK: actionGenerator.genActions(ADD_OR_UN_SOCIAL_LINK),
  GET_BILL_POINT: actionGenerator.genActions(GET_BILL_POINT),
  LIKE_OR_DISLIKE: actionGenerator.genActions(LIKE_OR_DISLIKE),
  GET_TOP_10_ALL: actionGenerator.genActions(GET_TOP_10_ALL),
  GET_TOP_10_WEB: actionGenerator.genActions(GET_TOP_10_WEB),
  GET_HIGHEST_POINT: actionGenerator.genActions(GET_HIGHEST_POINT),
  GET_FULL_LINK: actionGenerator.genActions(GET_FULL_LINK),
  PUBLIC_VIDEO: actionGenerator.genActions(PUBLIC_VIDEO),
  GET_PUBLIC_VIDEO_URL: actionGenerator.genActions(GET_PUBLIC_VIDEO_URL),
};

export default actions;
