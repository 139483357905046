// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupPostFacebook = (props) => {
  const navigate = useNavigate();
  const {
    platform,
    searchText,
    setSearchText,
    closeModal,
    onContinue,
    linkPostFB,
    linkPostTiktok,
    onShowGuide,
    onGuideGetLinkFB,
  } = props;
  const [isShowCopy, setIsShowCopy] = useState(false);

  useEffect(() => {
    switch (platform) {
      case "Facebook":
        if (!!linkPostFB) {
          setIsShowCopy(true);
          setSearchText(linkPostFB);
        }
        break;

      case "Tiktok":
        if (!!linkPostTiktok) {
          setIsShowCopy(true);
          setSearchText(linkPostTiktok);
        }
        break;

      default:
        break;
    }
  }, []);

  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>LIÊN KẾT {platform?.toUpperCase()}</h3>
              <p>
                <b>
                  Copy link video dự thi đã đăng trên {platform} và điền vào ô
                  bên dưới để được cộng điểm từ lượt like và share.
                </b>
              </p>
              <br />
              <div className={`form-field ${isShowCopy && "hasIcon"}`}>
                {isShowCopy && (
                  <img
                    src="assets/img/icon/i-copy.svg"
                    alt="text-info "
                    className="i-copy"
                    // onClick={() =>
                    //   props?.clearFieldFormData("fullName")
                    // }
                  />
                )}

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="linkUrl"
                    name="linkUrl"
                    placeholder={`http://${platform}.com/123456789...`}
                    value={searchText}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.startsWith(" ")) {
                        setSearchText(value.trimStart());
                      } else {
                        setSearchText(value);
                      }
                    }}
                  />
                </div>
              </div>
              <p>
                Chi tiết cách lấy link bài dự thi xin hãy{" "}
                <a className="boldText" onClick={onGuideGetLinkFB}>
                  xem ở đây
                </a>
                .
              </p>
              <p>
                Chưa biết cách đăng bài dự thi trên {platform}? Làm theo hướng
                dẫn ở{" "}
                <a className="boldText" onClick={onShowGuide}>
                  đây
                </a>
                .
              </p>
            </div>
            <div className="group-btn-inline">
              <button
                onClick={() => {
                  setSearchText("");
                  closeModal("");
                }}
                type="button"
                className="btn btn-warning btn-block"
              >
                <a>Đóng</a>
              </button>
              <button
                onClick={() => onContinue(platform)}
                type="button"
                className={`btn btn-primary btn-block ${
                  searchText?.length === 0 && "disabled"
                }`}
              >
                <a>Liên kết</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupPostFacebook;
