// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import DuetPageView from "./view";
import LoadingSpinner from "components/LoadingSpinner";
import PopupLogin from "components/popup-login";
import { useDispatch } from "react-redux";
import actions from "redux/home/actions";
import actionsDuet from "redux/duet/actions";
import { CAMPAIGNID, MESSAGE_ERROR } from "constants/common";
import PopupOtp from "components/popup-otp";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import PopupMessage from "components/popup-message";
import { useNavigate } from "react-router-dom";
import PopupGuideLink from "components/popup-guide-link";
import PopupCheckPC from "components/popup-check-pc";
import { checkPc } from "utils/common";

const DuetPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalOtp, setModalOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isErrorOTP, setIsErrorOTP] = useState(false);
  const [modal, setModal] = useState(false);
  const [nameBtn, setNameBtn] = useState("");
  const [content, setContent] = useState("");
  const [searchText, setSearchText] = useState("");
  const [submissions, setSubmissions] = useState([]);
  const [highestPoints, setHighestPoints] = useState(null);
  const [topSubs, setTopSubs] = useState([]);
  const [topEventSubs, setTopEventSubs] = useState([]);
  const [isReload, setIsReload] = useState(false);
  const actionMessage = useRef("");
  const [isShowGuideLink, setIsShowGuideLink] = useState(false);
  const [isShowPopupPC, setIsShowPopupPC] = useState(false);

  const HOTLINE = "Hotline <strong>1900638880</strong>";

  useEffect(() => {
    document.body.className = "";
    const accessToken = localStorage.getItem("accessToken");
    if (!!accessToken) {
      setIsLogin(true);
      getMySub();
      getMyPoints();
      getHighestPoints();
    }
    getTopSub();
    getTopEventSubs();
  }, []);

  const getTopSub = () => {
    setIsLoading(true);
    dispatch(
      actionsDuet.GET_TOP_10_ALL.request(
        {},
        (res) => {
          setTopSubs(res || []);
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const getTopEventSubs = (topType) => {
    setIsLoading(true);
    dispatch(
      actionsDuet.GET_TOP_10_WEB.request(
        {},
        (res) => {
          setTopEventSubs(res || []);
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const getMyPoints = () => {
    setIsLoading(true);
    dispatch(
      actionsDuet.GET_MY_POINTS.request(
        {},
        (res) => {
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const getHighestPoints = () => {
    setIsLoading(true);
    dispatch(
      actionsDuet.GET_HIGHEST_POINT.request(
        {},
        (res) => {
          setIsLoading(false);
          setHighestPoints(res || []);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const getMySub = () => {
    setIsLoading(true);
    dispatch(
      actionsDuet.GET_MY_SUB.request(
        {},
        (res) => {
          setIsLoading(false);
          setSubmissions(res || []);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const removeModalOpen = () => {
    setIsLoading(false);
    document.body.classList.remove("modal-open");
  };

  const requestOTP = (phoneNumber) => {
    setModalLogin(false);
    setIsLoading(true);
    setPhoneNumber(phoneNumber);
    localStorage.setItem("accessToken", "");
    dispatch(
      actions.REQUEST_OTP.request(
        {
          phoneNumber: phoneNumber,
          campaignId: parseInt(CAMPAIGNID),
        },
        (res) => {
          setModalOtp(true);
          console.log("success");
          removeModalOpen();
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleSubmitOTP();
    }
  }, [otp]);

  const handleSubmitOTP = () => {
    console.log("OTP===>", otp);
    setIsLoading(true);
    verifyOTP();
  };

  const verifyOTP = () => {
    dispatch(
      actions.VERIFY_OTP.request(
        {
          otpCode: otp,
          phoneNumber: phoneNumber,
        },
        (res) => {
          // removeModalOpen();
          setModalOtp(false);
          // var errorMessage = `<h5>Thông báo</h5><p>Chúc mừng Quý khách trúng thưởng giải III 100.000 VNĐ tiền nạp điện thoại từ Hảo Hảo. Giải thưởng sẽ được nạp tự động vào số điện thoại của Quý khách trong vòng 24 giờ. Vui lòng giữ lại thẻ cào để tham gia quay số và có cơ hội trúng thưởng giải I hoặc giải II. Liên hệ <span style="color: #FD2B64">1900299981</span> (1,000đ/phút) để được hỗ trợ và tư vấn<p/>`;
          // setNameBtn(`ĐÓNG`);
          // setContent(errorMessage);
          // setModal(true);

          setIsErrorOTP(false);
          requestLogin(res?.token?.accessToken);
        },
        (err) => {
          setIsLoading(false);
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code || err?.data?.message;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }

          // const errorCode = err?.data?.error?.code;
          // var errorMessage = "";
          // switch (errorCode) {
          //   case "OutOfStock":
          //     errorMessage = `<h5>Thông báo</h5><p>Số lượng giải III đã hết. </br> Vui lòng giữ lại thẻ cào để tham gia quay số và có cơ hội trúng thưởng giải I hoặc giải II. Liên hệ <span style="color: #FD2B64">1900299981</span> (1,000đ/phút) để được hỗ trợ và tư vấn<p/>`;
          //     setNameBtn(`ĐÓNG`);

          //     break;

          //   case "NotStartYetError":
          //     errorMessage = `<h5>Chương trình KHUYẾN MẠI</h5>
          //     <h4>SINH NHẬT 23</h4>
          //     <h4>chưa bắt đầu</h4>
          //     <p>Giữ lại thẻ cào và gửi lại Mã thẻ cào<br/> từ <span>07:00</span> ngày <span>04/09/2023</span> đến <span>23:59</span> ngày <span>30/11/2023</span>. Rất mong sớm gặp lại Quý khách tại chương trình.</p>
          //     `;
          //     setNameBtn(`ĐÓNG`);
          //     break;

          //   case "FinishedError":
          //     errorMessage = `<h5>Chương trình đã kết thúc</h5><p>Thời hạn chương trình khuyến mãi đã kết thúc vào lúc <span>23:59</span> ngày <span>30/11/2023</span>. Cảm ơn Quý khách đã tin dùng sản phẩm của Hảo Hảo.<p/>`;
          //     setNameBtn(`ĐÓNG`);
          //     break;

          //   case "ExceedRewardPerUser":
          //     errorMessage = `<h5>Thông báo</h5><p>SĐT <span style="color: #FD2B64">${phoneNumber}</span> đã tham gia chương trình. Cảm ơn Quý khách hàng đã tin dùng sản phẩm của Hảo Hảo. Liên hệ <span style="color: #FD2B64">1900299981</span> (1,000đ/phút) để được hỗ trợ và tư vấn<p/>`;
          //     setNameBtn(`ĐÓNG`);
          //     break;

          //   case "CodeIsNotExist":
          //   case "CodeIsUsed":
          //     errorMessage = `<h5>Thông báo</h5><p>Mã số <span style="color: #FD2B64">${code} </span> không hợp lệ hoặc đã được sử dụng. Quý khách vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900299981</span> (1,000đ/phút) để được hỗ trợ và tư vấn<p/>`;
          //     setNameBtn(`ĐÓNG`);
          //     break;

          //   case "ReachingFailureLimit":
          //     errorMessage = `<h5>Thông báo</h5><p>Mã số vừa nhập không đúng. Rất tiếc, Quý khách đã bị chặn vì nhập sai mã số quá 05 lần/ngày. Liên hệ <span style="color: #FD2B64">1900299981</span> (1,000đ/phút) để được hỗ trợ và tư vấn<p/>`;
          //     setNameBtn(`ĐÓNG`);
          //     break;

          //   case "UserIsBlocked":
          //     errorMessage = `<h5>Thông báo</h5><p>Xin lỗi Quý khách, SĐT <span style="color: #FD2B64">${phoneNumber}</span> đã bị chặn vì nhập sai mã số quá 05 lần. Liên hệ <span style="color: #FD2B64">1900299981</span> (1,000đ/phút) để được hỗ trợ và tư vấn<p/>`;
          //     setNameBtn(`ĐÓNG`);
          //     break;

          //   case "SomethingWentWrong":
          //     errorMessage = `<h5>Thông báo</h5><p>Đã có lỗi xảy ra. Quý khách vui lòng thử lại sau.<p/>`;
          //     setNameBtn(`ĐÓNG`);
          //     break;

          //   case "InvalidOtp":
          //     setIsErrorOTP(true);
          //     setIsLoading(false);
          //     break;

          //   default:
          //     break;
          // }

          // if (errorMessage?.trim()?.length > 0) {
          //   removeModalOpen();
          //   setModalOtp(false);

          //   setContent(errorMessage);
          //   setModal(true);
          // }
        }
      )
    );
  };

  const checkError = (errCode) => {
    let content = "";
    switch (errCode) {
      case "NotStartYetError":
        content = `
        <h5>Thông báo</h5>
        <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" sẽ bắt đầu vào 00:00 ngày 12/08/2024. Acecook Việt Nam rất mong nhận được sự tham gia nhiệt tình của Quý khách.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "FinishedError":
        content = `
        <h5>Thông báo</h5>
        <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" đã kết thúc vào 23:59 ngày 11/11/2024. Chân thành cảm ơn Quý khách đã nhiệt tình tham gia và luôn tin dùng sản phẩm của Acecook Việt Nam.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ReachedMaxFailuresPerDay":
        content = `
          <h5>Thông báo</h5>
          <p>Quý khách đã nhập sai mã thẻ cào quá 05 lần. Rất tiếc, số điện thoại của Quý khách đang bị tạm khóa. Vui lòng thử lại sau 24h. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "RequestOTPFail":
        content = `
          <h5>Thông báo</h5>
          <p>Yêu cầu gửi mã OTP của bạn đang được chúng tôi xử lý, bạn hãy thử lại sau 1 phút nhé.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "VerifyOTPFail":
        content = `
          <h5>Thông báo</h5>
          <p>Mã xác thực chưa chính xác hoặc đã hết hạn, vui lòng kiểm tra lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "InvalidResendTime":
        content = `
          <h5>Thông báo</h5>
          <p>Yêu cầu gửi mã OTP của bạn đang được chúng tôi xử lý, bạn hãy thử lại sau 1 phút nhé. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ERR_NETWORK":
        content = `
          <h5>Thông báo</h5>
          <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      default:
        content = `
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;
    }
    setContent(content);
    showModal(true);
  };

  const showModal = () => {
    setModal(true);
    document.body.classList.add("modal-open");
  };

  const showDefaultErrModal = () => {
    setModal(true);
    setContent(`
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `);
    setNameBtn(`ĐÓNG`);
  };

  const requestLogin = (accessToken) => {
    signInWithCustomToken(getAuth(), accessToken)
      .then((authCredential) => {
        setIsLoading(false);
        let user = authCredential?.user;
        console.log(user);
        localStorage.setItem("accessToken", user.accessToken || "");
        localStorage.setItem("refreshToken", user.refreshToken);

        localStorage.setItem("phoneNumber", phoneNumber);
        createUser();

        setModal(true);
        setContent(
          `Bạn đã đăng nhập thành công. Trang hiện tại sẽ được tự động tải lại sau 5 giây.`
        );
        setNameBtn(`ĐÓNG`);
        setIsReload(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        removeModalOpen();
        console.log(error);
      });
  };

  const createUser = () => {
    dispatch(
      actions.CREATE_USER.request(
        {},
        (res) => {},
        (err) => {}
      )
    );
  };

  const closeModalOTP = () => {
    setModalOtp(false);
    setOtp("");
    setIsErrorOTP(false);
    document.body.classList.remove("modal-open");
  };

  const resendOTP = () => {
    setIsErrorOTP(false);
    setOtp("");
    requestOTP(phoneNumber);
  };

  const clickAction = () => {
    hideModal();
    if (isReload) {
      window.location.reload();
    }

    if (actionMessage.current === "NAVIGATE_SCAN_BILL_GUIDE") {
      navigate("/scanbillguide");
    }
  };

  const hideModal = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    document.body.classList.remove("modal-open");
  };

  const onSeeAllSub = () => {
    navigate("/danhsachbaithi", { state: { text: "" } });
  };

  const onSearchSub = () => {
    navigate("/danhsachbaithi", { state: { text: searchText } });
  };

  const onClickScanBill = () => {
    if (checkPc()) {
      setIsShowPopupPC(true);
    } else {
      if (!submissions.length) {
        setModal(true);
        setContent(`
          <h5>Thông báo</h5>
          <p>${MESSAGE_ERROR.NotEngaged}<p/>
          `);
        actionMessage.current = "NAVIGATE_SCAN_BILL_GUIDE";
        setNameBtn(`ĐÓNG`);
      } else {
        navigate("/scanbillguide");
      }
    }

    // setIsLoading(true);
    // dispatch(
    //   actions.CREATE_USER.request(
    //     {},
    //     (res) => {
    //       setIsLoading(false);
    //       if (res?.contestNumber <= 0) {
    //         setModal(true);
    //         setContent(`
    //     <h5>Thông báo</h5>
    //     <p>${MESSAGE_ERROR.NotEngaged}<p/>
    //     `);
    //         actionMessage.current = "NAVIGATE_SCAN_BILL_GUIDE";
    //         setNameBtn(`ĐÓNG`);
    //       } else {
    //         navigate("/scanbillguide");
    //       }
    //     },
    //     (err) => {
    //       setIsLoading(true);
    //     }
    //   )
    // );
  };

  const joinNow = () => {
    if (checkPc()) {
      setIsShowPopupPC(true);
    } else {
      navigate("/choncasi");
    }
  };
  const closePopupPC = () => {
    const path = window.location.pathname;
    if (path === "/hatcungthantuong") {
      setIsShowPopupPC(false);
    } else {
      navigate("/hatcungthantuong");
    }
  };

  const onViewHistoryBill = () => {
    if (checkPc()) {
      setIsShowPopupPC(true);
    } else {
      navigate("/lichsuchuphoadon");
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <DuetPageView
        setModalLogin={setModalLogin}
        isLogin={isLogin}
        submissions={submissions}
        onSeeAllSub={onSeeAllSub}
        setSearchText={setSearchText}
        searchText={searchText}
        onSearchSub={onSearchSub}
        topSubs={topSubs}
        topEventSubs={topEventSubs}
        highestPoints={highestPoints}
        onClickScanBill={onClickScanBill}
        setIsShowGuideLink={setIsShowGuideLink}
        joinNow={joinNow}
        onViewHistoryBill={onViewHistoryBill}
      />
      {modalLogin && (
        <PopupLogin
          isLoading={isLoading}
          setModalLogin={setModalLogin}
          requestOTP={requestOTP}
        />
      )}
      {modalOtp && (
        <PopupOtp
          setModal={setModalOtp}
          setOtp={setOtp}
          otp={otp}
          handleSubmitOTP={handleSubmitOTP}
          closeModalOTP={closeModalOTP}
          resendOTP={resendOTP}
          isErrorOTP={isErrorOTP}
          isLoading={isLoading}
        />
      )}
      {isShowPopupPC && <PopupCheckPC closePopupPC={closePopupPC} />}
      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          setModal={setModal}
          content={content}
          clickAction={clickAction}
        />
      )}
      {isShowGuideLink && <PopupGuideLink closeModal={setIsShowGuideLink} />}
    </>
  );
};
export default DuetPage;
