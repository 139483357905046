import React from "react";
const LoadingSpinner = (props) => {
  return (
    <>
      {props.isLoading && (
        // <div className="spinner-container">
        //   <div className="loading-spinner"></div>
        // </div>
        <div id="globalSpiner" className="overlay overlay-inner outer">
          <div className="inner">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </>
  );
};
export default LoadingSpinner;
