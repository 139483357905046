// @ts-nocheck
import AppConfigs from "configs/env";
import { END_POINTS } from "constants/apiUrl";
import { APIUtils } from "utils";

export const validateCampaignApi = (payload) => {
  return APIUtils.get(END_POINTS.VALIDATE_CAMPAIGN_URL, {
    params: payload,
  });
};
export const getProvincesApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_PROVINCES_URL, {
    params: payload,
    headers: {
      "Api-version": AppConfigs.apiVersionCMS,
    },
  });
};

export const validateCodeApi = () => {
  return APIUtils.post(END_POINTS.VALIDATE_CODE_URL);
};

export const OTPLogApi = (payload) => {
  return APIUtils.post(END_POINTS.OTP_LOG_URL, {
    body: payload,
  });
};

export const requestOTPApi = (payload) => {
  return APIUtils.post(END_POINTS.REQUEST_OTP_URL, {
    body: payload,
  });
};

export const verifyOTPApi = (payload) => {
  return APIUtils.post(END_POINTS.VERIFY_OTP_URL, {
    body: payload,
  });
};

export const requestLoginApi = (payload) => {
  return APIUtils.post(END_POINTS.REQUEST_LOGIN_URL, {
    body: payload,
  });
};

export const updateUserInfoApi = (payload) => {
  return APIUtils.post(END_POINTS.UPDATE_USER_INFO_URL, {
    body: payload,
  });
};

export const requestGigyaApi = (payload) => {
  return APIUtils.post(END_POINTS.REQUEST_GIGYA_URL, {
    body: payload,
  });
};

export const exchangeCodeApi = (payload) => {
  return APIUtils.post(END_POINTS.EXCHANGE_CODE_URL, {
    body: payload,
  });
};

export const topupMobileApi = (payload) => {
  return APIUtils.post(END_POINTS.TOPUP_MOBILE_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyUtop,
    },
  });
};

export const checkPhoneApi = (payload) => {
  return APIUtils.post(END_POINTS.CHECK_PHONE_URL, {
    body: payload,
  });
};

export const getWinnersHomeApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_WINNERS_HOME_URL, {
    params: payload,
  });
};

export const createUserApi = (payload) => {
  return APIUtils.get(END_POINTS.CREATE_USER_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};
