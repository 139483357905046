import TheFooter from "layout/TheFooter";
import React from "react";
const RulesInformationPage = (props) => {
  // localStorage.setItem("rule", "true");
  return (
    <div className="rules">
      <div className="container">
        <h2 className="name-block">
          <img className="img-full" src="assets/img/Title-tttt.png" alt="" />
        </h2>
        <div className="rules-inner">
          <p>
            <b>I. Thu thập thông tin cá nhân:</b>
          </p>
          <p>
            Mục đích thu nhập: Acecook Việt Nam thu thập thông tin cá nhân nhằm
            mục đích cung cấp dịch vụ tốt nhất cho khách hàng:
          </p>
          <ul>
            <li>
              Hỗ trợ Khách hàng tham gia vào các chương trình ưu đãi do Công ty
              tổ chức, tổng kết, trả thưởng;
            </li>
            <li>
              Thông báo thông tin khuyến mại, quảng cáo, các hoạt động xúc tiến
              thương mại khác;
            </li>
            <li>Hỗ trợ khách hàng khi có yêu cầu</li>
          </ul>
          <p>
            <b>II. Phạm vi thu thập</b>
          </p>
          <ul>
            <li>
              Thông tin của Khách hàng mà Công ty thực hiệp thu thập bao gồm:
              <li>Họ tên</li>
              <li>Số điện thoại</li>
              <li>Tên và địa chỉ người nhận hàng.</li>
              <li>Thông tin giao dịch (lịch sử mua hàng)</li>
            </li>
          </ul>
          <p>
            <b>III. Phạm vi sử dụng thông tin</b>
          </p>
          <p>
            Phù hợp với mục đích thu thập thông tin, Công ty có thể cấp quyền
            truy cập thông tin cho đối tác quảng cáo, vận hành, quản trị, xử lý
            dữ liệu và công nghệ thông tin, các đối tác khác có ký kết Hợp đồng
            với Công ty để hỗ trợ Công ty trong việc xử lý và bảo vệ dữ liệu của
            Khách hàng. Trong trường hợp này, các Công ty được cấp quyền truy
            cập thông tin Khách hàng sẽ phải tuân thủ nghiêm ngặt các quy định
            bảo mật được quy định trong Chính Sách này.
          </p>
          <p>
            Các trường hợp khác mà Công ty được phép sử dụng theo quy định của
            pháp luật hiện hành.
          </p>
          <p>
            <br />
            <b>IV. Bảo mật thông tin Khách hàng</b>
          </p>
          <p>
            Công ty cam đoan không bán, không chia sẻ dẫn đến làm lộ thông tin
            cá nhân của Khách hàng trái với cam kết ghi trong Chính sách này,
            trừ khi buộc phải cung cấp thông tin theo quy định của pháp luật
            hoặc Cơ quan nhà nước có thẩm quyền.
          </p>
          <p>
            Công ty sẽ sử dụng các biện pháp kỹ thuật và tổ chức để bảo vệ thông
            tin này khỏi việc truy cập, sử dụng hoặc tiết lộ không được phép
          </p>
          <p>
            <br />
            <b>V. Quyền lợi của Khách hàng</b>
          </p>
          <p>
            Quý khách có quyền yêu cầu truy cập, cập nhật hoặc xóa bỏ thông tin
            cá nhân của mình tại bất kỳ thời điểm nào
          </p>
          <p>
            Quý khách có quyền từ chối việc nhận thông tin và ưu đãi từ Acecook
            bằng cách thông báo cho chúng tôi qua email hoặc số điện thoại hỗ
            trợ khách hàng
          </p>
          <p>
            <br />
            <b>VI. Thay đổi điều khoản</b>
          </p>
          <p>
            Acecook Việt Nam có quyền thay đổi điều khoản sử dụng này vào bất kỳ
            thời điểm nào mà không cần thông báo trước. Mọi thay đổi sẽ được cập
            nhật trên trang web của chúng tôi
          </p>
          <p>
            <br />
            <b>VII. Thông tin đơn vị thu thập và quản lý thông tin cá nhân</b>
          </p>
          <p>Công ty cổ phần Acecook Việt Nam</p>
          <p>
            Địa chỉ trụ sở: Lô II-3, Đường số 11, Nhóm CN II,Khu Công nghiệp Tân
            Bình, Phường Tây Thạnh, Quận Tân Phú, Thành phố Hồ Chí Minh, Việt
            Nam
          </p>
          <p>
            Nếu Khách hàng có bất kỳ phản hồi, thắc mắc, kiến nghị hoặc khiếu
            nại nào liên quan đến Chính Sách này, vui lòng liên hệ theo địa chỉ:
            info@acecookvietnam.com hoặc Hotline (028) 38150969
          </p>
        </div>
      </div>
      <TheFooter />
    </div>
  );
};
export default RulesInformationPage;
