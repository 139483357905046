import { START_DATE } from "constants/common";
import TheBanner from "layout/TheBanner/TheBanner";
import TheFooter from "layout/TheFooter";
import TheHeader from "layout/TheHeader";
import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import routes from "routes";
import actions from "redux/home/actions";
import PopupCheckPC from "components/popup-check-pc";
import { checkPc } from "utils/common";

const TheRoutes = () => {
  const nav = routes.map((elm) => {
    return <Route key={elm.path} path={elm.path} element={elm.element} />;
  });

  const checkPreventPc = () => {
    const validPaths = [
      "/choncasi",
      "/chonbaihat",
      "/preview",
      "/quay",
      "/lichsuchuphoadon",
      "/scanbillguide",
      "/scanbill",
      "/scanbillsuccess",
    ];

    const currentPath = window.location.pathname;
    const isValidPath = validPaths.includes(currentPath);

    console.log(isValidPath);
    return (
      <>
        {checkPc() && isValidPath && (
          <PopupCheckPC closePopupPC={closePopupPC} />
        )}
      </>
    );
  };

  const closePopupPC = () => {
    window.location.href = "/hatcungthantuong";
  };

  return (
    <BrowserRouter>
      <TheHeader />
      <main>
        <Routes>{nav}</Routes>
        {checkPreventPc()}
      </main>
      {/* <TheFooter /> */}
    </BrowserRouter>
  );
};

export default TheRoutes;
