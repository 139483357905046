import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import PopupMessage from "components/popup-message";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "redux/duet/actions";
import actionsHome from "redux/home/actions";
import DuetReviewView from "./view";
import PopupInputName from "components/popup-input-name";
import PopupGuideLink from "components/popup-guide-link";

const DuetReviewPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { linkTiktok, songDetail } = location.state || {};

  const [isLoading, setIsLoading] = useState(false);

  const [nameBtn, setNameBtn] = useState("");
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [videoId, setVideoId] = useState("");
  const [linkVideo, setLinkVideo] = useState("");
  const [isPlay, setIsPlay] = useState(false);

  const videoRef = useRef(null);
  const iframeRef = useRef(null);

  const [subId, setSubId] = useState("");

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [isShowModalName, setIsShowModalName] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isShowGuideLink, setIsShowGuideLink] = useState(false);
  const [isCreateSucess, setIsCreateSucess] = useState(false);

  useEffect(() => {
    if (!!linkTiktok) {
      if (linkTiktok?.split('/video/')?.length > 1) {
        setVideoId(linkTiktok.split('/video/')[1].split('?')[0]);
      } else {
        setContent(`
          <h5>Thông báo</h5>
          <p>Đường link không hợp lệ hoặc không được chia sẻ công khai. Vui lòng kiểm tra lại.<p/>
          `);
        setNameBtn(`ĐÓNG`);
        setModal(true);
      }

    } else {
      setLinkVideo(songDetail?.url);

    }
  }, []);


  useEffect(() => {
    // Function to handle messages from the TikTok player
    const handleMessage = (event) => {
      if (event.origin !== "https://www.tiktok.com") return;

      const message = event.data;
      if (message["x-tiktok-player"]) {
        switch (message.type) {
          case "play":
            console.log("Video started playing:", message.value);
            break;
          case "pause":
            console.log("Video paused:", message.value);
            break;
          case "error":
            console.error("Error from TikTok player:", message.value);
            break;
          case "onPlayerReady":
            break;

          case "onStateChange":
            console.log('onStateChange', message.value);
            if (message.value === 0) {
              setIsPlay(false);
            }
            break;

          default:
            console.log("Unknown message type:", message);
        }
      }
    };

    // Add event listener for messages
    window.addEventListener("message", handleMessage);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const playVideoTiktok = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const message = {
        "x-tiktok-player": true,
        type: "play", // Change this based on TikTok's supported types
        value: {},
      };

      iframeRef.current.contentWindow.postMessage(
        message,
        "https://www.tiktok.com"
      );
    }
  };

  const onPauseVideoTiktok = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const message = {
        "x-tiktok-player": true,
        type: "pause", // Change this based on TikTok's supported types
        value: {},
      };

      iframeRef.current.contentWindow.postMessage(
        message,
        "https://www.tiktok.com"
      );
    }
  };

  const hideModal = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    document.body.classList.remove("modal-open");
    document.body.classList.remove("open-duet");
  };
  const showModal = () => {
    setModal(true);
    document.body.classList.add("modal-open");
  };
  const clickSendPost = () => {
    hideModal();
    // setPending(true);
    if (isCreateSucess) {
      navigate(`/chitietbaithi?id=${subId}`);
    }
  };

  const sendPost = () => {
    // getSastoken();
    setIsLoading(true);
    dispatch(
      actionsHome.CREATE_USER.request(
        {},
        (res) => {
          console.log(res);
          if (!!res?.userName) {
            createSubmission(res?.userName);
          } else {
            setIsLoading(false);
            setIsShowModalName(true);
          }
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const createSubmission = (userName) => {
    dispatch(
      actions.CREATE_SUBMISSION_TIKTOK.request(
        {
          songId: songDetail?.songId,
          Url: linkTiktok,
          Name: userName
        },
        (res) => {
          if (!!res?.id) {
            setSubId(res?.id);
            setIsLoading(false);
            setIsCreateSucess(true);
            setContent(` <h3>THÔNG BÁO</h3> <p>Bài dự thi của bạn đã được nộp và đang được xét duyệt trong vòng tối đa 5 giờ (từ 08:00 - 20:00 hàng ngày). Bạn có thể share bài dự thi lên Facebook, Tiktok để được cộng điểm từ tương tác. <br/> <br/>Chi tiết cách tính điểm xin hãy
              <br/><a class="linkAcc">xem ở đây</a>.</p>`);
            setNameBtn(`ĐÓNG`);
            showModal();
          }
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const checkError = (errCode) => {
    let content = "";
    switch (errCode) {
      case "InvalidLink":
        content = `
        <h5>Thông báo</h5>
        <p>Link không liên kết tới bài đăng nào.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "NotFoundVideoUser":
        content = `
        <h5>Thông báo</h5>
        <p>Bài đăng không thuộc về tài khoản đã liên kết.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "NotVideoLink":
        content = `
          <h5>Thông báo</h5>
          <p>Bài đăng không phải video.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ERR_NETWORK":
        content = `
          <h5>Thông báo</h5>
          <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      default:
        content = `
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;
    }
    setContent(content);
    showModal();
  };

  const showDefaultErrModal = () => {
    setModal(true);
    setContent(`
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `);
    setNameBtn(`ĐÓNG`);
  };

  useEffect(() => {
    document.body.classList.add("open-duet");
  }, []);

  const onContinue = () => {
    console.log(searchText);
    if (searchText?.trim()?.length > 0) {
      setIsLoading(true);
      createSubmission(searchText);
    }
  }

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <DuetReviewView
        sendPost={sendPost}
        songDetail={songDetail}
        videoId={videoId}
        linkVideo={linkVideo}
        videoRef={videoRef}
        playVideoTiktok={playVideoTiktok}
        isPlay={isPlay}
        setIsPlay={setIsPlay}
        iframeRef={iframeRef}
        isLoading={isLoading}
        onPauseVideoTiktok={onPauseVideoTiktok}
      />

      {isShowModalName && (
        <PopupInputName
          searchText={searchText}
          setSearchText={setSearchText}
          closeModal={setIsShowModalName}
          onContinue={onContinue}
        />
      )}

      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          content={content}
          clickAction={clickSendPost}
          showModalGuideLink={setIsShowGuideLink}
        />
      )}

      {isShowGuideLink && (
        <PopupGuideLink
          closeModal={setIsShowGuideLink}
        />
      )}
    </>
  );
};
export default DuetReviewPage;
