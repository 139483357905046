import React, { useEffect, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import DuetSelectSingerView from "./view";
import { useDispatch } from "react-redux";
import actions from "redux/duet/actions";
import { useNavigate } from "react-router-dom";

const DuetSelectSingerPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [singers, setSingers] = useState(false);

  useEffect(() => {
    getSingers();
  }, []);

  const getSingers = () => {
    setIsLoading(true);
    dispatch(
      actions.GET_SINGERS.request(
        {},
        (res) => {
          setIsLoading(false);
          setSingers(res?.singers || []);
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  }

  const onSelectSinger = (singerId) => {
    localStorage.setItem("singerId", singerId);
    navigate("/chonbaihat");
  }

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <DuetSelectSingerView singers={singers} onSelectSinger={onSelectSinger} />
    </>
  );
};
export default DuetSelectSingerPage;
