import { actionGenerator } from "utils";

const PREFIX = "DUET";

export const GET_SASTOKEN = actionGenerator.genActionTypes(
  `${PREFIX}_GET_SASTOKEN`
);

export const UPLOAD_VIDEO = actionGenerator.genActionTypes(
  `${PREFIX}_UPLOAD_VIDEO`
);

export const CREATE_SUBMISSION = actionGenerator.genActionTypes(
  `${PREFIX}_CREATE_SUBMISSION`
);

export const CREATE_SUBMISSION_TIKTOK = actionGenerator.genActionTypes(
  `${PREFIX}_CREATE_SUBMISSION_TIKTOK`
);

export const GET_SINGERS = actionGenerator.genActionTypes(
  `${PREFIX}_GET_SINGERS`
);

export const DELETE_SUBMISSION = actionGenerator.genActionTypes(
  `${PREFIX}_DELETE_SUBMISSION`
);

export const GET_SETTINGS = actionGenerator.genActionTypes(
  `${PREFIX}_GET_SETTINGS`
);

export const CREATE_SETTINGS = actionGenerator.genActionTypes(
  `${PREFIX}_CREATE_SETTINGS`
);

export const GET_MY_SUB = actionGenerator.genActionTypes(
  `${PREFIX}_GET_MY_SUB`
);

export const GET_MY_POINTS = actionGenerator.genActionTypes(
  `${PREFIX}_GET_MY_POINTS`
);

export const GET_DETAIL_SUB = actionGenerator.genActionTypes(
  `${PREFIX}_GET_DETAIL_SUB`
);

export const GET_LIST_SUB = actionGenerator.genActionTypes(
  `${PREFIX}_GET_LIST_SUB`
);

export const GET_TOP_SUB = actionGenerator.genActionTypes(
  `${PREFIX}_GET_TOP_SUB`
);

export const ADD_OR_UN_SOCIAL_LINK = actionGenerator.genActionTypes(
  `${PREFIX}_ADD_OR_UN_SOCIAL_LINK`
);

export const GET_BILL_POINT = actionGenerator.genActionTypes(
  `${PREFIX}_GET_BILL_POINT`
);

export const LIKE_OR_DISLIKE = actionGenerator.genActionTypes(
  `${PREFIX}_LIKE_OR_DISLIKE`
);

export const GET_TOP_10_WEB = actionGenerator.genActionTypes(
  `${PREFIX}_GET_TOP_10_WEB`
);

export const GET_TOP_10_ALL = actionGenerator.genActionTypes(
  `${PREFIX}_GET_TOP_10_ALL`
);

export const GET_HIGHEST_POINT = actionGenerator.genActionTypes(
  `${PREFIX}_GET_HIGHEST_POINT`
);

export const GET_FULL_LINK = actionGenerator.genActionTypes(
  `${PREFIX}_GET_FULL_LINK`
);

export const PUBLIC_VIDEO = actionGenerator.genActionTypes(
  `${PREFIX}_PUBLIC_VIDEO`
);

export const GET_PUBLIC_VIDEO_URL = actionGenerator.genActionTypes(
  `${PREFIX}_GET_PUBLIC_VIDEO_URL`
);