// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import DuetInfoDetailView from "./view";
import PopupMessage from "components/popup-message";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "redux/home/actions";
import actionsDuet from "redux/duet/actions";
import reactUtils from "utils/reactUtils";
import PopupPostFacebook from "components/popup-post-facebook";
import PopupGuideFacebook from "components/popup-guide-facebook";
import PopupPostSub from "components/popup-post-sub";
import PopupLogin from "components/popup-login";
import PopupOtp from "components/popup-otp";
import { CAMPAIGNID } from "constants/common";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import PopupDownload from "components/popup-download";
import PopupGuideLink from "components/popup-guide-link";
import PopupGuideGetLinkFB from "components/popup-guide-get-linkfb";
import $ from "jquery";
const DuetInfoDetailPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isGoBack = false } = location.state || {};

  const [isLoading, setIsLoading] = useState(false);
  const [isShowModalLink, setIsShowModalLink] = useState(false);
  const [isShowModalGuide, setIsShowModalGuide] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [platform, setPlatform] = useState("");
  const [nameBtn, setNameBtn] = useState("");
  const [nameBtn1, setNameBtn1] = useState("");
  const [nameBtn2, setNameBtn2] = useState("");
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [subDetail, setSubDetail] = useState({});
  const [action, setAction] = useState("");
  const [videoId, setVideoId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isReloadSub, setIsReloadSub] = useState(false);
  const [isShowModalSub, setIsShowModalSub] = useState(false);
  const [stateDuetInfoDetailPage, setStateDuetInfoDetailPage] = useState({});
  const [isPlay, setIsPlay] = useState(false);
  const iframeRef = useRef();

  const [modalLogin, setModalLogin] = useState(false);
  const [modalOtp, setModalOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isErrorOTP, setIsErrorOTP] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isShowDownload, setShowDownload] = useState(false);
  const [downloadedPercent, setDownloadedPercent] = useState(0);
  const [isGoHome, setIsGoHome] = useState(false);
  const [isShowGuideLink, setIsShowGuideLink] = useState(false);
  const [isGuideGetLinkFB, setIsGuideGetLinkFB] = useState(false);
  const [onPlayerReady, setOnPlayerReady] = useState(false);

  useEffect(() => {
    $(window).scrollTop(0);
    document.body.classList.add("open-duet");
    return () => {
      document.body.classList.remove("open-duet");
    };
  }, []);

  const getDetailSub = (subId) => {
    setIsLoading(true);
    let params = {
      subId: subId,
    };
    dispatch(
      actionsDuet.GET_DETAIL_SUB.request(
        params,
        (res) => {
          setIsLoading(false);
          setSubDetail(res);
          let subDetailTemp = res;
          if (res?.videoSource === 1) {
            setVideoId(res?.publicUrl.split("/video/")[1].split("?")[0]);
          }
          if (res.status === 0) {
            let interval = setInterval(() => {
              dispatch(
                actionsDuet.GET_PUBLIC_VIDEO_URL.request(
                  subId,
                  (res) => {
                    if (res?.publicUrl && res?.status) {
                      console.log("subDetail >>>", subDetail);
                      setSubDetail({
                        ...subDetailTemp,
                        publicUrl: res?.publicUrl,
                        status: res?.status,
                      });
                      clearInterval(interval);
                    }
                  },
                  (err) => {}
                )
              );
            }, 20000);
          }
          setIsLiked(res?.isLiked);
        },
        (err) => {
          setIsLoading(false);
          setIsGoHome(true);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
            switch (errCode) {
              case "SubmissionNotFound":
              case "CannotAccessVideo":
                setModal(true);
                setContent(`
                  <h5>Thông báo</h5>
                  <p>Nội dung này hiện không hiển thị. Lỗi này thường do video chưa được chia sẻ công khai, thay đổi người được xem hoặc đã xóa nội dung.<p/>
                  `);
                setNameBtn(`VỀ TRANG CHỦ`);
                break;

              default:
                setModal(true);
                setContent(`
                  <h5>Thông báo</h5>
                  <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
                  `);
                setNameBtn(`VỀ TRANG CHỦ`);
                break;
            }
          } else {
            setModal(true);
            setContent(`
              <h5>Thông báo</h5>
              <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
              `);
            setNameBtn(`VỀ TRANG CHỦ`);
          }
        }
      )
    );
  };

  const publicVideo = () => {
    setIsLoading(true);
    let payload = {
      subId: stateDuetInfoDetailPage?.subId,
    };
    dispatch(
      actionsDuet.PUBLIC_VIDEO.request(
        payload,
        (res) => {
          setIsReload(true);
          setIsLoading(false);
          setContent(`<h3>THÔNG BÁO</h3> <p>Bài dự thi của bạn đã được nộp và đang được xét duyệt trong vòng tối đa 5 giờ (từ 08:00 -20:00 hàng ngày). Bạn có thể share bài dự thi lên Facebook, Tiktok để được cộng điểm từ tương tác. <br/> <br/>Chi tiết cách tính điểm xin hãy
              <br/><a class="linkAcc">xem ở đây</a>.</p>`);
          setNameBtn("ĐÓNG");
          setModal(true);
          setIsGoHome(true);
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const removeModalOpen = () => {
    setIsLoading(false);
    document.body.classList.remove("modal-open");
  };

  const requestOTP = (phoneNumber) => {
    setModalLogin(false);
    setIsLoading(true);
    setPhoneNumber(phoneNumber);
    localStorage.setItem("accessToken", "");
    dispatch(
      actions.REQUEST_OTP.request(
        {
          phoneNumber: phoneNumber,
          campaignId: parseInt(CAMPAIGNID),
        },
        (res) => {
          setModalOtp(true);
          console.log("success");
          removeModalOpen();
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleSubmitOTP();
    }
  }, [otp]);

  const handleSubmitOTP = () => {
    console.log("OTP===>", otp);
    setIsLoading(true);
    verifyOTP();
  };

  const verifyOTP = () => {
    dispatch(
      actions.VERIFY_OTP.request(
        {
          otpCode: otp,
          phoneNumber: phoneNumber,
        },
        (res) => {
          setModalOtp(false);
          setIsErrorOTP(false);
          requestLogin(res?.token?.accessToken);
        },
        (err) => {
          setIsLoading(false);
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code || err?.data?.message;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const checkError = (errCode) => {
    let content = "";
    switch (errCode) {
      case "NotStartYetError":
        content = `
        <h5>Thông báo</h5>
        <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" sẽ bắt đầu vào 00:00 ngày 12/08/2024. Acecook Việt Nam rất mong nhận được sự tham gia nhiệt tình của Quý khách.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "FinishedError":
        content = `
        <h5>Thông báo</h5>
        <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" đã kết thúc vào 23:59 ngày 11/11/2024. Chân thành cảm ơn Quý khách đã nhiệt tình tham gia và luôn tin dùng sản phẩm của Acecook Việt Nam.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ReachedMaxFailuresPerDay":
        content = `
          <h5>Thông báo</h5>
          <p>Quý khách đã nhập sai mã thẻ cào quá 05 lần. Rất tiếc, số điện thoại của Quý khách đang bị tạm khóa. Vui lòng thử lại sau 24h. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "RequestOTPFail":
        content = `
          <h5>Thông báo</h5>
          <p>Yêu cầu gửi mã OTP của bạn đang được chúng tôi xử lý, bạn hãy thử lại sau 1 phút nhé.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "VerifyOTPFail":
        content = `
          <h5>Thông báo</h5>
          <p>Mã xác thực chưa chính xác hoặc đã hết hạn, vui lòng kiểm tra lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "InvalidResendTime":
        content = `
          <h5>Thông báo</h5>
          <p>Yêu cầu gửi mã OTP của bạn đang được chúng tôi xử lý, bạn hãy thử lại sau 1 phút nhé. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "AlreadyLinked":
        content = `
          <h5>Thông báo</h5>
          <p>Tài khoản này đã được liên kết tới số điện thoại khác.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ERR_NETWORK":
        content = `
          <h5>Thông báo</h5>
          <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      default:
        content = `
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;
    }
    setContent(content);
    showModal(true);
  };

  const requestLogin = (accessToken) => {
    signInWithCustomToken(getAuth(), accessToken)
      .then((authCredential) => {
        setIsLoading(false);
        let user = authCredential?.user;
        console.log(user);
        localStorage.setItem("accessToken", user.accessToken || "");
        localStorage.setItem("refreshToken", user.refreshToken);

        localStorage.setItem("phoneNumber", phoneNumber);
        createUser();

        setModal(true);
        setContent(
          `Bạn đã đăng nhập thành công. Trang hiện tại sẽ được tự động tải lại sau 5 giây.`
        );
        setNameBtn(`ĐÓNG`);
        setIsReload(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        removeModalOpen();
        console.log(error);
      });
  };

  const createUser = () => {
    dispatch(
      actions.CREATE_USER.request(
        {},
        (res) => {},
        (err) => {}
      )
    );
  };

  const closeModalOTP = () => {
    setModalOtp(false);
    setOtp("");
    setIsErrorOTP(false);
    document.body.classList.remove("modal-open");
  };

  const resendOTP = () => {
    setIsErrorOTP(false);
    setOtp("");
    requestOTP(phoneNumber);
  };

  const hideModal = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    setNameBtn1("");
    setNameBtn2("");
    document.body.classList.remove("modal-open");
  };

  const hideModalPublic = () => {
    window.location.reload();
  };

  const showModal = () => {
    setModal(true);
    document.body.classList.add("modal-open");
  };
  const clickAction = () => {
    hideModal();
    if (isGoHome) {
      navigate("/hatcungthantuong");
    }
    if (isReload) {
      window.location.reload();
    }
    if (isReloadSub) {
      setIsReloadSub(false);
      getDetailSub(stateDuetInfoDetailPage?.subId);
    }
  };

  const clickAction1 = () => {
    hideModal();
    setAction("");
  };

  const clickAction2 = () => {
    switch (action) {
      case "linkFB":
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              const accessTokenFB = response.authResponse.accessToken;
              const userID = response.authResponse.userID;

              let data = {
                settingType: "facebooksetting",
                access_token: accessTokenFB,
                fb_user_id: userID,
              };
              onCreateSetting(data, stateDuetInfoDetailPage?.subId);
            } else {
              console.log("User cancelled login or failed.");
            }
          },
          {
            scope:
              "user_likes,user_photos,user_videos,user_posts,public_profile",
          }
        );
        break;

      case "linkTiktok":
        const clientKey = process.env.REACT_APP_CLIENT_KEY_TIKTOK;
        // const redirectUri = 'https://stwebacecookqa.z23.web.core.windows.net/chitietbaithi';
        const redirectUri = reactUtils.extractBaseURL(window.location.href);
        const scope = "user.info.basic,video.list,user.info.stats";
        const responseType = "code";
        const state = "";

        const url = `https://www.tiktok.com/v2/auth/authorize/?client_key=${clientKey}&scope=${scope}&response_type=${responseType}&redirect_uri=${redirectUri}&state=${state}`;

        window.location.replace(url);
        break;

      case "deleteSub":
        setIsLoading(true);
        dispatch(
          actionsDuet.DELETE_SUBMISSION.request(
            {
              submissionId: subDetail.id,
            },
            (res) => {
              setIsLoading(false);
              navigate("/hatcungthantuong");
            },
            (err) => {
              setIsLoading(false);
            }
          )
        );
        break;

      case "unlink":
        onContinue(platform, false);
        break;

      case "login":
        hideModal();
        setModalLogin(true);
        break;

      default:
        break;
    }
  };

  const goBack = () => {
    if (stateDuetInfoDetailPage.isGoBack) {
      window.history.back();
    } else {
      navigate("/hatcungthantuong");
    }
  };

  const onLinkFB = () => {
    setContent(
      `<h3>LIÊN KẾT TÀI KHOẢN</h3><p>Để liên kết bài đăng trên các nền tảng và đồng bộ lượt like và share, bạn cần cấp quyền truy cập bài đăng cho Acecook.</p><p><b>Lưu ý: Tài khoản đã liên kết không thể thay đổi, xin hãy liên kết đúng tài khoản dự thi của bạn.</b></p>`
    );
    setNameBtn1(`HỦY BỎ`);
    setNameBtn2(`LIÊN KẾT`);
    showModal();
    setAction("linkFB");
  };

  const onLinkTikTok = async () => {
    setContent(
      `<h3>LIÊN KẾT TÀI KHOẢN</h3><p>Để liên kết bài đăng trên các nền tảng và đồng bộ lượt like và share, bạn cần cấp quyền truy cập bài đăng cho Acecook.</p><p><b>Lưu ý: Tài khoản đã liên kết không thể thay đổi, xin hãy liên kết đúng tài khoản dự thi của bạn.</b></p>`
    );
    setNameBtn1(`HỦY BỎ`);
    setNameBtn2(`LIÊN KẾT`);
    showModal();
    setAction("linkTiktok");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const state = params.get("state");
    const id = params.get("id");

    const scopes = params.get("scopes");
    const error_code = params.get("error_code");

    const substrings = ["user.info.basic", "video.list", "user.info.stats"];
    let containsAllSubstrings = undefined;
    if (!!scopes) {
      containsAllSubstrings = substrings.every((substring) =>
        scopes.includes(substring)
      );
    }

    if (!!id) {
      localStorage.setItem(
        "stateDuetInfoDetailPage",
        JSON.stringify({
          subId: id,
          isGoBack: false,
          ...location.state,
        })
      );

      setStateDuetInfoDetailPage({
        subId: id,
        isGoBack: false,
        ...location.state,
      });

      if (!!code && containsAllSubstrings === true && !error_code) {
        let data = {
          settingType: "tiktoksetting",
          auth_code: code,
        };
        onCreateSetting(data, id);
      } else {
        if (containsAllSubstrings !== undefined) {
          setModal(true);
          setContent(`
            <h5>Thông báo</h5>
            <p>Vui lòng chia sẻ với Acecook Việt Nam thông tin và số lượt thích video của bạn.<p/>
            `);
          setNameBtn(`ĐÓNG`);
        }
        getDetailSub(id);
      }
    } else {
      let state = JSON.parse(
        localStorage.getItem("stateDuetInfoDetailPage") || "{}"
      );
      console.log("state", state);
      if (!!state?.subId) {
        setStateDuetInfoDetailPage(state);

        if (!!code && containsAllSubstrings && !error_code) {
          let data = {
            settingType: "tiktoksetting",
            auth_code: code,
          };
          onCreateSetting(data, state?.subId);
        } else {
          if (containsAllSubstrings !== undefined) {
            setModal(true);
            setContent(`
              <h5>Thông báo</h5>
              <p>Vui lòng chia sẻ với Acecook Việt Nam thông tin và số lượt thích video của bạn.<p/>
              `);
            setNameBtn(`ĐÓNG`);
          }
          getDetailSub(state?.subId);
        }
      }
    }
  }, [location.search]);

  const onCreateSetting = (data, subId) => {
    hideModal();

    setIsLoading(true);
    let params = {};

    switch (data.settingType) {
      case "tiktoksetting":
        params = {
          settingType: data.settingType,
          body: {
            data: {
              auth_code: data.auth_code,
              redirect_url: reactUtils.extractBaseURL(window.location.href),
            },
          },
        };
        break;

      case "facebooksetting":
        params = {
          body: {
            data: {
              access_token: data.access_token,
              fb_user_id: data.fb_user_id,
            },
          },
          settingType: data.settingType,
        };
        break;

      default:
        break;
    }

    dispatch(
      actionsDuet.CREATE_SETTINGS.request(
        params,
        (res) => {
          setIsLoading(false);
          getDetailSub(subId);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const onDeleteSub = () => {
    setContent(
      `<h3>THÔNG BÁO </h3><p>Bài dự thi bị xoá sẽ không thể phục hồi. Bạn có chắc chắn muốn xoá bài dự thi này?</p>`
    );
    setNameBtn1(`HỦY`);
    setNameBtn2(`XOÁ BÀI DỰ THI`);
    showModal();
    setAction("deleteSub");
  };

  const onCheckTiktok = () => {
    let url =
      subDetail?.linkedSocialData?.socialData?.Tiktok?.FULL_FINAL_URL || "";

    if (subDetail?.isLinkedTiktok) {
      if (!!url) {
        onViewSub(url, "Tiktok");
      } else {
        setContent(`<h3>THÔNG BÁO</h3> <p>chưa làm</p>`);
        setNameBtn(`ĐÓNG`);
        showModal();
      }
    } else {
      setContent(
        `<h3>THÔNG BÁO</h3> <p>Chưa có bài đăng liên kết tương ứng trên nền tảng Tiktok</p>`
      );
      setNameBtn(`ĐÓNG`);
      showModal();
    }
  };

  const onCheckFb = () => {
    let url =
      subDetail?.linkedSocialData?.socialData?.Facebook?.FULL_FINAL_URL || "";

    if (subDetail?.isLinkedFacebook) {
      if (!!url) {
        onViewSub(url, "Facebook");
      } else {
        setContent(` <h3>THÔNG BÁO</h3> <p>chưa làm</p>`);
        setNameBtn(`ĐÓNG`);
        showModal();
      }
    } else {
      setContent(
        `<h3>THÔNG BÁO</h3> <p>Chưa có bài đăng liên kết tương ứng trên nền tảng Facebook</p>`
      );
      setNameBtn(`ĐÓNG`);
      showModal();
    }
  };

  const onViewTiktok = () => {
    let url =
      subDetail?.linkedSocialData?.socialData?.Tiktok?.FULL_FINAL_URL || "";

    if (!!url && subDetail?.linkedSocialData?.linkedTiktokStatus === 2) {
      window.open(url, "_blank");
    } else {
      setContent(
        `<h3>THÔNG BÁO</h3> <p>Chưa có bài đăng liên kết tương ứng trên nền tảng Tiktok</p>`
      );
      setNameBtn(`ĐÓNG`);
      showModal();
    }
  };

  const onViewFb = () => {
    let url =
      subDetail?.linkedSocialData?.socialData?.Facebook?.FULL_FINAL_URL || "";

    if (!!url && subDetail?.linkedSocialData?.linkedFacebookStatus === 2) {
      window.open(url, "_blank");
    } else {
      setContent(
        `<h3>THÔNG BÁO</h3> <p>Chưa có bài đăng liên kết tương ứng trên nền tảng Facebook</p>`
      );
      setNameBtn(`ĐÓNG`);
      showModal();
    }
  };

  const onViewSub = (url, platform) => {
    setPlatform(platform);
    setSearchText(url);
    setIsShowModalSub(true);
  };

  // const onDownloadVideo = async () => {
  //   const response = await fetch(subDetail?.publicUrl);
  //   const blob = await response.blob();
  //   const url = window.URL.createObjectURL(blob);

  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "video.mp4";

  //   if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
  //     const reader = new FileReader();
  //     reader.onloadend = function () {
  //       link.href = reader.result;
  //       link.click();
  //     };
  //     reader.readAsDataURL(blob);
  //   } else {
  //     link.click();
  //   }

  //   document.body.appendChild(link);
  //   document.body.removeChild(link);
  // };

  const onDownloadVideo = async () => {
    setShowDownload(true);
    var url = subDetail?.publicUrl;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Origin: "*",
      },
    })
      .then((res) => {
        const contentLength = res.headers.get("content-length");
        let loaded = 0;

        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = res.body.getReader();

              read();
              function read() {
                reader.read().then((progressEvent) => {
                  if (progressEvent.done === true) {
                    controller.close();
                    return;
                  }
                  loaded += progressEvent.value.byteLength;
                  let percent = Math.round((loaded / contentLength) * 100);
                  setDownloadedPercent(percent);
                  console.log(percent);
                  controller.enqueue(progressEvent.value);
                  read();
                });
              }
            },
          })
        );
      })
      .then(async (response) => {
        const blob = await response.blob();
        const url1 = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url1;
        link.download = "video-duet.mp4";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url1);
        setShowDownload(false);
        setDownloadedPercent(0);
      });
  };

  const onRegLink = (platform) => {
    setIsShowModalLink(true);
    setPlatform(platform);
  };

  const onConfirmUnLink = () => {
    setContent(
      `<h3>THÔNG BÁO</h3><p>Bạn có chắc muốn hủy link liên kết này?</b></p>`
    );
    setNameBtn1(`ĐÓNG`);
    setNameBtn2(`HUỶ LINK`);
    showModal();
    setAction("unlink");
  };

  const onContinue = (platform, isLink = true) => {
    let socialType = platform === "Facebook" ? 1 : 2;
    let type = isLink ? "link" : "unlink";

    setIsLoading(true);
    let params = {
      socialType: socialType,
      submissionId: subDetail?.id,
      url: searchText,
      type: type,
    };

    dispatch(
      actionsDuet.ADD_OR_UN_SOCIAL_LINK.request(
        params,
        (res) => {
          setPlatform("");
          setSearchText("");
          setIsLoading(false);
          setIsShowModalGuide(false);
          setIsShowModalLink(false);
          setIsShowModalSub(false);

          if (isLink) {
            setIsReloadSub(true);
            setContent(
              `<h3>LIÊN KẾT THÀNH CÔNG</h3> <p>Yêu cầu của bạn đang được xét duyệt, vui lòng kiểm tra lại trong vòng tối đa 5 giờ (từ 08:00 - 20:00 hàng ngày).</p>`
            );
            setNameBtn(`ĐÓNG`);
            showModal();
          } else {
            getDetailSub(stateDuetInfoDetailPage?.subId);
            hideModal();
          }
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let reason = "";
            let errCode = err?.data?.error?.code;
            switch (errCode) {
              case "InvalidLink":
                reason = "Link không liên kết tới bài đăng nào.";
                break;

              case "NotFoundVideoUser":
                reason = "Bài đăng không thuộc về tài khoản đã liên kết.";
                break;

              case "NotVideoLink":
                reason = "Bài đăng không phải video.";
                break;

              default:
                break;
            }

            setContent(`<h3>LIÊN KẾT THẤT BẠI</h3>  
              <p>Link liên kết không hợp lệ, bạn vui lòng kiểm tra lại.</p>
              ${!!reason ? `<p><b>${reason}</b></p>` : ""}
              <p>Chi tiết cách lấy link bài đăng liên kết hợp lệ xin hãy xem ở <a class="guideLink">đây.</a></p>
              <p>Trong trường hợp không xử lý được, xin hãy liên hệ hotline <a class="hotline">1900638880</a> để được hỗ trợ xử lý.</p>`);
            setNameBtn(`ĐÓNG`);
            showModal();
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const showDefaultErrModal = () => {
    setModal(true);
    setContent(`
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `);
    setNameBtn(`ĐÓNG`);
  };

  const onGuideGetLinkFB = () => {
    setIsGuideGetLinkFB(true);
  };
  const onShowGuide = () => {
    setSearchText("");
    setIsShowModalLink(false);
    setIsShowModalGuide(true);
  };
  const onLikeOrDislike = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!!accessToken) {
      let type = isLiked ? "dislike" : "like";
      setIsLoading(true);
      let params = {
        subId: stateDuetInfoDetailPage?.subId,
        type: type,
      };
      dispatch(
        actionsDuet.LIKE_OR_DISLIKE.request(
          params,
          (res) => {
            setIsLoading(false);
            setIsLiked(!isLiked);
          },
          (err) => {
            setIsLoading(false);
          }
        )
      );
    } else {
      setContent(
        `<h3>THÔNG BÁO</h3><p>Bạn cần đăng nhập bằng số điện thoại để thực hiện chức năng này.</b></p>`
      );
      setNameBtn1(`HỦY BỎ`);
      setNameBtn2(`ĐĂNG NHẬP`);
      showModal();
      setAction("login");
    }
  };

  useEffect(() => {
    // Function to handle messages from the TikTok player
    const handleMessage = (event) => {
      if (event.origin !== "https://www.tiktok.com") return;

      const message = event.data;
      if (message["x-tiktok-player"]) {
        switch (message.type) {
          case "play":
            console.log("Video started playing:", message.value);
            break;
          case "pause":
            console.log("Video paused:", message.value);
            break;
          case "error":
            console.error("Error from TikTok player:", message.value);
            break;
          case "onPlayerReady":
            setOnPlayerReady(true);
            break;

          case "onStateChange":
            console.log("onStateChange", message.value);
            if (message.value === 0) {
              setIsPlay(false);
            }
            break;

          default:
            console.log("Unknown message type:", message);
        }
      }
    };

    // Add event listener for messages
    window.addEventListener("message", handleMessage);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const playVideoTiktok = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const message = {
        "x-tiktok-player": true,
        type: "play", // Change this based on TikTok's supported types
        value: {},
      };

      iframeRef.current.contentWindow.postMessage(
        message,
        "https://www.tiktok.com"
      );
    }
  };

  const onPauseVideoTiktok = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const message = {
        "x-tiktok-player": true,
        type: "pause", // Change this based on TikTok's supported types
        value: {},
      };

      iframeRef.current.contentWindow.postMessage(
        message,
        "https://www.tiktok.com"
      );
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <DuetInfoDetailView
        goBack={goBack}
        onLinkFB={onLinkFB}
        onLinkTikTok={onLinkTikTok}
        subDetail={subDetail}
        onDeleteSub={onDeleteSub}
        onCheckTiktok={onCheckTiktok}
        onCheckFb={onCheckFb}
        onViewTiktok={onViewTiktok}
        onViewFb={onViewFb}
        onDownloadVideo={onDownloadVideo}
        videoId={videoId}
        onRegLink={onRegLink}
        onLikeOrDislike={onLikeOrDislike}
        isLiked={isLiked}
        iframeRef={iframeRef}
        playVideoTiktok={playVideoTiktok}
        isPlay={isPlay}
        setIsPlay={setIsPlay}
        isLoading={isLoading}
        setIsShowGuideLink={setIsShowGuideLink}
        publicVideo={publicVideo}
        onPlayerReady={onPlayerReady}
        setOnPlayerReady={setOnPlayerReady}
        onPauseVideoTiktok={onPauseVideoTiktok}
      />

      {modalLogin && (
        <PopupLogin setModalLogin={setModalLogin} requestOTP={requestOTP} />
      )}
      {modalOtp && (
        <PopupOtp
          setModal={setModalOtp}
          setOtp={setOtp}
          otp={otp}
          handleSubmitOTP={handleSubmitOTP}
          closeModalOTP={closeModalOTP}
          resendOTP={resendOTP}
          isErrorOTP={isErrorOTP}
          isLoading={isLoading}
        />
      )}

      {isShowModalLink && (
        <PopupPostFacebook
          platform={platform}
          onGuideGetLinkFB={onGuideGetLinkFB}
          isShowModalLink={isShowModalLink}
          closeModal={setIsShowModalLink}
          searchText={searchText}
          setSearchText={setSearchText}
          onContinue={onContinue}
          linkPostFB={
            subDetail?.linkedSocialData?.socialData?.Facebook?.FULL_FINAL_URL ||
            ""
          }
          linkPostTiktok={
            subDetail?.linkedSocialData?.socialData?.Tiktok?.FULL_FINAL_URL ||
            ""
          }
          onShowGuide={onShowGuide}
        />
      )}

      {isShowModalGuide && (
        <PopupGuideFacebook
          platform={platform}
          closeModal={setIsShowModalGuide}
          onDownloadVideo={onDownloadVideo}
          searchText={searchText}
          setSearchText={setSearchText}
          onContinue={onContinue}
          videoSource={subDetail?.videoSource}
          onGuideGetLinkFB={onGuideGetLinkFB}
        />
      )}

      {isShowDownload && (
        <PopupDownload
          content={"Đang tải xuống"}
          downloadedPercent={downloadedPercent}
        />
      )}

      {isShowModalSub && (
        <PopupPostSub
          platform={platform}
          closeModal={setIsShowModalSub}
          searchText={searchText}
          onConfirmUnLink={onConfirmUnLink}
          subDetail={subDetail}
          onGuideGetLinkFB={onGuideGetLinkFB}
        />
      )}

      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          nameBtn1={nameBtn1}
          nameBtn2={nameBtn2}
          content={content}
          clickAction={clickAction}
          clickAction1={clickAction1}
          clickAction2={clickAction2}
          showModalGuideLink={setIsShowGuideLink}
          showModalGuideGetLinkFB={setIsGuideGetLinkFB}
        />
      )}
      {isShowGuideLink && <PopupGuideLink closeModal={setIsShowGuideLink} />}
      {isGuideGetLinkFB && (
        <PopupGuideGetLinkFB
          closeModal={setIsGuideGetLinkFB}
          platform={platform}
        />
      )}
    </>
  );
};
export default DuetInfoDetailPage;
