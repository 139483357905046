import { combineReducers } from "redux";
import homeReducer from "../home/reducers";
import prizeReducer from "../prize/reducers";
import scanBillReducer from "../scanBill/reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const homeReducerConfig = {
  key: "home",
  storage,
  version: 1,
};

const prizeReducerConfig = {
  key: "prize",
  storage,
  version: 1,
};

const scanBillReducerConfig = {
  key: "scanBill",
  storage,
  version: 1,
};

const rootReducer = combineReducers({
  home: persistReducer(homeReducerConfig, homeReducer),
  prize: persistReducer(prizeReducerConfig, prizeReducer),
  scanBill: persistReducer(scanBillReducerConfig, scanBillReducer),
});

export default rootReducer;
