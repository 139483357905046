import React, { useEffect, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import DuetListView from "./view";
import { useDispatch } from "react-redux";
import actionsDuet from "redux/duet/actions";
import actions from "redux/home/actions";

import { useLocation, useNavigate } from "react-router-dom";

const DuetListPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { text = "", } = location.state || {};

  const [isLoading, setIsLoading] = useState(false);
  const [listSub, setListSub] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [searchText, setSearchText] = useState("");

  const PIZE_SIZE = 7;

  useEffect(() => {
    setSearchText(text);
    getListSub(1, text);
  }, []);

  const getListSub = (page, searchText) => {
    setIsLoading(true);
    let params = {
      page: page,
      searchText: searchText,
      pageSize: PIZE_SIZE
    }
    dispatch(
      actionsDuet.GET_LIST_SUB.request(
        params,
        (res) => {
          setIsLoading(false);
          setListSub(res?.data || []);
          setPages(Math.ceil(res?.total / PIZE_SIZE));
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const onSearchSub = () => {
      setPageOffset(0);
      getListSub(1, searchText);

  };

  const onChangePage = (pageOffsetTmp, prizeType = 0) => {
    setPageOffset(pageOffsetTmp);
    getListSub(pageOffsetTmp + 1, searchText);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <DuetListView
        listSub={listSub}
        pages={pages}
        onChangePage={onChangePage}
        pageOffset={pageOffset}
        searchText={searchText}
        setSearchText={setSearchText}
        onSearchSub={onSearchSub}
      />
    </>
  );
};
export default DuetListPage;
