// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupCheckBill = (props) => {
  const navigate = useNavigate();
  const { requestCheck } = props;
  const [message, setMessage] = useState("");

  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>Hóa đơn sẽ được kiểm tra lại</h3>
              <p>
                Thời gian kiểm tra hóa đơn:
                <br />
                trong vòng <strong className="text-red">5 giờ (từ 08:00 - 20:00 hàng ngày)</strong>
              </p>
              <br />
              <div className="form-group">
                <textarea
                  rows="4"
                  cols="50"
                  maxLength={200}
                  className="form-control"
                  id="linkUrl"
                  name="linkUrl"
                  placeholder="Tôi muốn kiểm tra lại hoá đơn vì..."
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </div>
            <button
              onClick={() => {
                requestCheck(message);
              }}
              type="button"
              className="btn btn-primary btn-block"
            >
              <a>Đồng ý</a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupCheckBill;
