// @ts-nocheck
import LoadingSpinner from "components/LoadingSpinner";
import PopupVideo from "components/popup-video";
import { CAMPAIGNID } from "constants/common";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/prize/actions";
import PrizeView from "./view";
import { sha256 } from "js-sha256";
import PopupMessage from "components/popup-message";

const PrizePage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [remaining, setRemaining] = useState({});
  const [listBannerSpecial, setListBannerSpecial] = useState([]);
  const [listWinnerSpecial, setListWinnerSpecial] = useState([]);
  const [listWinnerSecond, setListWinnerSecond] = useState([]);
  const [listWinnerThird, setListWinnerThird] = useState([]);

  const [listPhaseSpecial, setListPhaseSpecial] = useState(
    Array.from(Array(7).keys())
  );
  const [listPhaseSecond, setListPhaseSecond] = useState(
    Array.from(Array(7).keys())
  );
  const [listPhaseThird, setListPhaseThird] = useState(
    Array.from(Array(7).keys())
  );

  const [listPicture, setListPicture] = useState([]);
  const listWinnerSpecialOrigin = useRef([]);
  const listWinnerSecondOrigin = useRef([]);

  const [listWinnerSpeciaSwiper, setListWinnerSpeciaSwiper] = useState([]);

  const [pages, setPages] = useState(0);
  const [pagesSpecial, setPagesSpecial] = useState(0);
  const [pagesSecond, setPagesSecond] = useState(0);
  const [pagesThird, setPagesThird] = useState(0);

  const [prizeTypeSpecial, setPrizeTypeSpecial] = useState(0);
  const [prizeTypeSecond, setPrizeTypeSecond] = useState(0);
  const [prizeTypeThird, setPrizeTypeThird] = useState(0);

  const [searchCode, setSearchCode] = useState("");
  const [searchCodeSpecial, setSearchCodeSpecial] = useState("");
  const [searchCodeSecond, setSearchCodeSecond] = useState("");
  const [searchCodeThird, setSearchCodeThird] = useState("");

  const searchCodeSpecialRef = useRef("");
  const searchCodeSecondRef = useRef("");
  const searchCodeThirdRef = useRef("");

  const [searchCodeTemp, setSearchCodeTemp] = useState("");
  const [pageOffset, setPageOffset] = useState(0);

  const [pageOffsetSpecial, setPageOffsetSpecial] = useState(0);
  const [pageOffsetSecond, setPageOffsetSecond] = useState(0);
  const [pageOffsetThird, setPageOffsetThird] = useState(0);

  const prizeTypeSpecialRef = useRef(0);
  const prizeTypeSecondlRef = useRef(0);

  const TAKE = 10;
  const [content, setContent] = useState("");
  const [nameBtn, setNameBtn] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    prizeTypeSpecialRef.current = prizeTypeSpecial;
  }, [prizeTypeSpecial]);

  useEffect(() => {
    prizeTypeSecondlRef.current = prizeTypeSecond;
  }, [prizeTypeSecond]);

  useEffect(() => {
    searchCodeSpecialRef.current = searchCodeSpecial;
  }, [searchCodeSpecial]);

  useEffect(() => {
    searchCodeSecondRef.current = searchCodeSecond;
  }, [searchCodeSecond]);

  useEffect(() => {
    // getRemaining();
    // getRemainingPrize();
    // getListPicture();
    // getListWinnerByType(0, 1, "");
    // getListWinner(1, "", "", 1);
    // getListWinner(2, "", "", 1);
    // getListWinner(3, "", "", 1);
    getAllListWinner(1);
    setModalVideo(true);
    // document.body.classList.add("modal-open");
  }, []);

  const getAllListWinner = (type) => {
    setIsLoading(true);
    let params = {
      pageNumber: 1,
      pageSize: TAKE,
      Type: type,
    };

    dispatch(
      actions.GET_LIST_WINNER_BY_TYPE.request(
        params,
        (res) => {
          switch (type) {
            case 1:
              setListBannerSpecial(res?.results);
              setListWinnerSpecial(res?.results || []);
              getAllListWinner(2);
              break;

            case 2:
              setListWinnerSecond(res?.results || []);
              setPagesSecond(Math.ceil(res?.totalResult / TAKE));
              getAllListWinner(3);

            case 3:
              setListWinnerThird(res?.results || []);
              setPagesThird(Math.ceil(res?.totalResult / TAKE));
              setIsLoading(false);
              break;

            default:
              break;
          }
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            if (errCode === "ERR_NETWORK") {
              setContent(`
              <h5>Thông báo</h5>
              <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
              `);
              setNameBtn(`ĐÓNG`);
              setModal(true);
            } else {
              showDefaultErrModal();
            }
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const getRemaining = () => {
    dispatch(
      actions.GET_REMAINING.request(
        {},
        (res) => {
          setRemaining(res);
          console.log("success", res);
        },
        (err) => {}
      )
    );
  };
  const getListPicture = () => {
    dispatch(
      actions.GET_LIST_PICTURE.request(
        null,
        (res) => {
          console.log("resGET_LIST_PICTURE", res);
          if (res?.length) {
            setListPicture(res || []);
          }
        },
        (err) => {}
      )
    );
  };
  const getRemainingPrize = () => {
    setIsLoading(true);
    dispatch(
      actions.GET_REMAINING_PRIZE.request(
        null,
        (res) => {
          setRemaining(res);
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const getListWinner = (type, phaseId, searchText, pageNumber) => {
    setIsLoading(true);
    let params = {
      pageNumber: pageNumber,
      pageSize: TAKE,
      Type: type,
    };

    if (!!searchText) {
      params = {
        searchText: searchText,
        ...params,
      };
    } else if (!!phaseId) {
      params = {
        phaseId: phaseId != 0 ? "Phase" + phaseId : "",
        ...params,
      };
    }

    dispatch(
      actions.GET_LIST_WINNER_BY_TYPE.request(
        params,
        (res) => {
          setIsLoading(false);
          switch (type) {
            case 1:
              // if (!params?.phaseId) {
              setListBannerSpecial(res?.results);
              // }
              setListWinnerSpecial(res?.results || []);
              break;

            case 2:
              setListWinnerSecond(res?.results || []);
              setPagesSecond(Math.ceil(res?.totalResult / TAKE));
              break;

            case 3:
              setListWinnerThird(res?.results || []);
              setPagesThird(Math.ceil(res?.totalResult / TAKE));
              break;

            default:
              break;
          }
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            if (errCode === "ERR_NETWORK") {
              setContent(`
              <h5>Thông báo</h5>
              <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
              `);
              setNameBtn(`ĐÓNG`);
              setModal(true);
            } else {
              showDefaultErrModal();
            }
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const showDefaultErrModal = () => {
    setModal(true);
    setContent(`
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `);
    setNameBtn(`ĐÓNG`);
  };

  const clickAction = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    document.body.classList.remove("modal-open");
  };

  const getListWinnerByType = (SKIP, prizeType, searchCodeTmp) => {
    setIsLoading(true);
    dispatch(
      actions.GET_LIST_WINNER_BY_TYPE.request(
        {
          phaseId: 1,
          prize: 1,
        },
        (res) => {
          setPages(Math.ceil(res.total / TAKE));
          console.log("success", res);
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const onChangePage = (pageOffsetTmp) => {
    setPageOffset(pageOffsetTmp);
    // getListWinnerByType(pageOffsetTmp * TAKE, prizeType, searchCodeTemp);
  };

  const onChangePageSpecial = (pageOffsetTmp, prizeType = 0) => {
    setPageOffsetSpecial(pageOffsetTmp);
    getListWinner(1, "", "", pageOffsetTmp + 1);
  };

  const onChangePageSecond = (pageOffsetTmp, prizeType = 0) => {
    setPageOffsetSecond(pageOffsetTmp);
    getListWinner(2, prizeTypeSecond, searchCodeSecond, pageOffsetTmp + 1);
  };

  const onChangePageThird = (pageOffsetTmp, prizeType = 0) => {
    setPageOffsetThird(pageOffsetTmp);
    getListWinner(3, prizeTypeThird, searchCodeThird, pageOffsetTmp + 1);
  };

  const onChangePrizeTypeSpecial = (prizeTypeTmp) => {
    setPrizeTypeSpecial(parseInt(prizeTypeTmp));
    setSearchCodeSpecial("");
    getListWinner(1, prizeTypeTmp, "", 1);
  };

  const onChangePrizeTypeSecond = (prizeTypeTmp) => {
    setPageOffsetSecond(0);
    setPrizeTypeSecond(parseInt(prizeTypeTmp));
    setSearchCodeSecond("");
    getListWinner(2, prizeTypeTmp, "", 1);
  };

  const onChangePrizeTypeThird = (prizeTypeTmp) => {
    setPageOffsetThird(0);
    setPrizeTypeThird(parseInt(prizeTypeTmp));
    setSearchCodeThird("");
    getListWinner(3, prizeTypeTmp, "", 1);
  };

  const onSearchCodeSecond = (searchStr) => {
    setPrizeTypeSecond(0);
    setPageOffsetSecond(0);
    setSearchCodeSecond(searchStr);
    getListWinner(2, "", searchStr, 1);
  };

  const onSearchCodeThird = (searchStr) => {
    setPrizeTypeThird(0);
    setPageOffsetThird(0);
    setSearchCodeThird(searchStr);
    getListWinner(3, "", searchStr, 1);
  };

  const onSearchCodeSpecial = (searchStr) => {
    setPrizeTypeSpecial(0);
    setSearchCodeSpecial(searchStr);
    getListWinner(1, "", searchStr, 1);
  };

  const closeModalVideo = () => {
    setModalVideo(false);
    document.body.classList.remove("modal-open");
  };
  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {/* {modalVideo && <PopupVideo closeModalVideo={closeModalVideo} />} */}
      <PrizeView
        onChangePage={onChangePage}
        onChangePrizeTypeSpecial={onChangePrizeTypeSpecial}
        onChangePrizeTypeSecond={onChangePrizeTypeSecond}
        onChangePrizeTypeThird={onChangePrizeTypeThird}
        onSearchCodeSpecial={onSearchCodeSpecial}
        onSearchCodeSecond={onSearchCodeSecond}
        onSearchCodeThird={onSearchCodeThird}
        pageOffset={pageOffset}
        remaining={remaining}
        listWinnerSecond={listWinnerSecond}
        listWinnerThird={listWinnerThird}
        listWinnerSpecial={listWinnerSpecial}
        listPhaseSpecial={listPhaseSpecial}
        listPhaseThird={listPhaseThird}
        listPhaseSecond={listPhaseSecond}
        pagesSecond={pagesSecond}
        pagesSpecial={pagesSpecial}
        pagesThird={pagesThird}
        pages={pages}
        searchCode={searchCode}
        searchCodeTemp={searchCodeTemp}
        setSearchCode={setSearchCode}
        setSearchCodeSpecial={setSearchCodeSpecial}
        setSearchCodeSecond={setSearchCodeSecond}
        setSearchCodeThird={setSearchCodeThird}
        onChangePageSecond={onChangePageSecond}
        onChangePageThird={onChangePageThird}
        onChangePageSpecial={onChangePageSpecial}
        prizeTypeSecond={prizeTypeSecond}
        prizeTypeThird={prizeTypeThird}
        prizeTypeSpecial={prizeTypeSpecial}
        searchCodeSpecial={searchCodeSpecial}
        searchCodeSecond={searchCodeSecond}
        searchCodeThird={searchCodeThird}
        listWinnerSpeciaSwiper={listWinnerSpeciaSwiper}
        pageOffsetSecond={pageOffsetSecond}
        pageOffsetThird={pageOffsetThird}
        pageOffsetSpecial={pageOffsetSpecial}
        listPicture={listPicture}
        listBannerSpecial={listBannerSpecial}
      />
      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          setModal={setModal}
          content={content}
          clickAction={clickAction}
        />
      )}
    </>
  );
};

export default PrizePage;
