const AppConfigs = {
  gaKey: process.env.REACT_APP_GA_KEY,
  apiVersion: process.env.REACT_APP_API_VERSION,
  xEnvironment: process.env.REACT_APP_X_ENVIRONMENT,
  subscriptionKeyCampaign:
    process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_CAMPAIGN,
  subscriptionKeyUtop: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_UTOP,
  subscriptionKeyDuet: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_DUET,
  subscriptionKeyLoyalty: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_LOYALTY,
  apiVersionCMS: process.env.REACT_APP_API_VERSION_CMS,
};

export default AppConfigs;
