import React, { useEffect, useState } from "react";
import {
  END_DATE,
  END_DATE_TIME,
  START_DATE,
  START_DATE_TIME,
} from "constants/common";
const CommingSoonCountDown = (props) => {
  const [countDateEnd, setCountDateEnd] = React.useState(true);
  function calculateTimeLeft() {
    const dateArr = START_DATE.split("/");
    const dateEndArr = END_DATE.split("/");

    const year = parseInt(dateArr[2]).toString();
    const month = parseInt(dateArr[1]).toString();
    const day = parseInt(dateArr[0]).toString();

    const yearE = parseInt(dateEndArr[2]).toString();
    const monthE = parseInt(dateEndArr[1]).toString();
    const dayE = parseInt(dateEndArr[0]).toString();

    const dateB = `${year}/${month}/${day} ${START_DATE_TIME}`;
    const dateBEnd = `${yearE}/${monthE}/${dayE} ${END_DATE_TIME}`;

    const difference = +new Date(`${dateB}`) - +new Date();

    const differenceEnd = +new Date(`${dateBEnd}`) - +new Date();

    localStorage.setItem("difference", JSON.stringify(difference));
    localStorage.setItem("differenceEnd", JSON.stringify(differenceEnd));

    let timeLeft = {};
    if (difference > 0) {
      localStorage.setItem("commingSoon", "true");
      localStorage.setItem("dateStart", START_DATE);
    } else {
      localStorage.setItem("commingSoon", "false");
      localStorage.setItem("dateStart", START_DATE);
    }
    if (countDateEnd) {
      timeLeft = {
        Ngày: Math.floor(differenceEnd / (1000 * 60 * 60 * 24)),
        Giờ: Math.floor((differenceEnd / (1000 * 60 * 60)) % 24),
        Phút: Math.floor((differenceEnd / 1000 / 60) % 60),
        Giây: Math.floor((differenceEnd / 1000) % 60),
      };
    } else {
      timeLeft = {
        Ngày: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Giờ: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Phút: Math.floor((difference / 1000 / 60) % 60),
        Giây: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  });
  const timerComponents = Object.keys(timeLeft).map((interval) => {
    return (
      <span className="itemWarp">
        <span className="itemValue">{timeLeft[interval]}</span>
        <p>{interval}</p>
      </span>
    );
  });
  return (
    <>
      {timerComponents.length > 0 && (
        <div className="count-down-warp">
          {countDateEnd && <p>Chương trình sẽ kết thúc sau</p>}
          {!countDateEnd && <p>Chương trình sẽ bắt đầu sau</p>}
          {timerComponents}
        </div>
      )}
    </>
  );
};
export default CommingSoonCountDown;
