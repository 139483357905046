import CommingSoonCountDown from "components/commingsoon-count-down";
import PopupLogout from "components/popup-logout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "routes";
const TheHeader = () => {
  const [nav, setNav] = useState(false);
  // const [navItem, setNavItem] = useState([]);
  const [active, setActive] = useState(null);
  const [namePage, setNamePage] = useState("Home");
  const navigate = useNavigate();
  const handleNav = () => {
    setNav(true);
    document.body.classList.add("modal-open");
  };
  const handleCloseNav = (e) => {
    setNav(false);
    document.body.classList.remove("modal-open");
  };

  const handleNavigate = (e) => {
    navigate(e);
    handleCloseNav();
  };
  useEffect(() => {
    if (window.location.pathname) {
      // handleNavigate(window.location.pathname);
      setActive(window.location.pathname);
      document.body.classList.remove("home-page");
      console.log("window.location.pathname", window.location.pathname);
      if (window.location.pathname === "/") {
        document.title = "HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG";
      } else {
        // document.title = "HaoHao" + `${namePage && " - " + namePage}`;
        document.title = "HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG";
      }
      if (window.location.pathname === "/thelechuongtrinh") {
        document.body.classList.remove("home-page");
        document.body.classList.add("bgr-rule");
      }
    } else {
      // handleNavigate("/");
      setActive("/");
      document.body.classList.add("home-page");
      document.title = "HÒA NHẠC MỲ LY TRÚNG NGAY MIC VÀNG";
    }
  }, [window.location.pathname]);

  const getTitleHead = (e) => {
    var x = document.createElement("TITLE");
    var t = document.createTextNode("HTML DOM Objects");
    x.appendChild(t);
    document.head.appendChild(x);

    document.getElementById("demo").innerHTML = "You have ";
  };
  const result = routes.filter(
    (link) => link.path === "/" || link.path === "/thelechuongtrinh"
  );

  const commingSoon = JSON.parse(localStorage.getItem("commingSoon"));
  // console.log("result===heaer", result, commingSoon);
  let navItem = [];
  // if (commingSoon) {
  //   navItem = result.map((link, index) => {
  //     return (
  //       <li key={index} className="nav-item">
  //         <a
  //           href="javascript:void(0)"
  //           className={`nav-link ${active == link.path && "active"}`}
  //           onClick={() => {
  //             setActive(link.path === "guide" ? "/" : link.path);
  //             setActive(link.path);
  //             setNamePage(link.name);
  //             handleNavigate(link.path);
  //           }}
  //         >
  //           {link.name}
  //         </a>
  //       </li>
  //     );
  //   });
  // } else {
  navItem = routes
    .filter((elm) => elm.path !== "logout")
    .map((link, index) => {
      return (
        <>
          {link.path !== "/chonbaihat" &&
            link.path !== "/choncasi" &&
            link.path !== "/chitietbaithi" &&
            link.path !== "/scanbillsuccess" &&
            link.path !== "/preview" &&
            link.path !== "/danhsachbaithi" &&
            link.path !== "/scanbillguide" &&
            link.path !== "/scanbill" &&
            link.path !== "/lichsuchuphoadon" &&
            link.path !== "/quay" &&
            link.path !== "guide" &&
            link.path !== "/xemlaibaithi" &&
            link.path !== "/guideduet" &&
            link.path !== "/logout" &&
            link.path !== "/pc" && (
              <>
                <li key={index} className="nav-item">
                  <a
                    href={`${
                      link.path === "joinNow"
                        ? `#${link.path}`
                        : "javascript:void(0)"
                    }`}
                    className={`nav-link ${active == link.path && "active"}`}
                    onClick={() => {
                      setActive(
                        link.path === "joinNow" ||
                          link.path === "joinNow#joinNow"
                          ? "/"
                          : link.path
                      );
                      setNamePage(link.name);
                      handleNavigate(link.path);
                    }}
                  >
                    {link.name}
                  </a>
                </li>
              </>
            )}
        </>
      );
    });
  // }
  useEffect(() => {
    if (window.location.pathname) {
      setActive(
        window.location.pathname === "/guide" ? "/" : window.location.pathname
      );
    }
  }, [window.location.pathname]);

  function calculateTimeLeft() {
    let timeLeft = {};
    const difference = JSON.parse(localStorage.getItem("difference"));

    if (difference > 0) {
      localStorage.setItem("commingSoon", "true");
      timeLeft = difference;
    } else {
      localStorage.setItem("commingSoon", "false");
      timeLeft = difference;
    }

    return timeLeft;
  }
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      // console.log("timeLeft", timeLeft);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, [timeLeft]);
  return (
    <>
      {window.location.pathname !== "/chitietbaithi" &&
        window.location.pathname !== "/quay" &&
        window.location.pathname !== "/scanbillsuccess" &&
        window.location.pathname !== "/preview" &&
        window.location.pathname !== "/scanbill" &&
        window.location.pathname !== "/scanbillguide" && (
          <header>
            {window.location.pathname === "/chitietbaithi" && (
              <span
                className="icon-back"
                onClick={() => {
                  navigate("/chonbaihat");
                }}
              ></span>
            )}
            {window.location.pathname === "/chonbaihat" && (
              <span
                className="icon-back"
                onClick={() => {
                  navigate("/choncasi");
                }}
              ></span>
            )}
            {window.location.pathname === "/choncasi" && (
              <span
                className="icon-back"
                onClick={() => {
                  navigate("/hatcungthantuong");
                }}
              ></span>
            )}
            {window.location.pathname === "/lichsuchuphoadon" && (
              <span
                className="icon-back"
                onClick={() => {
                  navigate("/hatcungthantuong");
                }}
              ></span>
            )}
            {window.location.pathname === "/danhsachbaithi" && (
              <span
                className="icon-back"
                onClick={() => {
                  window.history.back();
                }}
              ></span>
            )}
            {window.location.pathname === "/xemlaibaithi" && (
              <span
                className="icon-back"
                onClick={() => {
                  window.history.back();
                }}
              ></span>
            )}
            {window.location.pathname === "/guideduet" && (
              <span
                className="icon-back"
                onClick={() => {
                  navigate("/hatcungthantuong");
                }}
              ></span>
            )}

            {window.location.pathname === "/danhsachbaithi" && (
              <p className="d-none">HÁT CÙNG THẦN TƯỢNG</p>
            )}
            {window.location.pathname === "/xemlaibaithi" && (
              <p className="d-none">HÁT CÙNG THẦN TƯỢNG</p>
            )}
            {window.location.pathname === "/guideduet" && (
              <p className="d-none">HƯỚNG DẪN DUET</p>
            )}
            {window.location.pathname === "/chonbaihat" && (
              <p className="d-none">HÁT CÙNG THẦN TƯỢNG</p>
            )}
            {window.location.pathname === "/hatcungthantuong" && (
              <p className="d-none">HÁT CÙNG THẦN TƯỢNG</p>
            )}
            {window.location.pathname === "/choncasi" && (
              <p className="d-none">HÁT CÙNG THẦN TƯỢNG</p>
            )}
            {window.location.pathname === "/thelechuongtrinh" && (
              <p className="d-none">THỂ LỆ CHƯƠNG TRÌNH</p>
            )}
            {window.location.pathname === "/danhsachtrungthuong" && (
              <p className="d-none">DANH SÁCH TRÚNG THƯỞNG</p>
            )}

            {window.location.pathname === "/lichsuchuphoadon" && (
              <p className="d-none">CHỤP HÓA ĐƠN NHẬN THƯỞNG</p>
            )}
            {window.location.pathname !== "/danhsachbaithi" &&
              window.location.pathname !== "/chonbaihat" &&
              window.location.pathname !== "/xemlaibaithi" &&
              window.location.pathname !== "/guideduet" &&
              window.location.pathname !== "/choncasi" &&
              window.location.pathname !== "/thelechuongtrinh" &&
              window.location.pathname !== "/danhsachtrungthuong" &&
              window.location.pathname !== "/lichsuchuphoadon" &&
              window.location.pathname !== "/hatcungthantuong" && (
                <a
                  onClick={() => {
                    setActive("/");
                    handleNavigate("/");
                  }}
                  className="logo d-none"
                >
                  <img src="assets/img/logoBrand.png" />
                </a>
              )}

            <a
              onClick={() => {
                setActive("/");
                handleNavigate("/");
              }}
              className="logo d-none-mb"
            >
              <img src="assets/img/logoBrand.png" />
            </a>
            <span className="icon-nav" onClick={handleNav}></span>
            <nav id="sidr-main">
              <div className="nav-inner">
                <span
                  className="icon-close d-none"
                  onClick={handleCloseNav}
                ></span>
                <a
                  onClick={() => {
                    setActive("/");
                    handleNavigate("/");
                  }}
                  className="logo-nav d-none"
                >
                  <img src="assets/img/logoBrand.png" />
                </a>
                <ul className={`${commingSoon ? "commingSoon" : ""}`}>
                  {navItem}
                </ul>
              </div>
            </nav>
            <div className="count-time-header">
              <CommingSoonCountDown />
            </div>
          </header>
        )}
      {nav && (
        <PopupLogout content="Đăng xuất" handleCloseNav={handleCloseNav} />
      )}
    </>
  );
};

export default TheHeader;
