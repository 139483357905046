// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import TheFooter from "layout/TheFooter";

const DuetSelectSongView = (props) => {
  const { songs, onDuet, onDeleteSub, onReview } = props;
  const [hasSong, setHasSong] = useState(false);
  const navigate = useNavigate();
  return (
    <section>
      <div className=" des-green  bgr-green">
        <div
          className=" des-green container"
          style={{
            minHeight: window.innerHeight - 220,
            objectFit: "contain",
          }}
        >
          <br />
          <img className="img-full" src="assets/img/Title-selectsong.png" />
          <ul className="list-singer">
            {songs?.length > 0 &&
              songs.map((item) => {
                return (
                  <li>
                    <img
                      className="img-full"
                      src={item?.songImage || "assets/img/singer.png"}
                    />
                    <p className="see-review">Xem trước</p>
                    <span
                      className="playCam"
                      onClick={() => onReview(item)}
                    ></span>
                    <div className="list-action action-song">
                      <h3>{item?.songName}</h3>
                      {!!item?.submissionId ? (
                        <>
                          <p>Bạn đã quay bài hát này</p>
                          <div className="group-btn-inline">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary btn-border btn-block"
                              onClick={() => {
                                onDeleteSub(item?.submissionId);
                              }}
                            >
                              <a>
                                <span className="i-dell"></span>Xóa bài
                              </a>
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary btn-block"
                              onClick={() => {
                                navigate(
                                  `/chitietbaithi?id=${item?.submissionId}`
                                );
                              }}
                            >
                              <a>
                                <span className="i-info"></span>Chi tiết
                              </a>
                            </button>
                          </div>
                        </>
                      ) : (
                        <div className="group-btn-inline">
                          <button
                            type="button"
                            className="btn btn-sm btn-warning btn-block"
                            onClick={() => {
                              props.sendconnectTiktok(item);
                            }}
                          >
                            <a>
                              <span className="i-tiktok"></span>QUAY TRÊN TIKTOK
                            </a>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary btn-block"
                            onClick={() => {
                              onDuet(item);
                            }}
                          >
                            <a>
                              <span className="i-camera"></span>QUAY NGAY
                            </a>
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="TheFooterRaidius">
          <TheFooter />
        </div>
      </div>
    </section>
  );
};
export default DuetSelectSongView;
