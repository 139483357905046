import TheFooter from "layout/TheFooter";
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const RulesPage = (props) => {
  // localStorage.setItem("rule", "true");
  return (
    <div className="rules noinfor">
      <img className="img-full d-none-mb" src="assets/img/title-rule.png" />
      <div className="container">
        <h2 className="name-block">
          <img className="img-full d-none" src="assets/img/title-rule-mb.png" />
        </h2>
        <div className="rules-inner-noinfor">
          <Tabs>
            <TabList>
              <Tab>♦ Săn thẻ cào trong ly ♦</Tab>
              <Tab>♦ hát cùng thần tượng ♦</Tab>
            </TabList>
            <TabPanel>
              <section>
                <p>
                  <strong>
                    1. Tên chương trình khuyến mại: SĂN THẺ CÀO TRONG LY - TRÚNG
                    QUÀ MÊ LY
                  </strong>
                </p>
                <p>
                  (thuộc khuôn khổ hoạt động “HÒA NHẠC MÌ LY - TRÚNG NGAY MIC
                  VÀNG”)
                </p>
                <p>
                  <u>Ghi chú: </u>Chương trình theo thể lệ này thuộc khuôn khổ
                  hoạt động “HÒA NHẠC MÌ LY - TRÚNG NGAY MIC VÀNG”, gồm 02 hoạt
                  động khuyến mại sau:
                </p>
                <ul>
                  <li>
                    SĂN THẺ CÀO TRONG LY - TRÚNG QUÀ MÊ LY: thuộc trường hợp
                    đăng ký hoạt động khuyến mại tại Cục Xúc Tiến Thương Mại –
                    Bộ Công Thương theo Điều 19, Nghị định 81/2018/NĐ-CP.
                  </li>
                  <li>
                    HÁT CÙNG THẦN TƯỢNG: thuộc trường hợp thông báo hoạt động
                    khuyến mại tại các Sở Công Thương theo Điều 17, Nghị định
                    81/2018/NĐ-CP.
                  </li>
                </ul>
                <p>
                  <strong>2. Hàng hóa, dịch vụ khuyến mại:</strong> tất cả sản
                  phẩm mì ly Modern, Handy Hảo Hảo, Caykay, và Tô Nhớ Mãi Mãi,
                  bao gồm:
                </p>
                <ul>
                  <li>Mì ly Modern hương vị Lẩu Thái Tôm</li>
                  <li>Mì ly Modern hương vị Bò Hầm Rau Thơm,</li>
                  <li>Mì ly Modern hương vị Thịt Xào</li>
                  <li>Mì ly Modern hương vị Riêu Cua</li>
                  <li>Mì ly CayKay hương vị Bò</li>
                  <li>Mì ly CayKay hương vị Kim Chi</li>
                  <li>Mì ly Handy Hảo Hảo hương vị TomYum</li>
                  <li>Mì ly Handy Hảo Hảo hương vị Tôm Chua Cay</li>
                  <li>Handy Hảo Hảo Mì Chay hương vị Lẩu Nấm Thập Cẩm</li>
                  <li>Nhớ Mãi Mãi hương vị Hủ Tiếu Nam Vang</li>
                  <li>Nhớ Mãi Mãi hương vị Phở Bò</li>
                  <li>Mì Nhớ Mãi Mãi hương vị Lẩu Thái</li>
                  <li>Nhớ Mãi Mãi hương vị Miến Sườn Heo</li>
                </ul>
                <p>
                  Tổng số sản phẩm khuyến mại là 38.004.600 ly sản phẩm, tương
                  đương với giá trị là 263.758.090.000 VND (Bằng chữ: Hai trăm
                  sáu mươi ba tỷ bảy trăm năm mươi tám triệu không trăm chín
                  mươi ngàn đồng); trong đó sản phẩm khuyến mại có thẻ cào là
                  450.000 sản phẩm.
                </p>
                <p>
                  <strong>3. Thời gian khuyến mại:</strong> Từ 00:00 ngày
                  12/08/2024 đến 23:59 ngày 11/11/2024
                </p>
                <p>
                  <strong>4. Địa bàn (Phạm vi) khuyến mãi:</strong> Trên toàn
                  lãnh thổ Việt Nam
                </p>
                <p>
                  <strong>5. Hình thức khuyến mại: </strong>Chương trình khuyến
                  mại may rủi{" "}
                </p>
                <p>
                  <strong>
                    6. Khách hàng của chương trình khuyến mại (đối tượng hưởng
                    khuyến mại):{" "}
                  </strong>
                  người tiêu dùng trong lãnh thổ Việt Nam trong thời gian khuyến
                  mại.
                </p>
                <p>
                  Các đối tượng không được tham gia chương trình khuyến mại:
                  người lao động đang làm việc cho các công ty:
                </p>
                <ul>
                  <li>Công ty Cổ phần Acecook Việt Nam</li>
                  <li>Công ty TNHH Hakuhodo & Quảng cáo Sài Gòn</li>
                  <li>Công ty Cổ phần Công nghệ UTOP</li>
                  <li>Công ty TNHH Nguyễn Quang Huy</li>
                  <li>
                    Các công ty và đối tác thông qua Công ty TNHH Hakuhodo &
                    Quảng cáo Sài Gòn, Công ty Cổ phần Công nghệ UTOP và Công ty
                    TNHH Nguyễn Quang Huy tham gia thực hiện chương trình này,
                    bao gồm cung cấp quà tặng làm giải thưởng, vận hành chương
                    trình.
                  </li>
                </ul>
                <p>
                  <strong>7. Cơ cấu giải thưởng: </strong>
                </p>
                <div className="scroll-table">
                  <table className="rules-table">
                    <thead>
                      <tr>
                        <th>Giải thưởng</th>
                        <th>Nội dung giải thưởng</th>
                        <th>Trị giá (VND)</th>
                        <th>Số giải</th>
                        <th>Tổng giá trị (VND)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Giải I</td>
                        <td>
                          01 Ly bằng vàng 24K từ thương hiệu PNJ, tương đương 7
                          chỉ vàng. (Giá trị 7 chỉ vàng tại thời điểm đăng ký:
                          54.313.000 VND; chi phí gia công cho 7 chỉ vàng:
                          7.000.000 VND)
                        </td>
                        <td>61.313.000</td>
                        <td>6</td>
                        <td>367.878.000</td>
                      </tr>
                      <tr>
                        <td>Giải II</td>
                        <td>
                          01 ĐIỆN THOẠI IPHONE 15​​ Pro 128gb, màu Titan tự
                          nhiên
                        </td>
                        <td>24.490.000</td>
                        <td>18</td>
                        <td>440.820.000</td>
                      </tr>
                      <tr>
                        <td>Giải III</td>
                        <td>
                          01 SMART TIVI SAMSUNG CRYSTAL UHD 4K 55 INCH
                          UA55AU7700KXXV
                        </td>
                        <td>9.190.000</td>
                        <td>30</td>
                        <td>275.700.000</td>
                      </tr>
                      <tr>
                        <td>Giải IV</td>
                        <td>01 tài khoản điện thoại trị giá 150.000 đồng </td>
                        <td>150.000</td>
                        <td>5.000</td>
                        <td>750.000.000</td>
                      </tr>
                      <tr>
                        <td>Giải V</td>
                        <td>01 tài khoản điện thoại trị giá 100.000 đồng</td>
                        <td>100.000</td>
                        <td>35.000</td>
                        <td>3.500.000.000</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          // @ts-ignore
                          colSpan="3"
                        >
                          TỔNG CỘNG (bao gồm VAT)
                        </td>
                        <td>40.054</td>
                        <td>5.334.398.000</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <ul>
                  <li>
                    Giá trị tất cả các giải thưởng đã bao gồm thuế giá trị gia
                    tăng (GTGT) và không thể quy đổi thành tiền.
                  </li>
                  <li>
                    Giá trị giải thưởng chưa bao gồm tiền thuế thu nhập cá nhân
                    mà Công ty Cổ phần Acecook Việt Nam sẽ đóng thay khách hàng
                    trúng thưởng (nếu có).
                  </li>
                  <li>
                    Tổng giá trị giải thưởng trong chương trình khuyến mại đã
                    bao gồm thuế GTGT là: 5.334.398.000 đồng (bằng chữ: Năm tỷ
                    ba trăm ba mươi bốn triệu ba trăm chín mươi tám nghìn đồng).
                  </li>
                  <li>
                    Tổng giá trị giải thưởng so với tổng giá trị hàng hóa khuyến
                    mại: khoảng 2,02%.
                  </li>
                  <li>
                    Công ty Cổ phần Acecook Việt Nam cam kết trao thưởng đúng
                    giải thưởng được mô tả ở Cột “Nội dung giải thưởng”.
                  </li>
                </ul>
                <p>
                  <strong>
                    8. Nội dung và thể lệ chi tiết chương trình khuyến mại:{" "}
                  </strong>
                </p>
                <p>
                  <strong>
                    8.1. Điều kiện, cách thức, thủ tục cụ thể khách hàng phải
                    thực hiện để được tham gia chương trình khuyến mại:{" "}
                  </strong>
                </p>
                <p>
                  Trong thời gian khuyến mại, khi mua bất kỳ sản phẩm thuộc loại
                  sản phẩm khuyến mại nêu tại Mục 2, khách hàng sẽ có cơ hội
                  nhận được 01 (một) thẻ cào đặt bên trong sản phẩm khuyến mại.
                  Tổng số thẻ cào được phát hành là: 450.000 thẻ cào sẽ được đưa
                  ngẫu nhiên vào 450.000 sản phẩm khuyến mại trên tổng số
                  38.004.600 sản phẩm khuyến mại. Trên mỗi thẻ cào, khách hàng
                  sẽ tìm được mã thẻ cào như mô tả tại Mục 8.3.1
                </p>
                <p>
                  Khách hàng nhận được mã thẻ cào làm theo hướng dẫn bên dưới:
                </p>
                <ul>
                  <li>
                    {" "}
                    Trong Thời gian khuyến mại quy định tại Mục 3, Khách hàng
                    thực hiện các cách thức như hướng dẫn tại mục 8.4.3 của Thể
                    lệ để tham gia Chương trình.
                  </li>
                  <li>
                    Khách hàng có mã thẻ cào thuộc 35.000 mã thẻ cào đầu tiên
                    tham gia Chương Trình hợp lệ sẽ chắc chắn trúng Giải V.
                    Khách hàng có mã thẻ cào không thuộc nhóm 35.000 mã thẻ cào
                    đầu tiên tham gia Chương Trình hợp lệ sẽ được tham gia vòng
                    quay trên Website Chương Trình để có cơ hội trúng giải IV.
                  </li>
                  <li>
                    Tất cả khách hàng tham gia chương trình hợp lệ (kể cả khách
                    hàng đã trúng thưởng Giải IV và Giải V) sẽ có cơ hội trúng
                    Giải I, giải II hoặc Giải III bằng hình thức quay số trúng
                    thưởng theo quy định tại Mục 8.4.5 của Thể lệ.
                  </li>
                </ul>
                <p>
                  <strong>
                    8.2. Thời gian và cách thức phát hành bằng chứng xác định
                    trúng thưởng
                  </strong>
                </p>
                <p>
                  Công ty Cổ phần Acecook Việt Nam sẽ tiến hành đặt thẻ cào đã
                  được để trong bao nhựa trong suốt và ngẫu nhiên đặt vào bên
                  trong ly hoặc tô sản phẩm khuyến mại dưới sự chứng kiến của
                  đại diện Sở Công Thương tại địa phương (nếu có), và sau đó
                  phân bố số sản phẩm khuyến mại trên toàn quốc từ ngày
                  12/08/2024.
                </p>
                <p>
                  <strong>
                    8.3. Quy định về bằng chứng xác định trúng thưởng
                  </strong>
                </p>
                <p>
                  <strong>8.3.1. Thẻ cào:</strong>
                </p>
                <ul>
                  <li>
                    Thẻ cào hợp lệ có kích thước cụ thể là: chiều dọc 6 cm x
                    chiều ngang 4 cm. Thẻ cào hợp lệ nhận thưởng phải còn nguyên
                    vẹn, phần cào nội dung không bị mất số và phần kiểm soát
                    không bị trầy xước.
                  </li>
                  <li>
                    Mã QR trên sản phẩm hợp lệ là mã QR dẫn về website chính
                    thức của chương trình: “www.khuyenmaiacecook.com”
                  </li>
                  <li>
                    Tổng số thẻ cào do Công ty Cổ phần Acecook Việt Nam phát
                    hành trong thời gian khuyến mại là 450.000 thẻ.
                  </li>
                  <li>
                    Thẻ cào thể hiện các nội dung sau:
                    <ul>
                      <li>
                        Biểu tượng thương hiệu của Công ty Cổ phần Acecook Việt
                        Nam
                      </li>
                      <li>Tên chương trình khuyến mại </li>
                      <li>
                        Ghi chú: Hình ảnh thẻ cào thể hiện 2 chương trình trong
                        khuôn khổ hoạt động “HÒA NHẠC MÌ LY - TRÚNG NGAY MIC
                        VÀNG”, gồm:
                        <ul>
                          <li>
                            SĂN THẺ CÀO TRONG LY - TRÚNG QUÀ MÊ LY: thuộc trường
                            hợp đăng ký hoạt động khuyến mại tại Cục Xúc Tiến
                            Thương Mại – Bộ Công Thương theo Điều 19, Nghị định
                            81/2018/NĐ-CP.
                          </li>
                          <li>
                            HÁT CÙNG THẦN TƯỢNG: thuộc trường hợp thông báo hoạt
                            động khuyến mại tại các Sở Công Thương theo Điều 17,
                            Nghị định 81/2018/NĐ-CP.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Mã thẻ cào được phủ cào tráng bạc tại tại khu vực dưới
                        dòng chữ “CÀO TẠI ĐÂY”
                      </li>
                      <li>Mã kiểm soát</li>
                      <li>
                        Nội dung và hướng dẫn tham gia chương trình khuyến mại
                      </li>
                      <li> Thông tin liên hệ</li>
                      <li>
                        Thông tin lưu ý khách hàng về việc giữ thẻ cào đề phòng
                        khi chưa nhận giải thưởng “Vui lòng giữ lại thẻ cào để
                        nhận thưởng. Thẻ cào hợp lệ nhận thưởng phải còn nguyên
                        vẹn, phần cào nội dung không bị mất số và phần mã kiểm
                        soát không bị trầy xước.”
                      </li>
                      <li>
                        Hình ảnh thẻ cào:
                        <p className="text-center">
                          <img src="assets/img/rule/rule1.png" />
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    Mã thẻ cào bao gồm 08 ký tự ngẫu nhiên, bao gồm các loại ký
                    tự: số (từ 0 đến 9), chữ cái (từ A đến Z). Các chữ số và ký
                    tự được loại trừ và không xuất hiện trong mã thẻ cào bao
                    gồm: 0, 1, 2, 5, 6, 8, G, Z, I, B, O, S, U, W do Công ty Cổ
                    phần Acecook Việt Nam phát hành.
                  </li>
                  <li>
                    Danh sách mã thẻ cào tham gia chương trình khuyến mại là
                    danh sách 450.000 mã thẻ cào do Công ty Cổ phần Acecook Việt
                    Nam quản lý và được Công ty TNHH Nguyễn Quang Huy sử dụng để
                    in ấn thẻ cào. Công ty Cổ Phần Acecook sẽ phát hành thêm
                    45.000 mã thẻ cào dự bị để sử dụng trong trường hợp in ấn
                    thẻ cào bị lỗi (“Danh sách mã thẻ cào”). Trường hợp in ấn bị
                    lỗi, Công ty Cổ phần Acecook sẽ tiến hành hủy các thẻ cào bị
                    in lỗi và sử dụng mã thẻ cào dự bị để in thẻ cào thay thế.
                    Tổng số lượng thẻ cào phát hành cuối cùng vẫn là 450.000 mã
                    thẻ cào và được đưa ngẫu nhiên vào 450.000 sản phẩm khuyến
                    mại theo quy định tại Mục 8.1, Thể lệ này.
                  </li>
                </ul>
                <p>
                  <strong>8.3.2. Tin nhắn thông báo trúng thưởng:</strong> tin
                  nhắn thông báo trúng thưởng hợp lệ là tin nhắn thông báo trúng
                  thưởng từ tổng đài có tên định danh Acecook VN gửi đến Số Điện
                  Thoại Khách Hàng của khách hàng trúng thưởng để thông báo
                  khách hàng đã trúng thưởng Giải I, Giải II hoặc Giải III theo
                  quy định tại Mục 8.5.2.a Thể lệ.
                </p>
                <p>
                  <strong>
                    8.4. Thời gian, địa điểm và cách thức xác định trúng thưởng:{" "}
                  </strong>
                </p>
                <p>
                  <strong>8.4.1 </strong>Khách hàng mở sản phẩm khuyến mại để
                  tìm thẻ cào:
                </p>
                <ul>
                  <li>
                    Trường hợp trong sản phẩm khuyến mại không có thẻ cào thì
                    khách hàng không trúng thưởng;
                  </li>
                  <li>
                    Trường hợp trong sản phẩm khuyến mại có thẻ cào thì khách
                    hàng có cơ hội trúng các giải thưởng theo quy định của Thể
                    lệ.
                  </li>
                </ul>
                <p>
                  <strong>8.4.2 </strong>Khách hàng cào tại “CÀO TẠI ĐÂY” trên
                  thẻ cào (phần tráng bạc) để xuất hiện dòng chữ: “ABCD1234”,
                  trong đó ABCD1234 là mã thẻ cào gồm 08 (tám) ký tự.
                </p>
                <p>
                  <strong>8.4.3 </strong>Trong Thời gian khuyến mại nêu tại Mục
                  3 Thể lệ, Khách hàng nhập mã thẻ cào để tham gia Chương trình
                  bằng các cách thức sau:
                </p>
                <p>
                  {" "}
                  <strong>
                    Cách 1: Nhập mã thẻ cào tại Website Chương Trình
                  </strong>{" "}
                  “www.khuyenmaiacecook.com”
                </p>
                <p>
                  Khách hàng truy cập trực tiếp vào Website Chương Trình tại
                  đường dẫn www.khuyenmaiacecook.com hoặc quét mã QR trên bao bì
                  sản phẩm khuyến mại để vào Website Chương trình.
                </p>
                <p>Hình ảnh Website:</p>
                <p className="text-center">
                  <img src="assets/img/rule/rule2.png" />
                </p>
                <p>
                  Khách hàng điền thông tin tại mục “ĐIỀN THÔNG TIN THAM GIA”
                  với các thông tin bắt buộc gồm: Họ và tên, Số điện thoại, Nhà
                  mạng, Tỉnh/Thành phố và Mã thẻ cào tham gia Chương trình
                  khuyến mại. Sau đó, khách hàng chọn “Thể lệ của chương trình”
                  để xem nội dung Thể lệ, chọn “Chính sách thu thập thông tin cá
                  nhân” để xem nội dung Chính sách thu thập thông tin cá nhân và
                  chọn ô “Tôi đã đọc và đồng ý với Thể lệ của chương trình” và ô
                  “Tôi đã hiểu và đồng ý với Chính sách thu thập thông tin cá
                  nhân khi tham gia chương trình khuyến mại này” và bấm “Vui
                  lòng giữ lại mã thẻ cào để tham gia quay thưởng trúng thêm
                  giải thưởng khác bạn nhé! - THAM GIA NGAY” để Website Chương
                  Trình ghi nhận thông tin tham gia của khách hàng.
                </p>
                <p>Hình ảnh hiển thị dự kiến:</p>
                <p className="text-center">
                  <img src="assets/img/rule/rule3.png" />
                </p>
                <p>Sau đó, khách hàng nhận được phản hồi như sau:</p>
                <ul>
                  <li>
                    <p>
                      Trường hợp mã thẻ cào hợp lệ (mã thuộc Danh sách Mã Thẻ
                      Cào) và thuộc 35.000 mã thẻ cào đầu tiên tham gia Chương
                      Trình hợp lệ: Khách hàng sẽ nhận được thông báo trúng
                      thưởng trên màn hình Website Chương Trình ngay lập tức với
                      nội dung: “Chúc mừng Quý khách trúng thưởng Giải V - Tài
                      khoản điện thoại trị giá 100.000Đ. Giá trị giải thưởng sẽ
                      được tự động nạp vào tài khoản điện thoại trong vòng 24h
                      hoặc 01 mã thẻ nạp điện thoại sẽ được gửi qua tin nhắn đến
                      số điện thoại của Quý khách. Vui lòng giữ lại thẻ cào và
                      theo dõi chương trình để có cơ hội trúng thêm giải thưởng
                      khác. Liên hệ 1900638880 (1.000Đ/phút) để được hỗ trợ và
                      tư vấn.”
                    </p>
                    <p>Hình ảnh hiển thị dự kiến:</p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule4.png" />
                    </p>
                    <p>
                      {" "}
                      Nếu Số Điện Thoại Khách Hàng thuộc loại thuê bao trả sau
                      không đăng ký nạp trực tiếp (loại thuê bao do nhà mạng tự
                      động phân loại trong vòng 24 giờ (trừ trường hợp bất khả
                      kháng) kể từ khi khách hàng nhận được thông báo trúng
                      thưởng trên màn hình website www.khuyenmaiacecook.com,
                      tổng đài có tên định danh Acecook VN gửi tin nhắn đến Số
                      Điện Thoại Khách Hàng với nội dung: “Chuc mung QK trung
                      Giai V. Ma the nap la XXXXYYYYXXXXYYYY. QK vui long giu
                      the cao. Lien he 1900638880 neu can ho tro (1.000D/phut).”
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Trường hợp mã thẻ cào hợp lệ (mã thuộc Danh Sách Mã Thẻ
                      Cào) nhưng không thuộc 35.000 mã thẻ cào đầu tiên tham gia
                      Chương Trình hợp lệ: Website Chương Trình sẽ ngay lập tức
                      hiển thị vòng quay may mắn. Khách hàng quay vòng quay may
                      mắn ngay trên màn hình Website Chương Trình để có cơ hội
                      nhận giải IV là giải tài khoản điện thoại trị giá 150.000
                      VND. Khách hàng bắt buộc quay vòng quay may mắn để có cơ
                      hội trúng giải IV trước 23h59 ngày 11/11/2024.
                    </p>
                    <p>
                      Vòng quay bao gồm 6 ô, có nội dung “TÀI KHOẢN ĐIỆN THOẠI
                      150.000VNĐ” và “CHÚC BẠN MAY MẮN LẦN SAU” xen kẽ nhau với
                      cách thức như hình ảnh bên dưới.
                    </p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule5.png" />
                    </p>
                    <p>
                      Khi khách hàng nhấn vào nút “QUAY NGAY”, vòng quay sẽ tự
                      động quay ngẫu nhiên trong số giải thưởng IV của chương
                      trình khuyến mại tính đến thời điểm khách hàng quay và tự
                      động dừng lại ở một vị trí ngẫu nhiên để xác định giải
                      thưởng mà khách hàng trúng. Khách hàng quay số sẽ trúng
                      thưởng giải IV khi quay vào ô “TÀI KHOẢN ĐIỆN THOẠI
                      150.000VNĐ”, khách hàng không trúng thưởng khi quay vào ô
                      “CHÚC BẠN MAY MẮN LẦN SAU”.
                    </p>
                    <ul>
                      <li>
                        <p>
                          Trường hợp khách hàng quay số trúng thưởng giải IV:
                          Khách hàng sẽ nhận được thông báo trúng thưởng trên
                          màn hình Website Chương Trình ngay lập tức với nội
                          dung: “Chúc mừng Quý khách trúng thưởng Giải IV - Tài
                          khoản điện thoại trị giá 150.000Đ. Giá trị giải thưởng
                          sẽ được tự động nạp vào tài khoản điện thoại trong
                          vòng 24h hoặc 02 mã thẻ nạp điện thoại (mệnh giá
                          100.000Đ và 50.000Đ) sẽ được gửi qua tin nhắn đến số
                          điện thoại của Quý khách. Vui lòng giữ lại thẻ cào và
                          theo dõi chương trình để có cơ hội trúng thêm giải
                          thưởng khác. Liên hệ 1900638880 (1.000Đ/phút) để được
                          hỗ trợ và tư vấn.”
                        </p>
                        <p className="text-center">
                          <img src="assets/img/rule/rule6.png" />
                        </p>
                        <p>
                          Nếu Số Điện Thoại Khách Hàng thuộc loại thuê bao trả
                          sau không đăng ký nạp trực tiếp (loại thuê bao do nhà
                          mạng tự động phân loại trong vòng 24 giờ (trừ trường
                          hợp bất khả kháng) kể từ khi khách hàng nhận được
                          thông báo trúng thưởng trên màn hình Website
                          www.khuyenmaiacecook.com tổng đài có tên định danh
                          Acecook VN - gửi tin nhắn đến Số Điện Thoại Khách Hàng
                          với nội dung: “Chuc mung QK trung Giai IV. Ma the nap
                          la XXXXYYYYXXXXYYYY va XXXXYYYYXXXXYYYY. QK vui long
                          giu the cao. Lien he 1900638880 neu can ho tro (1.000
                          D/phut).”
                        </p>
                      </li>
                      <li>
                        <p>
                          Trường hợp khách hàng quay số không trúng thưởng giải
                          IV: Khách hàng sẽ nhận được thông báo trên màn hình
                          Website Chương Trình ngay lập tức với nội dung: “Chúc
                          Quý khách may mắn lần sau. Vui lòng giữ lại thẻ cào và
                          theo dõi chương trình để có cơ hội trúng thêm giải
                          thưởng khác. Liên hệ 1900638880 (1.000Đ/phút) để được
                          hỗ trợ và tư vấn.”
                        </p>
                        <p className="text-center">
                          <img src="assets/img/rule/rule7.png" />
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      Trường hợp mã thẻ cào không hợp lệ (mã không thuộc Danh
                      sách Mã Thẻ Cào hoặc đã được sử dụng: khách hàng sẽ nhận
                      được thông báo trên màn hình website ngay lập tức với nội
                      dung: “Mã thẻ cào ABCD1234 không hợp lệ hoặc đã được sử
                      dụng. Quý khách tiếp tục săn thẻ cào để có thêm cơ hội may
                      mắn. Liên hệ 1900638880 (1.000Đ/phút) để được hỗ trợ và tư
                      vấn.”
                    </p>
                    <p>Hình ảnh hiển thị dự kiến:</p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule8.png" />
                    </p>
                  </li>
                  <li>
                    Trường hợp nhập sai mã thẻ cào lần thứ 5 liên tiếp trong
                    ngày, số điện thoại tham gia của khách hàng sẽ bị tạm khóa
                    24 giờ tính từ lần nhập sai thứ 5 và Website sẽ hiển thị
                    thông báo trên màn hình: “Quý khách đã nhập sai mã thẻ cào
                    quá 05 lần. Rất tiếc, số điện thoại của Quý khách đang bị
                    tạm khóa. Vui lòng thử lại sau 24h. Liên hệ 1900638880
                    (1.000Đ/phút) để được hỗ trợ và tư vấn.”
                    <p className="text-center">
                      <img src="assets/img/rule/rule9.png" />
                    </p>
                    <p>
                      Trường hợp khách hàng truy cập Website Chương trình trước
                      thời gian bắt đầu chương trình khuyến mại: Website sẽ hiển
                      thị thông báo trên màn hình: “Chương trình "Săn Thẻ Cào
                      Trong Ly, Trúng Quà Mê Ly" sẽ bắt đầu vào 00:00 ngày
                      12/08/2024. Acecook Việt Nam rất mong nhận được sự tham
                      gia nhiệt tình của Quý khách.”
                    </p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule10.png" />
                    </p>
                    <p>
                      Trường hợp khách hàng truy cập Website Chương trình khi đã
                      kết thúc thời gian khuyến mại: Website sẽ hiển thị thông
                      báo trên màn hình: “Chương trình "Săn Thẻ Cào Trong Ly,
                      Trúng Quà Mê Ly" đã kết thúc vào 23:59 ngày 11/11/2024.
                      Chân thành cảm ơn Quý khách đã nhiệt tình tham gia và luôn
                      tin dùng sản phẩm của Acecook Việt Nam.”{" "}
                    </p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule11.png" />
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>
                    Cách 2: Nhắn tin mã thẻ cào đến tổng đài tin nhắn 8066
                  </strong>
                </p>
                <p>
                  Khách hàng nhắn tin mã thẻ cào theo cú pháp “ACECOOK{"<"}Mã số
                  thẻ cào{">"}” tới tổng đài tin nhắn 8066 (1.000 VND/01 tin
                  nhắn). Sau đó, khách hàng nhận được phản hồi như sau:
                </p>
                <ul>
                  <li>
                    Trường hợp mã thẻ cào hợp lệ (mã thuộc Danh Sách Mã Thẻ Cào)
                    và thuộc 35.000 mã thẻ cào đầu tiên tham gia Chương Trình
                    hợp lệ:
                    <ul>
                      <li>
                        Trong vòng 24 giờ (trừ trường hợp bất khả kháng) kể từ
                        khi khách hàng nhập mã thẻ cào, tổng đài tin nhắn 8066
                        phản hồi “Chuc mung QK trung giai V. Giai thuong se duoc
                        nap tu dong vao tai khoan DT cua QK. QK vui long giu the
                        cao. Lien he 1900638880 neu can ho tro (1.000 D/phut).”
                      </li>
                      <li>
                        Nếu Số Điện Thoại Khách Hàng thuộc loại thuê bao trả sau
                        không đăng ký nạp trực tiếp (loại thuê bao do nhà mạng
                        tự động phân loại): trong vòng 24 giờ (trừ trường hợp
                        bất khả kháng) kể từ khi khách hàng nhập mã thẻ cào,
                        tổng đài có tên định danh Acecook VN phản hồi “Chuc mung
                        QK trung Giai V. Ma the nap la XXXXYYYYXXXXYYYY. QK vui
                        long giu the cao. Lien he 1900638880 neu can ho tro
                        (1.000D/phut).”
                      </li>
                      <li>
                        Trường hợp mã thẻ cào hợp lệ (mã thuộc Danh Sách Mã Thẻ
                        Cào) nhưng không thuộc 35.000 mã thẻ cào đầu tiên tham
                        gia Chương Trình hợp lệ: Trong vòng 24 giờ (trừ trường
                        hợp bất khả kháng) kể từ khi khách hàng nhập mã thẻ cào,
                        tổng đài tin nhắn 8066 phản hồi “Rat tiec giai V da het.
                        QK giu the cao va vao khuyenmaiacecook.com tham gia de
                        co co hoi nhan giai thuong khac. Lien he 1900638880 neu
                        can ho tro (1.000D/phut).”. Khách hàng sẽ được quay vòng
                        quay may mắn bằng cách nhấn vào link website chương
                        trình www.khuyenmaiacecook.com” và điền thông tin tại
                        mục “ĐIỀN THÔNG TIN THAM GIA”. Sau khi khách hàng nhập
                        thông tin và mã thẻ cào hợp lệ, Website Chương Trình sẽ
                        ngay lập tức hiển thị vòng quay may mắn, khách hàng quay
                        vòng quay may mắn ngay trên màn hình Website Chương
                        Trình để có cơ hội nhận giải IV là giải tài khoản trị
                        giá 150.000 VND. Khách hàng bắt buộc quay vòng quay may
                        mắn để có cơ hội trúng giải IV trước 23h59 ngày
                        11/11/2024.
                      </li>
                      <li>
                        Phương thức xác định trúng thưởng giải IV được thực hiện
                        như Cách 1 - Nhập mã thẻ cào tại Website Chương Trình.
                      </li>
                      <li>
                        Trường hợp khách hàng quay số trúng thưởng giải IV:
                        Khách hàng sẽ nhận được thông báo trúng thưởng trên màn
                        hình Website Chương Trình ngay lập tức với nội dung:
                        “Chúc mừng Quý khách trúng thưởng Giải IV - Tài khoản
                        điện thoại trị giá 150.000Đ. Giá trị giải thưởng sẽ được
                        tự động nạp vào tài khoản điện thoại trong vòng 24h hoặc
                        02 mã thẻ nạp điện thoại (mệnh giá 100.000Đ VND và
                        50.000Đ) sẽ được gửi qua tin nhắn đến số điện thoại của
                        Quý khách. Vui lòng giữ lại thẻ cào và theo dõi chương
                        trình để có cơ hội trúng thêm giải thưởng khác. Liên hệ
                        1900638880 (1.000Đ/phút) để được hỗ trợ và tư vấn.”
                        <p className="text-center">
                          <img src="assets/img/rule/rule12.png" />
                        </p>
                        <p>
                          Nếu Số Điện Thoại Khách Hàng thuộc loại thuê bao trả
                          sau không đăng ký nạp trực tiếp (loại thuê bao do nhà
                          mạng tự động phân loại), tổng đài có tên định danh
                          Acecook VN gửi tin nhắn đến Số Điện Thoại Khách Hàng
                          với nội dung: “Chuc mung QK trung Giai IV. Ma the nap
                          la XXXXYYYYXXXXYYYY va XXXXYYYYXXXXYYYY. QK vui long
                          giu the cao. Lien he 1900638880 neu can ho tro (1.000
                          D/phut).”
                        </p>
                      </li>
                      <li>
                        Trường hợp khách hàng quay số không trúng thưởng giải
                        IV: Khách hàng sẽ nhận được thông báo trên màn hình
                        Website Chương Trình ngay lập tức với nội dung: “Chúc
                        Quý khách may mắn lần sau. Vui lòng giữ lại thẻ cào và
                        theo dõi chương trình để có cơ hội trúng thêm giải
                        thưởng khác. Liên hệ 1900638880 (1.000Đ/phút) để được hỗ
                        trợ và tư vấn.”
                        <p className="text-center">
                          <img src="assets/img/rule/rule13.png" />
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Trường hợp mã thẻ cào không hợp lệ (mã không thuộc Danh Sách
                    Mã Thẻ Cào) hoặc đã được sử dụng: Trong vòng 01 phút kể từ
                    khi khách hàng gửi tin nhắn, tổng đài tin nhắn 8066 phản hồi
                    “Ma the cao ABCD1234 khong hop le hoac da duoc su dung. QK
                    san them the cao de co them co hoi may man. Lien he
                    1900638880 de duoc ho tro (1.000 D/phut).”
                  </li>
                  <li>
                    Trường hợp nhắn sai cú pháp: tổng đài tin nhắn 8066 phản hồi
                    trong vòng 01 phút kể từ thời điểm khách hàng gửi tin nhắn:
                    “Cu phap khong dung. QK vui long nhap lai theo cu phap
                    "ACECOOK {"<"}Ma the cao{">"}" gui 8066. Lien he 1900638880
                    de duoc ho tro (1.000 D/phut).”
                  </li>
                  <li>
                    Trường hợp nhắn sai mã thẻ cào hợp lệ lần thứ 5 liên tiếp
                    trong ngày, số điện thoại tham gia của khách hàng sẽ bị tạm
                    khóa 24h tính từ lần nhập sai thứ 5, tổng đài tin nhắn 8066
                    sẽ gửi tin nhắn trong vòng 1 phút: “Rat tiec SDT cua QK dang
                    bi tam khoa do nhap sai ma the cao qua 05 lan. Vui long thu
                    lai sau 24h. Lien he 1900638880 de duoc ho tro (1.000
                    D/phut).”
                  </li>
                  <li>
                    Trường hợp nhắn mã thẻ cào trước thời gian bắt đầu chương
                    trình khuyến mại: tổng đài tin nhắn 8066 phản hồi trong vòng
                    01 phút kể từ thời điểm khách hàng nhập mã thẻ cào: “Chuong
                    trinh San The Cao Trong Ly, Trung Qua Me Ly của Acecook
                    Vietnam se bat dau vao 00:00 ngay 12/08/2024. Mong nhan duoc
                    su tham gia nhiet tinh của QK.”
                  </li>
                  <li>
                    Trường hợp nhắn mã thẻ cào khi đã kết thúc thời gian khuyến
                    mại: tổng đài tin nhắn 8066 phản hồi trong vòng 01 phút kể
                    từ thời điểm khách hàng nhập mã thẻ cào: “Chuong trinh San
                    The Cao Trong Ly, Trung Qua Me Ly cua Acecook Viet Nam da
                    ket thuc vao 23:59 ngay 11/11/2024. Cam on QK da tin dung
                    san pham cua Acecook.”
                  </li>
                </ul>
                <p>
                  <strong>
                    Cách 3: Nhập mã thẻ cào tại Zalo OA “Acecook Việt Nam”
                  </strong>
                </p>
                <ul>
                  <li>
                    Khách hàng truy cập vào ứng dụng Zalo trên điện thoại (đăng
                    nhập vào tài khoản nếu khách hàng chưa đăng nhập/ đăng ký
                    nếu khách hàng chưa có tài khoản) rồi truy cập tài khoản
                    Zalo OA “Acecook Việt Nam”. Khách hàng chọn “Tôi muốn tham
                    gia chương trình Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly (nếu
                    chưa hiển thị mục này để chọn thì khách hàng cần bấm vào
                    “Quan tâm” (nếu giao diện Zalo tiếng Anh: “Follow”) hoặc
                    “Nhắn tin” (nếu giao diện Zalo tiếng Anh: “Message”) ở trang
                    chính của Zalo OA “Acecook Việt Nam” để hiển thị).
                    <p>Hình ảnh hiển thị dự kiến:</p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule14.png" />
                    </p>
                  </li>
                  <li>
                    Sau đó, khách hàng chọn “Nhập Mã Thẻ Cào” để tiếp tục.
                    <p>Hình ảnh hiển thị dự kiến:</p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule15.png" />
                    </p>
                  </li>
                  <li>
                    Trường hợp tài khoản Zalo của khách hàng chưa tham gia
                    chương trình khuyến mại của Công ty Cổ phần Acecook Việt Nam
                    trước đó thông qua Zalo OA “Acecook Việt Nam”, khách hàng sẽ
                    nhận được tin nhắn qua Zalo OA nội dung “Quý khách vui lòng
                    click vào banner trên, điền đầy đủ và chính xác thông tin cá
                    nhân. Số điện thoại Quý khách cung cấp sẽ là căn cứ trao
                    thưởng. Vui lòng kiểm tra kĩ thông tin tham gia chương trình
                    để đảm bảo tính chính xác.”, khách hàng nhấn vào banner tin
                    nhắn để điền thông tin (thông tin bao gồm Họ tên, Số điện
                    thoại, Địa chỉ và chọn Tỉnh / thành phố; Quận / huyện).
                    Khách hàng chọn “Đồng ý cung cấp thông tin cho Acecook Việt
                    Nam”, khách hàng chọn “GỬI THÔNG TIN” để hệ thống ghi nhận
                    thông tin tham gia chương trình khuyến mại của khách hàng.
                    Khách hàng cần kiểm tra thông tin số điện thoại đã cung cấp,
                    số điện thoại này phải liên kết với Zalo để nhận được tin
                    nhắn từ chương trình.
                    <p>Hình ảnh hiển thị dự kiến:</p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule16.png" />
                    </p>
                  </li>
                  <li>
                    Trường hợp khách hàng đã tham gia chương trình khuyến mại
                    của Công ty Cổ phần Acecook Việt Nam trước đó thông qua Zalo
                    OA “Acecook Việt Nam” hoặc khách hàng vừa hoàn tất việc điền
                    thông tin để hệ thống ghi nhận thông tin tham gia chương
                    trình khuyến mại thì khách hàng sẽ nhận được hướng dẫn nhập
                    mã thẻ cào:
                    <p>
                      “Quý khách vui lòng nhập mã thẻ cào theo cú pháp:
                      <br />
                      ACECOOK{"<"}Mã thẻ cào{">"}
                      <br />
                      Ví dụ: Cú pháp đúng của mã thẻ cào ABCD1234 là ACECOOK
                      ABCD1234 hoặc ACECOOK”
                      <br />
                      Hình ảnh hiển thị dự kiến:
                    </p>
                    <p className="text-center">
                      <img src="assets/img/rule/rule17.png" />
                    </p>
                  </li>
                  <li>
                    Sau khi nhập mã thẻ cào, khách hàng nhận được phản hồi như
                    sau:
                    <ul>
                      <li>
                        Trường hợp mã thẻ cào hợp lệ (mã thuộc Danh Sách Mã Thẻ
                        Cào) và thuộc 35.000 mã thẻ cào đầu tiên tham gia Chương
                        Trình hợp lệ:
                        <p>
                          Trong vòng 24 giờ (trừ trường hợp bất khả kháng) kể từ
                          khi khách hàng nhập mã thẻ cào, khách hàng sẽ nhận
                          được thông báo trúng thưởng từ Zalo OA “Acecook Việt
                          Nam” với nội dung: “Chúc mừng Quý khách trúng thưởng
                          Giải V - Tài khoản điện thoại trị giá 100.000Đ. Giá
                          trị giải thưởng sẽ được tự động nạp vào tài khoản điện
                          thoại trong vòng 24h hoặc 01 mã thẻ nạp điện thoại sẽ
                          được gửi qua tin nhắn đến số điện thoại của Quý khách.
                          Vui lòng giữ lại thẻ cào và theo dõi chương trình để
                          có cơ hội trúng thêm giải thưởng khác. Liên hệ
                          1900638880 (1.000Đ/phút) để được hỗ trợ và tư vấn.”
                        </p>
                        <p>Hình ảnh hiển thị dự kiến:</p>
                        <p className="text-center">
                          <img src="assets/img/rule/rule18.png" />
                        </p>
                        <p>
                          Nếu Số Điện Thoại Khách Hàng thuộc loại thuê bao trả
                          sau không đăng ký nạp trực tiếp (loại thuê bao do nhà
                          mạng tự động phân loại): trong vòng 24 giờ (trừ trường
                          hợp bất khả kháng) kể từ khi khách hàng nhập mã thẻ
                          cào, tổng đài có tên định danh Acecook VN gửi tin nhắn
                          đến Số Điện Thoại Khách Hàng với nội dung: “Chuc mung
                          QK trung Giai V. Ma the nap la XXXXYYYYXXXXYYYY. QK
                          vui long giu the cao. Lien he 1900638880 neu can ho
                          tro (1.000D/phut).”
                        </p>
                      </li>
                      <li>
                        Trường hợp mã thẻ cào hợp lệ (mã thuộc Danh Sách Mã Thẻ
                        Cào) nhưng không thuộc 35.000 mã thẻ cào đầu tiên tham
                        gia Chương Trình hợp lệ: trong vòng 24 giờ (trừ trường
                        hợp bất khả kháng) kể từ khi khách hàng nhập mã thẻ cào,
                        khách hàng sẽ nhận được thông báo từ Zalo OA “Acecook
                        Việt Nam” với nội dung: “Quý khách đã nhập mã thẻ cào
                        thành công. Rất tiếc, hiện tại Giải V - Tài khoản điện
                        thoại trị giá 100.000Đ đã hết. Vui lòng giữ lại thẻ cào
                        và truy cập website www.khuyenmaiacecook.com để tiếp tục
                        tham gia và có cơ hội nhận giải IV - Tài khoản điện
                        thoại 150.000Đ và các giải thưởng hấp dẫn khác. Liên hệ
                        1900638880 (1.000Đ/phút) để được hỗ trợ và tư vấn.”.
                        <p className="text-center">
                          <img src="assets/img/rule/rule19.png" />
                        </p>
                      </li>
                      <li>
                        Khách hàng sẽ được quay vòng quay may mắn bằng cách nhấn
                        vào link website chương trình www.khuyenmaiacecook.com”
                        và điền thông tin tại mục “ĐIỀN THÔNG TIN THAM GIA”. Sau
                        khi khách hàng nhập thông tin và mã thẻ cào hợp lệ,
                        Website Chương Trình sẽ ngay lập tức hiển thị vòng quay
                        may mắn, khách hàng quay vòng quay may mắn ngay trên màn
                        hình Website Chương Trình để có cơ hội nhận giải IV là
                        giải tài khoản điện thoại trị giá 150.000 VND. Khách
                        hàng bắt buộc quay vòng quay may mắn để có cơ hội trúng
                        giải IV trước 23h59 ngày 11/11/2024. Phương thức xác
                        định trúng thưởng giải IV được thực hiện như Cách 1 -
                        Nhập mã thẻ cào tại Website Chương Trình.
                      </li>
                      <li>
                        Trường hợp khách hàng quay số trúng thưởng giải IV:
                        Khách hàng sẽ nhận được thông báo trúng thưởng trên màn
                        hình Website Chương Trình ngay lập tức với nội dung:
                        “Chúc mừng Quý khách trúng thưởng Giải IV - Tài khoản
                        điện thoại trị giá 150.000Đ. Giá trị giải thưởng sẽ được
                        tự động nạp vào tài khoản điện thoại trong vòng 24h hoặc
                        02 mã thẻ nạp điện thoại (mệnh giá 100.000Đ VND và
                        50.000Đ) sẽ được gửi qua tin nhắn đến số điện thoại của
                        Quý khách. Vui lòng giữ lại thẻ cào và theo dõi chương
                        trình để có cơ hội trúng thêm giải thưởng khác. Liên hệ
                        1900638880 (1.000Đ/phút) để được hỗ trợ và tư vấn.”
                        <p className="text-center">
                          <img src="assets/img/rule/rule20.png" />
                        </p>
                        Nếu Số Điện Thoại Khách Hàng thuộc loại thuê bao trả sau
                        không đăng ký nạp trực tiếp (loại thuê bao do nhà mạng
                        tự động phân loại), tổng đài có tên định danh Acecook VN
                        gửi tin nhắn đến Số Điện Thoại Khách Hàng với nội dung:
                        “Chuc mung QK trung Giai IV. Ma the nap la
                        XXXXYYYYXXXXYYYY va XXXXYYYYXXXXYYYY. QK vui long giu
                        the cao. Lien he 1900638880 neu can ho tro (1.000
                        D/phut).”
                      </li>
                      <li>
                        Trường hợp khách hàng quay số không trúng thưởng giải
                        IV: Khách hàng sẽ nhận được thông báo trên màn hình
                        Website Chương Trình ngay lập tức với nội dung: “Chúc
                        Quý khách may mắn lần sau. Vui lòng giữ lại thẻ cào và
                        theo dõi chương trình để có cơ hội trúng thêm giải
                        thưởng khác. Liên hệ 1900638880 (1.000Đ/phút) để được hỗ
                        trợ và tư vấn.”
                        <p className="text-center">
                          <img src="assets/img/rule/rule21.png" />
                        </p>
                      </li>
                      <li>
                        Trường hợp mã thẻ cào không hợp lệ (mã không thuộc Danh
                        Sách Mã Thẻ Cào) hoặc đã được sử dụng: khách hàng sẽ
                        nhận được thông báo từ Zalo OA “Acecook Việt Nam” ngay
                        lập tức với nội dung: “Mã thẻ cào ABCD1234 không hợp lệ
                        hoặc đã được sử dụng. Quý khách tiếp tục săn thẻ cào và
                        truy cập website www.khuyenmaiacecook.com để có thêm cơ
                        hội may mắn. Liên hệ 1900638880 (1.000Đ/phút) để được hỗ
                        trợ và tư vấn.”
                        <p>Hình ảnh hiển thị dự kiến:</p>
                        <p className="text-center">
                          <img src="assets/img/rule/rule22.png" />
                        </p>
                        <p>
                          Trường hợp nhập sai cú pháp: khách hàng sẽ nhận được
                          thông báo từ Zalo OA “Acecook Việt Nam” ngay lập tức
                          với nội dung:
                          <br />
                          “Cú pháp của Quý khách vừa gửi không chính xác, vui
                          lòng nhập lại theo cú pháp: ACECOOK {"<"}Mã thẻ cào
                          {">"}
                          <br />
                          Ví dụ: Cú pháp đúng của mã thẻ cào ABCD1234 là ACECOOK
                          ABCD1234 hoặc ACECOOK{"<"}ABCD1234{">"}”
                          <br />
                          Hình ảnh hiển thị dự kiến:
                        </p>
                        <p className="text-center">
                          <img src="assets/img/rule/rule23.png" />
                        </p>
                        <p>
                          Khách hàng chỉ được tham gia xác định trúng thưởng khi
                          thực hiện thao tác nhập mã thẻ cào theo hướng dẫn nêu
                          trên trong Thời gian khuyến mại quy định tại Mục 3 Thể
                          lệ (từ 00:00 ngày 12/08/2024 đến 23:59 ngày
                          11/11/2024). Trước và sau thời gian nói trên, tất cả
                          những mã thẻ cào và việc nhập mã thẻ cào theo hướng
                          dẫn sẽ không có giá trị sử dụng.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  <strong>8.4.4.</strong> Tất cả các khách hàng đã nhập mã thẻ
                  cào đúng quy định để tham gia Chương trình theo quy định sẽ có
                  cơ hội trúng Giải I, Giải II hoặc Giải III bằng hình thức quay
                  số trúng thưởng để xác định trúng thưởng theo quy định của Thể
                  lệ
                </p>
                <p>
                  {" "}
                  <strong>8.4.5.</strong> Khách hàng được xác định trúng thưởng
                  như sau:
                </p>
                <ul>
                  <li>
                    Đối với Giải V: Khách hàng nhập mã thẻ cào đúng quy định và
                    thuộc 35.000 mã thẻ cào đầu tiên tham gia Chương Trình hợp
                    lệ sẽ chắc chắn trúng thưởng giải V.
                  </li>
                  <li>
                    Đối với Giải IV: Khách hàng nhập mã thẻ cào đúng quy định
                    nhưng không thuộc 35.000 mã thẻ cào đầu tiên tham gia Chương
                    Trình hợp lệ sẽ tiến hành quay vòng quay trên Website Chương
                    trình để có cơ hội trúng giải IV.
                  </li>
                  <li>
                    Đối với Giải I, II, III: Tất cả khách hàng nhập mã thẻ cào
                    đúng quy định (kể cả khách hàng đã trúng Giải IV và Giải V)
                    sẽ được tham gia xác định trúng thưởng để có cơ hội trúng
                    thưởng Giải I, Giải II hoặc Giải III thông qua quay số trúng
                    thưởng Cụ thể việc xác định trúng thưởng được thực hiện như
                    sau:
                    <ul>
                      <li>
                        <strong>Thời gian xác định trúng thưởng:</strong> có 06
                        (sáu) đợt xác định trúng thưởng, đợt tham gia xác định
                        trúng thưởng của khách hàng phụ thuộc vào thời gian
                        khách hàng nhập mã thẻ cào, cụ thể như sau:
                        <p className="text-center">
                          <img src="assets/img/rule/rule25.png" />
                        </p>
                        <ol>
                          <li>
                            Trường hợp số lượng khách hàng được tham gia xác
                            định trúng thưởng của đợt đó ít hơn tổng số lượng
                            giải thưởng sẽ xác định trúng thưởng của đợt đó, số
                            lượng giải thưởng chưa xác định được người trúng
                            thưởng của đợt đó sẽ được xem là giải thưởng chưa
                            xác định được người trúng thưởng và sẽ được cộng vào
                            số lượng giải thưởng của đợt xác định trúng thưởng
                            cuối cùng.
                          </li>
                          <li>
                            Mã thẻ cào của khách hàng đã trúng thưởng Giải I
                            hoặc Giải II hoặc Giải III sẽ không được tiếp tục sử
                            dụng để tham gia xác định trúng thưởng vào các đợt
                            sau đó. Giải thưởng không có giá trị quy đổi thành
                            tiền mặt.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b> Địa điểm xác định trúng thưởng:</b>
                        <ol>
                          <li>
                            Địa điểm đợt 1,4,5,6: Văn phòng Công ty Cổ phần
                            Acecook Việt Nam, Lô số II-3, đường số 11, nhóm CN
                            II, Khu Công Nghiệp Tân Bình, Phường Tây Thạnh, Quận
                            Tân Phú, Thành phố Hồ Chí Minh
                          </li>
                          <li>
                            Địa điểm đợt 2: Aeon Mall Tân Phú 30 Đ. Tân Thắng,
                            Sơn Kỳ, Tân Phú, Hồ Chí Minh
                          </li>
                          <li>
                            Địa điểm đợt 3: Aeon Mall Long Biên - 27 Đ. Cổ Linh,
                            Long Biên, Hà Nội
                          </li>
                        </ol>
                      </li>
                      <li>
                        <p>
                          <b>Cách thức xác định trúng thưởng:</b> Với mỗi đợt
                          xác định trúng thưởng, việc tổ chức xác định trúng
                          thưởng sẽ được thực hiện như sau:
                        </p>
                        <p>
                          {" "}
                          Đại điện Công ty Cổ phần Acecook Việt Nam sẽ lên bấm
                          ngẫu nhiên vào phần mềm quay số trúng thưởng (phần mềm
                          kỹ thuật số ngẫu nhiên) để xác định các khách hàng
                          trúng thưởng theo thứ tự Giải III, Giải II hoặc Giải I
                          trong số các khách hàng có mã thẻ cào thuộc Danh sách
                          mã thẻ cào tham gia xác định trúng thưởng của đợt xác
                          định trúng thưởng đó Khách hàng có thể theo dõi kết
                          quả xác định trúng thưởng đối với Giải I, Giải II và
                          Giải III trên Website Chương Trình
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  <strong>8.5. Thông báo trúng thưởng: </strong>
                </p>
                <p>
                  <strong>8.5.1.Đối với Giải IV và Giải V: </strong> Khách hàng
                  sẽ nhận được thông báo trúng thưởng như quy định tại Mục 8.4.3
                  Thể lệ.
                </p>
                <p>
                  <strong>8.5.2. </strong>Đối với Giải I, Giải II và Giải III:
                </p>
                <ul>
                  <li>
                    {" "}
                    Trong vòng 24h kể từ khi có kết quả xác định trúng thưởng,
                    Tổng đài có tên định danh Acecook VN sẽ gửi tin nhắn đến số
                    điện thoại của Khách hàng (Số điện thoại được khách hàng
                    cung cấp được gọi chung là “Số Điện Thoại Khách Hàng”) để
                    thông báo trúng thưởng với nội dung
                    <ul>
                      <li>
                        Đối với Giải III: “Chuc mung QK trung Giai III. Acecook
                        se lien he QK de trao giai thuong. Vui long giu the cao
                        va tin nhan nay. Lien he 1900638880 neu can ho tro
                        (1.000 D/phut).”
                      </li>
                      <li>
                        Đối với Giải II: “Chuc mung QK trung Giai II. Acecook se
                        lien he QK de trao giai thuong. Vui long giu the cao va
                        tin nhan nay. Lien he 1900638880 neu can ho tro (1.000
                        D/phut).”.
                      </li>
                      <li>
                        Đối với Giải I: “Chuc mung QK trung Giai I. Acecook se
                        lien he QK de trao giai thuong. Vui long giu the cao va
                        tin nhan nay. Lien he 1900638880 neu can ho tro (1.000
                        D/phut).”.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Trong vòng 05 ngày kể từ ngày có kết quả xác định trúng
                    thưởng, tổng đài 02873089880 sẽ gọi điện thoại đến Số Điện
                    Thoại Khách Hàng để thông báo trúng thưởng và ghi nhận thông
                    tin nhận thưởng, gồm: họ tên, địa chỉ, số CMND/CCCD/Hộ chiếu
                    hoặc giấy tờ pháp lý tương đương có tính định danh khác như
                    Giấy phép lái xe, Giấy khai sinh và ngày cấp, nơi cấp, số
                    điện thoại, email (nếu có), nơi trao thưởng và đặt lịch hẹn
                    để trao thưởng tại địa phương nơi khách hàng trúng thưởng
                    sinh sống/làm việc. Trường hợp chưa liên hệ được với khách
                    hàng, tổng đài 02873089880 sẽ gọi lại 05 lần vào khung giờ
                    hoạt động của tổng đài trong vòng 05 ngày sau đó, mỗi ngày
                    01 lần.
                  </li>
                </ul>
                <p>
                  <strong>
                    8.6. Thời gian, địa điểm, cách thức và thủ tục trao thưởng:
                  </strong>
                </p>
                <p>
                  {" "}
                  <strong> 8.6.1 Đối với Giải IV và Giải V:</strong>trong vòng
                  24 giờ kể từ khi nhận được thông báo trúng thưởng của chương
                  trình:
                </p>
                <ul>
                  <li>
                    Đối với thuê bao trả trước và thuê bao trả sau có đăng ký
                    nạp trực tiếp (loại thuê bao do nhà mạng tự động phân loại):
                    Khách hàng sẽ được nạp trực tiếp toàn bộ giá trị giải thưởng
                    vào Số Điện Thoại Khách Hàng.
                  </li>
                  <li>
                    Đối với thuê bao trả sau không đăng ký nạp trực tiếp (loại
                    thuê bao do nhà mạng tự động phân loại): Tổng đài có tên
                    định danh Acecook VN sẽ nhắn tin mã nạp tiền điện thoại đến
                    Số Điện Thoại Khách Hàng với mã nạp tiền điện thoại thuộc
                    nhà mạng tương ứng với nhà mạng của Số Điện Thoại Khách Hàng
                    với nội dung: Chuc mung QK trung Giai V. Ma the nap la
                    XXXXYYYYXXXXYYYY. QK vui long giu the cao. Lien he
                    1900638880 neu can ho tro (1.000 D/phut).” Hoặc “Chuc mung
                    QK trung Giai IV. Ma the nap la XXXXYYYYXXXXYYYY va
                    XXXXYYYYXXXXYYYY. QK vui long giu the cao. Lien he
                    1900638880 neu can ho tro (1.000 D/phut)”.
                  </li>
                </ul>
                <p>
                  {" "}
                  <strong>
                    8.6.2. Đối với Giải I, Giải II và Giải III:
                  </strong>{" "}
                  Tại thời điểm trao thưởng đã hẹn trước với khách hàng tại địa
                  phương nơi khách hàng sinh sống/ làm việc khi tổng đài
                  02873089880 liên hệ để thông báo trúng thưởng, khách hàng cần
                  xuất trình: (a) giấy tờ tùy thân hợp lệ (CMND/ CCCD/ Hộ chiếu
                  hoặc giấy tờ pháp lý tương đương có tính định danh khác như
                  Giấy phép lái xe, Giấy khai sinh) và (b) thẻ cào trúng thưởng
                  hợp lệ và tin nhắn thông báo trúng thưởng Giải I, Giải II hoặc
                  Giải III từ tổng đài có tên định danh Acecook VN gửi đến Số
                  Điện Thoại Khách Hàng cho nhân viên của Bên trao giải để tiến
                  hành kiểm tra đối chiếu, ký Biên bản trao nhận giải thưởng và
                  nhận giải thưởng.
                </p>
                <ul>
                  <li>
                    Trường hợp khách hàng chỉ xuất trình được giấy tờ tùy thân
                    hợp lệ và thẻ cào trúng thưởng hợp lệ: Bên trao giải tiến
                    hành kiểm tra đối chiếu, khách hàng ký Biên bản xác nhận mất
                    tin nhắn thông báo trúng thưởng và Biên bản trao nhận giải
                    thưởng và nhận giải thưởng.
                  </li>
                  <li>
                    Trường hợp khách hàng chỉ xuất trình được giấy tờ tùy thân
                    hợp lệ và tin nhắn thông báo trúng thưởng Giải I, Giải II
                    hoặc Giải III từ tổng đài có tên định danh Acecook VN gửi
                    đến Số Điện Thoại Khách Hàng: Bên trao giải tiến hành kiểm
                    tra đối chiếu, khách hàng ký Biên bản xác nhận mất thẻ cào
                    và Biên bản trao nhận giải thưởng và nhận giải thưởng.
                  </li>
                </ul>
                <p>
                  Ban tổ chức sẽ xác định phần thưởng là giải thưởng không có
                  người trúng thưởng để nộp Ngân sách Nhà nước theo quy định
                  pháp luật hiện hành trong trường hợp hết thời hạn trao thưởng
                  nhưng:
                  <br />
                  Không xác định được người trúng thưởng;
                  <br />
                  hoặc
                  <br />
                  Khách hàng không nhận thưởng.
                  <br />
                  <br />
                  <strong>
                    Khách hàng không nhận thưởng thuộc những trường hợp sau:
                  </strong>{" "}
                  Hết thời hạn trao thưởng nhưng không liên hệ được với khách
                  hàng trúng thưởng; khách hàng trúng thưởng không cung cấp
                  thông tin hoặc cung cấp không chính xác theo quy định; khách
                  hàng trúng thưởng không đáp ứng điều kiện quy định về việc
                  nhận thưởng đối với Giải I, Giải II và Giải III như không xuất
                  trình: giấy tờ tùy thân hợp lệ hoặc thẻ cào trúng thưởng hợp
                  lệ và tin nhắn thông báo trúng thưởng Giải I, Giải II hoặc
                  Giải III từ tổng đài có tên định danh Acecook VN gửi đến Số
                  Điện Thoại Khách Hàng (trừ trường hợp quy định tại Điều
                  8.6.2.a hoặc 8.6.2.b Thể lệ); khách hàng trúng thưởng không ký
                  tên vào Biên bản trao nhận giải thưởng hoặc Biên bản xác nhận
                  mất thẻ cào hoặc Biên bản xác nhận mất tin nhắn thông báo
                  trúng thưởng theo quy định; khách hàng trúng thưởng từ chối
                  nhận thưởng.
                  <br />
                  Trong vòng 05 ngày kể từ ngày khách hàng không nhận thưởng
                  Giải I hoặc Giải II hoặc Giải III, tổng đài có tên định danh
                  Acecook VN sẽ nhắn tin thông báo đến Số Điện Thoại Khách Hàng
                  với nội dung: “Do khong thuc hien day du cac buoc theo the le
                  de nhan giai thuong, Acecook se huy giai thuong cua QK. Lien
                  he 1900638880 neu can ho tro (1.000 D/phut).”.
                  <br />
                  Việc xác định tổng giá trị giải thưởng không có người trúng
                  thưởng làm cơ sở nộp tiền mặt vào ngân sách nhà nước dựa trên
                  báo cáo tổng kết tổng giá trị giải thưởng đã nạp và đã trao
                  nhận cho khách hàng theo các cách thức và quy định nêu trên.
                  <br />
                  Thời hạn cuối cùng trao giải thưởng cho khách hàng trúng
                  thưởng là 45 ngày kể từ ngày kết thúc thời gian khuyến mại.
                  <br />
                  Chi phí trao thưởng sẽ do Công ty TNHH Hakuhodo & Quảng cáo
                  Sài Gòn chịu trách nhiệm thay Công ty Cổ phần Acecook Việt Nam
                  thanh toán.
                  <br />
                  Công ty Cổ phần Acecook Việt Nam sẽ chi trả và kê khai phần
                  thuế thu nhập cá nhân không thường xuyên thay cho khách hàng
                  trúng thưởng (nếu có).
                </p>
                <p>
                  <strong>
                    9. Quy định về đầu mối giải đáp thắc mắc liên quan đến
                    chương trình khuyến mại:
                  </strong>
                  <br />
                  Mọi thắc mắc liên quan đến chương trình khuyến mại này, khách
                  hàng liên hệ đến Hotline 1900638880 (1.000 đồng/phút) để được
                  hướng dẫn, giải đáp. Hotline hoạt động từ 08:00 đến 20:00 hằng
                  ngày (kể cả ngày lễ, thứ Bảy, Chủ Nhật) bắt đầu từ ngày
                  12/08/2024 đến ngày 20/11/2024.
                </p>
                <p>
                  <strong>10. Trách nhiệm công bố thông tin:</strong>
                  <br />
                  Công ty Cổ phần Acecook Việt Nam thông báo công khai chi tiết
                  nội dung của Thể lệ chương trình khuyến mại trên website
                  www.KhuyenmaiAcecook.com và tại các đại lý, nhà phân phối hàng
                  hóa khuyến mại trên toàn quốc.
                  <br />
                  Công ty Cổ phần Acecook Việt Nam có trách nhiệm công bố kết
                  quả trúng thưởng trên website www.KhuyenmaiAcecook.com
                </p>
                <p>
                  <strong>11. Các quy định khác:</strong>
                  <br />
                  Công ty Cổ phần Acecook Việt Nam chịu toàn bộ trách nhiệm
                  trong việc quản lý khâu in ấn bằng chứng xác định trúng
                  thưởng, tính chính xác của bằng chứng xác định trúng thưởng và
                  danh sách mã thẻ cào
                  <br />
                  Công ty Cổ phần Acecook Việt Nam chịu toàn bộ trách nhiệm
                  trong việc phát hành bằng chứng xác định trúng thưởng kèm theo
                  hàng hóa khuyến mại.
                  <br />
                  Việc tổ chức chương trình khuyến mại phải đảm bảo tính công
                  bằng, minh bạch, khách quan.
                  <br />
                  Nếu khách hàng trúng thưởng đồng ý cho phép sử dụng tên và
                  hình ảnh cá nhân thì Công ty Cổ phần Acecook Việt Nam được sử
                  dụng tên và hình ảnh của khách hàng trúng thưởng cho mục đích
                  quảng cáo thương mại.
                  <br />
                  Công ty Cổ phần Acecook Việt Nam, Công ty TNHH Hakuhodo &
                  Quảng cáo Sài Gòn, Công ty Cổ phần Công nghệ UTOP, Công ty
                  TNHH Nguyễn Quang Huy không chịu trách nhiệm đối với bất cứ mã
                  trúng thưởng nào được liên hệ ngoài thời gian khuyến mại.
                  <br />
                  Trong trường hợp xảy ra tranh chấp liên quan đến chương trình
                  khuyến mại này, Công ty Cổ phần Acecook Việt Nam, Công ty TNHH
                  Hakuhodo & Quảng cáo Sài Gòn, Công ty Cổ phần Công nghệ UTOP,
                  Công ty TNHH Nguyễn Quang Huy có trách nhiệm liên đới trực
                  tiếp giải quyết, nếu không thỏa thuận được, tranh chấp sẽ được
                  xử lý theo quy định của pháp luật.
                  <br />
                  Đối với những giải thưởng không có người trúng thưởng, Công ty
                  Cổ phần Acecook Việt Nam phải có trách nhiệm trích nộp bằng
                  tiền mặt 50% giá trị đã công bố của giải thưởng đó vào ngân
                  sách nhà nước theo quy định tại Điều 96.4 Luật Thương mại.
                </p>
              </section>
            </TabPanel>
            <TabPanel>
              <section>
                <p>
                  <strong>
                    1. Tên chương trình: Hát cùng thần tượng, cơ hội trúng mic
                    vàng
                  </strong>
                </p>
                <h3>2. Thời gian tham gia:</h3>
                <p>Từ 00h00 ngày 12/08/2024 đến 23h59 ngày 11/11/2024</p>

                <h3>3. Đối tượng tham gia:</h3>
                <p>
                  Công dân Việt Nam hợp pháp đang sinh sống tại Việt Nam độ tuổi
                  từ 18 tuổi trở lên.
                </p>

                <h3>4. Cách thức tham gia:</h3>
                <p>
                  Người tham gia có 2 cách thực hiện để tạo và gửi bài dự thi:
                </p>

                <h4>
                  Cách 1: Tham gia thông qua Website chương trình
                  www.KhuyenmaiAcecook.com
                </h4>
                <p>
                  <b>Bước 1: </b>Truy cập Website www.KhuyenmaiAcecook.com chọn
                  “Tham gia Duet” -{">"} chọn “Tham gia ngay”. Sau đó chọn 1
                  trong 3 ca sĩ bạn yêu thích để cùng song ca:
                  <ul>
                    <li>Sơn Tùng M-TP (02 đoạn nhạc)</li>
                    <li>Mỹ Mỹ (02 đoạn nhạc)</li>
                    <li>Anh Tú (01 đoạn nhạc)</li>
                  </ul>
                </p>
                <p>
                  <b>Bước 2: </b> Chọn “Bài hát” và chọn “Quay ngay” và cùng hát
                  đoạn nhạc với ca sĩ đã chọn. Sau khi quay xong thì chọn “Lưu
                  bài”.
                </p>
                <p>
                  <b>Bước 3: </b> Sau khi đợi hệ thống xử lý video xong thì chọn
                  “Đăng bài”. Bài dự thi sẽ được Ban tổ chức kiểm duyệt theo quy
                  định và sẽ duyệt bài thi nếu đúng quy định hợp lệ.
                </p>
                <p>
                  <b>Bước 4: </b> (Không bắt buộc thực hiện): Người chơi có thể
                  chia sẻ video lên các nền tảng mạng xã hội (Facebook, Tiktok)
                  để được cộng thêm điểm nếu muốn bằng cách tải lưu video về và
                  đăng video lên các nền tảng mạng xã hội ở chế độ công khai kèm
                  hashtag #HoaNhacMiLy #TrungNgayMicVang. Trong trường hợp này
                  người chơi cần thực hiện liên kết tài khoản Facebook/Tiktok cá
                  nhân với trang website chương trình và gắn đường link video đã
                  đăng trên các nền tảng mạng xã hội (Facebook/Tiktok) vào trang
                  website chương trình www.KhuyenmaiAcecook.com để được cộng
                  thêm điểm từ lượt like/share.
                </p>

                <h4>Cách 2: Tham gia thông qua Tiktok “Acecook Vietnam”</h4>

                <p>
                  <b>Bước 1: </b>Truy cập trang Tiktok “Acecook Vietnam”{" "}
                  <a
                    href="https://www.tiktok.com/@acecook.vietnam"
                    target="_blank"
                  >
                    https://www.tiktok.com/@acecook.vietnam
                  </a>
                  . Chọn video tiktok tương ứng với đoạn nhạc của ca sĩ mà mình
                  muốn song ca:
                  <ul>
                    <li>Sơn Tùng M-TP (02 đoạn nhạc)</li>
                    <li>Mỹ Mỹ (02 đoạn nhạc)</li>
                    <li>Anh Tú (01 đoạn nhạc)</li>
                  </ul>
                </p>
                <p>
                  <b>Bước 2: </b>Bấm nút “Chia sẻ” chọn tính năng “Duet”.
                </p>
                <p>
                  <b>Bước 3: </b>Hoàn tất video và đăng tải bài thi lên trang
                  Tiktok Cá Nhân ở chế độ công khai kèm hashtag #HoaNhacMiLy
                  #TrungNgayMicVang.
                </p>
                <p>
                  <b>Bước 4: </b>Liên kết tài khoản Tiktok cá nhân với website
                  chương trình (bằng cách vào website www.KhuyenmaiAcecook.com -
                  {">"} vào mục “Tham gia Duet” -{">"} chọn nút “tham gia ngay”
                  -{">"} chọn ca sĩ -{">"}
                  chọn nút “Quay trên TikTok” -{">"} chọn “Liên kết”).
                </p>
                <p>
                  <b>Bước 5: </b> Gắn đường link video Tiktok vào website để tạo
                  bài dự thi (bằng cách chọn nút “Quay trên TikTok” trên website
                  chương trình -{">"} nhập đường link video TikTok vào ô).
                </p>
                <p>
                  <b>Bước 6: </b> Chọn "Đăng bài" để nộp bài dự thi và chờ BTC
                  duyệt bài dự thi. Bài dự thi gốc trên Tiktok sẽ được đăng kí
                  để cộng điểm từ lượt like/share.
                </p>
                <p>
                  <b>Bước 7: </b>(Không bắt buộc thực hiện): Người chơi có thể
                  chia sẻ bài dự thi video lên thêm trên nền tảng mạng xã hội
                  khác (Facebook - ở chế độ công khai) để được cộng thêm điểm
                  nếu muốn. Trong trường hợp này người chơi cần liên kết tài
                  khoản Facebook cá nhân với trang website chương trình
                  www.KhuyenmaiAcecook.com và gắn đường link video đã đăng trên
                  Facebook để được BTC tính điểm cộng thêm hợp lệ.
                </p>

                <p>
                  Đặc biệt ngoài ra người chơi có thể được cộng điểm cho bài dự
                  thi của mình bằng cách:
                </p>
                <ul>
                  <li>
                    Mua bất kỳ sản phẩm mì ly Modern/ Handy Hảo Hảo/ Caykay/ Tô
                    Nhớ Mãi Mãi tại các siêu thị hoặc cửa hàng tiện lợi trên
                    toàn quốc có tên trong danh sách hệ thống áp dụng trong thời
                    gian diễn ra chương trình.
                  </li>
                  <li>
                    Đăng tải ảnh chụp/ scan hoá đơn mua hàng trên website chương
                    trình www.KhuyenmaiAcecook.com để nhận thêm điểm.
                  </li>
                </ul>

                <h4>Cách tính điểm:</h4>
                <p>
                  Với mỗi giá trị 500 đồng (Bằng chữ: Năm trăm đồng) trên hóa
                  đơn khi mua bất kỳ sản phẩm mì ly Modern/ Handy Hảo Hảo/
                  Caykay/ Tô Nhớ Mãi Mãi (không tính sản phẩm khác) sẽ được quy
                  đổi thành 2 điểm. Càng nhiều số lượng hoá đơn mua sản phẩm mì
                  ly của Acecook hoặc hóa đơn có giá trị mua sản phẩm mì ly
                  Acecook càng cao được đăng tải hơp lệ trên Website chương
                  trình www.KhuyenmaiAcecook.com thì người tham gia sẽ được quy
                  đổi ra càng nhiều điểm bình chọn.
                </p>

                <h4>
                  Danh sách tên các hệ thống siêu thị và cửa hàng tiện lợi có áp
                  dụng gồm:
                </h4>
                <ul>
                  <li>BIG C</li>
                  <li>Coop Mart</li>
                  <li>Coop Xtra</li>
                  <li>Coop Foods</li>
                  <li>Lotte</li>
                  <li>Aeon mall</li>
                  <li>Emart</li>
                  <li>Satra Mart</li>
                  <li>Satra Foods</li>
                  <li>MM Mega</li>
                  <li>Ministop</li>
                  <li>7eleven</li>
                  <li>Bách Hóa Xanh</li>
                  <li>Family mart</li>
                  <li>Circle K</li>
                  <li>GS25</li>
                  <li>BS-Mart</li>
                  <li>WinMart</li>
                  <li>Lanchi Mart</li>
                </ul>

                <h3>* Cách thức bình chọn cho bài dự thi</h3>
                <p>Có 2 cách:</p>
                <h4>Cách 1:</h4>
                <p>
                  Tương tác trực tiếp trên bài dự thi ở các kênh mạng xã hội
                  (Facebook, Tiktok)
                </p>
                <ul>
                  <li>
                    Bấm vào link bài dự thi và bình chọn bằng cách bấm vào icon
                    Thả tim hoặc Share trên Tiktok hoặc tương tác Like/Thả tim/
                    Haha/ Share trên Facebook
                  </li>
                </ul>

                <h4>Cách 2:</h4>
                <p>
                  Vào website của chương trình tại www.KhuyenmaiAcecook.com đăng
                  ký bằng số điện thoại và xác nhận mã OTP. Vào bài dự thi mà
                  bạn muốn bình chọn thông qua tìm kiếm trên trang chủ “Hát cùng
                  thần tượng” hoặc thông qua đường link chia sẻ của bạn bè chọn
                  bài dự thi muốn Bình chọn và bình chọn bằng cách bấm vào icon
                  Like.
                </p>
              </section>

              <section>
                <h3>5. Cơ cấu giải thưởng</h3>
                <ul>
                  <li>
                    01 Giải Đặc Biệt 1: là 01 microphone bằng vàng 24K từ thương
                    hiệu PNJ với trọng lượng 8 chỉ vàng trị giá 70 triệu đồng{" "}
                    dành cho bài dự thi hợp lệ và có tổng điểm bình chọn cao
                    nhất ở cả 3 nền tảng Website/Facebook/Tiktok. Giải thưởng
                    không quy đổi thành tiền mặt.
                  </li>
                  <li>
                    01 Giải Đặc Biệt 2: là 01 microphone bằng vàng 24K từ thương
                    hiệu PNJ với trọng lượng 8 chỉ vàng trị giá 70 triệu đồng{" "}
                    dành cho bài dự thi hợp lệ và có điểm bình chọn cao nhất ở 1
                    nền tảng Website chương trình. Giải thưởng không quy đổi
                    thành tiền mặt.
                  </li>
                </ul>
                <p>
                  (Tổng số điểm bình chọn tính trên 1 nền tảng Website sẽ gồm:
                  số lượt Like của video bài dự thi + số điểm được quy đổi từ
                  hóa đơn mua hàng)
                </p>
                <h3>6. Cách thức chấm giải:</h3>
                <p>Bài thi hợp lệ phải đáp ứng các yêu cầu sau:</p>
                <ul>
                  <li>
                    Video phải được chia sẻ ở chế độ công khai trên các nền tảng
                    mạng xã hội.
                  </li>
                  <li>
                    Video và Link Video dự thi đã chia sẻ cần được đăng tải lên
                    website chương trình www.KhuyenmaiAcecook.com BTC chỉ tính
                    điểm trên các bài dự thi được nhập link lên website chương
                    trình.
                  </li>
                  <li>
                    Cách thức tính điểm:
                    <ul>
                      <li>
                        Mỗi lượt share của bài đăng trên Facebook và Tiktok: 5
                        điểm
                      </li>
                      <li>
                        Mỗi lượt tương tác mạng xã hội (Facebook, Tiktok) và
                        trên Website www.KhuyenmaiAcecook.com: 1 điểm
                      </li>
                    </ul>
                  </li>
                  <li>
                    Đối với hóa đơn mua hàng:
                    <ul>
                      <li>
                        Với mỗi giá trị 500 đồng (Bằng chữ: Năm trăm đồng) trên
                        hóa đơn khi mua bất kỳ sản phẩm mì ly Modern/ Handy Hảo
                        Hảo/ Caykay/ Tô Nhớ Mãi Mãi (không tính sản phẩm khác)
                        sẽ được quy đổi thành 2 điểm. Càng nhiều số lượng hoá
                        đơn mua sản phẩm mì ly của Acecook hoặc hóa đơn có giá
                        trị mua sản phẩm mì ly Acecook càng cao được đăng tải
                        hơp lệ trên Website chương trình
                        www.KhuyenmaiAcecook.com thì người tham gia sẽ được quy
                        đổi ra càng nhiều điểm bình chọn.
                      </li>
                      <li>
                        Người tham gia không được sử dụng chung hóa đơn với nhau
                        các hóa đơn không được trùng lặp nhau. Trường hợp trùng
                        hóa đơn ban tổ chức sẽ chỉ tính ở lần nhập đầu tiên.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Tổng số điểm bình chọn tính trên 1 nền tảng Website sẽ gồm:
                    số lượt Like của video bài dự thi + số điểm được quy đổi từ
                    hóa đơn mua hàng.
                  </li>
                  <li>
                    Mỗi người tham gia chỉ được tham gia nộp 01 bài thi đối với
                    mỗi đoạn nhạc của 03 ca sĩ trường hợp người tham gia muốn
                    nộp lại bài dự thi mới thì phải xóa bài dự thi cũ của đoạn
                    nhạc đó. Tổng số lượng bài thi tham gia tối đa của mỗi người
                    là 05 bài.
                  </li>
                </ul>

                <h4>Cách thức xác định người thắng giải:</h4>
                <ul>
                  <li>
                    Giải Đặc Biệt 1: Bài dự thi hợp lệ và có tổng điểm bình chọn
                    cao nhất ở cả 3 nền tảng website/Facebook/Tiktok. Trong
                    trường hợp 2 bài dự thi hợp lệ và có tổng điểm bình chọn cao
                    nhất ở cả 3 nền tảng Website/Facebook/Tiktok bằng nhau thì
                    ưu tiên bài dự thi nào có điểm trên website cao hơn sẽ được
                    xác nhận thắng giải.
                  </li>
                  <li>
                    Giải Đặc Biệt 2: Bài dự thi hợp lệ và có điểm bình chọn cao
                    nhất ở 1 nền tảng Website chương trình. (Tổng số điểm bình
                    chọn tính trên 1 nền tảng Website sẽ gồm: số lượt Like của
                    video bài dự thi + số điểm được quy đổi từ hóa đơn mua
                    hàng). Trong trường hợp 2 bài dự thi hợp lệ và có điểm bình
                    chọn cao nhất ở nền tảng Website chương trình bằng nhau thì
                    bài dự thi nào đăng sớm hơn trên website sẽ được xác nhận
                    thắng giải.
                  </li>
                  <li>
                    Mỗi người chỉ nhận được một giải trường hợp nếu người tham
                    dự thắng cả 2 giải thì phần thưởng còn lại sẽ dành cho người
                    có tổng số điểm bình chọn cao thứ hai ở cả 3 nền tảng
                    Website/ Facebook/Tiktok.
                  </li>
                </ul>

                <h3>Quy định về bài thi hợp lệ:</h3>
                <ul>
                  <li>
                    Người nhận giải phải là cùng 1 người trong video bài dự thi.
                  </li>
                  <li>
                    Người dự thi phải hát hết tất cả đoạn nhạc cần hát trong bài
                    dự thi và phải quay hết đoạn nhạc của ca sỹ.
                  </li>
                  <li>
                    Nội dung video bài dự thi đăng tải trên các nền tảng xã hội
                    (Tiktok Facebook) phải là cùng 1 nội dung video trên website
                    chương trình www.KhuyenmaiAcecook.com thì mới được tính là
                    hợp lệ và được cộng điểm dựa trên lượt Like/React và Share.
                  </li>
                  <li>
                    Mỗi video bài dự thi đăng tải trên website chương trình
                    www.KhuyenmaiAcecook.com người tham gia chỉ được đính kèm
                    thêm 1 link Facebook và 1 link Tiktok đã đăng tải video. Mỗi
                    người chơi sẽ có tối đa 5 videos: 5 link Facebook và 5 link
                    Tiktok.
                  </li>
                  <li>
                    BTC có quyền xóa các bài dự thi vi phạm quy định pháp luật
                    thuần phong mỹ tục chính trị tôn giáo có nội dung không tốt
                    ảnh hưởng xấu đến hình ảnh nhãn hàng và nghệ sĩ.
                  </li>
                  <li>
                    Chương trình có quyền sử dụng video dự thi nhằm mục đích
                    quảng bá và truyền thông.
                  </li>
                </ul>

                <h3>7. Công bố và Trao thưởng:</h3>
                <ul>
                  <li>
                    Bài dự thi thắng giải sẽ được BTC thông báo trên Facebook
                    Fanpage/ Website của chương trình www.KhuyenmaiAcecook.com
                    trong vòng 03 (ba) ngày làm việc sau khi kết thúc chương
                    trình.
                  </li>
                  <li>
                    Trong vòng 05 (năm) ngày sau khi công bố kết quả người thắng
                    giải vui lòng gửi thông tin nhận giải đến BTC thông qua tin
                    nhắn tại Facebook Fanpage của chương trình{" "}
                    <a
                      href="https://www.facebook.com/acecookcup"
                      target="_blank"
                    >
                      https://www.facebook.com/acecookcup
                    </a>{" "}
                    bao gồm các nội dung sau:
                    <ul>
                      <li>
                        Tên chương trình tham gia “Hát cùng thần tượng cơ hội
                        trúng mic vàng”
                      </li>
                      <li>Họ tên người thắng giải</li>
                      <li>Số điện thoại</li>
                      <li>Địa chỉ</li>
                      <li>Ảnh chụp 2 mặt CMND/CCCD</li>
                    </ul>
                  </li>
                  <li>
                    Hạn chót gửi thông tin nhận giải về cho BTC là trong vòng 05
                    (năm) ngày kể từ ngày công bố người thắng giải. Sau thời
                    gian này BTC có quyền huỷ bỏ giải thưởng của người thắng
                    giải.
                  </li>
                  <li>
                    Giải thưởng sẽ được gửi đến cho người thắng giải theo địa
                    chỉ người thắng giải cung cấp.
                  </li>
                  <li>
                    Thông tin cá nhân sẽ được thu thập và xử lý theo Chính Sách
                    Cung Cấp Thông Tin Cá Nhân.
                  </li>
                </ul>

                <h3>8. Quy định khác:</h3>
                <ul>
                  <li>
                    Bài dự thi phải đảm bảo thuần phong mỹ tục, không vi phạm
                    đạo đức xã hội và các quy định về pháp luật Việt Nam hiện
                    hành.
                  </li>
                  <li>
                    Đối với bài hát của Sơn Tùng M-TP, mỗi bài hát có thời hạn
                    tham gia trong 1,5 tháng.
                  </li>
                  <li>
                    BTC có toàn quyền từ chối và loại bỏ các bài dự thi nộp
                    muộn, vi phạm quy định cuộc thi, có hành vi gian lận hoặc
                    các bài dự thi có nội dung không phù hợp.
                  </li>
                  <li>
                    Người tham gia đồng ý cho BTC được sử dụng hình ảnh được
                    người tham gia cung cấp cho mục đích truyền thông, quảng cáo
                    của Acecook Việt Nam, với tôn chỉ tôn trọng và bảo vệ quyền
                    lợi của người tham gia.
                  </li>
                  <li>
                    Bằng việc tham gia vào chương trình của, người tham gia đã
                    đọc, hiểu rõ và đồng ý với tất cả các quy định của chương
                    trình.
                  </li>
                  <li>
                    BTC không chịu trách nhiệm trong trường hợp thông tin khách
                    hàng cung cấp không chính xác, trung thực hoặc có sự nhầm
                    lẫn, lừa dối, hoặc số điện thoại khách hàng cung cấp không
                    liên lạc được.
                  </li>
                  <li>
                    Người tham gia chương trình sẽ hoàn toàn chịu trách nhiệm về
                    các thông tin do mình cung cấp trên website của chương
                    trình, quyền riêng tư của các thông tin cá nhân.
                  </li>
                  <li>
                    Trường hợp bất khả kháng do thiên tai, lũ lụt, sét đánh,
                    khủng bố, lỗi kỹ thuật tấn công máy chủ làm thất thoát dữ
                    liệu đăng ký của người tham dự; BTC giữ quyền quyết định
                    thay đổi chương trình và thông báo với người tham dự trong
                    thời gian sớm nhất.
                  </li>
                  <li>
                    BTC có toàn quyền quyết định về việc hình ảnh dự thi và nội
                    dung có đạt tiêu chuẩn, phù hợp với quy định của cuộc thi
                    hay không.
                  </li>
                  <li>
                    Nhân viên (toàn thời gian, bán thời gian, tư vấn thời vụ -
                    freelancers) hiện đang công tác tại công ty cổ phần Acecook
                    Việt Nam, Công ty TNHH Hakuhodo & Quảng Cáo Sài Gòn, các Nhà
                    cung cấp/ các Bên thứ Ba có liên quan đến cung cấp dịch vụ
                    cho Acecook Việt Nam không được tham gia cuộc thi.
                  </li>
                  <li>
                    BTC có quyền hủy kết quả, không trao giải hoặc thu hồi các
                    quà tặng đối với các trường hợp có dấu hiệu, vi phạm thể lệ,
                    gian lận hoặc trục lợi từ chương trình.
                  </li>
                  <li>
                    Chương trình có thể kết thúc sớm hơn hoặc muộn hơn thời gian
                    ở trên để đảm bảo quyền lợi cho người chơi.
                  </li>
                  <li>Quyền quyết định cuối cùng thuộc về Ban tổ chức. </li>
                </ul>

                <h3>9. Thông tin liên hệ</h3>
                <p>
                  Trong quá trình tham gia theo dõi và cổ vũ Cuộc thi nếu bạn
                  muốn biết thêm chi tiết hoặc có bất kỳ thắc mắc nào xin vui
                  lòng liên hệ với BTC tại Facebook Fanpage{" "}
                  <a href="https://www.facebook.com/acecookcup" target="_blank">
                    https://www.facebook.com/acecookcup
                  </a>
                  . Chúng tôi sẵn sàng tiếp nhận mọi ý kiến đóng góp nhằm giúp
                  cuộc thi được hoàn thiện và hấp dẫn hơn.
                </p>
                <p>Ban Tổ Chức</p>
              </section>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <TheFooter />
    </div>
  );
};
export default RulesPage;
