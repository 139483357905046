// @ts-nocheck
function formatNumber(n, symbol) {
  let parts = n ? n.toString().split(".") : "0";
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, symbol) +
    (parts[1] ? "," + parts[1] : "")
  );
}

function checkPc() {
  if (navigator.maxTouchPoints > 1) {
    return false;
  } else {
    return true;
  }
}

export { formatNumber, checkPc };
