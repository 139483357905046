// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupInputName = (props) => {
  const navigate = useNavigate();
  const { searchText, setSearchText, closeModal, onContinue } = props;

  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>THÔNG BÁO</h3>
              <p>
                <b>
                  Điền tên tham gia chương trình của bạn
                </b>
              </p>
              <p>
                Lưu ý: Tên sẽ không được thay đổi trong suốt thời gian chương trình diễn ra. <br /> <br />
              </p>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="linkUrl"
                  name="linkUrl"
                  maxLength={50}
                  placeholder="Điền tên của bạn..."
                  value={searchText}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.startsWith(" ")) {
                      setSearchText(value.trimStart());
                    } else {
                      setSearchText(value);
                    }
                  }}
                />
              </div>

            </div>
            <div className="group-btn-inline">
              <button onClick={() => {
                setSearchText("");
                closeModal(false);
              }} type="button" className="btn btn-warning btn-block">
                <a>Đóng</a>
              </button>
              <button onClick={onContinue} type="button" className={`btn btn-primary btn-block ${searchText?.length === 0 && "disabled"}`}>
                <a>Tiếp tục</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupInputName;
