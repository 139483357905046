import LoadingSpinner from "components/LoadingSpinner";
import PopupBillTrue from "components/popup-bill-true";
import PopupCheckBill from "components/popup-check-bill";
import PopupMessage from "components/popup-message";
import { MESSAGE_ERROR } from "constants/common";
import TheFooter from "layout/TheFooter";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import actionScanBills from "redux/scanBill/actions";

const ScanBillSuccessPage = (props) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const { state } = useLocation();
  const { status, invoiceHeaderId, message, acvPoint } = state || {};
  const [type, setType] = useState(status);
  const [showCheckBill, setShowCheckBill] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isBillTrue, setIsBillTrue] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.remove("open-scanbill");
  }, []);
  const closeBillTrue = () => {
    setIsBillTrue(false);
  };
  const requestCheck = (message) => {
    setLoading(true);
    dispatch(
      actionScanBills.REQUEST_CHECK.request(
        { invoiceHeaderId, message: message },
        async (res) => {
          setLoading(false);
          setShowCheckBill(false);
          if (res.status) {
            setType("pending");
          } else {
            setMessageError(MESSAGE_ERROR.default);
            setShowModal(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  const shopperApprove = () => {
    setLoading(true);
    dispatch(
      actionScanBills.SHOPPER_APPROVE.request(
        { invoiceHeaderId },
        async (res) => {
          setLoading(false);
          if (res.status) {
            navigate("/lichsuchuphoadon");
          } else {
            setMessageError(MESSAGE_ERROR.default);
            setShowModal(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div className="scan-bill-success">
        {/* <span
          className="i-back"
          onClick={() => {
            window.history.back();
          }}
        ></span> */}
        {type == "success" && (
          <>
            <img src="assets/img/i-bill-success.png" />
            <h2>
              Chúc mừng bạn đã nhận được
              <br /> +{acvPoint} điểm!
            </h2>
            <div className="group-btn-inline container">
              <button
                type="button"
                className="btn btn-warning  btn-block"
                onClick={() => {
                  setShowCheckBill(true);
                }}
              >
                <a>Kiểm tra lại</a>
              </button>
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={shopperApprove}
              >
                <a>Xác nhận</a>
              </button>
            </div>
          </>
        )}
        {type == "pending" && (
          <>
            <h2>
              Hoá đơn của bạn
              <br />
              đang chờ kiểm duyệt
            </h2>
            <img src="assets/img/i-bill-pending.png" />
            <div className="container">
              <p>
                Thời gian kiểm duyệt hóa đơn:
                <br />
                <strong className="text-red">
                  trong vòng 5 giờ (từ 08:00 -20:00 hàng ngày)
                </strong>
                . Vui lòng không chụp lại hóa đơn.
              </p>
              <p>
                Liên hệ hotline{" "}
                <strong className="text-red">1900 638 880</strong>
                <br />
                nếu cần hỗ trợ thêm.
              </p>
              <a
                onClick={() => {
                  setIsBillTrue(true);
                }}
              >
                <b>Xem Điều kiện hoá đơn hợp lệ</b>
              </a>
            </div>
            <div className=" container">
              <button
                type="button"
                className="btn btn-warning  btn-block"
                onClick={() => {
                  navigate("/lichsuchuphoadon");
                }}
              >
                <a>Lịch sử chụp hóa đơn</a>
              </button>
            </div>
          </>
        )}
        {type == "failed" && (
          <>
            <h2>
              Rất tiếc hoá đơn của bạn
              <br /> đã không hợp lệ
            </h2>
            <img src="assets/img/i-bill-fail.png" />
            <div className="container">
              <p>{message}</p>
              <p>
                Liên hệ hotline{" "}
                <strong className="text-red">1900 638 880</strong>
                <br />
                nếu cần hỗ trợ thêm.
              </p>
              <a
                onClick={() => {
                  setIsBillTrue(true);
                }}
              >
                <b>Xem Điều kiện hoá đơn hợp lệ</b>
              </a>
            </div>
            <div className=" container">
              <button
                type="button"
                className="btn btn-warning  btn-block"
                onClick={() => {
                  navigate("/scanbill");
                }}
              >
                <a>Chụp lại</a>
              </button>
            </div>
            <div className="container container-go-home">
              <button
                type="button"
                className="btn btn-warning  btn-block"
                onClick={() => {
                  navigate("/hatcungthantuong");
                }}
              >
                <a>Về trang chủ</a>
              </button>
            </div>
          </>
        )}
      </div>
      <div className="TheFooterbill">
        <TheFooter />
      </div>
      {showCheckBill && <PopupCheckBill requestCheck={requestCheck} />}
      {isBillTrue && <PopupBillTrue closeBillTrue={closeBillTrue} />}
      {showModal && (
        <PopupMessage
          nameBtn={""}
          nameBtn1={""}
          nameBtn2={""}
          setModal={setShowModal}
          content={messageError}
          clickAction={hideModal}
          clickAction1={hideModal}
          clickAction2={hideModal}
        />
      )}
    </>
  );
};
export default ScanBillSuccessPage;
