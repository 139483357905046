import { actionGenerator } from "utils";

const PREFIX = "PRIZE";

export const GET_REMAINING = actionGenerator.genActionTypes(
  `${PREFIX}_GET_REMAINING`
);

export const GET_LIST_WINNER_BY_TYPE = actionGenerator.genActionTypes(
  `${PREFIX}_GET_LIST_WINNER_BY_TYPE`
);
export const GET_LIST_PICTURE = actionGenerator.genActionTypes(
  `${PREFIX}_GET_LIST_PICTURE`
);

export const GET_REMAINING_PRIZE = actionGenerator.genActionTypes(
  `${PREFIX}_GET_REMAINING_PRIZE`
);
