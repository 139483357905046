// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupGiftTopup = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState(props?.dataModalTopup);

  const onTopup = () => {
    const el = document.querySelector("#telco");
    props.onTopupMobile({ ...data, carrier: el.value });
    document.body.classList.remove("modal-open");
  };
  const inputCodeAgain = () => { };
  return (
    <>
      <div className="popup-overlay modal-topup">
        <div className="popup-warp">
          <div className="popup-inner">
            <div className="content-popup">
              <h5>Chúc mừng bạn nhận được</h5>
              <img
                className="img-gift"
                src={props?.dataModalTopup?.extraInfo?.imageUrl}
                alt="200.png"
              />
              <img
                className="img-animation fadeIn"
                src="assets/img/colorful.svg"
                alt="200.png"
              />
              <h3 className="name-gift">{data?.extraInfo?.name}</h3>
              <div className="form-group topup">
                <label className="title-form" htmlFor="phone">
                  Số điện thoại cần nạp
                </label>
                <div className="form-field">
                  <input
                    type="tel"
                    className="form-control "
                    id="code"
                    maxLength={10}
                    name="code"
                    value={props?.phoneNumber}
                    min="0"
                    max="10"
                    inputMode="numeric"
                    readOnly
                  />
                  <select className="form-control" id="telco" name="telco" defaultValue={props?.networkPhone}>
                    <option value="Vinaphone">Vinaphone</option>
                    <option value="Mobiphone">Mobiphone</option>
                    <option value="Viettel">Viettel</option>
                    <option value="Vietnamobile">Vietnamobile</option>
                  </select>
                </div>
              </div>

              <p>
                Thời gian công bố GIẢI ĐẶC BIỆT TIẾP THEO vào ngày:{" "}
                <span>
                  {moment(props?.dataModalTopup?.nextSpinDate)
                    .format("DD/MM/YYYY")
                    .toString()}
                </span>
              </p>
            </div>
            <div className="btn-wrapper btn-center">
              <button
                type="button"
                className="btn btn-primary "
                onClick={onTopup}
              >
                NẠP NGAY
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupGiftTopup;
