import React, { useEffect } from "react";
const PopupMessage = (props) => {

  useEffect(() => {
    const linkElement = document.querySelector('.linkAcc');
    const guideLinkElement = document.querySelector('.guideLink');

    if (linkElement) {
      linkElement.addEventListener('click', handleClick);
    }

    if (guideLinkElement) {
      guideLinkElement.addEventListener('click', handleClickGuideLink);
    }

    return () => {
      if (linkElement) {
        linkElement.removeEventListener('click', handleClick);
      }
      if (guideLinkElement) {
        guideLinkElement.removeEventListener('click', handleClickGuideLink);
      }
    };
  }, []);

  const handleClick = () => {
    props?.showModalGuideLink(true);
  };

  const handleClickGuideLink = () => {
    props?.showModalGuideGetLinkFB(true);
  };

  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div
              className="content-popup"
              dangerouslySetInnerHTML={{ __html: props.content }}
            ></div>
            {props.nameBtn2 && props.nameBtn1 && (
              <div className="group-btn-inline">
                <button
                  type="button"
                  className="btn btn-warning btn-block"
                  onClick={props.clickAction1}
                >
                  <a dangerouslySetInnerHTML={{ __html: props.nameBtn1 }}></a>
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={props.clickAction2}
                >
                  <a dangerouslySetInnerHTML={{ __html: props.nameBtn2 }}></a>
                </button>
              </div>
            )}
            {props.nameBtn && (
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={props.clickAction}
              >
                <a dangerouslySetInnerHTML={{ __html: props.nameBtn }}></a>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupMessage;
