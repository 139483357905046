import { BlobServiceClient } from "@azure/storage-blob";
import LoadingSpinner from "components/LoadingSpinner";
import PopupBillTrue from "components/popup-bill-true";
import PopupMessage from "components/popup-message";
import {
  MESSAGE_ERROR,
  MESSAGE_SCAN_BILL,
  PENDING_ERROR_CODES,
} from "constants/common";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import actionScanBills from "redux/scanBill/actions";
import { Observable } from "rxjs";
// @ts-ignore
const ScanBillPage = (props) => {
  // @ts-ignore
  const navigate = useNavigate();
  // @ts-ignore
  const [isBillTrue, setIsBillTrue] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [listImages, setListImages] = useState([]);
  const [messageError, setMessageError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const currentIndexUploadImage = useRef(1);

  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/hatcungthantuong");
    } else {
      document.body.classList.add("open-scanbill");
    }
  }, []);

  const fileHandler = (e) => {
    if (FileReader && e.target.files && e.target.files.length) {
      setLoading(true);
      var fr = new FileReader();
      fr.onload = async function () {
        var drawFile = await getImageDimension(e.target.files[0]).toPromise();
        // @ts-ignore
        const imageData = cropImage2(drawFile, drawFile.width, drawFile.height);
        let tmp = e.target.files[0].name.split(".");
        let fileName;
        var prefixImage = createUUID();
        fileName =
          prefixImage + "_" + currentIndexUploadImage.current + "." + tmp[1];
        const test = dataURLtoFile(imageData, fileName);

        uploadFileToBlob(test, fileName);
      };
      fr.readAsDataURL(e.target.files[0]);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const createUUID = () => {
    // RFC 4122-ish
    return Array.from(Array(32))
      .map((e, i) => {
        let someRandomValue =
          i === 12 ? 4 : (+new Date() + Math.random() * 16) % 16 | 0;
        return `${(i === 16
          ? (someRandomValue & 0x3) | 0x8
          : someRandomValue
        ).toString(16)}`;
      })
      .join("");
  };

  const uploadFileToBlob = (file, fileName) => {
    // let tmp = file.name.split(".");
    // let fileName;
    // var prefixImage = createUUID();

    // fileName =
    //   prefixImage + "_" + currentIndexUploadImage.current + "." + tmp[1];
    dispatch(
      actionScanBills.GET_SASTOKEN.request(
        fileName,
        async (res) => {
          if (!!res?.url) {
            let fileNameTmp;
            fileNameTmp = res?.fileName;
            try {
              const container = "receipt";
              // auth with sastoken
              const blobService = new BlobServiceClient(res?.url);
              // get Container - full public read access
              const containerClient = blobService.getContainerClient(container);
              // create blobClient for container
              const blobClient =
                containerClient.getBlockBlobClient(fileNameTmp);
              // set mimetype as determined from browser with file upload control
              const options = {
                blobHTTPHeaders: { blobContentType: file.type },
              };
              // upload file
              await blobClient.uploadBrowserData(file, options);

              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                var fileTmp = {};
                fileTmp.imageData = reader.result.toString();
                fileTmp.fileId = fileNameTmp;
                const temp = [...listImages];
                temp.push(fileTmp);
                setListImages([...temp]);
                ++currentIndexUploadImage.current;
                setLoading(false);
              };
              reader.onerror = () => {
                setLoading(false);
                // this.showWarn("upload-file-khong-thanh-cong");
              };
            } catch (error) {
              setLoading(false);
              // this.showWarn("upload-file-khong-thanh-cong");
            }
          }
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  const getImageDimension = (fileInput) => {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        // @ts-ignore
        image.src = e.target.result;
        // @ts-ignore
        image.onload = (rs) => {
          observer.next(image);
          observer.complete();
        };
      };
      reader.readAsDataURL(fileInput);
    });
  };

  const cropImage2 = (img, iWidth, iHeight) => {
    var canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d"),
      oc = document.createElement("canvas"),
      octx = oc.getContext("2d");

    canvas.width = iWidth; // destination canvas size
    canvas.height = (canvas.width * img.height) / img.width;

    var cur = {
      width: Math.floor(img.width * 0.5),
      height: Math.floor(img.height * 0.5),
    };

    oc.width = cur.width;
    oc.height = cur.height;

    octx.drawImage(img, 0, 0, cur.width, cur.height);

    while (cur.width * 0.5 > iWidth) {
      cur = {
        width: Math.floor(cur.width * 0.5),
        height: Math.floor(cur.height * 0.5),
      };
      octx.drawImage(
        oc,
        0,
        0,
        cur.width * 2,
        cur.height * 2,
        0,
        0,
        cur.width,
        cur.height
      );
    }

    ctx.drawImage(
      oc,
      0,
      0,
      cur.width,
      cur.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // var rWidth = 720;
    // var ratio = iWidth / iHeight * 2;
    // rWidth = 720;
    // var rHeight = parseInt((rWidth / ratio) + "");
    // var canvas = document.getElementById("canvasx");

    // var canvasWidth = rWidth;
    // var canvasHeight = rHeight;
    // // @ts-ignore
    // var ctx = canvas.getContext("2d");
    // var rotate = false;
    // if (iWidth > iHeight) {
    //   canvasWidth = rHeight;
    //   canvasHeight = rWidth;
    //   rotate = true;
    // }
    // // @ts-ignore
    // canvas.height = canvasHeight;
    // // @ts-ignore
    // canvas.width = canvasWidth;

    // if (rotate) {
    //   ctx.translate(rHeight, 0);
    //   ctx.rotate((90 * Math.PI) / 180);
    // }
    // ctx.drawImage(image, 0, 0, iWidth, iHeight, 0, 0, rWidth, rHeight);
    // // @ts-ignore
    // // @ts-ignore
    // @ts-ignore
    return canvas.toDataURL("image/jpeg");;
  };

  const closeBillTrue = () => {
    setIsBillTrue(false);
  };

  const messageHandler = (errorCode) => {
    let errorCodeFormatter = errorCode;
    if (errorCode?.indexOf("@") > -1) {
      errorCodeFormatter = errorCode?.split("@")?.[0];
    }
    if (PENDING_ERROR_CODES.includes(errorCodeFormatter)) {
      navigate("/scanbillsuccess", {
        state: {
          status: "pending",
        },
      });
    } else {
      let message = MESSAGE_SCAN_BILL[errorCodeFormatter];
      if (message) {
        navigate("/scanbillsuccess", {
          state: {
            status: "failed",
            message: message,
          },
        });
      } else {
        setMessageError(MESSAGE_ERROR.default);
        setShowModal(true);
      }
    }
  };

  const executeInvoice = () => {
    if (listImages.length > 0) {
      setLoading(true);
      const fileIds = listImages.map((el) => {
        return el.fileId;
      });
      dispatch(
        actionScanBills.CHECK_STATUS_FILE_URL.request(
          { fileIds },
          (res) => {
            if (res?.canExecute) {
              dispatch(
                actionScanBills.EXECUTE_INVOICE.request(
                  { fileIds },
                  async (res) => {
                    if (res?.status) {
                      setLoading(false);
                      if (res?.errorCode) {
                        messageHandler(res?.errorCode);
                      } else {
                        if (res.acvPoint) {
                          navigate("/scanbillsuccess", {
                            state: {
                              status: "success",
                              invoiceHeaderId: res?.payload?.invoiceHeaderId,
                              acvPoint: res.acvPoint,
                            },
                          });
                        }
                      }
                    } else {
                      setLoading(false);
                      if (res?.errorCode) {
                        messageHandler(res?.errorCode);
                      }
                    }
                  },
                  (error) => {
                    setLoading(false);
                  }
                )
              );
            } else {
              setTimeout(() => {
                executeInvoice();
              }, 2000);
            }
          },
          (error) => {
            setLoading(false);
          }
        )
      );
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div className="camera-scanbill">
        <div className="header-bill-scan">
          <span
            className="back-icon"
            onClick={() => {
              navigate("/hatcungthantuong");
            }}
          >
            {" "}
          </span>
          <div
            onClick={() => {
              setIsBillTrue(true);
            }}
            className="text-scan"
          >
            Xem điều kiện hóa đơn hợp lệ
          </div>
          <span
            className="info-icon"
            onClick={() => {
              navigate("/scanbillguide");
            }}
          ></span>
        </div>
        <canvas
          className="canvas hide"
          id="canvasx"
          width="720"
          height="1280"
        ></canvas>
        <div className="picture-bill">
          {listImages.length === 0 ? (
            <img src="assets/img/guide0.png" />
          ) : (
            <>
              {listImages.map((el, index) => {
                return <img key={index} src={el.imageData} />;
              })}
            </>
          )}

          <div className="scroll-down">
            <p>Kéo xuống</p>
          </div>
        </div>
        <p className="text-note">
          Vui lòng giữ lại hoá đơn để kiểm chứng
          <br />
          trong trường hợp cần thiết
        </p>
        {!listImages.length && (
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={() => {}}
          >
            <a className="test">
              <input
                type="file"
                onChange={fileHandler}
                id="picture"
                name="picture"
                accept="image/*"
                capture="environment"
              />
              <span className="i-camera-w"></span>
              Chụp hóa đơn
            </a>
          </button>
        )}
        <div className="group-btn-inline">
          {listImages.length === 3 && (
            <button
              type="button"
              className="btn btn-warning btn-block"
              onClick={() => {
                navigate("/hatcungthantuong");
              }}
            >
              Huỷ
            </button>
          )}

          {listImages.length > 0 && (
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={executeInvoice}
            >
              <a>
                <span className="i-check"></span>hoàn thành
              </a>
            </button>
          )}
        </div>

        <div className="group-btn-inline">
          {listImages.length > 0 && listImages.length < 3 && (
            <button
              type="button"
              className="btn btn-warning btn-block"
              onClick={() => {
                navigate("/hatcungthantuong");
              }}
            >
              Huỷ
            </button>
          )}
          {listImages.length > 0 && listImages.length < 3 && (
            <button type="button" className="btn btn-warning btn-block">
              <a>
                <input
                  type="file"
                  onChange={fileHandler}
                  id="picture"
                  name="picture"
                  accept="image/*"
                  capture="environment"
                />
                Chụp thêm
              </a>
            </button>
          )}
        </div>
      </div>
      {isBillTrue && <PopupBillTrue closeBillTrue={closeBillTrue} />}

      {showModal && (
        <PopupMessage
          nameBtn={"Đóng"}
          nameBtn1={""}
          nameBtn2={""}
          setModal={setShowModal}
          content={messageError}
          clickAction={hideModal}
          clickAction1={hideModal}
          clickAction2={hideModal}
        />
      )}
    </>
  );
};
export default ScanBillPage;
