import React from "react";
import HomePage from "pages/home";
import QuestionPage from "pages/questions";
import RulesPage from "pages/rules";
import PrizePage from "pages/prize";
import DuetPage from "pages/duet";
// @ts-ignore
import DuetSelectSingerPage from "pages/duet-select-singer";
import DuetSelectSongPage from "pages/duet-select-song";
import DuetCameraPage from "pages/duet-camera";
import DuetInfoDetailPage from "pages/duet-info-detail";
import ScanBillGuidePage from "pages/scan-bill-guide";
import ScanBillPage from "pages/scan-bill";
import DuetReviewPage from "pages/duet-review";
import DuetRedirect from "pages/duet-redirect";
import DuetListPage from "pages/duet-list";
import ScanBillSuccessPage from "pages/scan-bill-success";
import RulesInformationPage from "pages/rules-information";
import ScanBillHistoryPage from "pages/scan-bill-history";
import LogoutPage from "pages/logout";
import GuideDuetPage from "pages/guide-duet";

const routesCampaign = [
  {
    path: "/" || "/#guide" || "/#joinNow",
    name: "Trang chủ",
    element: <HomePage />,
  },
  {
    path: "guide",
    name: "Hướng dẫn tham gia",
    element: <HomePage />,
  },
  {
    path: "joinNow",
    name: "Săn thẻ cào trong ly",
    element: <HomePage />,
  },
  {
    path: "/lichsuchuphoadon",
    name: "Lịch sử chụp hóa đơn",
    element: <ScanBillHistoryPage />,
  },
  {
    path: "/hatcungthantuong",
    name: "Hát cùng thần tượng",
    element: <DuetPage />,
  },
  {
    path: "/scanbillsuccess",
    name: "Hát cùng thần tượng",
    element: <ScanBillSuccessPage />,
  },
  {
    path: "/choncasi",
    name: "Chọn ca sĩ",
    element: <DuetSelectSingerPage />,
  },
  {
    path: "/chitietbaithi",
    name: "Chi tiết bài thi",
    element: <DuetInfoDetailPage />,
  },
  {
    path: "/chonbaihat",
    name: "Chọn bài hát",
    element: <DuetSelectSongPage />,
  },
  {
    path: "/quay",
    name: "Quay",
    element: <DuetCameraPage />,
  },
  {
    path: "/xemlaibaithi",
    name: "Xem lại bài thi",
    element: <DuetRedirect />,
  },
  {
    path: "/preview",
    name: "Xem lại bài thi",
    element: <DuetReviewPage />,
  },
  {
    path: "/danhsachbaithi",
    name: "Danh sách bài thi",
    element: <DuetListPage />,
  },
  {
    path: "/thelechuongtrinh",
    name: "Thể lệ chương trình",
    element: <RulesPage />,
  },
  {
    path: "/scanbillguide",
    name: "Hướng dẫn chụp bill",
    element: <ScanBillGuidePage />,
  },
  {
    path: "/scanbill",
    name: "Chụp bill",
    element: <ScanBillPage />,
  },
  {
    path: "/chinhsachthuthapthongtin",
    name: "Chính sách thu thập thông tin",
    element: <RulesInformationPage />,
  },
  {
    path: "/danhsachtrungthuong",
    name: "Danh sách trúng thưởng",
    element: <PrizePage />,
  },
  {
    path: "/guideduet",
    name: "Hướng dẫn Duet",
    element: <GuideDuetPage />,
  },
  {
    path: "/logout",
    name: "Logout",
    element: <LogoutPage />,
  },
];

export default routesCampaign;
