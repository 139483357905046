// @ts-nocheck
import { LINK_VIDEO } from "constants/common";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupImages = (props) => {
  return (
    <>
      <div className="popup-overlay modal-images">
        <div className="popup-warp">
          <div
            className="close-modal"
            onClick={() => {
              props.setModalImages(false);
            }}
          ></div>
          <div className="imagesWrapper">
            <img src={props?.image} />
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupImages;
