/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// @ts-nocheck

import PopupInputCode from "components/popup-input-code";
import PopupMessage from "components/popup-message";
import PopupOtp from "components/popup-otp";
import {
  BUSINESS_ACCOUNT_ID,
  CAMPAIGNID,
  END_DATE,
  END_DATE_TIME,
  GM,
  MB,
  SAAS_CAMPAIGNID,
  START_DATE,
  START_DATE_TIME,
  TELCO,
  TENANTID,
  VN,
  VNMB,
  VT,
} from "constants/common";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import actions from "redux/home/actions";
import actionsPrize from "redux/prize/actions";
import HomePageView from "./view";
import { signInWithCustomToken, getAuth } from "firebase/auth";
import LoadingSpinner from "components/LoadingSpinner";
import PopupGiftTopup from "components/popup-gift-topup";
import PopupNoGift from "components/popup-no-gift";
import { checkPhoneNetwork } from "utils";
import Cookies from "universal-cookie";
import $ from "jquery";
import PopupLuckyDraw from "components/popup-luckydraw";
import PopupGiftSuccess from "components/popup-gift-success";

const HomePage = () => {
  const myRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalOtp, setModalOtp] = useState(false);
  const [modalInput, setModalInput] = useState(false);
  const [content, setContent] = useState("");
  const [otp, setOtp] = useState("");
  const [isErrorOTP, setIsErrorOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModalTopup, setIsShowModalTopup] = useState(false);
  const [isShowModalNoGift, setIsShowModalNoGift] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [OtpRequestTime, setOtpRequestTime] = useState("");
  const [OtpResponseTime, setOtpResponseTime] = useState("");
  const [dataSubmit, setDataSubmit] = useState({});
  const [dataExchangeCode, setDataExchangeCode] = useState({});
  const [nameBtn, setNameBtn] = useState("");
  const [nameBtn1, setNameBtn1] = useState("");
  const [nameBtn2, setNameBtn2] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [dataModalTopup, setDataModalTopup] = useState({});
  const [isErrorPhoneNumber, setIsErrorPhoneNumber] = useState(false);
  const [isErrorCode, setIsErrorCode] = useState(false);
  const [networkPhone, setNetworkPhone] = useState("");
  const [isCommingSoon, setCommingSoon] = useState(false);
  const [isHideForm, setHideForm] = useState(false);
  const isEnableButton = useRef(false);
  const [provinceSelected, setProvinceSelected] = useState("");
  const [telcoSelected, setTelcoSelected] = useState("");

  const HOTLINE = "Hotline <strong>1900638880</strong>";
  const [listSpecial, setListSpecial] = useState([]);
  const [listPicture, setListPicture] = useState([]);
  const [winnersHome, setWinnersHome] = useState([]);

  localStorage.removeItem("rule");

  const [isShowPopupGiftSuccess, setIsShowPopupGiftSuccess] = useState(false);
  const [isShowPopupLuckyDraw, setIsShowPopupLuckyDraw] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  useEffect(() => {
    reset();

    let formDataTmp = localStorage.getItem("formData");

    if (formDataTmp) {
      formDataTmp = JSON.parse(formDataTmp);
      setValue("phone", formDataTmp?.phone);
      setValue("fullName", formDataTmp?.fullName);
      setValue("province", formDataTmp?.province);
      setValue("telco", formDataTmp?.telco);
      setProvinceSelected(formDataTmp?.province);
      setTelcoSelected(formDataTmp?.telco);
    }

    // getListWinner();
    // getListPicture();
    checkCommingSoon();
    const accessToken = localStorage.getItem("accessToken");
    if (!!accessToken) {
      const phoneNumber = localStorage.getItem("phoneNumber") || "";
      const phoneInput = document.getElementById("phone");
      if (!!phoneNumber && !!phoneInput) {
        setValue("phone", phoneNumber);
        phoneInput.disabled = true;
        document.getElementById("clear-icon-phone").classList.add("disabled");
      }
    }
  }, []);

  const detectMobileNetwork = (phone) => {
    const prefix = phone.substring(0, 3);
    let value;

    if (VT.includes(prefix)) {
      value = TELCO.find((elm) => elm.label === "Viettel")?.value;
    }

    if (MB.includes(prefix)) {
      value = TELCO.find((elm) => elm.label === "Mobiphone")?.value;
    }

    if (VN.includes(prefix)) {
      value = TELCO.find((elm) => elm.label === "Vinaphone")?.value;
    }

    if (VNMB.includes(prefix)) {
      value = TELCO.find((elm) => elm.label === "Vietnamobile")?.value;
    }

    if (GM.includes(prefix)) {
      value = TELCO.find((elm) => elm.label === "Gtel")?.value;
    }

    if (value) {
      setTelcoSelected(value);
      setValue("telco", value);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log("value, { name, type }", value, name, type);
      let isErr = false;
      if (name === "phone") {
        if (
          value.phone.startsWith("0") &&
          value.phone.length === 10 &&
          parseInt(value.phone) > 0
        ) {
          detectMobileNetwork(value.phone);
          setIsErrorPhoneNumber(false);
        } else {
          setIsErrorPhoneNumber(true);
        }
      }

      if (name === "code") {
        if (value.code.length === 8) {
          setIsErrorCode(false);
        } else {
          if (value.code.length > 0) {
            setIsSubmitSuccess(false);
          }
          setIsErrorCode(true);
        }
      }

      if (
        value?.code?.trim()?.length === 8 &&
        value.phone !== "" &&
        value?.phone?.length === 10 &&
        value?.fullName !== "" &&
        value?.fullName?.trim()?.length > 0 &&
        value.isAgree &&
        value.isAgree1 &&
        !!value.telco &&
        !!value.province &&
        !isErr
      ) {
        removeDisableBtn("#btn-register");
      } else {
        addDisableBtn("#btn-register");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isErrorPhoneNumber, isSubmitSuccess]);

  const hideModal = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    setNameBtn1("");
    setNameBtn2("");
    document.body.classList.remove("modal-open");
  };

  const getListWinner = () => {
    dispatch(
      actionsPrize.GET_LIST_WINNER_BY_TYPE.request(
        null,
        (res) => {
          if (res?.length) {
            const data = res?.find((elm) => elm?.prize === "SPECIAL");
            setListSpecial(data?.winners || []);
          }
        },
        (err) => {}
      )
    );
  };
  const getListPicture = () => {
    dispatch(
      actionsPrize.GET_LIST_PICTURE.request(
        null,
        (res) => {
          console.log("resGET_LIST_PICTURE", res);
          if (res?.length) {
            setListPicture(res || []);
          }
        },
        (err) => {}
      )
    );
  };

  const startTimer = () => {
    if (timerId) clearTimeout(timerId);
    setTimerId(setTimeout(hideModal, 10000));
  };

  const stopTimer = () => clearTimeout(timerId);

  const removeModalOpen = () => {
    setIsLoading(false);
    document.body.classList.remove("modal-open");
  };

  useEffect(() => {
    if (!modalOtp) {
      setOtp("");
      setIsErrorOTP(false);
      clearField();
    }
  }, [modalOtp]);

  useEffect(() => {
    document.body.classList.add("home-page");
    return stopTimer;
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      handleSubmitOTP();
    }
  }, [otp]);

  useEffect(() => {
    // validateCampaign();
    // validateCode();
    getWinners();
  }, []);

  const getWinners = () => {
    setIsLoading(true);

    let params = {
      pageNumber: 1,
      pageSize: 10,
      Type: 1,
    };

    dispatch(
      actionsPrize.GET_LIST_WINNER_BY_TYPE.request(
        params,
        (res) => {
          setIsLoading(false);
          setWinnersHome(res?.results || []);
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const validateCampaign = () => {
    setIsLoading(true);
    dispatch(
      actions.VALIDATE_CAMPAIGN.request(
        {},
        (res) => {
          // checkError("FinishedError");
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
            if (errCode == "NotStartYetError" || errCode == "FinishedError") {
              setHideForm(true);
            }
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  // const getProvinces = () => {
  //   setIsLoading(true);
  //   dispatch(
  //     actions.GET_PROVINCES.request(
  //       {},
  //       (res) => {
  //         if (res?.length > 0) {
  //           let newProvinces = res.map((e) => {
  //             return { label: e?.city, value: e?.code, ...e };
  //           });
  //           setProvinces(newProvinces);
  //           setIsLoading(false);
  //           //  setTimeout(() => {
  //           //   res.forEach((element) => {
  //           //     if (element?.id === "79") {
  //           //       setValue("address", element.value);
  //           //     }
  //           //   });
  //           //   setIsLoading(false);
  //           // }, 600);
  //         }
  //       },
  //       (err) => {
  //         setIsLoading(false);
  //       }
  //     )
  //   );
  // };

  const showModal = () => {
    setModal(true);
    document.body.classList.add("modal-open");
  };
  const closeModalOTP = () => {
    setModalOtp(false);
    setOtp("");
    setIsErrorOTP(false);
    document.body.classList.remove("modal-open");
    removeDisableBtn("#btn-register");
  };
  const onSubmit = (data) => {
    // handleJoin(data);
    if (isEnableButton.current) {
      localStorage.setItem("formData", JSON.stringify(data));

      const accessToken = localStorage.getItem("accessToken");
      localStorage.setItem("code", data.code || "");
      setDataSubmit(data);
      clearField();

      if (!!accessToken) {
        exchangeCode(data);
      } else {
        // requestOTP(data);
        document.body.classList.add("modal-open");
        addDisableBtn("#btn-register");
        checkPhone(data);
      }
    }
  };

  const checkPhone = (data) => {
    setIsLoading(true);
    dispatch(
      actions.CHECK_PHONE.request(
        {
          phoneNumber: data?.phone,
          provinceCode: data?.province,
          fullName: data?.fullName,
        },
        (res) => {
          requestOTP(data);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const checkCommingSoon = () => {
    let startDate = moment(START_DATE, "DD/MM/YYYY"); //20/03/2023
    let endDate = moment(END_DATE, "DD/MM/YYYY"); //20/05/2023
    let today = moment();
    if (today < startDate) {
      setContent(
        ` <h5>Thông báo</h5>
      <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" sẽ bắt đầu vào 00:00 ngày 12/08/2024. Acecook Việt Nam rất mong nhận được sự tham gia nhiệt tình của Quý khách.<p/>
      `
      );
      showModal();
      setHideForm(true);
      localStorage.setItem("isHideForm", true);
      setNameBtn(`ĐÓNG`);
      // startTimer();
    } else if (today > endDate) {
      setContent(
        ` <h5>Thông báo</h5>
      <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" đã kết thúc vào 23:59 ngày 11/11/2024. Chân thành cảm ơn Quý khách đã nhiệt tình tham gia và luôn tin dùng sản phẩm của Acecook Việt Nam.<p/>
     `
      );
      showModal();
      setHideForm(true);
      localStorage.setItem("isHideForm", true);
      setNameBtn(`ĐÓNG`);
      // startTimer();
    }
  };
  const handleJoin = (data) => {
    let startDate = moment(START_DATE, "DD/MM/YYYY"); //20/03/2023
    let endDate = moment(END_DATE, "DD/MM/YYYY"); //20/05/2023
    let today = moment();

    if (today < startDate) {
      setContent(
        `<h5>Chương trình KHUYẾN MẠI</h5>
        <h4>SINH NHẬT 23</h4>
        <h4>chưa bắt đầu</h4>
        <p>Giữ lại thẻ cào và gửi lại Mã thẻ cào<br/> từ <span>07:00</span> ngày <span>04/09/2023</span> đến <span>23:59</span> ngày <span>30/11/2023</span>. Rất mong sớm gặp lại Quý khách tại chương trình.</p>
        `
      );
      showModal();
      setNameBtn(`ĐÓNG`);
      // startTimer();
    } else if (today > endDate) {
      setContent(
        ` <h5>Chương trình đã kết thúc</h5>
        <p>Thời hạn chương trình khuyến mãi đã kết thúc vào lúc 23:59 ngày <span>23:59</span>. Cảm ơn Quý khách đã tin dùng sản phẩm của Hảo Hảo.</p>`
      );
      showModal();
      startTimer();
    } else {
      setContent("");
      if (data.code === "") {
        setContent(`Mã dự thưởng không hợp lệ!<br/>
        Vui lòng kiểm tra lại<br/>
        hoặc gọi đến ${HOTLINE}`);
        setNameBtn1(`ĐÓNG`);
        setNameBtn2(`<a href="#joinNow">NHẬP LẠI</a>`);
        showModal();
      } else {
        // validateCode(data);
        requestOTP(data);
        document.body.classList.add("modal-open");
        addDisableBtn("#btn-register");
      }
    }
  };

  const addDisableBtn = (id) => {
    const el = document.querySelector(id);
    el.classList.add("disabled");
    isEnableButton.current = false;
  };

  const removeDisableBtn = (id) => {
    const el = document.querySelector(id);
    el.classList.remove("disabled");
    isEnableButton.current = true;
  };

  const handleSubmitOTP = () => {
    console.log("OTP===>", otp);
    setIsLoading(true);
    verifyOTP();
  };

  const resendOTP = () => {
    setIsErrorOTP(false);
    setOtp("");
    requestOTP(dataSubmit);
  };

  const clearField = (type) => {
    addDisableBtn("#btn-register");
    setIsErrorPhoneNumber(false);
    if (type === "all") {
      setValue("code", "");
      setProvinceSelected("");
      // setValue("address", $("#city option:first").val());
      setValue("isAgree", false);
      setValue("isAgree1", false);
      setValue("phone", "");
      setValue("fullName", "");
    } else {
      setValue("isAgree", false);
      setValue("isAgree1", false);
    }
  };

  const verifyOTP = () => {
    setIsLoading(true);

    dispatch(
      actions.VERIFY_OTP.request(
        {
          otpCode: otp,
          phoneNumber: dataSubmit?.phone,
        },
        (res) => {
          // removeModalOpen();
          setModalOtp(false);
          // var errorMessage = `<h5>Thông báo</h5><p>Chúc mừng Quý khách trúng thưởng giải III 100.000 VNĐ tiền nạp điện thoại từ Hảo Hảo. Giải thưởng sẽ được nạp tự động vào số điện thoại của Quý khách trong vòng 24 giờ. Vui lòng giữ lại thẻ cào để tham gia quay số và có cơ hội trúng thưởng giải I hoặc giải II. Liên hệ <span style="color: #FD2B64">1900299981</span> (1.000Đ/phút) để được hỗ trợ và tư vấn<p/>`;
          // setNameBtn(`ĐÓNG`);
          // setContent(errorMessage);
          // setModal(true);

          setIsErrorOTP(false);
          requestLogin(res?.token?.accessToken);
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code || err?.data?.message;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const requestLogin = (accessToken) => {
    signInWithCustomToken(getAuth(), accessToken)
      .then((authCredential) => {
        let user = authCredential?.user;
        console.log(user);
        localStorage.setItem("accessToken", user.accessToken || "");
        localStorage.setItem("refreshToken", user.refreshToken);
        localStorage.setItem("phoneNumber", dataSubmit?.phone);

        const phoneInput = document.getElementById("phone");
        setValue("phone", dataSubmit?.phone);
        phoneInput.disabled = true;

        exchangeCode(dataSubmit);
        createUser();
        // dispatch(
        //   actions.REQUEST_LOGIN.request(
        //     {
        //       loginProviderId: user?.uid || "",
        //       phoneNumber: phoneNumber,
        //       otpInfo: {
        //         Otp: otp,
        //         OtpRequestTime: OtpRequestTime,
        //         OtpResponseTime: OtpResponseTime,
        //       },
        //       smsLogModel: {
        //         requestTime: OtpRequestTime,
        //         phoneNumber: phoneNumber,
        //         verifyTime: verifyOTPRequestTime,
        //         reponseTime: OtpResponseTime,
        //         content: otp,
        //         type: "otp",
        //         // campaignId: parseInt(CAMPAIGNID),
        //         tenantId: parseInt(TENANTID),
        //         userId: user?.uid || "",
        //       },
        //     },
        //     (res) => {
        //       updateUserInfo();
        //     },
        //     (err) => {
        //       removeModalOpen();
        //     }
        //   )
        // );
      })
      .catch((error) => {
        removeModalOpen();
        console.log(error);
      });
  };

  const createUser = () => {
    dispatch(
      actions.CREATE_USER.request(
        {},
        (res) => {},
        (err) => {}
      )
    );
  };

  const updateUserInfo = () => {
    dispatch(
      actions.UPDATE_USER_INFO.request(
        {
          fullName: fullName,
          address: address,
          phone: phoneNumber,
        },
        (res) => {
          requestGigya();
          // exchangeCode();
          // hideModal();
          // console.log("success");
        },
        (err) => {
          removeModalOpen();
        }
      )
    );
  };

  // const requestGigya = () => {
  //   dispatch(
  //     actions.REQUEST_GIGYA.request(
  //       {},
  //       (res) => {
  //         if (res.data) {
  //           console.log("aaaaa", res.data);
  //           let unileverId = res.data?.unileverId;
  //           cookies.set("UnileverId", unileverId);
  //           var event = {
  //             eventInfo: {
  //               type: "trackEvent",
  //               eventAction: "SignUp Submit",
  //               eventLabel: "Sign Up|Form Submit|Newsletter Sign Up",
  //               eventValue: 1,
  //             },
  //             category: {
  //               primaryCategory: "Other",
  //             },
  //             subcategory: "Lead",
  //           };
  //           // eslint-disable-next-line no-undef
  //           if (!!digitalData)
  //             digitalData.event.push && digitalData.event.push(event);
  //         }
  //         console.log("success");
  //       },
  //       (err) => {}
  //     )
  //   );
  // };

  const exchangeCode = (dataExchange) => {
    setIsLoading(true);

    let params = {
      code: dataExchange?.code,
      provinceCode: dataExchange?.province,
      fullName: dataExchange?.fullName,
      telcoCode: dataExchange?.telco,
    };
    setDataExchangeCode(params);

    dispatch(
      actions.EXCHANGE_CODE.request(
        params,
        (res) => {
          setIsLoading(false);
          setIsSubmitSuccess(true);

          setOtp("");
          removeModalOpen();
          console.log("success");
          hideModal();
          setValue("code", "");
          setValue("isAgree", false);
          setValue("isAgree1", false);

          setDataModalTopup(res);
          if (res?.errorCode === "NeedRetryExchange") {
            setIsShowPopupLuckyDraw(true);
            return;
          }

          onShowGift(res?.giftType);
        },
        (err) => {
          setIsShowPopupLuckyDraw(false);
          removeModalOpen();
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const onShowGift = (giftType) => {
    var content = ``;
    switch (giftType) {
      case 5:
        content = `
        <h5>Thông báo</h5>
        <p>Chúc mừng Quý khách trúng thưởng Giải V - Tài khoản điện thoại trị giá 100.000Đ. Giá trị giải thưởng sẽ được tự động nạp vào tài khoản điện thoại trong vòng 24h hoặc 01 mã thẻ nạp điện thoại sẽ được gửi qua tin nhắn đến số điện thoại của Quý khách. Vui lòng giữ lại thẻ cào và theo dõi chương trình để có cơ hội trúng thêm giải thưởng khác. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn<p/>
        `;
        break;

      case 4:
        content = `
        <h5>Thông báo</h5>
        <p>Chúc mừng Quý khách trúng thưởng Giải IV - Tài khoản điện thoại trị giá 150.000Đ. Giá trị giải thưởng sẽ được tự động nạp vào tài khoản điện thoại trong vòng 24h hoặc 02 mã thẻ nạp điện thoại (mệnh giá 100.000Đ và 50.000Đ) sẽ được gửi qua tin nhắn đến số điện thoại của Quý khách. Vui lòng giữ lại thẻ cào và theo dõi chương trình để có cơ hội trúng thêm giải thưởng khác. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn<p/>
        `;
        break;

      case 0:
        content = `
        <h5>Thông báo</h5>
        <p>Chúc Quý khách may mắn lần sau. Vui lòng giữ lại thẻ cào và theo dõi chương trình để có cơ hội trúng thêm giải thưởng khác. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn<p/>
        `;
        break;

      default:
        break;
    }
    setNameBtn(`ĐÓNG`);
    setContent(content);
    setModal(true);
  };

  const isValidPhone = (phone) => {
    var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
    return vnf_regex.test(phone) ? true : false;
  };
  const validateCode = () => {
    setIsLoading(true);
    dispatch(
      actions.VALIDATE_CODE.request(
        null,
        (res) => {
          setCommingSoon(false);
        },
        (err) => {
          removeModalOpen();
          setCommingSoon(true);
          if (err?.status !== 500) {
            const errorCode = err?.data?.error?.code;
            var errorMessage = "";
            switch (errorCode) {
              case "NotStartYetError":
                errorMessage = `<h5>Chương trình KHUYẾN MẠI</h5>
                <h4>SINH NHẬT 23</h4>
                <h4>chưa bắt đầu</h4>
                <p>Giữ lại thẻ cào và gửi lại Mã thẻ cào<br/> từ <span>07:00</span> ngày <span>04/09/2023</span> đến <span>23:59</span> ngày <span>30/11/2023</span>. Rất mong sớm gặp lại Quý khách tại chương trình.</p>
                `;
                setNameBtn(`ĐÓNG`);
                break;
              case "FinishedError":
                errorMessage = `<h5>Chương trình đã kết thúc</h5><p>Thời hạn chương trình khuyến mãi đã kết thúc vào lúc <span>23:59</span> ngày <span>30/11/2023</span>. Cảm ơn Quý khách đã tin dùng sản phẩm của Hảo Hảo.<p/>`;
                setNameBtn(`ĐÓNG`);
                break;

              default:
                break;
            }

            if (errorMessage?.trim()?.length > 0) {
              setContent(errorMessage);
              setModal(true);
            }
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const checkError = (errCode) => {
    let code = localStorage.getItem("code");
    let content = "";
    switch (errCode) {
      case "NotStartYetError":
        content = `
        <h5>Thông báo</h5>
        <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" sẽ bắt đầu vào 00:00 ngày 12/08/2024. Acecook Việt Nam rất mong nhận được sự tham gia nhiệt tình của Quý khách.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "FinishedError":
        content = `
        <h5>Thông báo</h5>
        <p>Chương trình "Săn Thẻ Cào Trong Ly, Trúng Quà Mê Ly" đã kết thúc vào 23:59 ngày 11/11/2024. Chân thành cảm ơn Quý khách đã nhiệt tình tham gia và luôn tin dùng sản phẩm của Acecook Việt Nam.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ReachedMaxFailuresPerDay":
        content = `
        <h5>Thông báo</h5>
        <p>Quý khách đã nhập sai mã thẻ cào quá 05 lần. Rất tiếc, số điện thoại của Quý khách đang bị tạm khóa. Vui lòng thử lại sau 24h. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "RequestOTPFail":
        content = `
        <h5>Thông báo</h5>
        <p>Yêu cầu gửi mã OTP của bạn đang được chúng tôi xử lý, bạn hãy thử lại sau 1 phút nhé.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "VerifyOTPFail":
        content = `
        <h5>Thông báo</h5>
        <p>Mã xác thực chưa chính xác hoặc đã hết hạn, vui lòng kiểm tra lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "InvalidCode":
        content = `
        <h5>Thông báo</h5>
        <p>Mã thẻ cào ${code} không hợp lệ hoặc đã được sử dụng. Quý khách tiếp tục săn thẻ cào để có thêm cơ hội may mắn. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "UsedCodeError":
        content = `
        <h5>Thông báo</h5>
        <p>Mã thẻ cào ${code} không hợp lệ hoặc đã được sử dụng. Quý khách tiếp tục săn thẻ cào để có thêm cơ hội may mắn. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "InvalidPhoneNumber":
        content = `
        <h5>Thông báo</h5>
        <p>Mã thẻ cào ${code} không hợp lệ hoặc đã được sử dụng. Quý khách tiếp tục săn thẻ cào để có thêm cơ hội may mắn. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      case "InvalidResendTime":
        content = `
          <h5>Thông báo</h5>
          <p>Yêu cầu gửi mã OTP của bạn đang được chúng tôi xử lý, bạn hãy thử lại sau 1 phút nhé. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ERR_NETWORK":
        content = `
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;

      default:
        content = `
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;
    }
    setContent(content);
    showModal(true);
  };

  const showDefaultErrModal = () => {
    setModal(true);
    setContent(`
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `);
    setNameBtn(`ĐÓNG`);
  };

  const requestOTP = (data) => {
    setOtpRequestTime(moment().utc().format());
    localStorage.setItem("accessToken", "");
    dispatch(
      actions.REQUEST_OTP.request(
        {
          phoneNumber: data.phone,
          campaignId: parseInt(CAMPAIGNID),
        },
        (res) => {
          setOtpResponseTime(moment().utc().format());
          // OTPLog(res, data);
          // setPhoneNumber(data.phone);
          // setFullName(data.fullName);
          // setAddress(data.address);
          setModalOtp(true);
          console.log("success");
          removeModalOpen();
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  };

  const OTPLog = (res, data) => {
    dispatch(
      actions.OTP_LOG.request(
        {
          transactionId: res?.transactionId || "",
          type: "OTP",
          phone: data.phone,
        },
        (res) => {},
        (err) => {}
      )
    );
  };

  const clickAction = () => {
    hideModal();
  };

  const onTopupMobile = (infoTopup) => {
    setIsLoading(true);

    dispatch(
      actions.TOPUP_MOBILE.request(
        {
          // campaignId: SAAS_CAMPAIGNID,
          businessAccountId: BUSINESS_ACCOUNT_ID,
          giftId: infoTopup.rewardId,
          phoneNumber: phoneNumber,
          carrier: infoTopup.carrier,
          allocationId: infoTopup.allocationId,
        },
        (res) => {
          removeModalOpen();
          setIsShowModalTopup(false);
          showModal();
          setNameBtn(`ĐÓNG`);
          setContent(
            `Mã nạp điện thoại sẽ được nạp trực tiếp đến số điện của thoại bạn trong ít phút tới. Lưu ý hãy giữ lại phần vỏ nhãn chứa mã dự thưởng gồm <strong>10 ký tự còn nguyên vẹn</strong> để đợi công bố GIẢI ĐẶC BIỆT từ HaoHao.<br/>${HOTLINE}`
          );
        },
        (err) => {
          removeModalOpen();
          showDefaultErrModal();
        }
      )
    );
  };
  const commingSoon = JSON.parse(localStorage.getItem("commingSoon"));
  const campainEnd = JSON.parse(localStorage.getItem("campainEnd"));
  // console.log("result=HOME", commingSoon, campainEnd);

  // useEffect(() => {
  //   if (commingSoon) {
  //     setContent(
  //       `<h5>Chương trình KHUYẾN MẠI</h5>
  //       <h4>SINH NHẬT 23</h4>
  //       <h4>chưa bắt đầu</h4>
  //       <p>Giữ lại thẻ cào và gửi lại Mã thẻ cào<br/> từ <span>07:00</span> ngày <span>04/09/2023</span> đến <span>23:59</span> ngày <span>30/11/2023</span>. Rất mong sớm gặp lại Quý khách tại chương trình.</p>
  //       `
  //     );
  //     showModal();
  //     setNameBtn(`ĐÓNG`);
  //   } else if (campainEnd) {
  //     setContent(
  //       ` <h5>Chương trình đã kết thúc</h5>
  //       <p>Thời hạn chương trình khuyến mãi đã kết thúc vào lúc <span>23:59</span> ngày <span>30/11/2023</span>. Cảm ơn Quý khách đã tin dùng sản phẩm của Hảo Hảo.</p>`
  //     );
  //     showModal();
  //     setNameBtn(`ĐÓNG`);
  //   } else {
  //     hideModal();
  //   }
  // }, [commingSoon, campainEnd]);

  function calculateTimeLeft() {
    let timeLeft = {};
    const difference = JSON.parse(localStorage.getItem("difference"));
    const differenceEnd = JSON.parse(localStorage.getItem("differenceEnd"));

    if (difference > 0) {
      localStorage.setItem("commingSoon", "true");
      timeLeft = difference;
    } else {
      localStorage.setItem("commingSoon", "false");
      timeLeft = difference;
    }
    if (differenceEnd > 0) {
      localStorage.setItem("campainEnd", "false");
    } else {
      localStorage.setItem("campainEnd", "true");
    }
    return timeLeft;
  }
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      // console.log("timeLeft", timeLeft);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, [timeLeft]);

  return (
    <div
      onClick={() => {
        if (modalOtp) {
          hideModal();
        }
      }}
    >
      <LoadingSpinner isLoading={isLoading} />
      <HomePageView
        isHideForm={isHideForm}
        winnersHome={winnersHome}
        register={register}
        setValue={setValue}
        getValues={getValues}
        handleSubmit={handleSubmit(onSubmit)}
        isErrorPhoneNumber={isErrorPhoneNumber}
        // isCommingSoon={isCommingSoon}
        provinceSelected={provinceSelected}
        setProvinceSelected={(e) => {
          setProvinceSelected(e);
        }}
        listSpecial={listSpecial}
        listPicture={listPicture}
        telcoSelected={telcoSelected}
        setTelcoSelected={setTelcoSelected}
        isErrorCode={isErrorCode}
        isSubmitSuccess={isSubmitSuccess}
      />
      {modalOtp && (
        <PopupOtp
          setModal={setModalOtp}
          setOtp={setOtp}
          otp={otp}
          handleSubmitOTP={handleSubmitOTP}
          closeModalOTP={closeModalOTP}
          resendOTP={resendOTP}
          isErrorOTP={isErrorOTP}
          isLoading={isLoading}
        />
      )}
      {isShowModalTopup && (
        <PopupGiftTopup
          dataModalTopup={dataModalTopup}
          phoneNumber={phoneNumber}
          onTopupMobile={onTopupMobile}
          networkPhone={networkPhone}
        />
      )}

      {/* {modalInput && <PopupInputCode setModalInput={setModalInput} content={content} />} */}

      {isShowPopupLuckyDraw && (
        <PopupLuckyDraw
          dataSubmit={dataSubmit}
          dataModalTopup={dataModalTopup}
          closeModal={setIsShowPopupLuckyDraw}
          onShowGift={onShowGift}
          checkError={checkError}
        />
      )}

      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          nameBtn1={nameBtn1}
          nameBtn2={nameBtn2}
          setModal={setModal}
          content={content}
          clickAction={clickAction}
          clickAction1={clickAction}
          clickAction2={clickAction}
        />
      )}

      {/* {isShowPopupGiftSuccess && (
        <PopupGiftSuccess
          dataModalTopup={rewardDataRef?.current}
          closeModal={setIsShowPopupGiftSuccess}
        />
      )} */}

      {isShowModalNoGift && (
        <PopupNoGift
          dataModalTopup={dataModalTopup}
          closeModal={setIsShowModalNoGift}
        />
      )}
    </div>
  );
};

export default HomePage;
