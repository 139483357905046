// @ts-nocheck
import { TELCO } from "constants/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const PopupShareLink = (props) => {
  const navigate = useNavigate();
  const {
    searchText,
    setSearchText,
    closeModal,
    onContinue,
    setIsGuideGetLinkFB,
  } = props;

  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div className="content-popup">
              <h3>BÀI DỰ THI TỪ TIKTOK</h3>
              <p>
                <b>
                  Quay bài dự thi trên nền tảng Tiktok và đăng tải trên trang
                  chương trình.
                </b>
              </p>
              <p>
                Bạn hãy copy đường link của bài dự thi trên Tiktok và điền vào ô
                bên dưới.
              </p>
              <br />
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="linkUrl"
                  name="linkUrl"
                  placeholder="http://Tiktok.com/123456789..."
                  value={searchText}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.startsWith(" ")) {
                      setSearchText(value.trimStart());
                    } else {
                      setSearchText(value);
                    }
                  }}
                />
              </div>
              <p className="text-yellow">
                Lưu ý: Người tham gia trong video duet và người đăng kí tham gia
                chương trình phải là một người. Trong trường hợp người dự thi
                đoạt giải không phải người xuất hiện trong video, ban tổ chức có
                quyền huỷ bỏ kết quả.
              </p>
              <p>
                Chi tiết cách lấy link bài dự thi xin hãy
                <br />
                <a
                  className="guideLink"
                  onClick={() => setIsGuideGetLinkFB(true)}
                >
                  xem ở đây
                </a>
                .
              </p>
            </div>
            <div className="group-btn-inline">
              <button
                onClick={() => {
                  setSearchText("");
                  closeModal(false);
                }}
                type="button"
                className="btn btn-primary btn-block"
              >
                <a>Đóng</a>
              </button>
              <button
                onClick={onContinue}
                type="button"
                className={`btn btn-warning btn-block ${
                  searchText?.length === 0 && "disabled"
                }`}
              >
                <a>Tiếp tục</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupShareLink;
