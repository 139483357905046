import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import PopupMessage from "components/popup-message";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "redux/duet/actions";
import actionsHome from "redux/home/actions";
import reactUtils from "utils/reactUtils";

const DuetRedirectPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [nameBtn, setNameBtn] = useState("");
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [state, setState] = useState("");


  const hideModal = () => {
    setModal(false);
    setContent("");
    setNameBtn("");
    document.body.classList.remove("modal-open");
    document.body.classList.remove("open-duet");
  };
  const showModal = () => {
    setModal(true);
    document.body.classList.add("modal-open");
  };
  const clickAction = () => {
    hideModal();
    if (state === '0') {
      navigate("/chonbaihat");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');
    const scopes = params.get('scopes');
    const error_code = params.get('error_code');

    const substrings = ['user.info.basic', 'video.list', 'user.info.stats'];
    let containsAllSubstrings = false;
    if (!!scopes) {
      containsAllSubstrings = substrings.every(substring => scopes.includes(substring));
    }
    setState(state);

    if (containsAllSubstrings === false || !!error_code) {
      setModal(true);
      setContent(`
        <h5>Thông báo</h5>
        <p>Vui lòng chia sẻ với Acecook Việt Nam thông tin và số lượt thích video của bạn.<p/>
        `);
      setNameBtn(`ĐÓNG`);
    } else {
      if (!!code && !!state) {
        let data = {
          settingType: 'tiktoksetting',
          auth_code: code,
          state: state
        }
        onCreateSetting(data);
      }
    }


  }, [location.search]);

  const onCreateSetting = (data) => {
    hideModal();

    setIsLoading(true);
    let params = {};

    switch (data.settingType) {
      case 'tiktoksetting':
        params = {
          settingType: data.settingType,
          body: {
            data: {
              auth_code: data.auth_code,
              redirect_url: reactUtils.extractBaseURL(window.location.href)
            }
          },
        }
        break;

      case 'facebooksetting':
        params = {
          body: {
            data: {
              access_token: data.access_token,
              fb_user_id: data.fb_user_id
            }
          },
          settingType: data.settingType,
        }
        break;

      default:
        break;
    }

    dispatch(
      actions.CREATE_SETTINGS.request(
        params,
        (res) => {
          setIsLoading(false);
          if (data?.state === '0') {
            navigate("/chonbaihat");
          }
        },
        (err) => {
          setIsLoading(false);
          if (err?.status !== 500) {
            let errCode = err?.data?.error?.code;
            checkError(errCode);
          } else {
            showDefaultErrModal();
          }
        }
      )
    );
  }

  const checkError = (errCode) => {
    let content = "";
    switch (errCode) {
      case "AlreadyLinked":
        content = `
          <h5>Thông báo</h5>
          <p>Tài khoản này đã được liên kết tới số điện thoại khác.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      case "ERR_NETWORK":
        content = `
          <h5>Thông báo</h5>
          <p>Đã có lỗi xảy ra trong quá trình kết nối mạng, vui lòng kiểm tra và thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
          `;
        setNameBtn(`ĐÓNG`);
        break;

      default:
        content = `
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `;
        setNameBtn(`ĐÓNG`);
        break;
    }
    setContent(content);
    showModal();
  };

  const showDefaultErrModal = () => {
    setModal(true);
    setContent(`
        <h5>Thông báo</h5>
        <p>Đã có lỗi xảy ra trong quá trình kết nối, vui lòng thử lại. Liên hệ <span style="color: #FD2B64">1900638880</span> (1.000Đ/phút) để được hỗ trợ và tư vấn.<p/>
        `);
    setNameBtn(`ĐÓNG`);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {modal && (
        <PopupMessage
          nameBtn={nameBtn}
          content={content}
          clickAction={clickAction}
        />
      )}
    </>
  );
};
export default DuetRedirectPage;
