import { all, fork } from "redux-saga/effects";

import homeSagas from "../home/sagas";
import prizeSagas from "../prize/sagas";
import duetSagas from "../duet/sagas";
import scanBillSagas from "../scanBill/sagas";


export default function* rootSaga() {
  yield all([fork(homeSagas), fork(prizeSagas), fork(duetSagas), fork(scanBillSagas)]);
}
