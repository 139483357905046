// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import TheFooter from "layout/TheFooter";
import moment from "moment";
import ReactPaginate from "react-paginate";

const DuetListView = (props) => {
  const {
    listSub,
    pages,
    onChangePage,
    pageOffset,
    searchText,
    setSearchText,
    onSearchSub,
  } = props;
  const navigate = useNavigate();

  const handlePageChange = (event) => {
    onChangePage(event.selected);
    console.log(event);
  };
  return (
    <section>
      <div className="bgr-green des-green">
        <br />
        <div
          className="searchDuet des-green container"
          style={{
            minHeight: window.innerHeight - 220,
            objectFit: "contain",
          }}
        >
          <img className="img-full d-none" src="assets/img/Title-tcbt.png" />
          <img
            className="img-full-des d-none-mb"
            src="assets/img/title-tbdt-des.png"
          />
          <div className="search-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="usr"
                name="usr"
                placeholder="Nhập SĐT/ SDB/ Bài hát/ Ca sĩ..."
                maxLength={50}
                min="0"
                max="50"
                value={searchText}
                onChange={(e) => {
                  if (!!e?.target?.value) {
                    setSearchText(e?.target?.value);
                  } else {
                    setSearchText("");
                  }
                }}
              />
            </div>
            <button
              className="btn btn-primary btn-block"
              id="btn-search"
              onClick={onSearchSub}
            >
              <a>
                {" "}
                <img src="assets/img/icon/search.svg" />
              </a>
            </button>
          </div>
          {/* <button type="button" className="btn btn-warning btn-block">
            <a>Xem tất cả bài dự thi</a>
          </button> */}
          {listSub?.length > 0 ? (
            <div className="TabPanel-duet">
              <ul className="list-duet">
                {listSub?.length > 0 &&
                  listSub?.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          navigate(`/chitietbaithi?id=${item?.id}`, { state: { isGoBack: true } });
                        }}
                      >
                        <div className="avarta">
                          <img className="avarta" src={item?.image} alt="" />
                          {item?.rank > 0 && (
                            <span className="tag">{item?.rank}</span>
                          )}
                        </div>
                        <ul className="content">
                          <li>
                            SBD {item?.contestantInfo?.contestNumber} |{" "}
                            {item?.contestantInfo?.userName}
                          </li>
                          <li>
                            {item?.submissionInfo?.songName} -{" "}
                            {item?.submissionInfo?.singerName}
                          </li>
                          <li>
                            Ngày dự thi:{" "}
                            {moment(item?.createdAt)
                              .format("DD/MM/YYYY")
                              .toString()}
                          </li>
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </div>
          ) : (
            <div className="no-data">
              <span>
                Không tìm thấy bài dự thi nào.
                <br />
                Vui lòng thử lại
              </span>
            </div>
          )}

          {listSub?.length > 0 && (
            <ReactPaginate
              previousLabel=""
              nextLabel=""
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pages || 0}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName="pagination container"
              activeClassName="active"
              forcePage={pageOffset}
            />
          )}
        </div>
        <div className="TheFooterRaidius">
          <TheFooter />
        </div>
      </div>
    </section>
  );
};
export default DuetListView;
