import React from "react";

const PopupDownload = (props) => {
  return (
    <>
      <div className="popup-overlay modal-notify">
        <div className="popup-warp">
          <div className="icon-popup"></div>
          <div className="popup-inner">
            <div
              className="content-popup"
              dangerouslySetInnerHTML={{ __html: props.content }}
            ></div>
            <div style={{ marginTop: 20, marginBottom: 20, display: "flex", justifyContent: "center" }}>
              <progress value={props?.downloadedPercent} max={100} />
          
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupDownload;
