// @ts-nocheck
import axios from "axios";
import AppConfigs from "configs/env";
import { END_POINTS } from "constants/apiUrl";
import { CAMPAIGNID, TENANTID_SCAN_BILL } from "constants/common";
import { APIUtils } from "utils";

export const getSasTokenApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_SASTOKEN_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const uploadVideoApi = (payload) => {
  let url = payload?.params?.url;
  let body = payload?.params?.blob;

  return APIUtils.put(url, {
    body: body,
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": "video/mp4",
    },
  });
};

export const createSubmissionApi = (payload) => {
  return APIUtils.post(END_POINTS.CREATE_SUBMISSION_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const createSubmissionTiktokApi = (payload) => {
  return APIUtils.post(END_POINTS.CREATE_SUBMISSION_TIKTOK_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getSingersApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_SINGERS_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const deleteSubmissionApi = (payload) => {
  let url = `${END_POINTS.DELETE_SUBMISSION_URL}/${payload?.submissionId}`;
  return APIUtils.delete(url, {
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getSettingsApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_SETTINGS_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const createSettingsApi = (payload) => {
  let url = `${END_POINTS.CREATE_SETTINGS_URL}/${payload.settingType}`;
  return APIUtils.post(url, {
    body: payload.body,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getMySubApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_MY_SUB_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getMyPointsApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_MY_POINTS_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      CampaignId: CAMPAIGNID,
      tenantId: TENANTID_SCAN_BILL,
    },
  });
};

export const getDetailSubApi = (payload) => {
  let url = `${END_POINTS.GET_DETAIL_SUB_URL}/${payload.subId}`;
  return APIUtils.get(url, {
    params: {},
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getListSubApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_LIST_SUB_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getTopSubApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_TOP_SUB_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const addOrUnSocialLinkApi = (payload) => {
  let url = `${END_POINTS.ADD_OR_UN_SOCIAL_LINK_URL}/${payload?.type}`;
  let body = {
    socialType: payload?.socialType,
    submissionId: payload?.submissionId,
    url: payload?.url,
  };
  return APIUtils.post(url, {
    body: body,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getBillPointApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_BILL_POINT_URL, {
    params: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyLoyalty,
      CampaignId: CAMPAIGNID,
    },
  });
};

export const getTop10AllApi = async (payload) => {
  const res = await axios.get(
    `${END_POINTS.GET_TOP_10_ALL_JSON_URL}?v=${Date.now()}`
  );
  return res.data;
};

export const getTop10WebApi = async (payload) => {
  const res = await axios.get(
    `${END_POINTS.GET_TOP_10_WEB_JSON_URL}?v=${Date.now()}`
  );
  return res.data;
};

export const getHighestPointApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_HIGHEST_POINT_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const likeOrDislikeApi = (payload) => {
  let url = `${END_POINTS.LIKE_OR_DISLIKE_URL}/${payload?.subId}/${payload?.type}`;
  return APIUtils.put(url, {
    body: {},
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getFullLinkApi = (payload) => {
  return APIUtils.post(END_POINTS.GET_FULL_LINK_URL, {
    body: payload,
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const publicVideoDetailApi = (payload) => {
  let url = `${END_POINTS.PUBLIC_VIDEO_DETAIL}/${payload?.subId}`;
  return APIUtils.put(url, {
    body: {},
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};

export const getPublicVideoApi = (payload) => {
  return APIUtils.get(END_POINTS.GET_PUBLIC_VIDEO_URL + payload, {
    params: {},
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKeyDuet,
    },
  });
};
