// @ts-nocheck
import CommingSoonCountDown from "components/commingsoon-count-down";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import TheBanner from "layout/TheBanner/TheBanner";
import $ from "jquery";
import TheFooter from "layout/TheFooter";
import moment from "moment";
import ReactPaginate from "react-paginate";

import reactUtils from "utils/reactUtils";
const ScanBillHistoryView = (props) => {
  const {
    listSub,
    pages,
    onChangePage,
    pageOffset,
    searchText,
    setSearchText,
    onSearchSub,
    billPoint,
    setModalImages,
    historyData,
    setImageZoom,
    setIsBillTrue,
  } = props;
  const navigate = useNavigate();

  const handlePageChange = (event) => {
    onChangePage(event.selected);
    console.log(event);
  };

  return (
    <section>
      <div className="bgr-green des-green">
        <br />
        <div className="scanBill container d-none">
          <div className="box-warper list-default">
            <ul>
              <li>
                <p>Hóa đơn đã chụp:</p>
                <p>{reactUtils.formatNumber(billPoint?.totalBills || 0)}</p>
              </li>
              <li>
                <p>Số hóa đơn hợp lệ/ đang chờ duyệt: </p>
                <p>
                  {reactUtils.formatNumber(
                    billPoint?.numOfValidOrWaitingApproveBills || 0
                  )}
                </p>
              </li>
              <li>
                <p>Số hóa đơn không hợp lệ:</p>
                <p>
                  {reactUtils.formatNumber(billPoint?.numOfInvalidBills || 0)}
                </p>
              </li>
            </ul>
          </div>
          <div className="btn-wrapper">
            <button
              onClick={() => {
                navigate("/scanbill");
              }}
              type="button"
              className="btn btn-primary btn-block"
            >
              <a>Chụp hóa đơn nhận điểm</a>
            </button>
            <button
              onClick={() => {
                setIsBillTrue(true);
              }}
              type="button"
              className="btn btn-warning btn-block"
            >
              <a>ĐIỀU KIỆN HÓA ĐƠN HỢP LỆ</a>
            </button>
          </div>
          {historyData?.data?.length > 0 && <div className="line"></div>}
        </div>
        <div className="list-hisbill container">
          <ul>
            {historyData?.data?.length > 0 &&
              historyData?.data.map((el) => {
                return (
                  <li key={el?.invoiceHeaderId}>
                    <div className="img-bill">
                      <img src={el?.fullPath} />
                      <div
                        className="zoom-modal"
                        onClick={() => {
                          setImageZoom(el?.fullPath);
                          props.setModalImages(true);
                        }}
                      ></div>
                    </div>
                    <div className="content-his">
                      {["Pending", "Check"].includes(el.status) && (
                        <>
                          <h4>
                            Hóa đơn sẽ được duyệt trong vòng 5 tiếng (từ
                            8h00-20h00 hàng ngày)
                          </h4>
                          <div className="foo-cont">
                            <span className="pending">Đang chờ duyệt</span>
                            <span className="date-time">
                              {moment
                                .utc(el?.createdAt)
                                .local()
                                .format("DD/MM/YYYY - HH:ss")}
                            </span>
                          </div>
                        </>
                      )}

                      {["Valid", "ApprovedByPG", "ApprovedByShopper"].includes(
                        el.status
                      ) && (
                        <>
                          <h4>
                            Hóa đơn đã được duyệt{" "}
                            <span className="tag-point">+{el.point} điểm</span>
                          </h4>
                          <div className="foo-cont">
                            <span className="success">Đã duyệt</span>
                            <span className="date-time">
                              {moment
                                .utc(el?.createdAt)
                                .local()
                                .format("DD/MM/YYYY - HH:ss")}
                            </span>
                          </div>
                        </>
                      )}

                      {el.status === "RejectByPG" && (
                        <>
                          <h4>Hóa đơn bị từ chối vì {el?.reason}</h4>
                          <div className="foo-cont">
                            <span className="cancel">Từ chối</span>
                            <span className="date-time">
                              {moment
                                .utc(el?.createdAt)
                                .local()
                                .format("DD/MM/YYYY - HH:ss")}
                            </span>
                          </div>
                        </>
                      )}

                      {el.status === "Invalid" && (
                        <>
                          <h4>Hóa đơn không hợp lệ hoặc bị mờ</h4>
                          <div className="foo-cont">
                            <span className="cancel">Không hợp lệ</span>
                            <span className="date-time">
                              {moment
                                .utc(el?.createdAt)
                                .local()
                                .format("DD/MM/YYYY - HH:ss")}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
            {/* <li>
              <div className="img-bill">
                <img src="assets/img/guide0.png" />
                <div
                  className="zoom-modal"
                  onClick={() => {
                    props.setModalImages(true);
                  }}
                ></div>
              </div>
              <div className="content-his">
                <h4>
                  Hóa đơn đã được duyệt{" "}
                  <span className="tag-point">+24 điển</span>
                </h4>
                <div className="foo-cont">
                  <span className="success">Đã duyệt</span>
                  <span className="date-time">21/11/2023 - 19:33</span>
                </div>
              </div>
            </li> */}
            {/* <li>
              <div className="img-bill">
                <img src="assets/img/guide0.png" />
                <div className="zoom-modal" onClick={() => {}}></div>
              </div>
              <div className="content-his">
                <h4>
                  Hóa đơn sẽ được duyệt trong vòng 4 tiếng (từ 8h00-20h00 hàng
                  ngày)
                </h4>
                <div className="foo-cont">
                  <span className="pending">Đang chờ duyệt</span>
                  <span className="date-time">21/11/2023 - 19:33</span>
                </div>
              </div>
            </li>
            <li>
              <div className="img-bill">
                <img src="assets/img/guide0.png" />
                <div className="zoom-modal" onClick={() => {}}></div>
              </div>
              <div className="content-his">
                <h4>Hóa đơn không hợp lệ hoặc bị mờ</h4>
                <div className="foo-cont">
                  <span className="cancel">Từ chối</span>
                  <span className="date-time">21/11/2023 - 19:33</span>
                </div>
              </div>
            </li>
            <li>
              <div className="img-bill">
                <img src="assets/img/guide0.png" />
                <div className="zoom-modal" onClick={() => {}}></div>
              </div>
              <div className="content-his">
                <h4>
                  Hóa đơn sẽ được duyệt trong vòng2 tiếng (từ 9h00-22h00 hàng
                  ngày)
                </h4>
                <div className="foo-cont">
                  <span className="pending">Đang chờ duyệt</span>
                  <span className="date-time">21/11/2023 - 19:33</span>
                </div>
              </div>
            </li> */}
          </ul>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <span>không có dữ liệu</span>
            </div> */}
          {/* {listSub?.length > 0 && (
            <ReactPaginate
              previousLabel=""
              nextLabel=""
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pages || 0}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName="pagination container"
              activeClassName="active"
              forcePage={pageOffset}
            />
          )} */}
        </div>
        <TheFooter />
      </div>
    </section>
  );
};
export default ScanBillHistoryView;
